<template>
  <div class="content">
    <page-loading-animation :displayed="pageLoadingAnimationDisplayed()"></page-loading-animation>
    <v-dialog
      v-model="studentMedicalConditionsLoadingAnimationDisplayed"
      persistent
      max-width="600"
    >
      <v-card>
        <v-card-text>
          <div>
            Standby whilst we retrieve student medical data from your student information system.
            <br>This may take some time as we need to retrieve data
            for each individual student selected.
            <br>Please report any errors to our support team.
          </div>
          <div style="margin-top:20px; text-align:center;">
            <v-progress-circular
              :size="70"
              color="rgb(29, 175, 236)"
              class="loading-img"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="excursion-stepper" v-show="!pageLoadingAnimationDisplayed()">
      <v-layout
        v-show="clientLogoMarginDisplayed()"
        :class="vendorClientLogoEnabled ? 'mt-10' : 'mt-6'"
      >
      </v-layout>
      <v-layout xm xs v-show="vendorClientLogoEnabled && userAuthenticated">
        <div class="client-logo">
          <img
            id="clientLogo"
            :style="{ background: clientLogoBackgroundColor }"
          >
        </div>
      </v-layout>
      <form class="mt-10" v-if="showError || !userAuthenticated">
        <v-layout class="mb-20">
          <v-img max-width="280px" :src="stbHorizontalLogo"></v-img>
        </v-layout>
        <v-layout class="mt-2 mb-3">
          <v-flex>
            You are not authorized to view SafeTrip Builder,
            please provide a correct auth token.
          </v-flex>
        </v-layout>
      </form>
      <form
        id="form"
        :class="vendorClientLogoEnabled ? 'mt-10' : ''"
        v-if="!showError && userAuthenticated"
      >
        <v-stepper v-model="step" vertical class="stepper-padding-bottom">
          <v-layout>
            <v-flex></v-flex>
          </v-layout>
          <v-layout class="safe-trip-title" v-show="stbLogoDisplayed">
            <v-img id="clientStepLogo" max-width="280px" :src="stbHorizontalLogo">
            </v-img>
          </v-layout>
          <v-layout class="safe-trip-title-remark">
            <v-flex class="excursion-page-title-heading">
              <i class="fas fa-info-circle"></i>
              Fill in your excursion details and complete checklists to
              produce your excursion risk assessment
            </v-flex>
          </v-layout>

          <v-stepper-step :complete="step > 1" step="1">
            <div
              @click="step = 1"
              :class="(this.step >= 1) ? 'safe-trip-step-header' : ''"
            >
              {{excursionStepperHeadingLabel.EXCURSION_DETAILS}}
            </div>
          </v-stepper-step>

          <v-stepper-content step="1" id="step1">
            <v-layout wrap v-if="vendorExcursionSidInputEnabled">
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Manager ID
                <span class="red--text">&ast;</span>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  id="excursion-stepper--excursion-manager-id"
                  solo
                  placeholder="Excursion Manager ID"
                  class="name-textbox excursion-input safe-excursion-detail-text"
                  v-model="vendorExcursionSid"
                  :error-messages="vendorExcursionSidErrors"
                  @input="$v.vendorExcursionSid.$touch()"
                  @blur="$v.vendorExcursionSid.$touch()"
                  required
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Name
                <span class="red--text">&ast;</span>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  id="excursion-stepper--excursion-name"
                  solo
                  placeholder="Name"
                  class="name-textbox safe-trip-font excursion-input safe-excursion-detail-text"
                  v-model="name"
                  :error-messages="nameErrors"
                  required
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Type
                <span class="red--text">&ast;</span>
                <v-tooltip right color="#ffffff"  max-width="650px">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn v-on="{ ...tooltip }" class="provider-tooltip-button" icon>
                      <i class="fas fa-info-circle"></i>
                    </v-btn>
                  </template>
                  <div class="type-tooltip-content">
                    <div
                      v-for="(excursionTypeInfoEntry, index) in excursionTypeInfoEntryList"
                      :key="index"
                    >
                      <!-- eslint-disable-next-line max-len -->
                      <b>{{excursionTypeInfoEntry.label}}</b> - {{excursionTypeInfoEntry.description}}
                    </div>
                  </div>
                </v-tooltip>
              </v-flex>
              <v-flex xs12 d-flex>
                <v-select
                  id="excursion-stepper--excursion-type"
                  class="type-dropdown safe-trip-font excursion-selection
                    safe-excursion-detail-text"
                  v-model="excursionType"
                  :items="excursionTypeChoiceList"
                  item-text="name"
                  item-value="id"
                  solo
                  :label="'Choose an Excursion Type'"
                  :error-messages="selectErrors"
                  required
                  @blur="$v.excursionType.$touch()"
                  @change="resetJurisdiction()"
                  :menu-props="{
                    closeOnClick: false,
                    closeOnContentClick: false,
                    openOnClick: false,
                    maxHeight: 400
                  }"
                  :disabled="!formControlEnabled"
                  :loading="excursionTypeLoaded"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="showJurisdiction">
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Destination
                <span class="red--text">&ast;</span>
                <v-menu open-on-hover right offset-x max-width="650px">
                  <template v-slot:activator="{ on: menu }">
                    <v-btn v-on="{ ...menu }" class="provider-tooltip-button" icon>
                      <i class="fas fa-info-circle"></i>
                    </v-btn>
                  </template>
                  <v-list class="jurisdiction-tooltip-content">
                    <v-list-item>
                      <p class="py-2 mb-0">
                        {{jurisdictionTooltip}}
                        Click
                        <a href="https://complispace-jira.atlassian.net/wiki/spaces/CS/pages/448056/STB+Excursion+Details" target="_blank">here</a>
                        to learn more.
                      </p>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
              <v-flex xs12 d-flex>
                <v-select
                  id="excursion-stepper--excursion-jurisdiction"
                  class="type-dropdown safe-trip-font excursion-selection
                    safe-excursion-detail-text"
                  v-model="jurisdiction"
                  :items="jurisdictions"
                  solo
                  :label="'Choose an Excursion State'"
                  :error-messages="selectErrors"
                  required
                  :menu-props="{
                    closeOnClick: false,
                    closeOnContentClick: false,
                    openOnClick: false,
                    maxHeight: 400
                  }"
                  :disabled="!formControlEnabled"
                  :loading="excursionJurisdictionLoaded"
                ></v-select>
              </v-flex>
            </v-layout>
            <v-btn
              class="normal-btn white--text continue-btn primary"
              :disabled="
                $v.$invalid
                || excursionSaveActionInProgress
                || unselectedJurisdiction
              "
              id="btnStep1Continue"
              @click.native="
                saveDraftByExcursionStepperHeading(excursionStepperHeadingLabel.EXCURSION_DETAILS)
              "
            >
            <v-icon v-if="buttonLoaderDisplayed">fa fa-spinner fa-pulse</v-icon>
              {{buttonContinueText}}
            </v-btn>
            <v-btn
              id="excursion-stepper--excursion-step1-clear-and-restart"
              class="normal-btn"
              @click.stop="resetConfirmationDialogDisplayed = true"
            >Clear and Restart</v-btn>
            <v-tooltip
              v-if="displayExcursionSyncButton()"
              top
              color="#ffffff"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  v-on="{ ...tooltip }"
                  class="normal-btn white--text btnSave continue-btn primary"
                  id="excursion-stepper--excursion-sync-button"
                  :disabled="excursionSyncActionInProgress || !syncAvailable"
                  @click.native="excursionSyncConfirmationDialogDisplayed = true"
                  name="excursion-stepper--excursion-sync-button"
                >
                  <v-icon v-if="excursionSyncActionInProgress">fa fa-spinner fa-pulse</v-icon>
                  Synchronise
                </v-btn>
              </template>
              <span class="submit-tooltip-content">Synchronise Excursion Management Data</span>
            </v-tooltip>
          </v-stepper-content>
          <v-stepper-step
            v-if="excursionHighRiskStudentMedicalEnabled"
            :complete="step > highRiskStudentMedicalStep"
            :step="highRiskStudentMedicalStep"
          >
            <div
              @click="turnToStep($v.$invalid, highRiskStudentMedicalStep)"
              :class="(this.step >= highRiskStudentMedicalStep) ? 'safe-trip-step-header' : ''"
            >
              {{excursionStepperHeadingLabel.STUDENT_MEDICAL_AND_RATIOS}}
            </div>
          </v-stepper-step>
          <v-stepper-content
            v-if="excursionHighRiskStudentMedicalEnabled"
            :step="highRiskStudentMedicalStep"
            id="step2"
            style="height:auto;"
          >
            <v-layout v-if="step === highRiskStudentMedicalStep">
              <v-flex>
                <risk-category
                  :riskCategories="excursionHighRisks"
                  :formControlEnabled="formControlEnabled"
                  :saveDraft="saveDraftOnMandatory"
                />
                <staff-student-supervision
                  :formControlEnabled="formControlEnabled"
                ></staff-student-supervision>
                <hr>
              </v-flex>
            </v-layout>
            <v-btn
              class="normal-btn white--text continue-btn primary"
              :disabled="
                $v.$invalid
                || excursionSaveActionInProgress
                || mandatoryLoadActionCompleted
              "
              id="btnStep2Continue"
              @click.native="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.STUDENT_MEDICAL_AND_RATIOS
                )
              "
            >
              <v-icon v-if="buttonLoaderDisplayed">fa fa-spinner fa-pulse</v-icon>
              {{buttonContinueText}}
            </v-btn>
            <v-btn
              id="excursion-stepper--excursion-step2-clear-and-restart"
              class="normal-btn"
              :disabled="mandatoryLoadActionCompleted"
              @click.stop="resetConfirmationDialogDisplayed = true"
            >Clear and Restart</v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="step > (highRiskStudentMedicalStep + 1)"
            :step="highRiskStudentMedicalStep + 1"
          >
            <div
              @click="turnToStep($v.$invalid, (highRiskStudentMedicalStep + 1))"
              :class="
                (this.step >= (highRiskStudentMedicalStep + 1))
                ? 'safe-trip-step-header'
                : ''
              "
            >
              {{excursionStepperHeadingLabel.MANDATORY_EXCURSION_CHECKLISTS}}
            </div>
          </v-stepper-step>
          <v-stepper-content
            :step="highRiskStudentMedicalStep + 1"
            id="step3"
            style="height:auto;"
          >
            <v-layout v-if="step === (highRiskStudentMedicalStep + 1)">
              <v-flex>
                <mandatory-checklist
                  :saveDraft="saveDraftOnMandatory"
                  :formControlEnabled="formControlEnabled"
                />
              </v-flex>
            </v-layout>
            <v-btn
              class="normal-btn white--text continue-btn primary"
              :disabled="
                $v.$invalid
                || excursionSaveActionInProgress
                || mandatoryLoadActionCompleted
              "
              id="btnStep3Continue"
              @click.native="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.MANDATORY_EXCURSION_CHECKLISTS
                )
              "
            >
              <v-icon v-if="buttonLoaderDisplayed">fa fa-spinner fa-pulse</v-icon>
              {{buttonContinueText}}
            </v-btn>
            <v-btn
              id="excursion-stepper--excursion-step3-clear-and-restart"
              class="normal-btn"
              :disabled="mandatoryLoadActionCompleted"
              @click.stop="resetConfirmationDialogDisplayed = true"
            >Clear and Restart</v-btn>
          </v-stepper-content>
          <div>
            <v-dialog
              v-model="riskCategoryChecklistsSkipConfirmationDialogDisplayed"
              persistent
              max-width="490"
            >
              <v-card>
                <v-card-text>
                  Please note, you are continuing without completing all checklists items.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="normal-btn white--text primary"
                    @click.native="saveDraftByRiskCategoriesSection()"
                    id="btnContinue"
                  >Continue</v-btn>
                  <v-btn
                    id="excursion-stepper--risk-cateogry-confirmation-cancel"
                    class="normal-btn"
                    @click="riskCategoryChecklistsSkipConfirmationDialogDisplayed = false"
                  >Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <v-stepper-step
            :complete="step > (highRiskStudentMedicalStep + 2)"
            :step="highRiskStudentMedicalStep + 2"
          >
            <div
              @click="turnToStep($v.$invalid, (highRiskStudentMedicalStep + 2))"
              :class="
                (this.step >= (highRiskStudentMedicalStep + 2))
                ? 'safe-trip-step-header'
                : ''
              "
            >
              {{excursionStepperHeadingLabel.RISK_CATEGORIES}}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="highRiskStudentMedicalStep + 2" id="step4">
            <v-layout v-if="step === (highRiskStudentMedicalStep + 2)">
              <v-flex>
                <v-flex class="update-provider-stepper" v-if="providerUpdateActionDisplayed">
                  <v-flex class="update-provider-stepper-text">
                    <i class="fas fa-info-circle"></i>
                    Click the Update Providers button for any immediate updates that are required.
                  </v-flex>
                  <v-flex class="update-provider-stepper-button">
                    <update-providers
                      :buttonUpdateProvidersEnabled=true
                    >
                    </update-providers>
                  </v-flex>
                </v-flex>
                <hr v-if="providerUpdateActionDisplayed">
                <v-flex class="riskManifest">
                  <i class="fas fa-info-circle"></i>
                  <span>The SafeTripBuilder risk manifest is available</span>
                  <span class="riskManifestLink">
                    <a
                      href="/public/SafeTripBuilder - Risk Categories Manifest.pdf"
                      target="_blank"
                    >here</a>
                  </span>
                  <span>
                    if you want to view all supported risk selections.
                  </span>
                </v-flex>
                <risk-category
                  :riskCategories="risks"
                  :formControlEnabled="formControlEnabled"
                  :saveDraft="saveDraftOnMandatory"
                />
                <hr>
                <export-excursion-policy
                  style="margin-top:24px;margin-left:-10px"
                  :excursionPolicyExportActionEnabled="
                    $v.$invalid
                    || !riskCategoriesValid
                  "
                  :submitExcursion="submit"
                  :valuesChanged="valuesChanged"
                  :excursionBuilderViewDisplayed="false"
                ></export-excursion-policy>
              </v-flex>
            </v-layout>
            <v-alert
              v-if="!activitySelectionValid"
              style="margin-top:15px;"
              color="#00adef"
              icon="info"
              outlined
            >
             An Activity has been selected in the excursion proposal.
             Please complete the Activity selection by selecting the individual risks.
            </v-alert>
            <v-layout>
              <v-btn
                class="normal-btn white--text btn-margin-0 continue-btn primary"
                style="margin-top:30px;"
                :disabled="
                  $v.$invalid
                  || !riskCategoriesValid
                  || excursionSaveActionInProgress
                  || !selectTypeValid
                  || !activitySelectionValid
                  || checklistFetchActionInProgress
                "
                id="btnStep4Continue"
                @click.native="
                  saveDraftByExcursionStepperHeading(excursionStepperHeadingLabel.RISK_CATEGORIES)
                "
              >
                <v-icon v-if="buttonLoaderDisplayed">fa fa-spinner fa-pulse</v-icon>
                {{buttonContinueText}}
              </v-btn>
              <v-btn
                id="excursion-stepper--excursion-step4-clear-and-restart"
                class="normal-btn"
                style="margin-top:30px;"
                @click.stop="resetConfirmationDialogDisplayed = true"
              >Clear and Restart</v-btn>
            </v-layout>
          </v-stepper-content>

          <v-stepper-step
            v-if="excursionAdditionalInformationEnabled"
            :complete="step > additionalInformationStep"
            :step="additionalInformationStep"
          >
            <div
              @click="turnToStep($v.$invalid,additionalInformationStep)"
              :class="(this.step >= additionalInformationStep) ? 'safe-trip-step-header' : ''"
            >
              {{excursionStepperHeadingLabel.ADDITIONAL_INFORMATION}}
            </div>
          </v-stepper-step>
          <v-stepper-content
            v-if="excursionAdditionalInformationEnabled"
            :step="additionalInformationStep"
            id="step5"
          >
            <v-flex xs12 v-if="clientTemplate.sid">
              <v-layout wrap>
                <v-flex xs12 class="form-label safe-trip-font">
                  Pro Forma Template
                </v-flex>
                <v-flex xs12>
                  {{clientTemplate.filename}}
                  <v-icon @click="downloadFile(clientTemplate)"
                    medium
                    style="font-size:15px;height:17px;margin-left:.7%;"
                  >fa fa-download</v-icon>
                </v-flex>
              </v-layout>
            </v-flex>
            <additional-info-list
              :additionalInformationItems="additionalInformationItems"
              @setAdditionalInformationValid = "setAdditionalInformationValid"
              :formControlEnabled="formControlEnabled"
              ref="additionalInfoList"
            ></additional-info-list>
            <v-btn
              class="normal-btn white--text continue-btn primary"
              :disabled="
                $v.$invalid
                || !additionalInformationValid
                || excursionAttachmentUploadActionInProgress
                || excursionSaveActionInProgress
              "
              id="btnStep5Continue"
              @click.native="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.ADDITIONAL_INFORMATION
                )
              "
            >
              <v-icon v-if="buttonLoaderDisplayed">fa fa-spinner fa-pulse</v-icon>
              {{buttonContinueText}}
            </v-btn>
            <v-btn
              id="excursion-stepper--excursion-step5-clear-and-restart"
              class="normal-btn"
              @click.stop="resetConfirmationDialogDisplayed = true"
            >Clear and Restart</v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="step > (additionalInformationStep + 1)"
            :step="additionalInformationStep + 1"
          >
            <div
              @click="turnToStep($v.$invalid,additionalInformationStep + 1)"
              :class="(this.step >= (additionalInformationStep + 1)) ? 'safe-trip-step-header' : ''"
            >
              {{excursionStepperHeadingLabel.EXCURSION_RISK_ASSESSMENT}}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="additionalInformationStep + 1" id="step6">
            <v-layout class="mt-3" v-if="excursionInherentRiskRatingDetected">
              <risk-rating-summary
                  headingLabel="Inherent Rating"
                  :tooltipBubbleText="inherentRiskRatingTooltipBubbleText"
                  riskRatingRefname="inherent"
              ></risk-rating-summary>
            </v-layout>
            <v-layout class="mt-3">
              <risk-rating-summary
                  :headingLabel="residualRiskRatingSummaryHeadingLabel"
                  :tooltipBubbleText="residualRiskRatingTooltipBubbleText"
                  riskRatingRefname="residual"
              ></risk-rating-summary>
            </v-layout>
            <v-layout align-start justify-start class="create-risk-assessment">
              <div>
                <export-excursion-risk-assessment
                  :excursionPolicyExportActionEnabled="$v.$invalid || !riskCategoriesValid"
                  :registerRiskAssessmentExportEvent="registerRiskAssessmentExportEvent"
                ></export-excursion-risk-assessment>
              </div>
              <div>
                <view-predeparture-checklist
                  :excursionPolicyExportActionEnabled="$v.$invalid"
                  :formControlEnabled="formControlEnabled"
                ></view-predeparture-checklist>
              </div>
            </v-layout>
            <v-btn
              class="normal-btn white--text continue-btn primary"
              :disabled="$v.$invalid || excursionSaveActionInProgress"
              id="btnStep6Continue"
              @click.native="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.EXCURSION_RISK_ASSESSMENT
                )
              "
            >
              <v-icon v-if="buttonLoaderDisplayed">fa fa-spinner fa-pulse</v-icon>
              {{buttonContinueText}}
            </v-btn>
            <v-btn
              id="excursion-stepper--excursion-step6-clear-and-restart"
              class="normal-btn"
              @click.stop="resetConfirmationDialogDisplayed = true"
            >Clear and Restart</v-btn>
          </v-stepper-content>

          <v-stepper-step
            :complete="step > (additionalInformationStep + 2)"
            :step="additionalInformationStep + 2"
          >
            <div
              @click="turnToStep($v.$invalid, additionalInformationStep + 2)"
              :class="(this.step >= (additionalInformationStep + 2)) ? 'safe-trip-step-header' : ''"
            >
              {{excursionStepperHeadingLabel.EXCURSION_RISK_MANAGEMENT_CONFIRMATION}}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="additionalInformationStep + 2" id="step7">
            <v-flex class="confirmation-info-text">
              <i class="fas fa-info-circle"></i>
              Confirm acknowledgement by reading, agreeing and ticking below.
            </v-flex>
            <excursion-confirmation></excursion-confirmation>
            <v-layout>
              <v-btn
                :disabled="
                  $v.$invalid
                  || !agreementConfirmationTicked
                  || excursionSaveActionInProgress
                  "
                  class="normal-btn white--text btnSave continue-btn save-excurison-button primary"
                  id="btnSave"
                  name="btnSave"
                  @click.native="
                  saveDraftByExcursionStepperHeading(
                    excursionStepperHeadingLabel.EXCURSION_RISK_MANAGEMENT_CONFIRMATION
                  )
                "
              >Save</v-btn>
              <v-btn
                class="normal-btn"
                @click.native="resetConfirmationDialogDisplayed = true"
                id="btnClearAndRestart"
              >Clear and Restart</v-btn>
            </v-layout>
          </v-stepper-content>
          <!-- Excursion History -->
          <excursion-history v-if="excursionHistoryDisplayed"/>
        </v-stepper>
        <div>
          <v-dialog v-model="genericDialogDisplayed" persistent max-width="490">
            <v-card>
              <v-card-title class="headline center">{{dialogMessage}}</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="excursion-stepper--generic-dialog-ok"
                  @click.native="redirectToExcursionBuilder"
                  testing-id="btn-redirect-to-excursion-builder"
                  class="normal-btn white--text primary"
                >OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-dialog v-model="resetConfirmationDialogDisplayed" persistent max-width="490">
            <v-card>
              <v-card-text>Are you sure you want to clear and restart
              your excursion? Doing so will clear all data.
              <br /><br />
              Are you sure you want to continue?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="normal-btn white--text primary"
                  @click.native="clear()"
                  id="btnClear"
                >Clear and Restart</v-btn>
                <v-btn
                  id="excursion-stepper--reset-dialog-cancel"
                  class="normal-btn"
                  @click="resetConfirmationDialogDisplayed = false"
                >Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-dialog v-model="excursionTypeWarningDisplayed" persistent max-width="490">
            <v-card>
              <v-card-text>
                You are about to change your Excursion Type after previously saving your selection.
                Doing so will clear your excursion and you will have to re-enter your details.
                It will keep other information except risk categories.<br /><br />
                Are you sure you want to continue?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  id="excursion-stepper--excursion-type-warning-dialog-ok"
                  class="normal-btn white--text primary"
                  @click.native="
                    excursionTypeWarningDisplayed = false;
                    clearCategoriesOnExcursionTypeUpdate();
                  "
                  testing-id="btn-excursion-type-warning-ok"
                >OK</v-btn>
                <v-btn
                  id="excursion-stepper--excursion-type-warning-dialog-cancel"
                  class="normal-btn"
                  @click.native="
                    excursionTypeWarningDisplayed = false;
                    cancelExcursionTypeUpdate();
                  "
                  testing-id="btn-excursion-type-warning-cancel"
                >Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-dialog
            v-model="mandatoryChecklistsSkipConfirmationDialogDisplayed"
            persistent
            max-width="490"
          >
            <v-card>
              <v-card-text>
                Please note, you are continuing without completing all checklists items.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="normal-btn white--text primary"
                  @click.native="saveDraftByMandatoryChecklistsSection()"
                  testing-id="step2-confirmation-continue"
                  id="btnContinue"
                  :disabled = "mandatoryChecklistsContinueButtonDisabled"
                >Continue</v-btn>
                <v-btn
                  id="excursion-stepper--mandatory-checklist-dialog-cancel"
                  class="normal-btn"
                  @click="mandatoryChecklistsSkipConfirmationDialogDisplayed = false"
                >Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-dialog
            v-model="highRiskChecklistsSkipConfirmationDialogDisplayed"
            persistent
            max-width="490"
          >
            <v-card>
              <v-card-text
                v-if="
                highRiskChecklistsMessageDisplayed
                && !highRiskNumbersMessageDisplayed
                "
              >
                Please note, you are continuing without completing all checklists items.
              </v-card-text>
              <v-card-text
                v-if="
                  !highRiskChecklistsMessageDisplayed
                  && highRiskNumbersMessageDisplayed
                "
              >
                {{StaffAndSuperVisionAndStudentNumberMessasge}}
              </v-card-text>
              <v-card-text
                v-if="
                  highRiskChecklistsMessageDisplayed
                  && highRiskNumbersMessageDisplayed
                "
              >
                Please note, you are continuing without completing
                <ul>
                  <li>All checklists items</li>
                  <li>Staff / supervision and student numbers</li>
                </ul>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="normal-btn white--text primary"
                  @click.native="saveDraftByStudentMedicalSection()"
                  testing-id="step2-confirmation-continue"
                  id="btnContinue"
                >Continue</v-btn>
                <v-btn
                  id="excursion-stepper--high-risk-checklist-dialog-cancel"
                  class="normal-btn"
                  @click="highRiskChecklistsSkipConfirmationDialogDisplayed = false"
                >Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-dialog v-model="excursionSyncConfirmationDialogDisplayed" persistent max-width="490">
            <v-card>
              <v-card-text>
                This will reset the excursion and you will lose all information.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="normal-btn white--text primary"
                  @click.native="excursionSync()"
                  id="btnExcursionSyncchroniseConfirm"
                >Yes</v-btn>
                <v-btn
                  class="normal-btn"
                  @click="excursionSyncConfirmationDialogDisplayed = false"
                >No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <v-dialog
            v-model="activityRiskReqiredMessageDisplayed"
            persistent
            max-width="490"
          >
            <v-card>
              <v-card-text>
                An Activity has been selected in the excursion proposal.<br/>
                Please complete the Activity selection by selecting the individual risks.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="normal-btn white--text primary"
                  @click.native="activityRiskReqiredMessageDisplayed = false"
                  id="btnContinue"
                >OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div>
          <excursion-copy-modal
            :propExcursionCopyModalRefreshed="false"
            :excursionTypeName="''"
            :excursionId="`${$route.params.id}`"
            :excursionTemplateFlag="false"
            :propCopyExcursion="copyExcursion"
            v-if="excursionCopyModalDisplayed"
          ></excursion-copy-modal>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
@import "../assets/css/components/_excursion-stepper.css";
</style>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import excursionJurisdictions from "../lib/const/excursionJurisdictions";
import excursionStatus from "../lib/const/excursionStatus";
import excursionStepperHeading from "../lib/const/excursionStepperHeading";
import excursionTypeInfo from "../lib/const/excursionTypeInfo";
import riskCategoryLabel from "../lib/const/riskCategoryLabel";
import trackableEvent from "../lib/const/trackableEvent";
import stbHorizontalLogo from '../../public/StbHorizontalLogo.png';

export default {
  mixins: [validationMixin],

  validations: {
    name: {
      required,
    },
    excursionType: {
      required,
    },
    vendorExcursionSid: {
      required,
    },
  },

  props: {
    propHasParentComponent: Boolean,
  },

  data() {
    return {
      stbHorizontalLogo,
      genericDialogDisplayed: false,
      riskCategories: [],
      resetConfirmationDialogDisplayed: false,
      mandatoryChecklistsSkipConfirmationDialogDisplayed: false,
      riskCategoryChecklistsSkipConfirmationDialogDisplayed: false,
      highRiskChecklistsSkipConfirmationDialogDisplayed: false,
      highRiskChecklistsMessageDisplayed: false,
      highRiskNumbersMessageDisplayed: false,
      highRiskChecklistsAllTicked: false,
      excursionId: 0,
      pdf: {
        title: "master excursion risk assessment - sample",
        path: "doc",
      },
      excursionSaveSuccessful: false,
      mandatoryChecklistSaveActionPerformed: false,
      excursionTypeWarningDisplayed: false,
      additionalInformationValid: true,
      buttonLoaderDisplayed: false,
      excursionAttachmentUploadActionInProgress: false,
      excursionStatusChanged: false,
      errorMessage: "",
      currentExcursion: {},
      excursionSaveActionInProgress: false,
      valuesChanged: false,
      firstTimeLoaded: false,
      excursionSyncConfirmationDialogDisplayed: false,
      excursionSyncActionInProgress: false,
      jurisdictionTooltip: "The content of the destination state should be considered when travelling interstate. Given that we research content across each State and Territory's Department of Education, in some cases there may be additional information to consider even if you are only travelling interstate for a day excursion.",
      activityRiskReqiredMessageDisplayed: false,
      buttonContinueText: "Save and Continue",
      StaffAndSuperVisionAndStudentNumberMessasge: "Please note, you are continuing without completing staff / supervision and student numbers.",
      mandatoryChecklistsContinueButtonDisabled: false,
    };
  },

  computed: {
    step: {
      get() {
        let currentStep = this.$store.state.excursion.currentStep;
        if (!currentStep) {
          currentStep = 1;
        }

        return currentStep;
      },
      set(value) {
        this.$store.commit("updateExcursionStep", value);
      },
    },
    name: {
      get() {
        return this.$store.state.excursion.name;
      },
      set(value) {
        this.$store.commit("updateExcursionName", value);
      },
    },
    providerUpdateActionDisplayed() {
      return this.$store.state.providerUpdateActionDisplayed;
    },
    excursionType: {
      get() {
        return this.$store.state.excursion.typeId;
      },
      set(value) {
        this.$store.commit("updateExcursionType", value);
        if (value) {
          this.$store.commit("resetStoreWhenChangingExcursionType");
        }
      },
    },
    jurisdiction: {
      get() {
        return ("" === this.$store.state.excursion.jurisdiction || !this.$store.state.excursion.jurisdiction)
          ? this.$store.state.apiDataDefaultJurisdiction : this.$store.state.excursion.jurisdiction;
      },
      set(value) {
        this.$store.commit("updateExcursionJurisdiction", value);
      },
    },
    vendorExcursionSid: {
      get() {
        if (this.vendorExcursionSidInputEnabled) {
          return this.$store.state.excursion.vendorData.vendorExcursionSid;
        }
        return 0;
      },
      set(value) {
        this.$store.commit("updateVendorExcursionId", value);
      },
    },
    vendorExcursionSidErrors() {
      const errors = [];

      if (this.vendorExcursionSidInputEnabled) {
        if (!this.$v.vendorExcursionSid.$dirty) return errors;
        if (!this.$v.vendorExcursionSid.required) {
          errors.push("Excursion Manager ID is required.");
        }
      }

      return errors;
    },
    excursionTypeChoiceList() {
      return Object.keys(this.$store.state.excursionTypeChoiceList);
    },
    showJurisdiction() {
      return this.$app.excursionCommon.showJurisdiction(this.$store.state);
    },
    jurisdictions() {
      return excursionJurisdictions;
    },
    unselectedJurisdiction() {
      return this.$app.excursionCommon.isWithinAustralia(this.$store.state)
        && this.$store.state.excursionDestinationContentEnabled
        && "" === this.$store.state.excursion.jurisdiction
        && "" === this.$store.state.apiDataDefaultJurisdiction;
    },
    excursionHighRiskStudentMedicalEnabled() {
      return this.$store.state.excursionHighRiskStudentMedicalEnabled;
    },
    excursionHighRisks() {
      const allRisks = this.$store.state.allRisks;
      return allRisks
      .filter(r => (r.name === riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS));
    },
    risks() {
      const allRisks = this.$store.state.allRisks;
      return allRisks
      .filter(r => (r.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS));
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.excursionType.$dirty) return errors;
      if (!this.$v.excursionType.required) {
        errors.push("Excursion Type is required");
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) {
        errors.push("Excursion is required.");
      }
      return errors;
    },
    userAuthenticated() {
      return this.$store.state.userAuthenticated;
    },
    userAuthenticationInProgress() {
      return this.$store.state.userAuthenticationInProgress;
    },
    showError() {
      return (this.$store.state.showError ? this.$store.state.showError : false);
    },
    activityCategoryName() {
      return this.$store.state.activitiesCategoryName;
    },
    allSelectedActivityRisk() {
      return this.$store.getters.getSelectedActivityClasses[0];
    },
    additionalInformationItems: {
      get() {
        const localInfoItems = this.$store.state.excursion.additionalInformationItems;

        if (localInfoItems && (0 < localInfoItems.length)) {
          const localInfoItemsAdded = localInfoItems.filter(r => !r.isDeleted);
          if (1 === localInfoItemsAdded.length) {
            localInfoItems.forEach((r) => {
              if (!r.isDeleted) {
                if (r.filename || r.fileSummary || r.comment) {
                  r.showDeleteButton = true;
                }
              }
            });
          } else {
            localInfoItems.forEach((r) => {
              r.showDeleteButton = true;
            });
          }

          return localInfoItems;
        }

        return [
          {
            fileSummary: "",
            comment: "",
            showDeleteButton: false,
          },
        ];
      },
    },
    dialogMessage() {
      if (this.excursionSaveSuccessful) {
        return "Save success";
      }
      let failedErrorMessage = "Save failed";
      if (this.errorMessage) {
        failedErrorMessage = this.errorMessage;
      }
      return failedErrorMessage;
    },
    agreementConfirmationTicked() {
      if (!this.$store.state.excursion.agreementConfirmation) {
        return false;
      }
      return this.$store.state.excursion.agreementConfirmation.ticked;
    },
    vendorExcursionSidInputEnabled() {
      return this.$store.state.vendorExcursionSidInputEnabled;
    },
    excursionAdditionalInformationEnabled() {
      return this.$store.state.excursionAdditionalInformationEnabled;
    },
    additionalInformationStep() {
      return (
        this.excursionAdditionalInformationEnabled
          ? (this.highRiskStudentMedicalStep + 3)
          : (this.highRiskStudentMedicalStep + 2)
      );
    },
    highRiskStudentMedicalStep() {
      return (this.excursionHighRiskStudentMedicalEnabled ? 2 : 1);
    },
    riskCategoriesValid() {
      return (0 < this.$store.getters.getSelectedCategoryCount);
    },
    selectTypeValid() {
      const categoriesResult = this.$store.state.excursion.categories.filter(
        c => (0 < c.types.length),
      );
      return (0 < categoriesResult.length);
    },
    excursionDisplayReady() {
      return this.$store.state.excursionDisplayReady;
    },
    excursionStudentMedicalConditionsDisplayReady() {
      return this.$store.state.excursionStudentMedicalConditionsDisplayReady;
    },
    excursionLogoDisplayReady() {
      return this.$store.state.excursionLogoDisplayReady;
    },
    clientLogoBackgroundColor() {
      return this.$store.state.clientLogoBackgroundColor;
    },
    vendorClientLogoEnabled() {
      return this.$store.state.vendorClientLogoEnabled;
    },
    stbLogoDisplayed() {
      const query = this.$route.query;

      let stbLogoDisplayed = true;
      if (query && query.referrer && ("pcg" === query.referrer.toLowerCase())) {
        stbLogoDisplayed = false;
      }

      return stbLogoDisplayed;
    },
    formControlEnabled() {
      return !(this.$store.state.excursion.status === excursionStatus.APPROVED);
    },
    excursionTypeLoaded() {
      return this.$store.state.excursionTypeLoaded;
    },
    excursionJurisdictionLoaded() {
      return this.$store.state.excursionJurisdictionLoaded;
    },
    mandatoryLoadActionCompleted() {
      return this.$store.state.mandatoryLoadActionCompleted;
    },
    selectedRisks() {
      return JSON.parse(JSON.stringify(this.$store.state.excursion.categories));
    },
    syncAvailable() {
      return this.$store.state.excursion.syncAvailable;
    },
    excursionSyncButtonDisplayed() {
      return this.$store.state.excursionSyncButtonDisplayed;
    },
    activitySelectionValid() {
      return this.$store.state.excursion.activitySelectionValid;
    },
    clientTemplate() {
      return this.$store.state.excursion.clientTemplate;
    },
    highRiskChecklistsNumbersMessageDisplayed() {
      return (
        !this.$store.state.excursion.staffSupervisorAttendanceCount
        || !this.$store.state.excursion.studentAttendanceCount
      );
    },
    excursionHistoryDisplayed() {
      return (0 < this.$store.state.excursion.id);
    },
    checklistFetchActionInProgress() {
      return this.$store.state.checklistFetchActionInProgress;
    },
    excursionCopyModalDisplayed() {
      const excursionId = this.$route.params ? this.$route.params.id : 0;
      return (0 < excursionId) && this.$store.state.excursion.excursionCopyFlag;
    },
    copyExcursion() {
      return this.$app.lodash.cloneDeep(this.$store.state.excursion);
    },
    excursionStepperHeadingLabel() {
      return excursionStepperHeading;
    },
    excursionTypeInfoEntryList() {
      return Object.values(excursionTypeInfo);
    },

    excursionStudentFetchTriggered() {
      return this.$store.state.excursionStudentFetchTriggered;
    },
    studentMedicalConditionsLoadingAnimationDisplayed() {
      return (
        !this.pageLoadingAnimationDisplayed()
        && (
          this.excursionStudentFetchTriggered
          && !this.excursionStudentMedicalConditionsDisplayReady
        )
      );
    },
    excursionRiskOverall() {
      return this.$store.state.excursion.riskOverall;
    },
    excursionInherentRiskRatingEnabled() {
      return this.$store.state.excursionInherentRiskRatingEnabled;
    },
    excursionInherentRiskRatingDetected() {
      this.pageLoadingAnimationDisplayed(); // Use this to force refresh after page load

      const riskOverall = this.excursionRiskOverall;
      const localExcursionInherentRiskRatingDetected = (
        this.excursionInherentRiskRatingEnabled
        && riskOverall
        && riskOverall.residualRiskRating
        && riskOverall.inherentRiskRating
      );

      return localExcursionInherentRiskRatingDetected;
    },
    residualRiskRatingSummaryHeadingLabel() {
      let localResidualRiskRatingSummaryHeadingLabel = "Overall Rating";

      if (this.excursionInherentRiskRatingDetected) {
        localResidualRiskRatingSummaryHeadingLabel = "Residual Rating";
      }

      return localResidualRiskRatingSummaryHeadingLabel;
    },
    residualRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected ? "Residual risk is the level of risk that remains after controls have been applied." : "";
    },
    inherentRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected ? "Inherent risk is the level of risk that exists where no controls have been applied." : "";
    },
  },

  methods: {
    getRiskAssessmentTrackableEventBaseArgHash() {
      const { apiDataDefaultJurisdiction, excursion } = this.$store.state;
      return this.$app.excursionCommon
      .getRiskAssessmentTrackableEventBaseArgHash(apiDataDefaultJurisdiction, excursion);
    },

    getRiskAssessmentTrackableEventOptionArgHash() {
      return this.$app.excursionCommon.getRiskAssessmentTrackableEventOptionArgHash();
    },

    registerRiskAssessmentExcursionDetailsUpdateEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter
      .registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_DETAILS_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
    },

    registerRiskAssessmentExportEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter
      .registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_EXPORT,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
    },

    // TODO: Simplify this to submit(submissionArgHash)
    async submit(
      status,
      statusChanged,
      callback,
      submissionModifier,
      targetExcursionStepperHeading,
    ) {
      if (!this.mandatoryChecklistSaveActionPerformed) {
        this.$v.$touch();
        if (this.$v.$error) return false;
      }

      this.excursionSaveActionInProgress = true;
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));
      const excursionMetadata = JSON.parse(
        JSON.stringify(this.$store.state.excursion),
      );
      this.excursionId = excursionMetadata.id;

      delete excursionMetadata.id;
      delete excursionMetadata.additionalInformationItems;
      delete excursionMetadata.computed;
      delete excursionMetadata.activitySelectionValid;
      delete excursionMetadata.auditList;

      excursionMetadata.name = this.name;
      excursionMetadata.typeId = this.excursionType;

      if (!excursionMetadata.currentStep) {
        await this.setDefaultJurisdiction(excursionMetadata);
      }

      let presaveActionPerformed = false;
      if (submissionModifier && ("downloadExcursionPolicy" === submissionModifier)) {
        presaveActionPerformed = true;
        this.excursionSaveActionInProgress = false;
      }

      let excursionSyncActionPerformed = false;
      if (submissionModifier && ("excursionSync" === submissionModifier)) {
        excursionSyncActionPerformed = true;
        this.excursionSaveActionInProgress = false;
        excursionMetadata.syncEnabled = true;
      }

      if (
        !this.mandatoryChecklistSaveActionPerformed
        && !presaveActionPerformed
        && !excursionSyncActionPerformed
      ) {
        excursionMetadata.currentStep = (this.step + 1);
      }

      const apiPayload = {
        metadata: excursionMetadata,
      };
      let excursionFromApi;

      if ("0" !== `${this.excursionId}`) {
        // Risk Categories
        if (
          ((3 === this.step) && !this.excursionHighRiskStudentMedicalEnabled)
          || (
            ((4 === this.step) || (2 === this.step)) && this.excursionHighRiskStudentMedicalEnabled
          )
        ) {
          const excursionCommonAdapter
            = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
          const excursionRiskCombo
            = await excursionCommonAdapter.getExcursionRiskCombo(this.$route.query);
          if (excursionRiskCombo) {
            apiPayload.metadata.riskOverall = excursionRiskCombo.riskOverall;
            apiPayload.metadata.riskCategories = excursionRiskCombo.riskCategories;
          }
        }

        // set updateSignature
        if (!apiPayload.metadata.updateSignature) {
          apiPayload.metadata.updateSignature = this.$app.uuid.v4();
        }

        const query = JSON.parse(JSON.stringify(this.$route.query));

        if (
          (1 === this.step)
          && query
          && query.referrer
          && ("pcg" === query.referrer.toLowerCase())
          && !this.syncAvailable
        ) {
          apiPayload.metadata.syncEnabled = true;
        }

        const putExcursionsApiArgHash = {
          store: this.$store,
          queryParam: apiQuery,
          id: this.excursionId,
          payload: apiPayload,
        };
        const putExcursionsCbResponse
          = await this.$app.stbApiAdapter.putExcursions(putExcursionsApiArgHash);

        this.$store.dispatch("setActivitySelectionValid");
        this.buttonLoaderDisplayed = false;

        if (callback && ("function" === typeof callback)) {
          await callback();
        }
        if (!this.$store.state.isResponseWithError) {
          if (!putExcursionsCbResponse.error) {
            const metadata = putExcursionsCbResponse.data.metadata;
            this.currentExcursion = putExcursionsCbResponse;

            if (!status && (metadata.status === excursionStatus.SUBMITTED)) {
              this.excursionStatusChanged = true;
              status = excursionStatus.DRAFT;
              metadata.status = status;
            }

            if (
              this.excursionStatusChanged
              && (metadata.status === excursionStatus.DRAFT)
              && statusChanged
            ) {
              const putExcursionStatusesArgHash = {
                queryParam: apiQuery,
                newStatus: status,
                store: this.$store,
                id: this.excursionId,
              };
              const statusResponse
                = await this.$app.stbApiAdapter.putExcursionStatuses(putExcursionStatusesArgHash);
              this.excursionStatusChanged = false;
              if (!statusResponse.error) {
                this.currentExcursion.data.metadata.status = status;
                this.excursionSaveSuccessful = true;
                this.$store.commit("setExcursion", this.currentExcursion);
                this.$store.commit("updateShowExcursionStepper", true);

                if (
                  (status === excursionStatus.SUBMITTED)
                  && !this.mandatoryChecklistSaveActionPerformed
                ) {
                  this.genericDialogDisplayed = true;
                }
              }
            } else {
              this.excursionSaveSuccessful = true;
              if (status && (status === excursionStatus.SUBMITTED)) {
                this.genericDialogDisplayed = true;
              } else {
                this.$store.commit("setExcursion", this.currentExcursion);
                this.$store.commit("updateShowExcursionStepper", true);
              }
            }

            // Handle student medical conditions
            excursionFromApi = putExcursionsCbResponse.data;

            // Remove additional information during sync
            if (apiPayload.metadata.syncEnabled) {
              const localInfoItems = this.$app.lodash.cloneDeep(
                this.$store.state.excursion.additionalInformationItems,
              );
              if (localInfoItems && (0 < localInfoItems.length)) {
                localInfoItems.forEach((a) => {
                  a.isDeleted = true;
                });
              }

              await this.updateAdditionalInformationList(localInfoItems);

              this.$store.commit("setRisksDisabled");
            }
          } else {
            this.excursionSaveSuccessful = false;
            this.genericDialogDisplayed = true;
            this.errorMessage = putExcursionsCbResponse.message;
          }
        }
        this.excursionSaveActionInProgress = false;
      } else {
        const postExcursionsApiArgHash = {
          store: this.$store,
          queryParam: apiQuery,
          payload: apiPayload,
        };
        const postExcursionsCbResponse
          = await this.$app.stbApiAdapter.postExcursions(postExcursionsApiArgHash);
        this.buttonLoaderDisplayed = false;

        if (callback && ("function" === typeof callback)) {
          await callback();
        }
        if (!this.$store.state.isResponseWithError) {
          if (!postExcursionsCbResponse.error) {
            if (this.$store.state.clientTemplate) {
              this.$store.state.excursion.clientTemplate = this.$store.state.clientTemplate;
            }

            excursionFromApi = postExcursionsCbResponse.data;

            // Update the store excursion so subsequent savings send PUT request instead of POST
            this.$store.commit("setExcursion", postExcursionsCbResponse);
            this.excursionSaveSuccessful = true;

            if (!this.mandatoryChecklistSaveActionPerformed) {
              const query = JSON.parse(JSON.stringify(this.$route.query));

              // Update apiParam in the store
              const computedQuery
                = JSON.parse(JSON.stringify(excursionFromApi.computed.query));
              this.$router.replace(`/excursions/${computedQuery["excursion-id"]}`);

              if (query && query["oauth-signature"]) {
                this.$router.replace({ query: computedQuery });
                this.$store.state.apiParam = JSON.parse(JSON.stringify(computedQuery));
              }
            }
          } else {
            this.errorMessage = postExcursionsCbResponse.message;
            this.excursionSaveSuccessful = false;
            this.genericDialogDisplayed = true;
          }
        }
        this.excursionSaveActionInProgress = false;
      }

      if (excursionFromApi) {
        if (excursionFromApi.computed) {
          const excursionStudentFetchTriggered
            = excursionFromApi.computed.excursionStudentFetchTriggered
              ? true
              : false;

          if (excursionStudentFetchTriggered) {
            const query = JSON.parse(JSON.stringify(this.$route.query));

            this.$store.commit("setExcursionStudentFetchTriggered", excursionStudentFetchTriggered);
            this.$store.commit("setExcursionStudentMedicalConditionsDisplayReady", false);

            const excursionIdFromApi = excursionFromApi.id;
            const getExcursionStudentMedicalConditionsApiArgHash = {
              excursionId: excursionIdFromApi,
              query,
              store: this.$store,
            };
            const getExcursionStudentMedicalConditionsCbResponse = await this.$app
            .stbApiAdapter.getExcursionStudentMedicalConditions(
              getExcursionStudentMedicalConditionsApiArgHash,
            );

            const studentMedicalConditions
              = getExcursionStudentMedicalConditionsCbResponse.data.data;
            this.$store.commit("setExcursionStudentMedicalConditions", studentMedicalConditions);
            this.$store.commit("setExcursionStudentMedicalConditionsDisplayReady", true);
          }
        }
      }

      if (excursionStepperHeading.EXCURSION_DETAILS === targetExcursionStepperHeading) {
        this.registerRiskAssessmentExcursionDetailsUpdateEvent();
      }

      this.$nextTick(() => {
        this.valuesChanged = false;
      });

      if (this.excursionSaveSuccessful) {
        return true;
      }

      return false;
    },
    async setDefaultJurisdiction(excursionMetadata) {
      if ("" === excursionMetadata.jurisdiction) {
        excursionMetadata.jurisdiction = this.$store.state.apiDataDefaultJurisdiction;
        // set default jurisdiction value if user didn't select any
      }
    },
    async saveDraft(targetExcursionStepperHeading) {
      this.mandatoryChecklistsSkipConfirmationDialogDisplayed = false;
      this.riskCategoryChecklistsSkipConfirmationDialogDisplayed = false;
      this.highRiskChecklistsSkipConfirmationDialogDisplayed = false;
      this.mandatoryChecklistSaveActionPerformed = false;
      this.buttonLoaderDisplayed = true;

      if (
        !this.callSaveDraft
        && this.$store.state.originalTypeId
        && (
          `${this.$store.state.excursion.typeId}`
          !== `${this.$store.state.originalTypeId}`
        )
        && (1 >= this.step)
      ) {
        this.excursionTypeWarningDisplayed = true;
        return;
      }

      this.callSaveDraft = false;

      if (excursionStepperHeading.ADDITIONAL_INFORMATION === targetExcursionStepperHeading) {
        if (
          this.additionalInformationItems
          && (0 < this.additionalInformationItems.length)
        ) {
          const excursionMetadata = JSON.parse(
            JSON.stringify(this.$store.state.excursion),
          );
          this.excursionId = excursionMetadata.id;

          const sortedAdditionalInformationItems = [];
          for (let i = 0; i < this.additionalInformationItems.length; i++) {
            this.additionalInformationItems[i].orderId = i + 1;
            sortedAdditionalInformationItems.push(this.additionalInformationItems[i]);
          }

          // add
          const fd = new FormData();
          const addAdditionalList = sortedAdditionalInformationItems.filter(
            a => ("undefined" !== typeof a.file),
          );
          if (addAdditionalList && (0 < addAdditionalList.length)) {
            const addItems = [];
            for (let i = 0; i < addAdditionalList.length; i++) {
              const additional = addAdditionalList[i];
              fd.append(`file-${i}`, additional.file);
              addItems.push({
                summary: additional.fileSummary,
                description: additional.comment,
                orderId: additional.orderId,
              });
            }

            fd.append("items", JSON.stringify(addItems));
            this.excursionAttachmentUploadActionInProgress = true;
            const argHash = {
              queryParam: this.$store.state.apiParam,
              excursionId: this.excursionId,
              payload: fd,
            };

            const attachmentResponse
              = await this.$app.stbApiAdapter.postExcursionAttachments(argHash);

            if (attachmentResponse && attachmentResponse.data) {
              const attachments = attachmentResponse.data;
              if (attachments && (0 < attachments.length)) {
                sortedAdditionalInformationItems.forEach((item) => {
                  const fileFound
                    = attachments.find(file => file.metadata.orderId === item.orderId);
                  if (fileFound) {
                    item.sid = fileFound.sid;
                  }
                });
              }
            }

            this.excursionAttachmentUploadActionInProgress = false;
          }
          // update
          const updateAdditionalInformationList = sortedAdditionalInformationItems.filter(
            a => (a.sid && (0 < a.sid.length)),
          );

          await this.updateAdditionalInformationList(updateAdditionalInformationList);

          this.$store.state.excursion.additionalInformationNotes = sortedAdditionalInformationItems
          .filter(item => !item.isDeleted).map((item) => {
            const additionalItem = {
              orderId: item.orderId,
              comment: item.comment,
              fileSummary: item.fileSummary,
              notes: item.notes,
            };
            if (item.sid) {
              additionalItem.sid = item.sid;
            }
            return additionalItem;
          });
        }
      }

      await this.submit(null, true, null, null, targetExcursionStepperHeading);
    },

    saveDraftByStudentMedicalSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_STUDENT_MEDICAL_AND_RATIOS_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
      this.saveDraft();
    },
    confirmOrSaveDraftByStudentMedicalSection() {
      // tlrc stands for: top level risk category
      const selectedHighRisk = this.$store.state.excursion.categories.find((tlrc) => {
        return (tlrc.name === riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS);
      });

      if (selectedHighRisk && selectedHighRisk.CompleteChecklist) {
        const checklist = selectedHighRisk.CompleteChecklist;
        const checklistString = JSON.stringify(checklist);
        const checkboxCount = (checklistString.match(/"contentType":"checkbox"/g) || []).length;
        const tickedCheckboxCount = (checklistString.match(/"ticked":true/g) || []).length;
        this.highRiskChecklistsAllTicked = (checkboxCount === tickedCheckboxCount);
      }

      this.highRiskChecklistsMessageDisplayed = selectedHighRisk
        && !this.highRiskChecklistsAllTicked;
      this.highRiskNumbersMessageDisplayed = this.highRiskChecklistsNumbersMessageDisplayed;

      if (this.highRiskChecklistsMessageDisplayed || this.highRiskNumbersMessageDisplayed) {
        this.highRiskChecklistsSkipConfirmationDialogDisplayed = true;
        this.$store.state.excursion.agreementConfirmation = false;
      } else {
        this.saveDraftByStudentMedicalSection();
      }
    },

    saveDraftByMandatoryChecklistsSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_MANDATORY_EXCURSION_CHECKLISTS_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
      this.mandatoryChecklistsContinueButtonDisabled = true;
      this.saveDraft();
    },
    confirmOrSaveDraftByMandatoryChecklistsSection() {
      const untickedMandatoryChecklistFound = this.$store.state.tickedMandatoryChecklists
      .some(checklist => (false === checklist.ticked));
      if (untickedMandatoryChecklistFound) {
        this.mandatoryChecklistsSkipConfirmationDialogDisplayed = true;
        this.mandatoryChecklistsContinueButtonDisabled = false;
      } else {
        this.saveDraftByMandatoryChecklistsSection();
      }
    },

    saveDraftByRiskCategoriesSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_RISK_CATEGORIES_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
      this.saveDraft();
    },
    confirmOrSaveDraftByRiskCategoriesSection() {
      const tickedRiskCategoryChecklists = this.$store.state.tickedRiskCategoryChecklists;
      const tickedRiskCategoryChecklistsKeys = (Object.keys(tickedRiskCategoryChecklists))
      .filter(t => t !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS);
      const untickedRiskCategoriesChecklistFound
        = tickedRiskCategoryChecklistsKeys.some(t => !tickedRiskCategoryChecklists[t]);

      if ((0 === tickedRiskCategoryChecklistsKeys.length) || untickedRiskCategoriesChecklistFound) {
        this.riskCategoryChecklistsSkipConfirmationDialogDisplayed = true;
        this.mandatoryChecklistsContinueButtonDisabled = false;
      } else {
        this.saveDraftByRiskCategoriesSection();
      }
    },

    saveDraftByExcursionRiskManagementConfirmationSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_MANAGEMENT_CONFIRMATION_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
      this.excursionStatusChanged = true;
      this.submit(excursionStatus.SUBMITTED, true);
    },

    async saveDraftOnMandatory(params, statusChanged, callback) {
      this.mandatoryChecklistSaveActionPerformed = true;
      await this.submit(params, statusChanged, callback);
    },

    incrementStep() {
      if (this.excursionSaveSuccessful) {
        this.step += 1;
      }
    },

    resetJurisdiction() {
      this.$store.state.excursion.jurisdiction = "";
    },

    clear() {
      const updateAdditionalInformationList = this.additionalInformationItems.filter(
        a => (a.sid && (0 < a.sid.length)),
      );
      updateAdditionalInformationList.forEach((a) => { a.isDeleted = true; });
      this.updateAdditionalInformationList(updateAdditionalInformationList);

      this.resetConfirmationDialogDisplayed = false;
      this.$v.$reset();

      this.$store.commit("resetAllState", [
        "excursionTypeChoiceList",
        "apiParam",
        "excursion",
        "excursionAdditionalInformationEnabled",
        "excursionBuilderButtonDisplayed",
        "excursionCreationButtonDisplayed",
        "excursionDestinationContentEnabled",
        "excursionDisplayReady",
        "excursionHighRiskStudentMedicalEnabled",
        "excursionLogoDisplayReady",
        "excursionPersonResponsibleEnabled",
        "excursionStudentFetchTriggered",
        "excursionStudentMedicalConditionsDisplayReady",
        "logoAttachmentUrl",
        "topToolbarTextButtonList",
        "providerUpdateActionEnabled",
        "showToolbar",
        "excursionSyncButtonDisplayed",
        "userAuthenticated",
        "userInitials",
        "vendorClientLogoEnabled",
        "vendorExcursionSidInputEnabled",
      ]);
      this.$store.commit("resetStore");
      this.step = 1;
    },

    turnToStep(invalid, step) {
      if (1 < step) {
        if (invalid) {
          return;
        }
      }

      if (!this.step) {
        this.step = 1;
      }

      if (this.step < step) {
        return;
      }

      this.step = step;
    },

    redirectToExcursionBuilder() {
      if (this.excursionSaveSuccessful) {
        window.location.reload();
      } else {
        this.genericDialogDisplayed = false;
      }
    },

    async clearCategoriesOnExcursionTypeUpdate() {
      this.callSaveDraft = true;
      this.$store.state.allRisks = [];

      // Clear and delete all attachments
      if (this.additionalInformationItems && (0 < this.additionalInformationItems.length)) {
        this.additionalInformationItems.forEach((item) => {
          item.isDeleted = true;
        });

        const additionalInformationItemsRemoved
          = this.$app.lodash.cloneDeep(this.additionalInformationItems);
        await this.updateAdditionalInformationList(additionalInformationItemsRemoved);
        this.$store.state.excursion.additionalInformationNotes = [];
      }

      this.saveDraft();
    },

    cancelExcursionTypeUpdate() {
      this.$store.commit("updateExcursionType", "");
      this.newExcursionType = "";
      this.$nextTick(() => {
        this.$store.commit(
          "updateExcursionType",
          this.$store.state.originalTypeId ? this.$store.state.originalTypeId : "",
        );
      });
    },

    async updateAdditionalInformationList(updateAdditionalInformationList) {
      if (!updateAdditionalInformationList || (0 === updateAdditionalInformationList.length)) {
        return;
      }

      for (let i = 0; i < updateAdditionalInformationList.length; i++) {
        const item = updateAdditionalInformationList[i];
        if (!item.sid) {
          continue;
        }

        if (item.isDeleted) {
          this.$refs.additionalInfoList.deleteAttachment(item);
        } else {
          const requestData = {};

          requestData.metadata = {
            summary: item.fileSummary,
            description: item.comment,
            orderId: item.orderId,
          };

          if (
            ("undefined" !== typeof requestData.metadata.summary)
            && ("" === requestData.metadata.summary.trim())
          ) {
            delete requestData.metadata.summary;
          }

          if (
            ("undefined" !== typeof requestData.metadata.description)
            && ("" === requestData.metadata.description.trim())
          ) {
            delete requestData.metadata.description;
          }
          const argHash = {
            queryParam: this.$store.state.apiParam,
            excursionId: this.excursionId,
            attachmentSid: item.sid,
            payload: requestData,
          };
          await this.$app.stbApiAdapter.putExcursionAttachments(argHash);
        }
      }
    },

    setAdditionalInformationValid(valid) {
      this.additionalInformationValid = valid;
    },

    clientLogoMarginDisplayed() {
      const query = this.$route.query;
      if (query.referrer || query["vendor-sid"] || query["oauth-signature"]) {
        return false;
      }
      return true;
    },

    displayExcursionSyncButton() {
      const query = this.$route.query;
      const excursionId = this.$route.params ? this.$route.params.id : "0";

      let excursionSyncButtonDisplayed = false;
      if (
        query
        && query.referrer
        && ("pcg" === query.referrer.toLowerCase())
        && (0 < excursionId)
        && this.excursionSyncButtonDisplayed
      ) {
        excursionSyncButtonDisplayed = true;
      }

      return excursionSyncButtonDisplayed;
    },

    async excursionSync() {
      this.excursionSyncConfirmationDialogDisplayed = false;
      this.excursionSyncActionInProgress = true;
      await this.submit(null, false, null, "excursionSync");
      this.excursionSyncActionInProgress = false;
    },

    async downloadFile(proformaTemplateFile) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: proformaTemplateFile.sid,
        store: this.$store,
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (0 < attachments.length) {
          const file = attachments[0];
          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, "");
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, "");
            const fileUrl = `${apiUrl}/${filePath}`;
            const filename = file.metadata.filename;
            const excursionCommonAdapter
              = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
            excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },

    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.userAuthenticationInProgress) {
        loadingAnimationDisplayed = true;
      } else {
        if (
          this.userAuthenticated
          && (
            !this.excursionDisplayReady
            || !this.excursionLogoDisplayReady
          )
        ) {
          loadingAnimationDisplayed = true;
        }
      }

      return loadingAnimationDisplayed;
    },

    saveDraftByExcursionStepperHeading(targetExcursionStepperHeading) {
      let trackableEventBaseArgHash;
      let trackableEventOptionArgHash;

      switch (targetExcursionStepperHeading) {
        case excursionStepperHeading.EXCURSION_DETAILS:
          this.saveDraft(targetExcursionStepperHeading);
          break;

        case excursionStepperHeading.STUDENT_MEDICAL_AND_RATIOS:
          this.confirmOrSaveDraftByStudentMedicalSection();
          break;

        case excursionStepperHeading.MANDATORY_EXCURSION_CHECKLISTS:
          this.confirmOrSaveDraftByMandatoryChecklistsSection();
          break;

        case excursionStepperHeading.RISK_CATEGORIES:
          this.confirmOrSaveDraftByRiskCategoriesSection();
          break;

        case excursionStepperHeading.ADDITIONAL_INFORMATION:
          trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
          trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
          this.$app.pluginCommonAdapter.registerTrackableEvent(
            trackableEvent.RISK_ASSESSMENT_ADDITIONAL_INFORMATION_UPDATE,
            trackableEventBaseArgHash,
            trackableEventOptionArgHash,
          );
          this.saveDraft(targetExcursionStepperHeading);
          break;

        case excursionStepperHeading.EXCURSION_RISK_ASSESSMENT:
          trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
          trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
          this.$app.pluginCommonAdapter.registerTrackableEvent(
            trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_UPDATE,
            trackableEventBaseArgHash,
            trackableEventOptionArgHash,
          );
          this.saveDraft();
          break;

        case excursionStepperHeading.EXCURSION_RISK_MANAGEMENT_CONFIRMATION:
          this.saveDraftByExcursionRiskManagementConfirmationSection();
          break;

        default:
          this.saveDraft();
          break;
      }
    },
  },

  beforeDestroy() {
    this.$app.eventBus.$off("getClientSetupsDone");
  },

  async mounted() {
    this.$store.commit("setMenu", this.$route.name);
    this.$store.commit("updateShowExcursionStepper", true);
    this.$store.commit("updatePrevRouteName", "SafeTripBuilder");

    if (!this.propHasParentComponent) {
      const query = this.$route.query;
      this.$app.authAgent.enforceVendorOrUserAuthStrategy(this.$route, query);

      const store = this.$store;
      this.$app.eventBus.$on("getClientSetupsDone", async function () {
        if (store.state.userAuthenticated) {
          const argHash = {
            stbApiAdapter: this.$app.stbApiAdapter,
            store: this.$store,
            queryParam: query,
          };
          await this.$store.dispatch("updateProviders", argHash);
          await this.$store.dispatch("getExcursionTypes", argHash);
          await this.$store.dispatch("getDefaultJurisdiction", argHash);

          const excursionId = this.$route.params ? this.$route.params.id : "0";
          const getExcursionsArgHash = {
            stbApiAdapter: this.$app.stbApiAdapter,
            store: this.$store,
            excursionId,
            query,
          };
          await this.$store.dispatch("getExcursions", getExcursionsArgHash);
        }
      });
    }
  },

  watch: {
    async step() {
      if (
        ((3 === this.step) && !this.excursionHighRiskStudentMedicalEnabled)
        || ((4 === this.step) && this.excursionHighRiskStudentMedicalEnabled)
      ) {
        const query = this.$route.query;
        await this.$store.dispatch("getAllRisksByExcursionTypeName", {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: query,
          excursionTypeName: this.excursionType,
        }, query);
      } else if (
        ((4 === this.step) && !this.excursionHighRiskStudentMedicalEnabled)
        || ((5 === this.step) && this.excursionHighRiskStudentMedicalEnabled)
      ) {
        const excursionCommonAdapter
          = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
        const genericFrcs = excursionCommonAdapter.getRiskCategoryParams();
        const activityFrcs = excursionCommonAdapter.getActivityParams();
        // frc stands for: factor risk category
        const frcs = genericFrcs.concat(activityFrcs);
        frcs.push([this.$store.state.excursion.typeId]);

        const query = this.$route.query;
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: query,
          payload: {
            "risk-categories": frcs,
          },
        };
        if (frcs) {
          await this.$store.dispatch("postPredepartureStrategies", argHash);
        }
      } else if (
        !this.$store.state.allRisks
        || (0 >= this.$store.state.allRisks.length)
      ) {
        await this.$store.dispatch("getAllRisksByExcursionTypeName", {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: this.$route.query,
          excursionTypeName: this.$store.state.excursion.typeId,
        });
      }
    },
    selectedRisks() {
      if (this.firstTimeLoaded) {
        this.valuesChanged = true;
      } else {
        this.firstTimeLoaded = true;
      }
    },
  },
};
</script>

<template>
  <cs-app
    :showHeader="isUserAuthorized"
    :appLogoUri="stbHorizontalLogo"
    :headerProfile="headerProfile"
    :helpBtn="helpBtn"
    :settingsBtn="settingsBtn"
    :version="version"
    homeRoute="/excursions"
  >
    <template v-slot:header-left>
      <v-btn
        :v-if="textButtonDisplayed(excursionCreationTextButton)"
        :id="excursionCreationTextButton.name"
        :href="excursionCreationTextButton.to"
        :title="excursionCreationTextButton.title"
        color="primary"
      >
        <span class="top-toolbar-item-link">{{ excursionCreationTextButton.label }}</span>
      </v-btn>
    </template>

    <template v-slot:header-right>
      <div :v-if="isUserAuthorized">
        <v-btn
          id="feedback"
          icon
          title="Feedback"
          @click="registerFeedbackLinkClickEvent($event, '/feedback')"
        >
          <v-icon size="18">fas fa-bullhorn</v-icon>
        </v-btn>
      </div>
    </template>

    <template v-slot:router-view>
      <v-slide-y-transition>
        <router-view></router-view>
      </v-slide-y-transition>
    </template>

    <template v-slot:footer>
      <div class="content">
        <v-layout class="demo-note" v-if="isDemoEnvironment">
          PLEASE NOTE: This is a demo site only, please do not enter any personal information.
          Information entered here may be deleted at any time.
        </v-layout>
        <v-layout align-center justify-center column fill-height>
          <v-img :src="complispaceLogo" max-width="200px" max-height="55px" class="img"> </v-img>
          <span>
            &copy;{{ getCopyrightYearRange() }} CompliSpace (v{{ version }}). All rights reserved.
          </span>
        </v-layout>
      </div>
    </template>

    <v-dialog v-model="isResponseWithError" persistent max-width="470">
      <v-card>
        <v-card-text
          >There seems to be an issue with the server, please try again later or log a support
          request.</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="isResponseWithError = false" class="normal-btn white--text primary"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="showCreateRiskAssessmentDialog" persistent max-width="290">
        <v-card>
          <v-card-text>
            Are you sure?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="normal-btn white--text primary"
              @click.native="
                showCreateRiskAssessmentDialog = false;
                resetExcursionInfo();
              "
              >Yes</v-btn
            >
            <v-btn class="normal-btn" @click="showCreateRiskAssessmentDialog = false">No</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </cs-app>
</template>

<script>
import defaultClientLogo from '../public/YourSchoolLogo.png';
import trackableEvent from './lib/const/trackableEvent';
import complispaceLogo from '../public/ComplispaceLogo.png';
import stbHorizontalLogo from '../public/StbHorizontalLogo.png';

// Downloadable file names can have spaces and/or special characters
// since they are the default names saved by users
require('../public/SafeTripBuilder - Risk Categories Manifest.pdf');

const packageParam = require('../package.json');

export default {
  data() {
    return {
      showContent: false,
      showMenu: true,
      fixed: true,
      excursionTypes: ['Type1', 'Type2'],
      riskCategories: [
        'High-Risk Student Medical Conditions',
        'Transport',
        'Venues',
        'Food and Beverage',
        'Activity'
      ],
      riskClasses: ['Swimming', 'Boarding'],
      excursionActive: false,
      showCreateRiskAssessmentDialog: false,
      complispaceLogo,
      stbHorizontalLogo,
      defaultClientLogo,
      headerProfile: {
        user: {
          givenName: '',
          surname: '',
          email: ''
        },
        actions: {
          logout: {
            action: this.logout
          }
        }
      }
    };
  },

  computed: {
    helpBtn() {
      return {
        action: this.registerSupportLinkClickEvent
      };
    },

    settingsBtn() {
      return {
        action: this.handleSettingsAction
      };
    },

    excursionCreationButtonDisplayed() {
      return this.$store.state.excursionCreationButtonDisplayed;
    },

    excursionBuilderButtonDisplayed() {
      return this.$store.state.excursionBuilderButtonDisplayed;
    },

    version() {
      const { bundleVersion } = packageParam;
      const uiVersion = packageParam.version;
      const dashApiVersion = this.$store.state.apiVersion ? `-${this.$store.state.apiVersion}` : '';

      return `${bundleVersion}#${uiVersion}${dashApiVersion}`;
    },

    topToolbarTextButtonList() {
      return (
        this.$store.state.topToolbarTextButtonList &&
        this.$store.state.topToolbarTextButtonList.filter(function(item) {
          return `${item.name}` !== 'Clients' && `${item.name}` !== 'SafeTripBuilder';
        })
      );
    },

    excursionCreationTextButton() {
      return (
        this.$store.state.topToolbarTextButtonList &&
        this.$store.state.topToolbarTextButtonList.find(function(item) {
          return `${item.name}` === 'SafeTripBuilder';
        })
      );
    },

    isResponseWithError: {
      get() {
        return this.$store.state.isResponseWithError;
      },
      set(value) {
        this.$store.commit('updateIsResponseWithError', value);
      }
    },
    isUserAuthorized() {
      return this.$app.authAgent.checkIfAuthorizationTokenValid();
    },
    showToolbar() {
      return this.$store.state.showToolbar;
    },
    prevRouteName() {
      return this.$store.state.prevRouteName;
    },
    isDemoEnvironment() {
      return this.$app.env.APP_ENV === 'demo';
    },

    userInitials() {
      return this.$store.state.userInitials;
    },

    routeName() {
      return this.$route.name;
    },

    contentHtmlClassConcat() {
      let localContentHtmlClassConcat = 'content';

      switch (this.$route.name) {
        case 'ClientAdminLayout':
          localContentHtmlClassConcat += ' full-width-layout';
          break;

        case 'SupportFeedbackLayout':
          localContentHtmlClassConcat += ' full-width-layout iframe-container-layout';
          break;

        default:
          break;
      }

      return localContentHtmlClassConcat;
    }
  },
  created() {
    const xClientSubdomain = this.$app.authAgent.getClientSubdomain();
    this.$app.authAgent.setClientSubdomainHeaders(xClientSubdomain);

    const that = this;
    that.$store.state.userAuthenticated = true;
    that.$store.state.userAuthenticationInProgress = true;
    this.$app.eventBus.$on('enforceAuthStrategyDone', function(clientQuery) {
      that.getClientSetups(clientQuery || {});
    });

    this.$app.eventBus.$on('setLogoImgUrlReady', function() {
      that.setLogoImgUrl();
    });

    const { query } = this.$route;
    this.$app.eventBus.$on('invalidateAuthorizationTokenDone', function(tokenEmpty) {
      if (tokenEmpty || !query.referrer) {
        const idpLoginReturnUrl = `${
          window.location.origin
        }/oauth2/callback?redirect-url=${encodeURIComponent(window.location.href)}`;
        window.location.href = `/login?redirect_uri=${encodeURIComponent(idpLoginReturnUrl)}`;
      } else {
        that.$store.state.userAuthenticated = false;
        that.$store.state.userAuthenticationInProgress = false;
      }
    });

    this.$app.eventBus.$on('getClientSetupsDone', () => {
      const userNames = (that.$store.state.userDisplayName &&
        that.$store.state.userDisplayName.split(' ')) || [''];
      that.headerProfile = {
        user: {
          givenName: userNames[0],
          surname: userNames[1] || userNames[0],
          email: that.$store.state.userEmail || ''
        },
        actions: {
          logout: {
            action: this.logout
          }
        }
      };
    });
  },
  beforeDestroy() {
    this.$app.eventBus.$off('enforceAuthStrategyDone');
    this.$app.eventBus.$off('setLogoImgUrlReady');
    this.$app.eventBus.$off('invalidateAuthorizationTokenDone');
  },
  async mounted() {
    this.$app.authAgent.handleIdpCallback();
    await this.$store.dispatch('updateApiVersion', { stbApiAdapter: this.$app.stbApiAdapter });
    this.$app.pluginCommonAdapter.initPluginBatch();
    const { query } = this.$route;

    const { path } = this.$route;
    const clients = '/excursions';
    const excursions = '/excursions';

    if (path) {
      let activatedTextButton = this.topToolbarTextButtonList.find(
        m => m.router.toLowerCase() === path.toLowerCase()
      );

      if (`${path}` === `${clients}`) {
        activatedTextButton = this.topToolbarTextButtonList.find(
          m => m.router.toLowerCase() === excursions.toLowerCase()
        );
      }

      if (activatedTextButton) {
        activatedTextButton.itemActivated = true;
      }
    }

    // TODO: Use different variable to show/hide menu
    this.showMenu = true;
    this.$store.commit('updateShowToolbar', true);

    if (query.referrer || query['vendor-sid'] || query['oauth-signature']) {
      this.showMenu = false;
      this.$store.commit('updateShowToolbar', false);
    }

    if (this.$route.path.toLowerCase() === '/login') {
      this.showMenu = false;
    }

    if (this.$route.name !== 'Oauth2') {
      this.showContent = true;
    }
  },

  methods: {
    logout() {
      this.$app.authAgent.invalidateAuthorizationToken();
    },

    redirect(path) {
      if (this.$router.currentRoute.path !== path) {
        this.$router.push(path);
      }
    },

    handleSettingsAction() {
      this.redirect('/clients/admin');
    },

    registerFeedbackLinkClickEvent(jsEvent, href) {
      jsEvent.preventDefault();
      const that = this;
      const trackableEventBaseArgHash = {
        defaultParams: true
      };
      const trackableEventOptionArgHash = {
        sentImmediately: true
      };
      const registerFeedbackLinkClickEventCb = () => {
        // This callback is used to prevent tracking interruption by page redirection
        that.$app.window.location = href;
      };

      this.$app.pluginCommonAdapter.registerTrackableEvent(
        trackableEvent.FEEDBACK_LINK_CLICK,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
        registerFeedbackLinkClickEventCb
      );

      this.redirect(href);
    },

    registerSupportLinkClickEvent() {
      const trackableEventBaseArgHash = {
        defaultParams: true
      };

      this.$app.pluginCommonAdapter.registerTrackableEvent(
        trackableEvent.SUPPORT_LINK_CLICK,
        trackableEventBaseArgHash
      );

      window.location.replace(
        'https://complispace-jira.atlassian.net/wiki/spaces/CS/pages/448055/SafeTripBuilder+Guides'
      );
    },

    resetExcursionInfo() {
      this.$store.commit('updateExcursionType', '');
      this.$store.commit('resetAllState', [
        'allProviders',
        'allRisks',
        'apiDataDefaultJurisdiction',
        'excursionTypeChoiceList',
        'apiParam',
        'excursion',
        'excursionAdditionalInformationEnabled',
        'logoAttachmentUrl',
        'topToolbarTextButtonList',
        'userAuthenticated',
        'vendorExcursionSidInputEnabled'
      ]);
      window.location.reload();
    },
    async getClientSetups(query) {
      const that = this;
      const { state } = this.$store;
      const { componentOption, pluginCommonAdapter, stbApiAdapter } = this.$app;
      const argHash = {
        componentOption,
        queryParam: query,
        pluginCommonAdapter,
        stbApiAdapter
      };

      that.$store.state.userAuthenticationInProgress = true;

      await this.$store.dispatch('getClientSetups', argHash);
      const routeName = that.$route.name;
      if (
        state.userAuthenticated &&
        ((!state.clientsTabDisplayed && routeName === 'Clients') ||
          (!state.clientUsersTabDisplayed && routeName === 'ClientUsers') ||
          (!state.usersTabDisplayed && routeName === 'Users') ||
          (!state.excursionBuilderButtonDisplayed && routeName === 'ExcursionBuilderNewRecord') ||
          (!state.excursionCreationButtonDisplayed && routeName === 'SafeTripBuilderNewRecord'))
      ) {
        that.$router.replace('/excursions');
      }
      that.$store.state.userAuthenticationInProgress = false;

      await that.$app.eventBus.$emit('getClientSetupsDone');

      if (
        routeName === 'SafeTripBuilderExistingRecord' ||
        routeName === 'ExcursionBuilderExistingRecord' ||
        routeName === 'SafeTripBuilderNewRecord' ||
        routeName === 'ExcursionBuilderNewRecord'
      ) {
        that.$app.eventBus.$emit('setLogoImgUrlReady');
      }
    },
    textButtonDisplayed(textButton) {
      let localTextButtonDisplayed = false;

      if (
        textButton &&
        ((this.excursionBuilderButtonDisplayed && textButton.name === 'ExcursionBuilder') ||
          (this.excursionCreationButtonDisplayed && textButton.name === 'SafeTripBuilder') ||
          (textButton.name !== 'ExcursionBuilder' && textButton.name !== 'SafeTripBuilder'))
      ) {
        localTextButtonDisplayed = true;
      }

      return localTextButtonDisplayed;
    },
    setLogoImgUrl() {
      const { state } = this.$store;
      let imageUrl = state.logoAttachmentUrl;
      if (imageUrl === '') {
        imageUrl = defaultClientLogo;
      }

      const logoContainer = document.getElementsByClassName('client-logo');
      if (logoContainer.length > 0) {
        state.excursionLogoDisplayReady = false;
        const logoImg = new Image();
        logoImg.src = imageUrl;
        logoImg.onerror = function() {
          state.excursionLogoDisplayReady = true;
        };
        logoImg.onload = function() {
          const img = document.getElementById('clientLogo');
          img.src = logoImg.src;
          state.excursionLogoDisplayReady = true;
        };
      }
    },
    redirectToUrl(url, newWindowEnabled) {
      if (newWindowEnabled) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
    getCopyrightYearRange() {
      const startYear = '2018';
      return `${startYear}-${new Date().getFullYear().toString()}`;
    }
  },

  watch: {
    async version() {
      if (!this.$store.state.apiVersion) {
        await this.$store.dispatch('updateApiVersion', { stbApiAdapter: this.$app.stbApiAdapter });
      }
    }
  }
};
</script>
<style module>
#app {
  font-size: 14px;
}
</style>

import appInfo from "./const/appInfo";
import excursionTypeInfo from "./const/excursionTypeInfo";
import riskCategoryLabel from "./const/riskCategoryLabel";
import trackableAttribute from "./const/trackableAttribute";

const riskTitleColumnIndex = 0;
const riskDescriptionColumnIndex = 1;
const riskResidualLikelihoodColumnIndex = 2;
const riskResidualConsequenceColumnIndex = 3;
const riskResidualRiskRatingColumnIndex = 4;
const riskMasterChecklistsColumnIndex = 5;
const riskMasterPoliciesColumnIndex = 6;
const riskInherentLikelihoodColumnIndex = 7;
const riskInherentConsequenceColumnIndex = 8;
const riskInherentRiskRatingColumnIndex = 9;

function checkIfRiskControlColumnDetected(rcaTableRow) {
  const riskControlInspectionColumn = rcaTableRow[riskInherentRiskRatingColumnIndex];
  const riskControlColumnDetected = (
    (10 === rcaTableRow.length)
    && (
      ("undefined" === typeof riskControlInspectionColumn)
      || (null === riskControlInspectionColumn)
      || ("" === `${riskControlInspectionColumn}`)
    )
  );

  return riskControlColumnDetected;
}

function appendRequestList(category, newCategory) {
  if (category.CompleteChecklist) {
    category.CompleteChecklist.forEach((completeChecklistItem) => {
      if (completeChecklistItem.checklist && (0 < completeChecklistItem.checklist.length)) {
        completeChecklistItem.checklist.forEach((item) => {
          newCategory.checklist.push({
            title: item.title.trim(),
          });
        });
      }
    });
  }
}

function appendRiskChecklist(newCategory, itemChecklistString) {
  if (itemChecklistString) {
    const itemChecklists = itemChecklistString.split("\n");
    if (
      itemChecklists
      && (0 < itemChecklists.length)
      && newCategory.checklist
      && (0 <= newCategory.checklist.length)
    ) {
      itemChecklists.forEach((itemChecklist) => {
        const existChecklist = newCategory.checklist.filter((ck) => {
          return (`${ck.title}` === `${itemChecklist.trim()}`);
        });

        if ((!existChecklist || (0 >= existChecklist.length)) && itemChecklist) {
          newCategory.checklist.push({
            title: itemChecklist.trim(),
          });
        }
      });
    }
  }
}

function appendRiskPolicy(newCategory, itemPolicy) {
  if (itemPolicy) {
    const itemPolicies = itemPolicy.split("\n");
    if (
      itemPolicies
      && (0 < itemPolicies.length)
      && newCategory.policies
      && (0 <= newCategory.policies.length)
    ) {
      itemPolicies.forEach((ip) => {
        const existPolicy = newCategory.policies.filter((ck) => {
          return `${ck.title}` === `${ip.trim()}`;
        });

        if ((!existPolicy || 0 >= existPolicy.length) && ip) {
          newCategory.policies.push({
            title: ip.trim(),
          });
        }
      });
    }
  }
}

function enableFormControl(selectorDOM, toggleValue) {
  const selectors = ["input", "select", "textarea"];

  if (!toggleValue) {
    for (const selector of selectors) {
      $(selectorDOM).find(selector).prop("disabled", true);
    }
  }
}

function setListItemId(selector) {
  const options = $(selector);
  const id = "listItem";
  options.each((index, option) => {
    $(option).prop("id", `${id}${index}`);
  });
}

function getRawRcaTable(riskResponse, tlrcName) {
  let assessment = {};
  // rca stands for: risk category assessment
  let rawRcaTable;

  if (
    riskResponse
    && riskResponse.optional
    && (0 < riskResponse.optional.length)
  ) {
    assessment = riskResponse.optional.filter((item) => {
      return (`${item.title}` === `${tlrcName}`);
    });
  }

  if (assessment && (0 < assessment.length)) {
    const rcaBase = assessment[0]["Risk Assessment"];

    if (rcaBase) {
      rawRcaTable = JSON.parse(rcaBase);
    }
  }

  return rawRcaTable;
}

function getActivityRcas(riskResponse, activityTlrcs, excursionInherentRiskRatingEnabled) {
  const activityLabelItems = [
    "Boating",
    "Camping",
    "Climbing",
    "Cycling",
    "Gymnastics",
    "Horse Riding",
    "Nature Walking",
    "Physical Conditioning",
    "Running",
    "Soccer",
    "Swimming",
  ];
  const sportLabelItems = [
    "Jumping",
    "Lifting",
  ];
  let activityRca = {};
  let newItem = 0;
  const activityRcas = [];

  const activityRawRcaTable = getRawRcaTable(riskResponse, riskCategoryLabel.ACTIVITIES);

  if (activityRawRcaTable && (0 < activityRawRcaTable.length)) {
    activityRawRcaTable.forEach((rcaTableRow, index) => {
      const classes = activityTlrcs.types.filter((tp) => {
        let riskClassFound;
        let riskItemClassName;

        if (tp.classes && (0 < tp.classes.length)) {
          riskClassFound = tp.classes.find(function (cl) {
            if (cl.name && activityLabelItems.includes(cl.name)) {
              cl.name += " Activities";
            } else if (cl.name && sportLabelItems.includes(cl.name)) {
              cl.name += " Sports";
            }
            return (`${cl.name}` === `${rcaTableRow[0]}`);
          });
        }

        if (riskClassFound) {
          riskItemClassName = riskClassFound.name;
        }

        return (`${riskItemClassName}` === `${rcaTableRow[0]}`);
      });

      let riskClassName;
      if (classes && (0 < classes.length) && (0 < classes[0].classes.length)) {
        const existClass = classes[0].classes.find(function (cl) {
          if (cl.name && activityLabelItems.includes(cl.name)) {
            cl.name += " Activities";
          } else if (cl.name && sportLabelItems.includes(cl.name)) {
            cl.name += " Sports";
          }
          return (`${cl.name}` === `${rcaTableRow[0]}`);
        });

        if (existClass) {
          riskClassName = existClass.name;
        }
      }

      if (!riskClassName && (0 === index)) {
        return;
      }

      const riskControlColumnDetected = checkIfRiskControlColumnDetected(rcaTableRow);

      if (
        (`${activityRca.title}` !== `${rcaTableRow[0]}`)
        && classes
        && (0 < classes.length)
        && (riskClassName === rcaTableRow[0])
      ) {
        if ("1" === `${newItem}`) {
          activityRcas.push(activityRca);
          newItem = 0;
        }

        const residualLikelihood = rcaTableRow[riskResidualLikelihoodColumnIndex];
        const residualConsequence = rcaTableRow[riskResidualConsequenceColumnIndex];
        const residualRiskRating = rcaTableRow[riskResidualRiskRatingColumnIndex];

        activityRca = {
          categoryName: riskCategoryLabel.ACTIVITIES,
          title: rcaTableRow[riskTitleColumnIndex],
          description: rcaTableRow[riskDescriptionColumnIndex],
          likelihood: residualLikelihood,
          consequence: residualConsequence,
          "risk-rating": residualRiskRating,
          checklist: [],
          policies: [],
        };

        if (excursionInherentRiskRatingEnabled) {
          activityRca.residualLikelihood = residualLikelihood ? residualLikelihood : "";
          activityRca.residualConsequence = residualConsequence ? residualConsequence : "";
          activityRca.residualRiskRating = residualRiskRating ? residualRiskRating : "";

          let resolvedRiskInherentLikelihoodColumnIndex = riskInherentLikelihoodColumnIndex;
          let resolvedRiskInherentConsequenceColumnIndex = riskInherentConsequenceColumnIndex;
          let resolvedRiskInherentRiskRatingColumnIndex = riskInherentRiskRatingColumnIndex;

          if (riskControlColumnDetected) {
            resolvedRiskInherentLikelihoodColumnIndex -= 1;
            resolvedRiskInherentConsequenceColumnIndex -= 1;
            resolvedRiskInherentRiskRatingColumnIndex -= 1;
          }

          const inherentLikelihood = rcaTableRow[resolvedRiskInherentLikelihoodColumnIndex];
          const inherentConsequence = rcaTableRow[resolvedRiskInherentConsequenceColumnIndex];
          const inherentRiskRating = rcaTableRow[resolvedRiskInherentRiskRatingColumnIndex];

          activityRca.inherentLikelihood = inherentLikelihood ? inherentLikelihood : "";
          activityRca.inherentConsequence = inherentConsequence ? inherentConsequence : "";
          activityRca.inherentRiskRating = inherentRiskRating ? inherentRiskRating : "";
        }

        newItem++;
        appendRequestList(activityTlrcs, activityRca);
      } else {
        if ("Risk Title" !== rcaTableRow[riskTitleColumnIndex]) {
          const checklistAndPolicySkipped
            = (excursionInherentRiskRatingEnabled && riskControlColumnDetected);

          const checklistCell = checklistAndPolicySkipped ? "" : rcaTableRow[riskMasterChecklistsColumnIndex];
          const policyCell = checklistAndPolicySkipped ? "" : rcaTableRow[riskMasterPoliciesColumnIndex];

          appendRiskChecklist(activityRca, checklistCell);
          appendRiskPolicy(activityRca, policyCell);
        }
      }

      if (`${index}` === `${activityRawRcaTable.length - 1}`) {
        activityRcas.push(activityRca);
      }
    });
  }

  return activityRcas;
}

function getStudentMedicalRcas(
  riskResponse,
  studentMedicalTlrcs,
  excursionInherentRiskRatingEnabled,
) {
  const studentMedicalRcas = [];

  const studentMedicalRawRcaTable
    = getRawRcaTable(riskResponse, riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS);
  if (studentMedicalRawRcaTable && (0 < studentMedicalRawRcaTable.length)) {
    // Build the risk category hash
    const newRcaHash = {};
    const commonRcaTableRows = [];
    let currentNewRcaHashKey = "";

    for (let i = 0; i < studentMedicalRawRcaTable.length; i++) {
      const rcaTableRow = studentMedicalRawRcaTable[i];

      if (
        ((rcaTableRow[5] && (0 === rcaTableRow[5].length)) || !rcaTableRow[5])
        && ((rcaTableRow[6] && (0 === rcaTableRow[6].length)) || !rcaTableRow[6])
      ) {
        // Handle rows that contain risk categories
        const newRcaHashKey = rcaTableRow[0];
        currentNewRcaHashKey = newRcaHashKey;
        newRcaHash[newRcaHashKey] = {
          index: i,
          rcaTableRow,
          childRcaTableRows: [],
        };
      } else if ("Risk Title" === rcaTableRow[0]) {
        // Handle rows that contain 'Risk Title' headings
        if ("" !== currentNewRcaHashKey) {
          const currentNewRcaHashValue = newRcaHash[currentNewRcaHashKey];
          const currentNewRcaHashIndex = currentNewRcaHashValue.index;
          const rcaTableRowCount = i - currentNewRcaHashIndex;

          // Treat 'Risk Title' rows that are
          // not immediately below risk category rows as delimiters
          if (1 < rcaTableRowCount) {
            currentNewRcaHashKey = "";
          }
        }
      } else {
        // Handle rows that contain checklists and policies
        if ("" !== currentNewRcaHashKey) {
          // Handle risk-specific checklists and policies
          const currentNewRcaHashValue = newRcaHash[currentNewRcaHashKey];
          currentNewRcaHashValue.childRcaTableRows.push(rcaTableRow);
        } else {
          // Handle common checklists and policies
          commonRcaTableRows.push(rcaTableRow);
        }
      }
    }

    // Append common risk assessment rows
    Object.keys(newRcaHash).forEach((newRcaHashKey) => {
      const newRcaHashValue = newRcaHash[newRcaHashKey];
      commonRcaTableRows.forEach((commonRcaTableRow) => {
        newRcaHashValue.childRcaTableRows.push(commonRcaTableRow);
      });
    });

    const newRcaHashKeys = Object.keys(newRcaHash);
    for (let i = 0; i < newRcaHashKeys.length; i++) {
      const newRcaHashKey = newRcaHashKeys[i];
      const newRcaHashValue = newRcaHash[newRcaHashKey];
      const { rcaTableRow, childRcaTableRows } = newRcaHashValue;

      const residualLikelihood = rcaTableRow[riskResidualLikelihoodColumnIndex];
      const residualConsequence = rcaTableRow[riskResidualConsequenceColumnIndex];
      const residualRiskRating = rcaTableRow[riskResidualRiskRatingColumnIndex];

      const studentMedicalRca = {
        categoryName: riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS,
        title: rcaTableRow[riskTitleColumnIndex],
        description: rcaTableRow[riskDescriptionColumnIndex],
        likelihood: residualLikelihood,
        consequence: residualConsequence,
        "risk-rating": residualRiskRating,
        checklist: [],
        policies: [],
      };

      if (excursionInherentRiskRatingEnabled) {
        studentMedicalRca.residualLikelihood = residualLikelihood ? residualLikelihood : "";
        studentMedicalRca.residualConsequence = residualConsequence ? residualConsequence : "";
        studentMedicalRca.residualRiskRating = residualRiskRating ? residualRiskRating : "";

        let resolvedRiskInherentLikelihoodColumnIndex = riskInherentLikelihoodColumnIndex;
        let resolvedRiskInherentConsequenceColumnIndex = riskInherentConsequenceColumnIndex;
        let resolvedRiskInherentRiskRatingColumnIndex = riskInherentRiskRatingColumnIndex;

        const riskControlColumnDetected = checkIfRiskControlColumnDetected(rcaTableRow);

        if (riskControlColumnDetected) {
          resolvedRiskInherentLikelihoodColumnIndex -= 1;
          resolvedRiskInherentConsequenceColumnIndex -= 1;
          resolvedRiskInherentRiskRatingColumnIndex -= 1;
        }

        const inherentLikelihood = rcaTableRow[resolvedRiskInherentLikelihoodColumnIndex];
        const inherentConsequence = rcaTableRow[resolvedRiskInherentConsequenceColumnIndex];
        const inherentRiskRating = rcaTableRow[resolvedRiskInherentRiskRatingColumnIndex];

        studentMedicalRca.inherentLikelihood = inherentLikelihood ? inherentLikelihood : "";
        studentMedicalRca.inherentConsequence = inherentConsequence ? inherentConsequence : "";
        studentMedicalRca.inherentRiskRating = inherentRiskRating ? inherentRiskRating : "";
      }

      appendRequestList(studentMedicalTlrcs, studentMedicalRca);

      childRcaTableRows.forEach((childRcaTableRow) => {
        const riskControlColumnDetected = checkIfRiskControlColumnDetected(childRcaTableRow);
        const checklistAndPolicySkipped
          = (excursionInherentRiskRatingEnabled && riskControlColumnDetected);

        const checklistCell = checklistAndPolicySkipped ? "" : childRcaTableRow[riskMasterChecklistsColumnIndex];
        const policyCell = checklistAndPolicySkipped ? "" : childRcaTableRow[riskMasterPoliciesColumnIndex];

        appendRiskChecklist(studentMedicalRca, checklistCell);
        appendRiskPolicy(studentMedicalRca, policyCell);
      });

      studentMedicalRcas.push(studentMedicalRca);
    }
  }

  return studentMedicalRcas;
}

function getGenericRcas(riskResponse, genericTlrcs, excursionInherentRiskRatingEnabled) {
  const genericRcas = [];

  genericTlrcs.forEach((genericTlrc) => {
    const rawRcaTable = getRawRcaTable(riskResponse, genericTlrc.name);
    const rcaTableRow = rawRcaTable[0];

    const residualLikelihood = rcaTableRow[riskResidualLikelihoodColumnIndex];
    const residualConsequence = rcaTableRow[riskResidualConsequenceColumnIndex];
    const residualRiskRating = rcaTableRow[riskResidualRiskRatingColumnIndex];

    const genericRca = {
      categoryName: genericTlrc.name,
      title: rcaTableRow[riskTitleColumnIndex],
      description: rcaTableRow[riskDescriptionColumnIndex],
      likelihood: residualLikelihood,
      consequence: residualConsequence,
      "risk-rating": residualRiskRating,
      checklist: [],
      policies: [],
    };

    if (excursionInherentRiskRatingEnabled) {
      genericRca.residualLikelihood = residualLikelihood ? residualLikelihood : "";
      genericRca.residualConsequence = residualConsequence ? residualConsequence : "";
      genericRca.residualRiskRating = residualRiskRating ? residualRiskRating : "";

      let resolvedRiskInherentLikelihoodColumnIndex = riskInherentLikelihoodColumnIndex;
      let resolvedRiskInherentConsequenceColumnIndex = riskInherentConsequenceColumnIndex;
      let resolvedRiskInherentRiskRatingColumnIndex = riskInherentRiskRatingColumnIndex;

      const riskControlColumnDetected = checkIfRiskControlColumnDetected(rcaTableRow);

      if (riskControlColumnDetected) {
        resolvedRiskInherentLikelihoodColumnIndex -= 1;
        resolvedRiskInherentConsequenceColumnIndex -= 1;
        resolvedRiskInherentRiskRatingColumnIndex -= 1;
      }

      const inherentLikelihood = rcaTableRow[resolvedRiskInherentLikelihoodColumnIndex];
      const inherentConsequence = rcaTableRow[resolvedRiskInherentConsequenceColumnIndex];
      const inherentRiskRating = rcaTableRow[resolvedRiskInherentRiskRatingColumnIndex];

      genericRca.inherentLikelihood = inherentLikelihood ? inherentLikelihood : "";
      genericRca.inherentConsequence = inherentConsequence ? inherentConsequence : "";
      genericRca.inherentRiskRating = inherentRiskRating ? inherentRiskRating : "";
    }

    appendRequestList(genericTlrc, genericRca);

    rawRcaTable.forEach((rawRcaTableRow, index) => {
      if ("Risk Title" !== rawRcaTableRow[riskTitleColumnIndex]) {
        const riskControlColumnDetected = checkIfRiskControlColumnDetected(rawRcaTableRow);
        const checklistAndPolicySkipped
          = (excursionInherentRiskRatingEnabled && riskControlColumnDetected);

        const checklistCell = checklistAndPolicySkipped ? "" : rawRcaTableRow[riskMasterChecklistsColumnIndex];
        const policyCell = checklistAndPolicySkipped ? "" : rawRcaTableRow[riskMasterPoliciesColumnIndex];

        appendRiskChecklist(genericRca, checklistCell);
        appendRiskPolicy(genericRca, policyCell);
      }
    });

    genericRcas.push(genericRca);
  });

  return genericRcas;
}

function getExcursionRcas(riskResponse, tlrcs, excursionInherentRiskRatingEnabled) {
  const rcas = [];

  // 1. Activity risk categories
  const activityTlrc = tlrcs.find(tlrc => tlrc.name === riskCategoryLabel.ACTIVITIES);

  if (
    activityTlrc
    && activityTlrc.types
    && (0 < activityTlrc.types.length)
  ) {
    const activityRcas
      = getActivityRcas(riskResponse, activityTlrc, excursionInherentRiskRatingEnabled);
    rcas.push(...activityRcas);
  }

  // 2. High-risk medical risk categories
  const studentMedicalTlrc = tlrcs
    .find(tlrc => (tlrc.name === riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS));

  if (studentMedicalTlrc) {
    const studentMedicalRcas
      = getStudentMedicalRcas(riskResponse, studentMedicalTlrc, excursionInherentRiskRatingEnabled);
    rcas.push(...studentMedicalRcas);
  }

  // 3. Generic risk categories
  const genericTlrc = tlrcs.filter((tlrc) => {
    return (
      (tlrc.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS)
      && (tlrc.name !== riskCategoryLabel.ACTIVITIES)
    );
  });

  if (genericTlrc) {
    const genericRcas
      = getGenericRcas(riskResponse, genericTlrc, excursionInherentRiskRatingEnabled);
    rcas.push(...genericRcas);
  }

  return rcas;
}

function getStaffToStudentRatio(staffSupervisorAttendanceCount, studentAttendanceCount) {
  let ratio = "";
  if (
    studentAttendanceCount
    && (0 < studentAttendanceCount)
    && staffSupervisorAttendanceCount
    && (0 < staffSupervisorAttendanceCount)
  ) {
    const studentRatio = (studentAttendanceCount / staffSupervisorAttendanceCount);
    const staffRatioFixed = Math.round(studentRatio * 100) / 100;
    if (0 === (staffRatioFixed % 1)) {
      ratio = `1:${staffRatioFixed}`;
    } else {
      ratio = `1:${staffRatioFixed.toFixed(2)}`;
    }
  }

  return ratio;
}

function isNewExcursion(state) {
  const excursion = state.excursion;
  return "0" === excursion.id
    || null === excursion.id
    || "undefined" === typeof (excursion.id);
}

function isWithinAustralia(state) {
  const mappedTypes = [
    excursionTypeInfo.DAY_EXCURSION,
    excursionTypeInfo.OVERNIGHT_EXCURSION,
  ].map(
    excursionType => excursionType.label,
  );
  return -1 < mappedTypes.indexOf(state.excursion.typeId);
}

function showJurisdiction(state) {
  const excursion = state.excursion;
  return state.excursionDestinationContentEnabled
    && isWithinAustralia(state) && (
      excursion.excursionDestinationContentEnabled || isNewExcursion(state)
    );
}

function getRiskAssessmentTrackableEventBaseArgHash(apiDataDefaultJurisdiction, excursion) {
  const localJurisdiction = excursion.jurisdiction;

  const trackableEventBaseArgHash = {};
  trackableEventBaseArgHash[trackableAttribute.APP_NAME] = appInfo.APP_NAME;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_ID] = excursion.id;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_NAME] = excursion.name;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_STATUS] = excursion.status;
  trackableEventBaseArgHash[trackableAttribute.EXCURSION_TYPE] = excursion.typeId;

  if (apiDataDefaultJurisdiction) {
    trackableEventBaseArgHash[trackableAttribute.JURISDICTION] = apiDataDefaultJurisdiction;
  }

  if (localJurisdiction) {
    trackableEventBaseArgHash[trackableAttribute.DESTINATION] = localJurisdiction;
  }

  return trackableEventBaseArgHash;
}

function getRiskAssessmentTrackableEventOptionArgHash() {
  const optionArgHash = {
    sentImmediately: true,
  };

  return optionArgHash;
}

class Adapter {
  constructor(dependencyHash) {
    const { store, app } = dependencyHash;

    this.store = store;
    this.stbApiAdapter = app.stbApiAdapter;
    this.pdf = app.pdf;
    this.lodash = app.lodash;
  }

  getActivityParams() {
    const riskCategoryParams = [];
    const allSelectedCategories = this.store.state.excursion.categories;
    const activityCategoryName = this.store.state.activitiesCategoryName;

    const selectedActivityRisk = allSelectedCategories.find(
      c => (c.name === activityCategoryName),
    );
    if ("undefined" !== typeof selectedActivityRisk) {
      selectedActivityRisk.types.forEach(function (riskType) {
        riskType.classes.forEach(function (riskClass) {
          if (riskClass.risks) {
            for (let riskKey = 0; riskKey < riskClass.risks.length; riskKey++) {
              const riskName = riskClass.risks[riskKey].name;
              const paramRiskCategory = [
                activityCategoryName,
                riskType.name,
                riskClass.name,
                riskName,
              ];
              riskCategoryParams.push(paramRiskCategory);
            }
          }
        });
      });
    }

    return riskCategoryParams;
  }

  getRiskCategoryParams() {
    const allSelectedCategories = this.store.state.excursion.categories;
    const activityCategoryName = this.store.state.activitiesCategoryName;
    const selectedRiskCategories = allSelectedCategories.filter(
      c => (c.name !== activityCategoryName),
    );

    const riskCategoryParams = [];

    selectedRiskCategories.forEach(function (category) {
      if (category.types && (0 === category.types.length)) {
        riskCategoryParams.push([category.name]);
      }
      category.types.forEach(function (riskType) {
        riskType.classes.forEach(function (riskClass) {
          const paramRiskCategory = [
            category.name,
            riskType.name,
            riskClass.name,
          ];
          riskCategoryParams.push(paramRiskCategory);
        });
      });
    });

    return riskCategoryParams;
  }

  getFrcs() {
    const activityRisks = this.getActivityParams();
    const riskCategoryRisks = this.getRiskCategoryParams();

    return riskCategoryRisks.concat(activityRisks);
  }

  downloadFile(href, filename) {
    this.pdf.downloadFile(href, filename);
  }

  async downloadAssessmentPdf(
    excursionId,
    normalizedRequestParam,
    allChecklistsExported,
    clientLogoEnabled,
    predepartureChecklistExported,
    deviceDetector,
  ) {
    await this.pdf.downloadPagePdf.downloadRiskAssignmentPdf(
      this.stbApiAdapter,
      this.store,
      excursionId,
      normalizedRequestParam,
      allChecklistsExported,
      clientLogoEnabled,
      predepartureChecklistExported,
      deviceDetector,
    );
  }

  async downloadPredeparturePdf(queryParam, deviceDetector) {
    const argHash = {
      stbApiAdapter: this.stbApiAdapter,
      store: this.store,
      queryParam,
    };
    const error = await this.pdf.downloadPagePdf.downloadPredeparturePdf(argHash, deviceDetector);
    return error;
  }

  async downloadExcursionRiskContentsPdf(queryParam, param, deviceDetector) {
    const frcs = this.getFrcs();

    const excursionPolicies = [];
    if (param.exportedItems && (0 < param.exportedItems.length)) {
      param.exportedItems.forEach((item) => {
        if (item.ticked) {
          excursionPolicies.push({ name: item.name });
        }
      });
    }

    const params = {
      clientLogoEnabled: param.clientLogoEnabled,
      excursionName: this.store.state.excursion.name,
      excursionId: this.store.state.excursion.id || 0,
      masterPolicyExported: param.masterPolicyExported,
      mandatoryChecklistsExported: param.mandatoryChecklistsExported,
      exportedCategoryChecklists: param.exportedCategoryChecklists,
      categoryChecklistsExported: param.categoryChecklistsExported,
      riskCategoryParams: frcs,
      excursionPolicies,
    };

    await this.pdf.downloadPagePdf.downloadExcursionRiskContentsPdf(
      this.stbApiAdapter,
      this.store,
      queryParam,
      params,
      deviceDetector,
    );
  }

  getExcursionRiskOverall(riskResponse, excursionInherentRiskRatingEnabled) {
    const excursionType = this.store.state.excursion.typeId;

    const riskOverall = {
      description: "",
      policies: [],
      checklists: [],
      /**
       * DB migration required after renaming these 'overall' attributes.
       * Therefore, avoid renaming these for now to allow backward compatibility:
       * - likelihood
       * - consequence
       * - risk-rating
       */
      likelihood: "",
      consequence: "",
      "risk-rating": "",
    };

    const mandatory = riskResponse.mandatory;
    if (mandatory && (0 < mandatory.length)) {
      const master = mandatory.filter(function (item) {
        return ("Master" === item.title);
      });

      if (master && (0 < master.length)) {
        const masterRiskBase = master[0]["Risk Assessment"];
        const masterRisk = JSON.parse(masterRiskBase);
        if (masterRisk && (0 < masterRisk.length)) {
          const masterRiskAssessment = masterRisk.filter(function (item) {
            return (item[0] === `${excursionType}s`);
          });

          if (masterRiskAssessment && (0 < masterRiskAssessment.length)) {
            riskOverall.description = masterRiskAssessment[0][riskDescriptionColumnIndex];

            const residualLikelihood = masterRiskAssessment[0][riskResidualLikelihoodColumnIndex];
            const residualConsequence = masterRiskAssessment[0][riskResidualConsequenceColumnIndex];
            const residualRiskRating = masterRiskAssessment[0][riskResidualRiskRatingColumnIndex];

            riskOverall.likelihood = residualLikelihood;
            riskOverall.consequence = residualConsequence;
            riskOverall["risk-rating"] = residualRiskRating;

            if (excursionInherentRiskRatingEnabled) {
              riskOverall.residualLikelihood = residualLikelihood ? residualLikelihood : "";
              riskOverall.residualConsequence = residualConsequence ? residualConsequence : "";
              riskOverall.residualRiskRating = residualRiskRating ? residualRiskRating : "";

              const inherentLikelihood = masterRiskAssessment[0][riskInherentLikelihoodColumnIndex];
              const inherentConsequence
                = masterRiskAssessment[0][riskInherentConsequenceColumnIndex];
              const inherentRiskRating = masterRiskAssessment[0][riskInherentRiskRatingColumnIndex];

              riskOverall.inherentLikelihood = inherentLikelihood ? inherentLikelihood : "";
              riskOverall.inherentConsequence = inherentConsequence ? inherentConsequence : "";
              riskOverall.inherentRiskRating = inherentRiskRating ? inherentRiskRating : "";
            }

            const masterChecklists = masterRiskAssessment[0][riskMasterChecklistsColumnIndex].split("\n");
            if (masterChecklists && (0 < masterChecklists.length)) {
              masterChecklists.forEach(function (masterChecklist) {
                riskOverall.checklists.push({
                  title: masterChecklist,
                });
              });
            }

            const masterPolicies = masterRiskAssessment[0][riskMasterPoliciesColumnIndex].split("\n");
            if (masterPolicies && (0 < masterPolicies.length)) {
              masterPolicies.forEach(function (masterPolicy) {
                riskOverall.policies.push({
                  title: masterPolicy,
                });
              });
            }
          }
        }
      }
    }

    return riskOverall;
  }

  async getExcursionRiskCombo(queryParam) {
    const excursionInherentRiskRatingEnabled = this.store.state.excursionInherentRiskRatingEnabled;

    const allSelectedCategories = this.lodash.cloneDeep(this.store.state.excursion.categories);
    const frcs = this.getFrcs();

    if (0 < frcs.length) {
      const argHash = {
        queryParam,
        store: this.store,
        frcs,
      };
      const riskResponse = await this.stbApiAdapter.getViewPolicy(argHash);
      this.store.state.ceCommitOid = riskResponse.ceCommitOid;
      const riskOverall
        = this.getExcursionRiskOverall(riskResponse, excursionInherentRiskRatingEnabled);

      const allRisks = this.lodash.cloneDeep(this.store.state.allRisks);
      const selectedTlrcs = [];

      if (allRisks && (0 < allRisks.length)) {
        allRisks.forEach(function (item) {
          const selectTlrc = allSelectedCategories.find(function (selected) {
            return (selected.name === item.name);
          });

          if (selectTlrc) {
            selectedTlrcs.push(selectTlrc);
          }
        });
      }

      const riskCategories
        = getExcursionRcas(riskResponse, selectedTlrcs, excursionInherentRiskRatingEnabled);

      return {
        riskOverall,
        riskCategories,
      };
    }

    return null;
  }
}

export default {
  Adapter,
  enableFormControl,
  getStaffToStudentRatio,
  isNewExcursion,
  isWithinAustralia,
  setListItemId,
  showJurisdiction,
  getRiskAssessmentTrackableEventBaseArgHash,
  getRiskAssessmentTrackableEventOptionArgHash,
};

<template>
  <cs-view single-view>
    <div class="center login-container" slot="content">
      <v-layout class="row center">
        <v-flex xs6 class="center">
          <img :src="stbHorizontalLogo" />
        </v-flex>
      </v-layout>
      <v-layout class="row center text-center login-button">
        <v-btn class="normal-btn white--text center primary" @click="loginAsClient()">Login</v-btn>
      </v-layout>
      <v-layout class="row center text-center login-desc">
        <v-flex xs7 class="center">
          When you click Login, you may be redirected to your Single Sign On login page
          <br />
          where you may need to provide your credentials to login to SafeTripBuilder.
        </v-flex>
      </v-layout>
      <v-layout class="row center text-center">
        <v-flex xs7 class="center staff-login">
          <a @click="loginAsComplispaceStaff()">CompliSpace Staff Login</a>
        </v-flex>
      </v-layout>
    </div>
  </cs-view>
</template>

<script>
import stbHorizontalLogo from '../../public/StbHorizontalLogo.png';

export default {
  data() {
    return {
      stbHorizontalLogo
    };
  },
  computed: {},
  mounted() {
    this.$app.eventBus.$emit('enforceAuthStrategyDone');
  },
  methods: {
    loginAsClient() {
      const { query } = this.$route;
      const defaultReturnUrl = `${
        window.location.origin
      }/oauth2/callback?redirect-url=${encodeURIComponent(`${window.location.origin}/excursions`)}`;
      let urlParam = `&redirect_uri=${defaultReturnUrl}`;

      if (query && query.redirect_uri) {
        urlParam = `&redirect_uri=${query.redirect_uri}`;
      }

      const idpQuerystring = `?response_type=token&client_id=pcg-data-api${urlParam}`;
      const idpBaseUrl = this.$store.state.idpUrl;
      const idpLoginUrl = `${idpBaseUrl}/oauth2/authorize${idpQuerystring}`;
      window.location.href = idpLoginUrl;
    },
    loginAsComplispaceStaff() {
      const { query } = this.$route;
      const defaultReturnUrl = `${
        window.location.origin
      }/oauth2/callback?redirect-url=${encodeURIComponent(`${window.location.origin}/excursions`)}`;
      let urlParam = `&redirect_uri=${defaultReturnUrl}`;

      if (query && query.redirect_uri) {
        urlParam = `&redirect_uri=${query.redirect_uri}`;
      }

      const idpQuerystring = `?response_type=token&client_id=pcg-data-api&scope=staff.complispace${urlParam}`;
      const idpBaseUrl = this.$store.state.idpUrl;
      const idpLoginUrl = `${idpBaseUrl}/oauth2/authorize${idpQuerystring}`;
      window.location.href = idpLoginUrl;
    }
  }
};
</script>

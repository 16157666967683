<template>
  <v-layout>
    <v-btn
      :disabled="this.excursionPolicyExportActionEnabled"
      @click.native="riskAssessmentExportModalDisplayed = true"
      class="normal-btn white--text continue-btn primary"
      id="btnCreateRiskAssessment"
      testing-id="btnCreateRiskAssessment"
    >
      <v-icon v-if="!riskAssessmentDownloadActionInProgress">fa-exclamation-triangle</v-icon>
      Export Risk Assessment
    </v-btn>
    <v-dialog
      v-model="riskAssessmentExportModalDisplayed"
      persistent
      max-width="40%"
      @keydown.enter="displayPresaveConfirmationDialog()"
    >
      <v-card>
        <v-card-title class="modalTitle">
          <v-flex>
            <strong>Export Risk Assessment</strong>
          </v-flex>
          <v-btn icon @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text>
          <v-container class="export-excursion-policy">
            <div>
              <v-layout class="selected-risk-container" >
                <v-flex xs1>
                  <v-checkbox
                    id="export-excursion-risk-assessment--export-all-checklists"
                    v-model="allChecklistsExported"
                    class="excursion-policy-row-title-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  align-end
                  justify-end
                  class="excursion-policy-row-title "
                  @click="allChecklistsExported = !allChecklistsExported"
                >Export All Checklists</v-flex>
              </v-layout>
              <v-layout class="selected-risk-container" >
                <v-flex xs1>
                  <v-checkbox
                    id="export-excursion-risk-assessment--pre-departure-checklist"
                    v-model="predepartureChecklistExported"
                    class="excursion-policy-row-title-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  align-end
                  justify-end
                  class="excursion-policy-row-title"
                  @click="predepartureChecklistExported = !predepartureChecklistExported"
                >Pre-Departure Checklist</v-flex>
              </v-layout>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="normal-btn white--text btnClear primary"
            :disabled="riskAssessmentDownloadActionInProgress"
            testing-id="btnShowPresaveConfirmationDialog"
            @click.native="displayPresaveConfirmationDialog()"
          >
            <v-icon
              v-if="riskAssessmentDownloadActionInProgress"
            >fa fa-spinner fa-pulse</v-icon>
            Export
          </v-btn>
          <v-btn class="normal-btn" @click.native="close()">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="presaveConfirmationDialogDisplayed" persistent max-width="490">
        <v-card>
          <v-card-text>
            Changes have been made to this excursion. Do you want to save?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="normal-btn white--text primary"
              testing-id="btnExportRiskAssessmentWithExcursionPresave"
              @click.native="presaveConfirmationDialogDisplayed = false; exportContent(true)"
            >Save</v-btn>
            <v-btn
              class="normal-btn"
              testing-id="btnExportRiskAssessmentWithoutExcursionPresave"
              @click.native="presaveConfirmationDialogDisplayed = false; exportContent(false)"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: [
    "excursionPolicyExportActionEnabled",
    "valuesChanged",
    "registerRiskAssessmentExportEvent",
    "submitExcursion",
  ],

  data() {
    return {
      riskAssessmentExportModalDisplayed: false,
      riskAssessmentDownloadActionInProgress: false,
      allChecklistsExported: true,
      presaveConfirmationDialogDisplayed: false,
      predepartureChecklistExported: false,
    };
  },

  methods: {
    close() {
      this.riskAssessmentDownloadActionInProgress = false;
      this.riskAssessmentExportModalDisplayed = false;
      this.allChecklistsExported = true;
      this.predepartureChecklistExported = false;
    },

    async downloadAssessmentPdf() {
      this.riskAssessmentDownloadActionInProgress = true;

      if ("function" === typeof this.registerRiskAssessmentExportEvent) {
        this.registerRiskAssessmentExportEvent();
      }

      const excursionMetadata = JSON.parse(
        JSON.stringify(this.$store.state.excursion),
      );

      const query = this.$route.query;
      const clientLogoEnabled = !(
        query
        && query.referrer
        && ("pcg" === query.referrer)
      );

      const excursionCommonAdapter
        = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });

      await excursionCommonAdapter.downloadAssessmentPdf(
        excursionMetadata.id,
        query,
        this.allChecklistsExported,
        clientLogoEnabled,
        this.predepartureChecklistExported,
        this.$app.deviceDetector,
      );

      this.close();
    },

    async displayPresaveConfirmationDialog() {
      if (
        this.submitExcursion
        && (("function" === typeof this.submitExcursion) && this.valuesChanged)
      ) {
        this.presaveConfirmationDialogDisplayed = true;
      } else {
        await this.downloadAssessmentPdf();
      }
    },

    async exportContent(presaveConfirmed) {
      if (presaveConfirmed) {
        this.riskAssessmentDownloadActionInProgress = true;
        this.submitExcursion(
          "downloadExcursionRiskAssessment",
          false,
          async () => {
            await this.downloadAssessmentPdf();
          },
        );
      }
    },
  },
};
</script>

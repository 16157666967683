import appInfo from "./const/appInfo";
import trackableAttribute from "./const/trackableAttribute";
import trackableEvent from "./const/trackableEvent";

/* eslint-disable max-len */
const mixpanelTrackableEventHash = {};
mixpanelTrackableEventHash[trackableEvent.FEEDBACK_LINK_CLICK] = true;
mixpanelTrackableEventHash[trackableEvent.PAGE_LOAD] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_ADDITIONAL_INFORMATION_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_APPROVAL] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_BUILDER_VIEW_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_EXCURSION_DETAILS_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_EXPORT] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_MANAGEMENT_CONFIRMATION_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_MANDATORY_EXCURSION_CHECKLISTS_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_RISK_CATEGORIES_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_STUDENT_MEDICAL_AND_RATIOS_UPDATE] = true;
mixpanelTrackableEventHash[trackableEvent.RISK_ASSESSMENT_UNAPPROVAL] = true;
mixpanelTrackableEventHash[trackableEvent.SUPPORT_LINK_CLICK] = true;

const appcuesTrackableEventHash = {};
appcuesTrackableEventHash[trackableEvent.EXCURSION_LIST_LOADED] = true;
/* eslint-enable max-len */

class Adapter {
  constructor(dependencyHash) {
    const {
      Appcues,
      authAgent,
      env,
      mixpanelBrowser,
      window,
    } = dependencyHash;

    this.Appcues = Appcues;
    this.authAgent = authAgent;
    this.appcuesEnabled = env.APPCUES_ENABLED;
    this.mixpanelBrowser = mixpanelBrowser;
    this.mixpanelEnabled = env.MIXPANEL_ENABLED;
    this.mixpanelProjectToken = env.MIXPANEL_PROJECT_TOKEN;
    this.mixpanelSetupSuccessful = false;
    this.mixpanelSetupValid = (this.mixpanelEnabled && this.mixpanelProjectToken);
    this.window = window;
  }

  initPluginBatch() {
    if (this.mixpanelSetupValid) {
      try {
        this.mixpanelBrowser.init(this.mixpanelProjectToken);
        this.mixpanelSetupSuccessful = true;
      } catch (error) {
        console.error(error); // send the error to newrelic in future
      }
    }
  }

  initTrackableVarBatch(argHash) {
    const {
      csClientId,
      userEmail,
      userClientAclRole,
      vendorName,
    } = argHash;

    this.csClientId = csClientId;
    this.userEmail = userEmail;
    this.userClientAclRole = userClientAclRole;
    this.vendorName = vendorName;
  }

  identifyUser() {
    const $unique_id = this.userEmail;

    if (this.mixpanelSetupSuccessful) {
      this.mixpanelBrowser.identify($unique_id);

      const mixpanelPeopleArgHash = {
        $email: $unique_id,
        $referring_domain: this.window.location.host,
      };

      if (this.mixpanelBrowser && this.mixpanelBrowser.people) {
        this.mixpanelBrowser.people.set(mixpanelPeopleArgHash);
      }
    }

    if (this.appcuesEnabled) {
      this.Appcues.identify($unique_id);
    }
  }

  getDefaultTrackableEventBaseArgHash() {
    const tenant = this.authAgent.getClientSubdomain();
    const userClientAclRole = this.userClientAclRole;
    const vendorName = this.vendorName;
    const csClientId = this.csClientId;

    const trackableEventBaseArgHash = {};
    trackableEventBaseArgHash[trackableAttribute.APP_NAME] = appInfo.APP_NAME;

    if (csClientId) {
      trackableEventBaseArgHash[trackableAttribute.CS_CLIENT_ID] = csClientId;
    }

    if (tenant) {
      trackableEventBaseArgHash[trackableAttribute.TENANT] = tenant;
    }

    if (userClientAclRole) {
      trackableEventBaseArgHash[trackableAttribute.USER_ROLE] = userClientAclRole;
    }

    trackableEventBaseArgHash[trackableAttribute.VENDOR] = vendorName;

    return trackableEventBaseArgHash;
  }

  registerTrackableEvent(
    registeredTrackableEvent,
    trackableEventBaseArgHash,
    trackableEventOptionArgHash,
    callback,
  ) {
    if (this.mixpanelSetupSuccessful && mixpanelTrackableEventHash[registeredTrackableEvent]) {
      const mixpanelOptionHash = {};

      if (trackableEventOptionArgHash) {
        if (trackableEventOptionArgHash.sentImmediately) {
          mixpanelOptionHash.send_immediately = true;
        }
      }

      if (trackableEventBaseArgHash) {
        trackableEventBaseArgHash = trackableEventBaseArgHash.defaultParams
          ? Object.assign(trackableEventBaseArgHash, this.getDefaultTrackableEventBaseArgHash())
          : trackableEventBaseArgHash;
        this.mixpanelBrowser
        .track(registeredTrackableEvent, trackableEventBaseArgHash, mixpanelOptionHash, callback);
      } else {
        this.mixpanelBrowser.track(registeredTrackableEvent, null, mixpanelOptionHash, callback);
      }
    }

    if (this.appcuesEnabled && appcuesTrackableEventHash[registeredTrackableEvent]) {
      if (trackableEventBaseArgHash) {
        this.Appcues.track(registeredTrackableEvent, trackableEventBaseArgHash);
      } else {
        this.Appcues.track(registeredTrackableEvent);
      }
    }
  }

  registerLoginEvent() {
    this.registerTrackableEvent(
      trackableEvent.PAGE_LOAD,
      this.getDefaultTrackableEventBaseArgHash(),
    );
  }
}

export default {
  Adapter,
};

import riskCategoryLabel from "../lib/const/riskCategoryLabel";

export default {
  selectedClassByCategoryName: state => (name) => {
    const categories = state.excursion.categories;
    let selectedCategories = [];
    if (categories) {
      selectedCategories = state.excursion.categories.filter(risk => risk.name === name);
      if (selectedCategories && (0 < selectedCategories.length)) {
        selectedCategories.forEach((category) => {
          if (category.types && (0 < category.types.length)) {
            category.types.forEach((type) => {
              if (type.classes && (0 < type.classes.length)) {
                type.classes.sort((a, b) => a.name.localeCompare(b.name));
              }
            });
          }
        });
      }
    }
    return selectedCategories;
  },
  getListRiskTypesByCategoryName: state => (categoryName) => {
    return state.allRisks.find(category => category.name === categoryName).types;
  },
  getTypesCountFromSelectedClasses: state => (categoryName) => {
    let count = 0;
    const categories = state.excursion.categories;
    if (categories) {
      const itemCategory = categories.find(c => c.name === categoryName);
      if (null != itemCategory) {
        for (const typeKey in itemCategory.types) {
          count += itemCategory.types[typeKey].classes.length;
        }
      }
    }

    return count;
  },
  getTypesCountForActivity: (state) => {
    let count = 0;

    const categories = state.excursion.categories;
    if (categories) {
      const itemCategory = categories.find(c => c.name === state.activitiesCategoryName);
      if (null != itemCategory) {
        for (const typeKey in itemCategory.types) {
          for (const classKey in itemCategory.types[typeKey].classes) {
            if (itemCategory.types[typeKey].classes[classKey].risks) {
              count += itemCategory.types[typeKey].classes[classKey].risks.length;
            }
          }
        }
      }
    }

    return count;
  },
  getAllActivityRisks: (state) => {
    const result = [];
    const allActivityRisks = state.allRisks.find(r => r.name === state.activitiesCategoryName);
    if (allActivityRisks) {
      allActivityRisks.types.forEach(function (type) {
        type.classes.forEach(function (riskClass) {
          result.push({
            name: riskClass.name,
            typeName: type.name,
            risks: riskClass.risks,
          });
        });
      });
    }
    return result;
  },
  getActivityRisksByClass: state => (typeName, className) => {
    let filterRisks = [];

    const allActivityRisks = state.allRisks.find(r => r.name === state.activitiesCategoryName);
    if (allActivityRisks) {
      allActivityRisks.types.forEach(function (type) {
        type.classes.forEach(function (riskClass) {
          filterRisks.push({
            name: riskClass.name,
            typeName: type.name,
            risks: riskClass.risks,
          });
        });
      });
    }

    if ("" !== `${typeName}`) {
      filterRisks = filterRisks.filter(r => r.typeName === typeName);
    }
    if ("" !== `${className}`) {
      filterRisks = filterRisks.filter(r => r.name === className);
    }

    return filterRisks;
  },
  getSelectedActivityClasses: (state) => {
    const categories = state.excursion.categories;
    if (categories) {
      return categories.filter(risk => risk.name === state.activitiesCategoryName);
    }
    return [];
  },
  getAllActivityTypes: (state) => {
    const activityTypes = [];
    const allActivityRisks = state.allRisks.find(r => r.name === state.activitiesCategoryName);
    if (allActivityRisks) {
      allActivityRisks.types.forEach(function (elementType) {
        activityTypes.push({
          id: elementType.id,
          name: elementType.name,
        });
      });
    }
    return activityTypes;
  },
  getProvidersByCategory: state => (categoryName) => {
    const arrayProviders = state.allProviders.filter(
      p => 0 <= (p.type
        ? p.type.toLowerCase().trim().indexOf(categoryName.toLowerCase().trim()) : -1),
    );

    const categories = state.excursion.categories;
    let selectedProviders = [];
    if (categories) {
      const category = state.excursion.categories.find(r => r.name === categoryName);

      if (category && category.selectedProviders) {
        selectedProviders = JSON.parse(JSON.stringify(category.selectedProviders));
      }
    }

    if (selectedProviders && (0 < selectedProviders.length)) {
      selectedProviders.forEach((provider) => {
        const providerCurrentItem = arrayProviders.find(p => p.name === provider.name);
        if (!providerCurrentItem) {
          provider.previousItem = true;
          arrayProviders.push(provider);
        }
      });
    }
    return arrayProviders;
  },
  getSelectedApprovedProvidersCount: state => (categoryName) => {
    const categories = state.excursion.categories;
    if (categories) {
      const category = state.excursion.categories.find(r => r.name === categoryName);

      if (category && category.selectedProviders) {
        return category.selectedProviders ? category.selectedProviders.length : 0;
      }
    }

    return 0;
  },
  getSelectedProvidersByCategory: state => (categoryName) => {
    const categories = state.excursion.categories;
    if (categories) {
      const category = state.excursion.categories.find(r => r.name === categoryName);

      if (category && category.selectedProviders) {
        return category.selectedProviders;
      }
    }

    return [];
  },

  getCategories: (state) => {
    const categories = state.excursion.categories;
    return categories;
  },
  getSelectedCategoryCount: (state) => {
    const riskCategories = state.excursion.categories
    .filter(tlrc => (tlrc.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS));
    return riskCategories.length;
  },
  getActivityRiskClass: state => (typeName, className) => {
    let riskClass = {};
    const category = state.excursion.categories.find(c => c.name === state.activitiesCategoryName);
    if (category && category.types) {
      const type = category.types.find(t => t.name === typeName);
      if (type && type.classes) {
        const findRiskClass = type.classes.find(c => c.name === className);
        if (findRiskClass) {
          riskClass = findRiskClass;
        }
      }
    }
    return riskClass;
  },
  getTlrc: state => (tlrcName) => {
    let localTlrc = {};
    const foundTlrc = state.excursion.categories.find(tlrc => tlrc.name === tlrcName);
    if (foundTlrc) {
      localTlrc = foundTlrc;
    }
    return localTlrc;
  },
};

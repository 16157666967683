<template>
  <cs-view single-view>
    <div class="content" style="min-height:837px;" slot="content">
      <page-loading-animation :displayed="pageLoadingAnimationDisplayed()"></page-loading-animation>
      <div class="mt-15">
        <div class="mt-15"
          v-show="showError || (!userAuthenticated && !userAuthenticationInProgress)"
        >
          <v-layout>
            <v-flex class="main-title">Excursions</v-flex>
          </v-layout>
          <v-layout class="mt-2 mb-3">
            <v-flex>
              You are not authorized to view Excursions,
              please provide a correct auth token.
            </v-flex>
          </v-layout>
        </div>
        <form
          id="form"
          class="mt-15"
          v-show="!showError && (userAuthenticated && !userAuthenticationInProgress)"
        >
          <v-card>
            <div class="excursions-content-padding">
              <div class="excursions-navigator">
                <router-link
                  class="excursions-navigator-link"
                  exact-active-class="excursions-navigator-link-active"
                  name="excursions"
                  to="/excursions"
                  testing-id="excursions"
                  id="entity-management--excursions"
                >EXCURSIONS</router-link>
                <router-link
                  class="excursions-navigator-link"
                  exact-active-class="excursions-navigator-link-active"
                  name="templates"
                  to="/templates"
                  testing-id="templates"
                  id="entity-management--templates"
                >TEMPLATES</router-link>
                <router-link
                  v-if="clientsTabDisplayed"
                  class="excursions-navigator-link"
                  exact-active-class="excursions-navigator-link-active"
                  name="clients"
                  to="/clients"
                  testing-id="clients"
                  id="entity-management--clients"
                >CLIENTS</router-link>
                <router-link
                  v-if="clientUsersTabDisplayed"
                  class="excursions-navigator-link"
                  exact-active-class="excursions-navigator-link-active"
                  name="clientUsers"
                  to="/clients/users"
                  testing-id="client-users"
                  id="entity-management--client-users"
                >CLIENT USERS</router-link>
                <router-link
                  v-if="usersTabDisplayed"
                  class="excursions-navigator-link"
                  exact-active-class="excursions-navigator-link-active"
                  name="users"
                  to="/users"
                  testing-id="users"
                  id="etity-management--users"
                >USERS</router-link>
                <div v-if="this.active===0" class="update-providers-margin">
                  <update-providers :buttonUpdateProvidersEnabled="true"></update-providers>
                </div>
              </div>
              <div class="create-new-client-button-clear"></div>
              <excursion-list :excursionTemplateFlag="false" v-if="0===this.active"></excursion-list>
              <excursion-list :excursionTemplateFlag="true" v-if="1===this.active"></excursion-list>
              <client-list v-if="(2===this.active) && clientsTabDisplayed"></client-list>
              <client-user-list v-if="(3===this.active) && clientUsersTabDisplayed">
              </client-user-list>
              <system-user-list v-if="(4===this.active) && usersTabDisplayed"></system-user-list>
            </div>
          </v-card>
        </form>
      </div>
    </div>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';

export default {

  extends: CSBase,

  props: [],

  data() {
    return {
      active: 0,
    };
  },
  computed: {
    clientsTabDisplayed() {
      return this.$store.state.clientsTabDisplayed;
    },
    clientUsersTabDisplayed() {
      return this.$store.state.clientUsersTabDisplayed;
    },
    usersTabDisplayed() {
      return this.$store.state.usersTabDisplayed;
    },
    userAuthenticated() {
      return this.$store.state.userAuthenticated;
    },
    userAuthenticationInProgress() {
      return this.$store.state.userAuthenticationInProgress;
    },
    showError() {
      return this.$store.state.showError ? this.$store.state.showError : false;
    },
  },
  methods: {
    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.userAuthenticationInProgress) {
        loadingAnimationDisplayed = true;
      }

      return loadingAnimationDisplayed;
    },
  },
  mounted() {
    this.$store.commit("resetAllState", [
      "excursionAdditionalInformationEnabled",
      "vendorExcursionSidInputEnabled",
      "providerUpdateActionEnabled",
    ]);

    this.$store.commit("updateShowToolbar", true);

    const route = this.$route;
    if (route) {
      if ("Excursions" === `${route.name}`) {
        this.active = 0;
      } else if ("Templates" === `${route.name}`) {
        this.active = 1;
      } else if ("Clients" === `${route.name}`) {
        this.active = 2;
      } else if ("ClientUsers" === `${route.name}`) {
        this.active = 3;
      } else {
        this.active = 4;
      }

      this.$store.commit("setMenu", "Excursions");
      this.$app.authAgent.enforceUserAuthStrategy(this.$route);
    }
  },
  watch: {
    $route(to, from) {
      if ("Excursions" === `${to.name}`) {
        this.active = 0;
      } else if ("Templates" === `${to.name}`) {
        this.active = 1;
      } else if ("Clients" === `${to.name}`) {
        this.active = 2;
      } else if ("ClientUsers" === `${to.name}`) {
        this.active = 3;
      } else {
        this.active = 4;
      }
    },
  },
};
</script>

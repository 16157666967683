<template>
  <v-dialog
    v-model="excursionCopyModalDisplayed"
    width="1024px"
    persistent
  >
    <v-card v-if="excursionCopyModalDisplayed" class="copy-excursion-modal">
      <v-card-title class="modalTitle">
        <v-flex>
          <strong v-if="!excursionTemplateFlag">Copy Excursion Wizard</strong>
          <strong v-if="excursionTemplateFlag">Create Template Wizard</strong>
        </v-flex>
        <v-btn v-if="!propCopyExcursion" icon @click="excursionCopyModalDisplayed = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="modalDivider"></v-divider>

      <div>
        <div v-if="loaderDisplayed" class="complete-loading">
          <v-progress-circular
            :size="70"
            color="rgb(29, 175, 236)"
            class="loading-img"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-stepper
          v-model="step"
          v-else-if="!loaderDisplayed"
          style="box-shadow:none;"
        >
          <v-stepper-header>
            <v-layout>
              <v-stepper-step
                :step="1"
                :complete="(1 < step)"
                editable
                id="excursion-copy-modal--details"
              >
                Details
              </v-stepper-step>
              <v-divider></v-divider>
            </v-layout>
            <v-layout v-for="(category, index) in risks" :key="index">
              <v-stepper-step
                :step="(index+2)"
                :complete="step > (index+2)"
                editable
                :id="getStepperStepId(category.name)"
              >
                {{category.name}}
              </v-stepper-step>
              <v-divider v-if="(index+1) < risks.length"></v-divider>
            </v-layout>
          </v-stepper-header>
          <div class="copy-excursion-modal-alert">
            <v-alert
              color="#00adef"
              outlined
              type="info"
              text
            >
              {{excursionCopyNotice}}
            </v-alert>
          </div>
          <v-stepper-items>
            <v-stepper-content :step="1">
              <v-layout wrap class="stepper-content">
                <v-flex xs12 class="form-label copy-excursion-font">
                  Excursion Name
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex xs12 class="excursion-detail-text">
                  <v-text-field
                    solo
                    placeholder="Name"
                    class="name-textbox excursion-input"
                    v-model="name"
                    :error-messages="nameErrors"
                    required
                    @input="$v.name.$touch()"
                    @blur="$v.name.$touch()"
                    id="excursion-copy-modal--excursion-name"
                  />
                </v-flex>
              </v-layout>
              <v-layout wrap class="stepper-content">
                <v-flex xs12 class="form-label copy-excursion-font">
                  Excursion Type
                  <span class="red--text">*</span>
                  <v-tooltip right color="#ffffff"  max-width="650px">
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn v-on="{ ...tooltip }" class="provider-tooltip-button" icon>
                        <i class="fas fa-info-circle"></i>
                      </v-btn>
                    </template>
                    <div class="type-tooltip-content">
                      <div
                        v-for="(excursionTypeInfoEntry, index) in excursionTypeInfoEntryList"
                        :key="index"
                      >
                        <!-- eslint-disable-next-line max-len -->
                        <b>{{excursionTypeInfoEntry.label}}</b> - {{excursionTypeInfoEntry.description}}
                      </div>
                    </div>
                  </v-tooltip>
                </v-flex>
                <v-flex xs12 d-flex  class="excursion-detail-text">
                  <v-select
                    class="type-dropdown excursion-selection"
                    v-model="excursionType"
                    :items="excursionTypeChoiceList"
                    item-text="name"
                    item-value="id"
                    solo
                    required
                    disabled
                    :loading="excursionTypeLoaded"
                    id="excursion-copy-modal--excursion-type"
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-stepper-content>
            <div v-for="(category, index) in risks" :key="index">
              <v-stepper-content :step="index+2" class="category-content">
                <v-layout class="stepper-content">
                  <select-control
                    class="category-selector"
                    background-color="#EEEEEE"
                    :name="category.name"
                    :id="getTypeControlId(category.name)"
                    v-if="
                      (0 > excludedRiskCategories.indexOf(category.name))
                      && (category.name !== highRiskCategoryName)
                    "
                    :formControlEnabled="formControlEnabled"
                    @changeRiskCategoryDone="markExistingData"
                  ></select-control>
                  <high-risk
                    background-color="#EEEEEE"
                    :name="category.name"
                    :id="getTypeControlId(category.name)"
                    v-if="category.name === highRiskCategoryName"
                    :formControlEnabled="formControlEnabled"
                    @changeRiskCategoryDone="markExistingData"
                  ></high-risk>
                  <activity-risk
                    v-if="'Activities' === category.name"
                    :formControlEnabled="formControlEnabled"
                    :checklistAndPolicyDisplayed="false"
                    @changeActivityDone="markExistingData"
                  ></activity-risk>
                </v-layout>
                <v-layout class="sub-category-section">
                  <v-flex
                    xs12
                    providers
                    label-padding
                    v-if="0 < getProvidersByCategoryCount(category.name)"
                  >
                    Providers
                    <v-tooltip right color="#ffffff">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                            v-if="hasNegativeProviderAnswer(category.name)"
                            v-on="{ ...tooltip }"
                            class="provider-tooltip-button"
                            icon
                        >
                          <i class="fas fa-info-circle"></i>
                        </v-btn>
                      </template>
                      <span class="provider-tooltip-content">{{negativeProviderAnswerHint}}</span>
                    </v-tooltip>
                  </v-flex>
                </v-layout>
                <v-layout
                  class="move-top-risk-title"
                  v-if="0 < getProvidersByCategoryCount(category.name)"
                >
                  <providers
                    :categoryName="category.name"
                    :formControlEnabled="formControlEnabled"
                    @changeProviderDone="markExistingData"
                  ></providers>
                </v-layout>
              </v-stepper-content>
            </div>
          </v-stepper-items>
        </v-stepper>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!propCopyExcursion"
          class="normal-btn"
          @click.native="closeModal()"
          id="excursion-copy-modal--cancel"
        >Cancel</v-btn>
        <v-btn
          v-if="continueButtonDisplayed"
          class="normal-btn white--text btnSave primary"
          name="btnSave"
          id="excursion-copy-modal--continue"
          :disabled="isInvalid"
          @click.native="continueClick()"
        >Continue</v-btn>
        <v-btn
          v-if="copyButtonDisplayed"
          class="normal-btn white--text btnSave primary"
          id="excursion-copy-modal--copy"
          :disabled="excursionCopyActionInProgress || isInvalid"
          @click.native="copyRiskAssessment()"
        >
          <v-icon v-if="excursionCopyActionInProgress">fa fa-spinner fa-pulse</v-icon>
          <span v-if="!excursionTemplateFlag">Copy Risk Assessment</span>
          <span v-if="excursionTemplateFlag">Create Template</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import riskCategoryLabel from "../lib/const/riskCategoryLabel";
import excursionTypeInfo from "../lib/const/excursionTypeInfo";

export default {
  mixins: [validationMixin],

  validations: {
    name: {
      required,
    },
    excursionType: {
      required,
    },
  },
  props: {
    propExcursionCopyModalRefreshed: Boolean,
    excursionId: String,
    excursionTypeName: String,
    excursionTemplateFlag: Boolean,
    propCopyExcursion: Object,
  },
  data() {
    return {
      step: 1,
      riskClasses: ["Swimming", "Boarding"],
      negativeProviderAnswerHint: "There are approved providers that have not completed all requested information.\nPlease speak with your school’s administration staff for more information.",
      excursionCopyModalDisplayed: false,
      riskCategories: [],
      saveButtonDisabled: false,
      excludedRiskCategories: ["Activities", "Outdoor Education Providers"],
      highRiskCategoryName: "",
      formControlEnabled: true,
      continueButtonDisplayed: true,
      copyButtonDisplayed: false,
      excursionCopyActionInProgress: false,
      copyExcursion: {},
      excursionCopyNotice: "The original risk selections are highlighted in blue. Please take care in ensuring the risk profile is carefully considered.",
    };
  },
  methods: {
    continueClick() {
      if (this.step < (this.risks.length + 1)) {
        this.step++;
      }
    },
    changeRiskCategory(categoryName) {
      this.markExistingData();
    },
    async getRiskCategories() {
      // Clear cached excursion data
      this.$store.commit("resetStore");

      const query = this.$route.query;
      const apiQuery = JSON.parse(JSON.stringify(query));
      const store = this.$store;

      const that = this;
      const getExcursionsArgHash = {
        stbApiAdapter: that.$app.stbApiAdapter,
        store,
        excursionId: that.excursionId,
        query: apiQuery,
        excursionCopyModalHandlingExpected: true,
      };

      await store.dispatch("getExcursions", getExcursionsArgHash);
      await store.dispatch("updateProviders", getExcursionsArgHash);

      const excursionTypeName = store.state.excursion.typeId;
      if (excursionTypeName) {
        await store.dispatch("getAllRisksByExcursionTypeName", {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: query,
          excursionTypeName,
          excursionCopyModalHandlingExpected: true,
        });
      }
      that.$store.commit("updateExcursionName", "");
      that.copyExcursion = JSON.parse(JSON.stringify(that.$store.state.excursion));
    },
    closeModal() {
      this.excursionCopyModalDisplayed = false;
    },
    setCompleteChecklist(completeChecklist) {
      completeChecklist.forEach((checklist) => {
        const item = checklist.checklist;

        if (item.Strategy && item.Strategy.data) {
          const key = Object.keys(item.Strategy.data);
          const subData = item.Strategy.data[key];
          if (subData && subData.children && (0 < subData.children.length)) {
            subData.children.forEach((checklistItem) => {
              if (
                checklistItem
                && checklistItem.children
                && (0 < checklistItem.children.length)
              ) {
                checklistItem.children.forEach((dc, index) => {
                  if (dc.children && (0 < dc.children.length)) {
                    delete dc.ticked;
                    dc.children.forEach((subItem, subIndex) => {
                      delete subItem.ticked;
                    });
                  } else {
                    delete dc.ticked;
                  }
                });
              }
            });
          }
        }
      });
    },
    async copyRiskAssessment() {
      this.$store.commit("updateExcursionStep", 1);
      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.excursion));
      delete excursionMetadata.id;
      delete excursionMetadata.additionalInformationItems;
      delete excursionMetadata.validCategories;
      delete excursionMetadata.computed;
      delete excursionMetadata.mandatoryChecklist;
      delete excursionMetadata.predepartures;
      delete excursionMetadata.riskOverall;
      delete excursionMetadata.studentAttendanceCount;
      delete excursionMetadata.staffSupervisorAttendanceCount;
      delete excursionMetadata.riskCategories;
      delete excursionMetadata.agreementConfirmation;
      delete excursionMetadata.predepartureChecklistCompleted;
      delete excursionMetadata.auditList;

      const categories = excursionMetadata.categories.filter((category) => {
        return (riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS !== category.name);
      });

      categories.forEach((category) => {
        if (category.CompleteChecklist && (0 < category.CompleteChecklist.length)) {
          // save completed checklist data for copied excursion
          // this.setCompleteChecklist(category.CompleteChecklist);
          delete category.CompleteChecklist;
        }

        delete category.personResponsible;

        if (
          (riskCategoryLabel.ACTIVITIES === category.name)
          && category.types
          && Array.isArray(category.types)
        ) {
          category.types.forEach((type) => {
            if (type.classes && Array.isArray(type.classes)) {
              type.classes.forEach((riskClass) => {
                if (riskClass.CompleteChecklist && (0 < riskClass.CompleteChecklist.length)) {
                  // save completed checklist data for copied excursion
                  // this.setCompleteChecklist(riskClass.CompleteChecklist);
                  delete riskClass.CompleteChecklist;
                }
              });
            }
          });
        }
      });

      excursionMetadata.categories = categories;
      excursionMetadata.excursionTemplateFlag = this.excursionTemplateFlag;

      // delete additionalInformationNotes
      delete excursionMetadata.additionalInformationNotes;

      const apiPayload = {
        metadata: excursionMetadata,
      };

      this.excursionCopyActionInProgress = true;
      if (this.propCopyExcursion) {
        excursionMetadata.excursionCopyFlag = false;
        const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));
        const argHash = {
          store: this.$store,
          queryParam: apiQuery,
          id: this.excursionId,
          payload: apiPayload,
        };
        const excursionResponse = await this.$app.stbApiAdapter.putExcursions(argHash);
        if (!excursionResponse.error) {
          window.location.reload();
        }
      } else {
        const argHash = {
          store: this.$store,
          queryParam: null,
          payload: apiPayload,
        };
        const cbResponse = await this.$app.stbApiAdapter.postExcursions(argHash);
        if (!cbResponse.error) {
          if (cbResponse.data.id) {
            // redirect to edit page
            window.location.href = `/excursions/${cbResponse.data.id}`;
          }
        }
      }

      this.excursionCopyActionInProgress = false;
    },
    markExistingData() {
      if ((2 <= this.step) && (0 < this.risks.length)) {
        this.$nextTick(() => {
          const selectedStepperText = this.risks[this.step - 2].name;
          const categories
            = this.copyExcursion.categories.filter(x => x.name === selectedStepperText);

          let categoriyString = "";
          if (categories && (0 < categories.length)) {
            categoriyString = JSON.stringify(categories[0]);
          }

          const $selector = $(".excursion-selection");
          for (let i = 0; i < $selector.length; i++) {
            const $riskClassSelectedItem = $($selector[i]).find(".v-select__selection");
            const riskClassText = $riskClassSelectedItem.text().trim();
            if (0 < categoriyString.indexOf(`"${riskClassText}"`)) {
              $($selector[i]).find(".v-input__slot").addClass("copy-excursion-modal-exist-data");
            } else {
              $($selector[i]).find(".v-input__slot").removeClass("copy-excursion-modal-exist-data");
            }

            const $selectedProviderItems = $($selector[i]).find(".v-chip__content");
            for (let j = 0; j < $selectedProviderItems.length; j++) {
              const providerText = $($selectedProviderItems[j]).text().replace("cancel", "").trim();
              if (0 < categoriyString.indexOf(`"${providerText}"`)) {
                $($selectedProviderItems[j]).addClass("copy-excursion-modal-exist-data");
              } else {
                $($selectedProviderItems[j]).removeClass("copy-excursion-modal-exist-data");
              }
            }
          }

          // setup providers background color
          const $providerItems = $(".provider-list-item");
          for (let k = 0; k < $providerItems.length; k++) {
            $($providerItems[k]).removeClass("container");
            $($providerItems[k]).addClass("copy-excursion-modal-provider-select-list-item");
            const providerItemText = $($providerItems[k]).find("div").last().text().trim();
            if (0 < categoriyString.indexOf(`"${providerItemText}"`)) {
              $($providerItems[k]).addClass("copy-excursion-modal-exist-data");
            } else {
              $($providerItems[k]).removeClass("copy-excursion-modal-exist-data");
            }
          }
        });
      }
    },
    getStepperStepId(categoryName) {
      const stepName = categoryName.replace(/\s*/g, "");
      return `excursion-copy-modal--${stepName}`;
    },
  },
  async mounted() {
    this.excursionCopyModalDisplayed = true;
    if (!this.propCopyExcursion) {
      await this.getRiskCategories();
    } else {
      this.copyExcursion = this.propCopyExcursion;
    }

    this.highRiskCategoryName = riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS;
  },
  computed: {
    name: {
      get() {
        return this.$store.state.excursion.name;
      },
      set(value) {
        this.$store.commit("updateExcursionName", value);
      },
    },
    excursionType: {
      get() {
        return this.$store.state.excursion.typeId;
      },
    },
    approvedProvidersCount() {
      return function (categoryName) {
        return this.$store.getters.getSelectedApprovedProvidersCount(categoryName);
      };
    },
    isInvalid() {
      return this.$v.$invalid;
    },
    excursionTypeChoiceList() {
      return [this.$store.state.excursion.typeId];
    },
    hasNegativeProviderAnswer() {
      return function (categoryName) {
        const providers = this.$store.getters.getProvidersByCategory(
          categoryName,
        );
        if (providers && 0 < providers.length) {
          return providers.some(provider => !provider.answer);
        }
        return false;
      };
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) {
        errors.push("Excursion is required.");
      }

      return errors;
    },
    risks() {
      const allRisks = this.$store.state.allRisks;
      return allRisks
      .filter(r => (r.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS));
    },
    loaderDisplayed() {
      return !(
        this.$store.state.allRisksInitialized
        || (this.$store.state.allProvidersInitialized
          && this.$store.state.excursionInitialized)
        // For Line 539: using OR is because if excursiontype name is not existed
        // we won't trigger get risks API call
      );
    },
    getTypeControlId() {
      return function (categoryName) {
        return categoryName.replace(/\s*/g, "");
      };
    },
    getProvidersByCategoryCount() {
      return function (categoryName) {
        return this.$store.getters.getProvidersByCategory(categoryName).length;
      };
    },
    excursionCopyModalRefreshed() {
      return this.propExcursionCopyModalRefreshed;
    },
    excursionTypeLoaded() {
      return this.$store.state.excursionTypeLoaded;
    },
    excursionTypeInfoEntryList() {
      return Object.values(excursionTypeInfo);
    },
  },
  watch: {
    async excursionCopyModalRefreshed() {
      await this.getRiskCategories();
      this.step = 1;
      this.excursionCopyModalDisplayed = true;
    },
    step() {
      if (this.step > this.risks.length && (1 !== this.step)) {
        this.continueButtonDisplayed = false;
        this.copyButtonDisplayed = true;
      } else {
        this.continueButtonDisplayed = true;
        this.copyButtonDisplayed = false;
      }
      this.markExistingData();
    },
  },
};
</script>

// TODO: Rename this module from 'auth' to 'authCommon'

class Agent {
  constructor(axios, Cookies, env, eventBus, jwtDecode, window) {
    this.axios = axios;
    this.Cookies = Cookies;
    this.eventBus = eventBus;
    this.jwtDecode = jwtDecode;
    this.window = window;

    this.cookieName = "token";
    this.uiBaseDomain = env.UI_BASE_DOMAIN;
  }

  getClientSubdomain() {
    const hostname = this.window.location.hostname;
    const clientSubdomain = hostname.substring(0, hostname.indexOf(this.uiBaseDomain) - 1);

    return clientSubdomain;
  }

  handleIdpCallback() {
    const locationHash = this.window.location.hash;

    if (-1 < locationHash.indexOf("access_token=")) {
      const token = locationHash.split("access_token=")[1].split("&")[0];
      if (token) {
        if ("automation" !== process.env.APP_ENV) {
          try {
            this.window.gtag("event", "login", { "method": "cs_idp" });
          } catch (err) {
            console.log("Login event not fired");
          }
        }

        this.Cookies.set("token", token);
        const redirectUrl = this.window.location.search;
        const url = redirectUrl.split("redirect-url=")[1];
        this.window.location.href = decodeURIComponent(url);
      }
    }
  }

  checkIfAuthorizationTokenValid() {
    const token = this.Cookies.get(this.cookieName);
    let tokenValid = false;

    if (token) {
      const decodedToken = this.jwtDecode(token);
      const differenceInMilliseconds = ((decodedToken.exp * 1000) - new Date().getTime());
      const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
      if (10 <= differenceInMinutes) {
        tokenValid = true;
      }
    }

    return tokenValid;
  }

  enforceUserAuthStrategy(route) {
    if (this.checkIfAuthorizationTokenValid()) {
      const token = this.Cookies.get(this.cookieName);
      this.axios.interceptors.request.use(
        (config) => {
          if (this.checkIfAuthorizationTokenValid()) {
            config.headers.authorization = `Bearer ${token}`;
          } else {
            this.invalidateAuthorizationToken();
          }

          return config;
        },
        (err) => {
          return Promise.reject(err);
        },
      );

      const routeName = route.name;
      const params = route.params;

      if (
        (
          ("SafeTripBuilderExistingRecord" === routeName)
          || ("ExcursionBuilderExistingRecord" === routeName)
        )
        && (params && (0 < params.id))
      ) {
        this.eventBus.$emit("enforceAuthStrategyDone", {
          "excursion-id": params.id,
        });
      } else {
        this.eventBus.$emit("enforceAuthStrategyDone", {});
      }
    } else {
      this.invalidateAuthorizationToken();
    }
  }

  enforceVendorOrUserAuthStrategy(route, query) {
    const vendorAuthStrategyPassed = (
      ("undefined" !== typeof query["vendor-sid"])
      && ("undefined" !== typeof query["oauth-signature"])
    );

    if (!vendorAuthStrategyPassed) {
      this.enforceUserAuthStrategy(route);
    } else {
      this.eventBus.$emit("enforceAuthStrategyDone", query);
    }
  }

  setClientSubdomainHeaders(clientSubdomain) {
    this.axios.interceptors.request.use(
      (config) => {
        config.headers["x-client-subdomain"] = clientSubdomain;
        return config;
      },
      (err) => {
        return Promise.reject(err);
      },
    );
  }

  invalidateAuthorizationToken() {
    const token = this.Cookies.get(this.cookieName);
    let tokenEmpty = false;
    if (!token) {
      tokenEmpty = true;
    }
    this.Cookies.remove(this.cookieName);
    this.eventBus.$emit("invalidateAuthorizationTokenDone", tokenEmpty);
  }
}

export default {
  Agent,
};

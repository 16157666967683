<template>
  <v-card flat class="tab-card-client">
    <v-btn
      @click.native="showNewUserModal('new', '')"
      id="newClient"
      class="normal-btn white--text create-new-client-button primary"
    >Create New User</v-btn>
    <div class="new-client-button-clear"></div>
    <v-layout class="search">
      <v-flex xs2 class="filter-by">FILTER BY:</v-flex>
      <v-flex xs4>
        <v-select
          placeholder="Select Client ACL Role"
          id="client-user-list--client-acl-role"
          :items="clientAclRoles"
          single-line
          v-model="searchClientAclRole"
          @change="getUserFilterData"
        ></v-select>
      </v-flex>
      <v-flex xs4>
        <v-text-field
          label="User Email"
          id="client-user-list--user-email"
          class="excursion-filter-search excursion-input"
          single-line
          type="text"
          v-model="searchUserEmail"
          @keyup.enter="getUserFilterData"
        ></v-text-field>
      </v-flex>
      <v-flex xs4>
        <v-text-field
          label="Search Client Name"
          id="client-user-list--client-name"
          class="excursion-filter-search excursion-input"
          append-icon="search"
          single-line
          type="text"
          v-model="searchClient"
          @click:append="getUserFilterData"
          @keyup.enter="getUserFilterData"
        ></v-text-field>
      </v-flex>
    </v-layout>
      <v-data-table
        :headers="userHeaders"
        :options.sync="pagination"
        class="elevation-2 excursion-table"
        :footer-props="footerProps"
        :items="clientUserList"
        :loading="loading"
        :server-items-length="clientUserListTotal"
        no-data-text="No user was found"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td class="excursion-name-cell">
              <a
                  class="client-id-label"
                  id="updateUser"
                  @click="showUpdateUserModal(props.item.id)"
              >{{props.item.id}}</a>
            </td>
            <td class="text-xs-left">{{props.item.email}}</td>
            <td class="text-xs-left">{{getUserClientName(props.item.clientList)}}</td>
            <td class="text-xs-left">{{props.item.created_at}}</td>
            <td class="text-xs-left">
              <v-tooltip
                  v-if="props.item.enabled"
                  left
                  color="#ffffff"
              >
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-on="{ ...tooltip }"
                    icon
                    @click.native="
                    displayUserEnabledConfirmationDialog(props.item.id, props.item.enabled)
                  "
                  >
                    <i class="fas fa-check action-tooltip-icon"></i>
                  </v-btn>
                </template>
                <span class="action-tooltip-content">Disable</span>
              </v-tooltip>
              <v-tooltip
                  v-if="!props.item.enabled"
                  left
                  color="#ffffff"
              >
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                      v-on="{ ...tooltip }"
                      icon
                      @click.native="
                    displayUserEnabledConfirmationDialog(props.item.id, props.item.enabled)
                  "
                  >
                    <i class="fas fa-ban action-tooltip-icon"></i>
                  </v-btn>
                </template>
                <span class="action-tooltip-content">Enable</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <client-user-individual-modal
      v-if="userModalDialog"
      :propClientUserIndividualModalRefreshed="propClientUserIndividualModalRefreshed"
      :propUserId="propUserId"
      @reloadUsers="getUserFilterData"
    />
    <div>
      <v-dialog v-model="userEnabledConfirmationDialogDisplayed" persistent max-width="490">
        <v-card>
          <v-card-text>
            {{userEnabledConfirmationDialogMessage}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="normal-btn white--text primary"
              @click.native="confirmUserEnabledToggle()"
            >{{userEnabledConfirmationDialogButtonLabel}}</v-btn>
            <v-btn
              class="normal-btn"
              @click.native="userEnabledConfirmationDialogDisplayed = false"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import aclRole from "../lib/const/aclRole";

export default {
  props: [],
  data() {
    return {
      userEnabledConfirmationDialogDisplayed: false,
      userEnabledConfirmationDialogMessage: "",
      userEnabledConfirmationDialogButtonLabel: "",
      userEnabledConfirmationDialogUserId: 0,
      userEnabledConfirmationDialogUserEnabled: true,
      userModalDialog: false,
      propClientUserIndividualModalRefreshed: true,
      propUserId: "",
      footerProps: {
        'items-per-page-options': [
          10,
          30,
          50,
        ]
      },
      pagination: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [false],
        sortBy: [''],
      },
      userHeaders: [
        {
          text: "User ID",
          value: "item.id",
          class: "header",
        },
        {
          text: "User Email",
          value: "item.email",
          class: "header",
        },
        {
          text: "Client",
          value: "item.client_name",
          class: "header",
        },
        {
          text: "Date Created",
          value: "item.created_at",
          class: "header",
        },
        {
          text: "Action",
          value: "item.enabled",
          class: "header",
        },
      ],
      searchUserEmail: "",
      searchClientAclRole: "",
      searchClient: "",
    };
  },
  computed: {
    clientUserList() {
      return this.$store.state.clientUserList.data;
    },
    loading() {
      return this.$store.state.clientUserList.loading;
    },
    clientUserListTotal() {
      return this.$store.state.clientUserList.total;
    },
    clientAclRoles() {
      const clientAclRoles = [
        { value: aclRole.CLIENT_ADMIN, text: aclRole.CLIENT_ADMIN },
        { value: aclRole.CLIENT_ADMIN_ASSISTANT, text: aclRole.CLIENT_ADMIN_ASSISTANT },
        { value: aclRole.CLIENT_APPROVER, text: aclRole.CLIENT_APPROVER },
        { value: aclRole.CLIENT_LITE_USER, text: aclRole.CLIENT_LITE_USER },
        { value: aclRole.CLIENT_USER, text: aclRole.CLIENT_USER },
        { value: aclRole.CLIENT_WATCHER, text: aclRole.CLIENT_WATCHER },
      ];
      if (this.$store.state.clientUserGroupRoleDisplayed) {
        clientAclRoles.push(
          { value: aclRole.CLIENT_GROUP_ADMIN, text: aclRole.CLIENT_GROUP_ADMIN },
          { value: aclRole.CLIENT_GROUP_APPROVER, text: aclRole.CLIENT_GROUP_APPROVER },
          { value: aclRole.CLIENT_GROUP_USER, text: aclRole.CLIENT_GROUP_USER },
          { value: aclRole.CLIENT_GROUP_WATCHER, text: aclRole.CLIENT_GROUP_WATCHER },
        );
      }
      return clientAclRoles;
    },
  },
  methods: {
    displayUserEnabledConfirmationDialog(userId, enabled) {
      if (enabled) {
        this.userEnabledConfirmationDialogMessage = "Are you sure you want to disable this user?";
        this.userEnabledConfirmationDialogButtonLabel = "Disable";
      } else {
        this.userEnabledConfirmationDialogMessage = "Are you sure you want to enable this user?";
        this.userEnabledConfirmationDialogButtonLabel = "Enable";
      }
      this.userEnabledConfirmationDialogUserId = userId;
      this.userEnabledConfirmationDialogDisplayed = true;
      this.userEnabledConfirmationDialogUserEnabled = !enabled;
    },
    async confirmUserEnabledToggle() {
      const store = this.$store;
      const requestParam = {
        "enabled": this.userEnabledConfirmationDialogUserEnabled,
      };
      const argHash = {
        id: this.userEnabledConfirmationDialogUserId,
        payload: requestParam,
      };
      await this.$app.stbApiAdapter.putClientUserEnabled(argHash);
      store.dispatch("toggleClientUserEnabled", this.userEnabledConfirmationDialogUserId);
      this.userEnabledConfirmationDialogDisplayed = false;
    },
    showNewUserModal() {
      this.userModalDialog = true;
      this.propClientUserIndividualModalRefreshed = !this.propClientUserIndividualModalRefreshed;
      this.propUserId = "";
    },
    showUpdateUserModal(userId) {
      this.userModalDialog = true;
      this.propClientUserIndividualModalRefreshed = !this.propClientUserIndividualModalRefreshed;
      this.propUserId = `${userId}`;
    },
    getUserClientName(clientList) {
      return (
        (clientList && (0 < clientList.length) && clientList[0].metadata)
          ? clientList[0].metadata.name
          : ""
      );
    },
    async getUserFilterData() {
      this.$store.state.clientUserList.loading = true;
      const filterParam = {
        "s-user-email": this.searchUserEmail,
        "s-client-acl-role": this.searchClientAclRole,
        "s-client-name": this.searchClient,
        page: this.pagination.page,
        length: this.pagination.itemsPerPage,
        direction: (false === this.pagination.sortDesc[0]) ? "ASC" : "DESC",
      };

      Object.keys(filterParam).forEach((key) => {
        if ("" === filterParam[key]) {
          delete filterParam[key];
        }
      });

      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        filterParam,
      };
      await this.$store.dispatch("getClientUserList", argHash);
    },
  },
  mounted() {

  },
  watch: {
    pagination: {
      handler() {
        this.getUserFilterData();
      },
    },
  },
};
</script>

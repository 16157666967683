// TODO: Rename this module from selectCustom to select2Util
export default {
  matchCustom(params, data) {
    // If there are no search terms, return all of the data
    if ("" === $.trim(params.term)) {
      return data;
    }

    // Skip if there is no 'children' property
    if ("undefined" === typeof data.children) {
      return null;
    }

    // `data.children` contains the actual options that we are matching against
    const filteredChildren = [];
    $.each(data.children, function (idx, child) {
      if ("-1" < `${child.text.toUpperCase().indexOf(params.term.toUpperCase())}`) {
        filteredChildren.push(child);
      }
    });

    // If we matched any of the timezone group's children,
    // then set the matched children on the group
    // and return the group object
    if (filteredChildren.length) {
      const modifiedData = $.extend({}, data, true);
      modifiedData.children = filteredChildren;

      // You can return modified objects from here
      // This includes matching the `children` how you want in nested data sets
      return modifiedData;
    }

    // Return `null` if the term should not be displayed
    return null;
  },
};

// TODO: Possibly use dependency injections
import Vue from "vue";
import Router from "vue-router";
import App from "../App";
import ClientAdminLayout from "../components/ClientAdminLayout";
import ExcursionBuilder from "../components/ExcursionBuilder";
import EntityManagement from "../components/EntityManagement";
import SafeTripBuilder from "../components/SafeTripBuilder";
import SupportFeedbackLayout from "../components/SupportFeedbackLayout";
import Login from "../components/Login";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/oauth2/callback",
      name: "Oauth2",
      component: App,
    },
    {
      path: "/excursions/builder/new",
      name: "ExcursionBuilderNewRecord",
      component: ExcursionBuilder,
    },
    {
      path: "/excursions/builder/:id",
      name: "ExcursionBuilderExistingRecord",
      component: ExcursionBuilder,
    },
    {
      path: "/excursions/new",
      name: "SafeTripBuilderNewRecord",
      component: SafeTripBuilder,
    },
    {
      path: "/excursions/:id",
      name: "SafeTripBuilderExistingRecord",
      component: SafeTripBuilder,
    },
    {
      path: "/excursions",
      name: "Excursions",
      component: EntityManagement,
    },
    {
      path: "/templates",
      name: "Templates",
      component: EntityManagement,
    },
    {
      path: "/clients",
      name: "Clients",
      component: EntityManagement,
    },
    {
      path: "/clients/users",
      name: "ClientUsers",
      component: EntityManagement,
    },
    {
      path: "/clients/admin",
      name: "ClientAdminLayout",
      component: ClientAdminLayout,
    },
    {
      path: "/feedback",
      name: "SupportFeedbackLayout",
      component: SupportFeedbackLayout,
    },
    {
      path: "/users",
      name: "Users",
      component: EntityManagement,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (
    ("SafeTripBuilderExistingRecord" === to.name)
    && (to.params && ("0" === to.params.id))
  ) {
    next("/excursions/new");
  }
  if (
    ("ExcursionBuilderExistingRecord" === to.name)
    && (to.params && ("0" === to.params.id))
  ) {
    next("/excursions/builder/new");
  }
  if ("/" === to.path || !to.name) {
    next("/excursions");
  }
  next();
});

export default router;

/* eslint-disable no-param-reassign,no-underscore-dangle,no-unused-vars,guard-for-in,no-restricted-syntax,no-undef,no-console */

import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import enumerable from 'linq';
import jwtDecode from 'jwt-decode';
import lodash from 'lodash';
import mixpanelBrowser from 'mixpanel-browser';
import moment from 'moment-timezone';
import _store from 'store2';
import uuid from 'uuid';
import VueCropper from 'vue-cropperjs';
import VueMarkdown from 'vue-markdown';

import App from './App';
import router from './router';
import storeConfig from './store/store';

// CSS
import 'select2/dist/css/select2.min.css';
import 'vuetify/dist/vuetify.css';
import './assets/css/main.css';

// Additional dependencies
import auth from './lib/auth';
import axios from './lib/axiosCustom';
import componentOption from './lib/componentOption';
import deviceDetector from './lib/deviceDetector';
import excursionCommon from './lib/excursionCommon';
import pdf from './lib/pdf';
import pluginCommon from './lib/pluginCommon';
import selectCustom from './lib/selectCustom';
import stbApi from './lib/stbApi';
import CsDesignSystem, { vuetify, i18n } from '@complispace/cs-design-system';

// Component imports
import ActivityRiskCategory from './components/ActivityRiskCategory';
import AdditionalInfoList from './components/AdditionalInfoList';
import ChecklistItemComment from './components/ChecklistItemComment';
import ClientIndividualModal from './components/ClientIndividualModal';
import ClientList from './components/ClientList.vue';
import ClientUserIndividualModal from './components/ClientUserIndividualModal.vue';
import ClientUserList from './components/ClientUserList.vue';
import CompleteChecklist from './components/CompleteChecklist';
import ExcursionBuilder from './components/ExcursionBuilder';
import ExcursionConfirmation from './components/ExcursionConfirmation';
import ExcursionCopyModal from './components/ExcursionCopyModal';
import ExcursionHistory from './components/ExcursionHistory';
import ExcursionList from './components/ExcursionList.vue';
import ExcursionStepper from './components/ExcursionStepper';
import ExportExcursionPolicy from './components/ExportExcursionPolicy';
import ExportExcursionRiskAssessment from './components/ExportExcursionRiskAssessment.vue';
import HighRisk from './components/HighRisk.vue';
import MandatoryChecklist from './components/MandatoryChecklist';
import PageLoadingAnimation from './components/PageLoadingAnimation';
import Providers from './components/Providers';
import RiskCategory from './components/RiskCategory';
import RiskClass from './components/RiskClass';
import RiskRatingSummary from './components/RiskRatingSummary';
import SelectControl from './components/SelectControl';
import StaffStudentSupervision from './components/StaffStudentSupervision.vue';
import SystemUserIndividualModal from './components/SystemUserIndividualModal.vue';
import SystemUserList from './components/SystemUserList.vue';
import UpdateProviders from './components/UpdateProviders';
import ViewAllMandatoryChecklist from './components/ViewAllMandatoryChecklist';
import ViewChecklist from './components/ViewMandatoryChecklist';
import ViewPolicy from './components/ViewPolicy';
import ViewPredepartureChecklist from './components/ViewPredepartureChecklist';
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;

Vue.config.productionTip = false;

Vue.use(Vuex);

// Component injections
Vue.component("activity-risk", ActivityRiskCategory);
Vue.component("additional-info-list", AdditionalInfoList);
Vue.component("checklist-item-comment", ChecklistItemComment);
Vue.component("client-individual-modal", ClientIndividualModal);
Vue.component("client-list", ClientList);
Vue.component("client-user-individual-modal", ClientUserIndividualModal);
Vue.component("client-user-list", ClientUserList);
Vue.component("complete-checklist", CompleteChecklist);
Vue.component("excursion-builder", ExcursionBuilder);
Vue.component("excursion-confirmation", ExcursionConfirmation);
Vue.component("excursion-copy-modal", ExcursionCopyModal);
Vue.component("excursion-history", ExcursionHistory);
Vue.component("excursion-list", ExcursionList);
Vue.component("excursion-stepper", ExcursionStepper);
Vue.component("export-excursion-policy", ExportExcursionPolicy);
Vue.component("export-excursion-risk-assessment", ExportExcursionRiskAssessment);
Vue.component("high-risk", HighRisk);
Vue.component("mandatory-checklist", MandatoryChecklist);
Vue.component("page-loading-animation", PageLoadingAnimation);
Vue.component("providers", Providers);
Vue.component("risk-category", RiskCategory);
Vue.component("risk-class", RiskClass);
Vue.component("risk-rating-summary", RiskRatingSummary);
Vue.component("select-control", SelectControl);
Vue.component("staff-student-supervision", StaffStudentSupervision);
Vue.component("system-user-individual-modal", SystemUserIndividualModal);
Vue.component("system-user-list", SystemUserList);
Vue.component("update-providers", UpdateProviders);
Vue.component("view-all-mandatory-checklist", ViewAllMandatoryChecklist);
Vue.component("view-checklist", ViewChecklist);
Vue.component("view-policy", ViewPolicy);
Vue.component("view-predeparture-checklist", ViewPredepartureChecklist);
Vue.component("vue-cropper", VueCropper);
Vue.component("vue-markdown", VueMarkdown);

// Additional dependency injections
const eventBus = new Vue();
const authAgent = new auth.Agent(axios, Cookies, env, eventBus, jwtDecode, window);
const { Appcues } = window;

const pluginCommonAdapter = new pluginCommon.Adapter({
  Appcues,
  authAgent,
  env,
  mixpanelBrowser,
  window
});

const stbApiAdapter = new stbApi.Adapter({
  axios,
  env
});

const app = {
  Appcues,
  authAgent,
  axios,
  componentOption,
  deviceDetector,
  enumerable,
  env,
  eventBus,
  excursionCommon,
  lodash,
  mixpanelBrowser,
  moment,
  pdf,
  pluginCommonAdapter,
  selectCustom,
  stbApiAdapter,
  uuid,
  window
};
Object.defineProperty(Vue.prototype, '$app', { value: app });

const store = new Vuex.Store(storeConfig);
_store({ initState: store.state });

Vue.use(CsDesignSystem, { store, router });

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

/* eslint-enable no-param-reassign,no-underscore-dangle,no-unused-vars,guard-for-in,no-restricted-syntax,no-undef */

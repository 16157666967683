import apiErrorMessage from "./content/apiErrorMessage";
import errorSid from "./const/errorSid";
import excursionStatus from "./const/excursionStatus";

async function excursionResponseHandler(axios, axiosConfig, cbResponse) {
  const axiosResponse = await axios(axiosConfig);

  if (axiosResponse && axiosResponse.data.data) {
    const excursions = axiosResponse.data.data;
    cbResponse.error = !Array.isArray(excursions);

    if (0 < excursions.length) {
      cbResponse.data = excursions[0];
    }
  }

  return cbResponse;
}

async function providerResponseHandler(axios, axiosConfig) {
  const axiosResponse = await axios(axiosConfig);
  return axiosResponse.data;
}

async function riskResponseHandler(axios, axiosConfig) {
  const axiosResponse = await axios(axiosConfig);
  return axiosResponse.data.data;
}
class Adapter {
  constructor(dependencyHash) {
    const {
      axios,
      env,
    } = dependencyHash;

    this.axios = axios;
    this.apiUrl = env.API_URL;
  }

  async getHealthAlive() {
    const url = "/health/alive";
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data;
  }

  async getRisks(argHash) {
    const {
      store,
      queryParam,
      excursionTypeName,
      excursionCopyModalHandlingExpected,
    } = argHash;

    let cbResponse = {
      error: true,
    };

    if (excursionTypeName) {
      let query = queryParam;
      if (!query) {
        query = {};
      }

      const apiParam = JSON.parse(JSON.stringify(query));
      if (!apiParam["vendor-sid"] && !apiParam["oauth-signature"]) {
        const userAuthSchemeApiParam = store.state.userAuthSchemeApiParam;
        $.extend(apiParam, userAuthSchemeApiParam);
      }
      const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

      const url = `/v1/cascading-risks/pcg/risk-categories/?${querystring}`;

      const axiosConfig = {
        method: "POST",
        baseURL: this.apiUrl,
        url,
        data: {
          "excursion-type": excursionTypeName,
        },
      };

      if (excursionCopyModalHandlingExpected) {
        try {
          cbResponse = await riskResponseHandler(this.axios, axiosConfig);
        } catch (error) {
          store.state.allRisksInitialized = true;
          store.state.isResponseWithError = false;
          cbResponse = [];
        }
      } else {
        cbResponse = await riskResponseHandler(this.axios, axiosConfig);
      }
    }

    return cbResponse;
  }

  async getCompleteChecklist(argHash) {
    const { store, queryParam, frcs } = argHash;

    let localFrcs = [];
    if (frcs) {
      localFrcs = JSON.parse(JSON.stringify(frcs));

      if (store.state.excursion.typeId) {
        localFrcs.unshift([store.state.excursion.typeId]);
      }
    }

    let query = queryParam;
    if (!query) {
      query = {};
    }
    query["excursion-id"] = store.state.excursion.id;
    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam["vendor-sid"] && !apiParam["oauth-signature"]) {
      const userAuthSchemeApiParam = store.state.userAuthSchemeApiParam;
      $.extend(apiParam, userAuthSchemeApiParam);
    }
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    const url = `/v1/cascading-risks/pcg/risk-bundles/?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: {
        "risk-categories": localFrcs,
      },
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data.data;
  }

  async getExcursionTypes(argHash) {
    const { store, queryParam } = argHash;
    let query = queryParam;
    if (!query) {
      query = {};
    }
    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam["vendor-sid"] && !apiParam["oauth-signature"]) {
      const userAuthSchemeApiParam = store.state.userAuthSchemeApiParam;
      $.extend(apiParam, userAuthSchemeApiParam);
    }
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/cascading-risks/pcg/excursion-types?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    const cbResponse = {
      error: false,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      cbResponse.data = axiosResponse.data.data;
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getViewPolicy(argHash) {
    let { queryParam } = argHash;
    const { store, frcs } = argHash;

    let localFrcs = [];
    if (frcs) {
      localFrcs = JSON.parse(JSON.stringify(frcs));

      if (store.state.excursion.typeId) {
        localFrcs.unshift([store.state.excursion.typeId]);
      }
    }

    if (!queryParam) {
      queryParam = {};
    }
    queryParam["excursion-id"] = store.state.excursion.id;
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    if (!apiParam["vendor-sid"] && !apiParam["oauth-signature"]) {
      const userAuthSchemeApiParam = store.state.userAuthSchemeApiParam;
      $.extend(apiParam, userAuthSchemeApiParam);
    }
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/cascading-risks/pcg/risk-bundles?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: {
        "risk-categories": localFrcs,
      },
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data.data;
  }

  async postExcursionAssessments(argHash) {
    const {
      store,
      queryParam,
      excursionId,
      allChecklistsExported,
      clientLogoEnabled,
      predepartureChecklistExported,
    } = argHash;
    let query = queryParam;
    if (!query) {
      query = {};
    }
    const apiParam = JSON.parse(JSON.stringify(query));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    const url = `/v1/excursions/${excursionId}/assessments?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: {
        "all-checklists-exported": allChecklistsExported ? true : false,
        "predeparture-checklist-exported": predepartureChecklistExported ? true : false,
        "client-logo-enabled": clientLogoEnabled ? true : false,
      },
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data;
  }

  async postExcursionPredepartureStrategies(argHash) {
    const { store } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }

    const query = queryParam.params.query;
    const payload = queryParam.params.payload;

    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam["vendor-sid"] && !apiParam["oauth-signature"]) {
      const userAuthSchemeApiParam = store.state.userAuthSchemeApiParam;
      $.extend(apiParam, userAuthSchemeApiParam);
    }

    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/excursions/${queryParam.excursionId}/strategies/predeparture?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      "Content-Type": "application/json",
      data: payload,
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data;
  }

  async putExcursionPredepartureStrategies(argHash) {
    let queryParam = argHash.queryParam;
    const { store } = argHash;
    if (!queryParam) {
      queryParam = {};
    }

    const query = queryParam.params.query;
    const payload = queryParam.params.payload;

    const apiParam = JSON.parse(JSON.stringify(query));
    if (!apiParam["vendor-sid"] && !apiParam["oauth-signature"]) {
      const userAuthSchemeApiParam = store.state.userAuthSchemeApiParam;
      $.extend(apiParam, userAuthSchemeApiParam);
    }

    const predepartures = payload.predepartures;
    predepartures.forEach((predeparture) => {
      predeparture.id = `${predeparture.id}`;
    });

    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    const url = `/v1/excursions/${queryParam.excursionId}/strategies/predeparture?${querystring}`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      "Content-Type": "application/json",
      data: payload,
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data;
  }

  async getProviders(argHash) {
    const { store } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/providers?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
      "Content-Type": "application/json",
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data.data;
  }

  async postProviders(argHash) {
    const { store, excursionCopyModalHandlingExpected } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/providers?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      "Content-Type": "application/json",
    };

    if (excursionCopyModalHandlingExpected) {
      try {
        return await providerResponseHandler(this.axios, axiosConfig);
      } catch (error) {
        store.state.allProvidersInitialized = true;
        store.state.isResponseWithError = false;
        return [];
      }
    }

    return providerResponseHandler(this.axios, axiosConfig);
  }

  async getExcursionList(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true,
    };
    let querystring = "";
    if (filterParam) {
      querystring = Object.keys(filterParam).map(key => `${key}=${encodeURIComponent(filterParam[key])}`).join("&");
    }
    const url = `/v1/excursions?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    const axiosResponse = await this.axios(axiosConfig);
    if (axiosResponse && axiosResponse.data.data) {
      const excursions = axiosResponse.data.data;
      cbResponse.error = !Array.isArray(excursions);
      cbResponse.data = axiosResponse.data;
    }
    return cbResponse;
  }

  async getExcursions(argHash) {
    const {
      excursionId,
      query,
      store,
      excursionCopyModalHandlingExpected,
    } = argHash;

    // This querystring appended to the request URL to handle authentication using oauth-signature
    const querystring = Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join("&");

    const cbResponse = {
      error: true,
    };

    const url = `/v1/excursions/${excursionId}?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };

    if (excursionCopyModalHandlingExpected) {
      try {
        return await excursionResponseHandler(this.axios, axiosConfig, cbResponse);
      } catch (error) {
        store.state.excursionInitialized = true;
        store.state.isResponseWithError = false;
        return [];
      }
    }

    return excursionResponseHandler(this.axios, axiosConfig, cbResponse);
  }

  async getExcursionStudentMedicalConditions(argHash) {
    const {
      excursionId,
      query,
    } = argHash;

    const querystring = Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join("&");

    const cbResponse = {
      error: true,
    };

    const url = `/v1/excursions/${excursionId}/students/medical-conditions?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };

    const axiosResponse = await this.axios(axiosConfig);
    if (axiosResponse && axiosResponse.data.data) {
      const studentMedicalConditions = axiosResponse.data.data;
      cbResponse.error = !Array.isArray(studentMedicalConditions);
      cbResponse.data = axiosResponse.data;
    }

    return cbResponse;
  }

  async postExcursions(argHash) {
    let { queryParam } = argHash;
    const { store, payload } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    payload.metadata.ceCommitOid = store.state.ceCommitOid;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/excursions?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        cbResponse.message = apiErr.response.data.message;
      }
    }
    return cbResponse;
  }

  async putExcursions(argHash) {
    let { queryParam } = argHash;
    const { store, id, payload } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    payload.metadata.ceCommitOid = store.state.ceCommitOid;

    const cbResponse = {
      error: true,
    };
    const url = `/v1/excursions/${id}?${querystring}`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (apiErr && apiErr.response && apiErr.response.data) {
        if ("409" === `${apiErr.response.data.statusCode}`) {
          cbResponse.message = apiErrorMessage.excursion_conflict_message;
        }
      }
    }

    return cbResponse;
  }

  async postExcursionRiskContents(argHash) {
    let { queryParam } = argHash;
    const { store, params } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const excursionId = params["excursion-id"] ? params["excursion-id"] : 0;
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/excursions/${excursionId}/risk-contents?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: {
        "client-logo-enabled": params["client-logo-enabled"]
          ? params["client-logo-enabled"]
          : false,
        "mandatory-checklists-exported": params["mandatory-checklists-exported"]
          ? params["mandatory-checklists-exported"]
          : false,
        "category-checklists": params["category-checklists"]
          ? params["category-checklists"]
          : [],
        "master-policy-exported": params["master-policy-exported"]
          ? params["master-policy-exported"]
          : false,
        "category-checklists-exported": params["category-checklists-exported"]
          ? params["category-checklists-exported"]
          : false,
        "risk-categories": params["risk-categories"]
          ? params["risk-categories"]
          : [],
        "excursion-policies": params["excursion-policies"]
          ? params["excursion-policies"]
          : [],
      },
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data;
  }

  async getClientList(argHash) {
    const { filterParam } = argHash;
    const querystring = Object.keys(filterParam).map(key => `${key}=${encodeURIComponent(filterParam[key])}`).join("&");

    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        const clients = axiosResponse.data.data;
        cbResponse.error = !Array.isArray(clients);
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientUserClientList(argHash) {
    const { filterParam } = argHash;
    const querystring = Object.keys(filterParam).map(key => `${key}=${encodeURIComponent(filterParam[key])}`).join("&");

    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients/users/clients?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        const clients = axiosResponse.data.data;
        cbResponse.error = !Array.isArray(clients);
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClients(argHash) {
    const { payload } = argHash;
    let cbResponse = {
      error: true,
    };
    const url = `/v1/clients`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && (
          ("409" === `${apiErr.response.data.statusCode}`)
          || (
            ("400" === `${apiErr.response.data.statusCode}`)
            && (errorSid.INVALID_CONTENT_ENGINE_PARAMETER === apiErr.response.data.errorSid)
          )
        )
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async putClients(argHash) {
    const { sid, payload } = argHash;
    let cbResponse = {
      error: true,
    };
    const url = `/v1/clients/${sid}`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && (
          ("409" === `${apiErr.response.data.statusCode}`)
          || (
            ("400" === `${apiErr.response.data.statusCode}`)
            && (errorSid.INVALID_CONTENT_ENGINE_PARAMETER === apiErr.response.data.errorSid)
          )
          || (
            ("400" === `${apiErr.response.data.statusCode}`)
            && (errorSid.INVALID_PROVIDER_PARAMETER === apiErr.response.data.errorSid)
          )
        )
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async postExcursionAttachments(argHash) {
    let { queryParam } = argHash;
    const { excursionId, payload } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const cbResponse = {
      error: true,
    };
    const url = `/v1/excursions/${excursionId}/attachments?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientAttachment(argHash) {
    const { sid, payload } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients/${sid}/attachments/categories`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientSubjects(argHash) {
    const { sid, payload } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients/${sid}/attachments/subjects`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.clientApiMetadata) {
        cbResponse.error = false;
        cbResponse.clientApiMetadata = axiosResponse.data.clientApiMetadata;
        cbResponse.schoolType = axiosResponse.data.schoolType;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClients(argHash) {
    const { sid, requestParam } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients/${sid}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
      params: requestParam,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientDomains(argHash) {
    const { domain } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients/domains/${domain}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putExcursionAttachments(argHash) {
    let { queryParam } = argHash;
    const { excursionId, attachmentSid, payload } = argHash;
    const cbResponse = {
      error: true,
    };
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/excursions/${excursionId}/attachments/${attachmentSid}?${querystring}`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async deleteExcursionAttachments(argHash) {
    let { queryParam } = argHash;
    const { excursionId, attachmentSid } = argHash;
    const cbResponse = {
      error: true,
    };
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/excursions/${excursionId}/attachments/${attachmentSid}?${querystring}`;
    const axiosConfig = {
      method: "DELETE",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async deleteRiskCaches(argHash) {
    let { queryParam } = argHash;
    const cbResponse = {
      error: true,
    };
    if (!queryParam) {
      queryParam = {};
    }

    const query = {
      "ce-api-uri": queryParam.ceApiUri,
      "x-site-uri": queryParam.xSiteUri,
      "module-name": queryParam.moduleName,
    };

    const apiParam = JSON.parse(JSON.stringify(query));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/cascading-risks/caches?${querystring}`;
    const axiosConfig = {
      method: "DELETE",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && ("400" === `${apiErr.response.data.statusCode}`)
      ) {
        cbResponse.error = false;
        cbResponse.data = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getAttachments(argHash) {
    let { queryParam } = argHash;
    const { sid, store } = argHash;

    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const cbResponse = {
      error: true,
    };
    const url = `/v1/attachments/${sid}?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putExcursionStatuses(argHash) {
    let { queryParam, newStatus } = argHash;
    const { store, id } = argHash;
    if (!newStatus) {
      newStatus = excursionStatus.DRAFT;
    }
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    store.state.apiParam = apiParam;
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const payload = {
      status: newStatus,
    };

    const cbResponse = {
      error: true,
    };
    const url = `/v1/excursions/${id}/statuses?${querystring}`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getVendorList(argHash) {
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    const url = `/v1/vendors?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data.data;
  }

  async getStudentManagementVendorList() {
    const argHash = {
      queryParam: {
        type: "student",
      },
    };

    const vendorList = await this.getVendorList(argHash);
    return vendorList;
  }

  async getExcursionManagementVendorList() {
    const argHash = {
      queryParam: {
        type: "excursion",
      },
    };

    const vendorList = await this.getVendorList(argHash);
    return vendorList;
  }

  async postPredepartureStrategies(argHash) {
    const { payload } = argHash;
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    const url = `/v1/strategies/predeparture?${querystring}`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    const axiosResponse = await this.axios(axiosConfig);
    return axiosResponse.data.data;
  }

  async getClientUserList(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true,
    };

    const apiParam = JSON.parse(JSON.stringify(filterParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/clients/users?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getUserList(argHash) {
    const { filterParam } = argHash;
    const cbResponse = {
      error: true,
    };
    const apiParam = JSON.parse(JSON.stringify(filterParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");

    const url = `/v1/users?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async postClientUsers(argHash) {
    const { payload } = argHash;
    let cbResponse = {
      error: true,
    };
    const url = `/v1/clients/users`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload.user,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && ("409" === `${apiErr.response.data.statusCode}`)
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async postUsers(argHash) {
    const { payload } = argHash;
    let cbResponse = {
      error: true,
    };
    const url = `/v1/users`;
    const axiosConfig = {
      method: "POST",
      baseURL: this.apiUrl,
      url,
      data: payload.user,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && ("409" === `${apiErr.response.data.statusCode}`)
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async putClientUsers(argHash) {
    const { id, payload } = argHash;
    let cbResponse = {
      error: true,
    };
    const url = `/v1/clients/users/${id}`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload.user,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && ("409" === `${apiErr.response.data.statusCode}`)
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async putUsers(argHash) {
    const { id, payload } = argHash;
    let cbResponse = {
      error: true,
    };
    const url = `/v1/users/${id}`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload.user,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && ("409" === `${apiErr.response.data.statusCode}`)
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getClientUsers(argHash) {
    const { id } = argHash;
    let cbResponse = {
      error: true,
    };
    const url = `/v1/clients/users/${id}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      if (
        apiErr
        && apiErr.response
        && apiErr.response.data
        && ("409" === `${apiErr.response.data.statusCode}`)
      ) {
        cbResponse = apiErr.response.data;
      } else {
        cbResponse.error = true;
      }
    }
    return cbResponse;
  }

  async getUsers(argHash) {
    const { id } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/users/${id}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientSetups(argHash) {
    let { queryParam } = argHash;
    if (!queryParam) {
      queryParam = {};
    }
    const apiParam = JSON.parse(JSON.stringify(queryParam));
    const querystring = Object.keys(apiParam).map(key => `${key}=${encodeURIComponent(apiParam[key])}`).join("&");
    const url = `/v1/clients/setups?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    const cbResponse = {
      error: true,
    };

    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putClientUserEnabled(argHash) {
    const { id, payload } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients/users/${id}/enabled`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putUserEnabled(argHash) {
    const { id, payload } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/users/${id}/enabled`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getAuditExcursions(argHash) {
    const { id, query } = argHash;
    const querystring = Object.keys(query).map(key => `${key}=${encodeURIComponent(query[key])}`).join("&");

    const cbResponse = {
      error: true,
    };
    const url = `/v1/audit/excursions/${id}?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientStudentGroupList(argHash) {
    const { studentVendorParams } = argHash;
    const cbResponse = {
      error: true,
    };
    const querystring = Object.keys(studentVendorParams).map(key => `${key}=${encodeURIComponent(studentVendorParams[key])}`).join("&");
    const url = `/v1/clients/students/groups?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async putClientEnabled(argHash) {
    const { sid, payload } = argHash;
    const cbResponse = {
      error: true,
    };
    const url = `/v1/clients/${sid}/enabled`;
    const axiosConfig = {
      method: "PUT",
      baseURL: this.apiUrl,
      url,
      data: payload,
    };
    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }

  async getClientJurisdiction(argHash) {
    const cbResponse = {
      error: true,
    };

    const { queryParam } = argHash;
    const querystring = Object.keys(queryParam).map(key => `${key}=${encodeURIComponent(queryParam[key])}`).join("&");

    const url = `/v1/cascading-risks/pcg/jurisdiction?${querystring}`;
    const axiosConfig = {
      method: "GET",
      baseURL: this.apiUrl,
      url,
    };

    try {
      const axiosResponse = await this.axios(axiosConfig);
      if (axiosResponse && axiosResponse.data.data) {
        cbResponse.error = false;
        cbResponse.data = axiosResponse.data;
      }
    } catch (apiErr) {
      cbResponse.error = true;
    }
    return cbResponse;
  }
}

export default {
  Adapter,
  excursionResponseHandler,
  providerResponseHandler,
  riskResponseHandler,
};

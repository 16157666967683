export default {
  APP_NAME: "App Name",
  CS_CLIENT_ID: "CompliSpace Client ID",
  DESTINATION: "STB Destination",
  EXCURSION_ID: "STB Excursion ID",
  EXCURSION_NAME: "STB Excursion Name",
  EXCURSION_STATUS: "STB Excursion Status",
  EXCURSION_TYPE: "STB Excursion Type",
  JURISDICTION: "STB Jurisdiction",
  TENANT: "STB Tenant",
  USER_ROLE: "STB User Role",
  VENDOR: "STB Vendor",
};

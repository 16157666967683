// TODO: Possibly use dependency injections
import axios from "axios";
import store from "../store/store";

// http request interceptors
axios.interceptors.request.use(
  (config) => {
    if (
      store.state.impersonatedClientSid
      && ("0" !== `${store.state.impersonatedClientSid}`)
    ) {
      config.headers["x-impersonated-client-sid"] = store.state.impersonatedClientSid;
    } else {
      config.headers["x-impersonated-client-sid"] = "";
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  },
);

// http response interceptors
axios.interceptors.response.use(
  (response) => {
    store.state.isResponseWithError = false;
    return response;
  },
  (error) => {
    if (
      error
      && error.response
      && ("401" === `${error.response.status}` || "403" === `${error.response.status}`)
    ) {
      store.state.userAuthenticated = false;
    } else {
      store.state.isResponseWithError = true;
    }

    return Promise.reject(error);
  },
);

export default axios;

<template>
  <cs-view single-view>
    <v-container class="feedback-container" slot="content">
      <iframe class="support-feedback-iframe" src="https://portal.productboard.com/ryksles2e1n71xyjgjkvefhi?hide_header=1" frameborder="0"></iframe>
    </v-container>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';

export default {

  extends: CSBase,

  data() {
    return {
    };
  },

  async mounted() {
    const query = this.$route.query;

    this.$app.authAgent.enforceVendorOrUserAuthStrategy(this.$route, query);
  },
};
</script>

<style scoped>
.feedback-container {
  width: 100%;
  height: 100%;
  max-width: unset;
}
</style>

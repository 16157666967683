<template>
  <v-dialog v-model="dialog" scrollable>
    <template v-slot:activator="{ on }">
      <v-btn
          id="view-all-mandatory-checklist--view-checklist"
          v-on="on"
          :disabled="dialog"
          @click.stop="getData()"
          :loading="dialog"
          depressed
          style="padding:0px 5px;"
          class="normal-btn mandatory-checklist-button"
      >
        View Checklist
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="modalTitle">
        <v-flex>
          <strong>Complete Checklist</strong>
        </v-flex>
        <v-btn icon @click="dialog = false">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider class="modalDivider"></v-divider>
      <v-card-text class="checklist view-checklist-policy">
        <v-list v-for="(checklist,index) in checklists" :key="index">
          <v-subheader>
            <h1>{{checklist.title}}</h1>
          </v-subheader>
          <v-list v-for="(item, index) in getItemsByTitle(checklist)" :key="index">
            <div v-if="item.contentType ==='form-group'">
              <v-subheader  v-if="item.label.indexOf(removeLabel)!==0">
                <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
              </v-subheader>
              <div v-for="(option, index) in item.children" :key="index">
                <v-list-item>
                  <v-list-item-action v-if="'checkbox' === option.contentType">
                    <v-checkbox
                      color="#00adef"
                      v-model="option.ticked"
                      class="cbOption"
                      :checked="option.ticked"
                      :disabled="true"
                      :id="`checklist-item-checkbox-all--${option.id}`"
                    ></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <label class="text" :for="`checklist-item-checkbox-all--${option.id}`">
                      <vue-markdown :source="option.label" :typographer="false"></vue-markdown>
                    </label>
                  </v-list-item-content>
                </v-list-item>
                <checklist-item-comment :checklistItem="option" :commentDisabled="true">
                </checklist-item-comment>
              </div>
            </div>
            <div v-else-if="item.contentType === 'checkbox'">
              <v-list-item>
                <v-list-item-action>
                  <v-checkbox
                    color="#00adef"
                    v-model="item.ticked"
                    class="cbOption"
                    :checked="item.ticked"
                    :id="`checklist-item-checkbox-all--${item.id}`"
                    :disabled="true"
                  ></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <label class="text" :for="`checklist-item-checkbox-all--${item.id}`">
                    <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                  </label>
                </v-list-item-content>
              </v-list-item>
              <checklist-item-comment :checklistItem="item" :commentDisabled="true">
              </checklist-item-comment>
            </div>
            <div v-else>
              <v-subheader>
                <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
              </v-subheader>
              <checklist-item-comment :checklistItem="item" :commentDisabled="true">
              </checklist-item-comment>
            </div>
          </v-list>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="normal-btn" id="btnClose" @click.stop="close()">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [],
  data: () => ({
    dialog: false,
    removeLabel: "The following risk controls have been selected by default.",
  }),
  computed: {
    checklists() {
      return this.$store.state.excursion.mandatoryChecklist
        ? this.$store.state.excursion.mandatoryChecklist : [];
    },
  },
  methods: {
    getData() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    getItemsByTitle(obj) {
      if (!obj.Strategy) {
        return [];
      }

      const checklist = obj.Strategy.data[Object.keys(obj.Strategy.data)].children;
      const result = this.$app.lodash.cloneDeep(checklist);
      return result;
    },
  },
};
</script>

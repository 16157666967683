export default {
  DAY_EXCURSION: {
    label: "Day Excursion",
    value: "day-excursion",
    description: "A Day Excursion is a one-off excursion that does not involve an overnight stay. A Day Excursion can range from an excursion that takes a few hours through to a full day trip and includes activities that occur on School premises (i.e. incursions).",
  },
  OVERNIGHT_EXCURSION: {
    label: "Overnight Excursion",
    value: "overnight-excursion",
    description: "An Overnight Excursion is an excursion that involves one or more overnight stays within Australia.",
  },
  INTERNATIONAL_EXCURSION: {
    label: "International Excursion",
    value: "international-excursion",
    description: "An International Excursion is any excursion that requires international travel.",
  },
};

import attachmentCategory from "../lib/const/attachmentCategory";
import dbEntityRelation from "../lib/const/dbEntityRelation";
import excursionStatus from "../lib/const/excursionStatus";
// TODO: Possibly use dependency injections
import _ from "lodash";
import _store from "store2";
import moment from "moment-timezone";

export default {
  updateApiVersion(state, value) {
    state.apiVersion = value;
  },
  setRisks(state, risksFromApi) {
    if (!risksFromApi || risksFromApi.error) {
      return;
    }

    state.allRisks = [];

    risksFromApi.forEach(function (elementRisk, riskId) {
      const existRiskCategory = state.allRisks.find(r => r.name === elementRisk[1]);
      if (null != existRiskCategory) {
        return;
      }

      const riskCategory = {
        name: elementRisk[1],
        displayed: true,
        types: [],
      };

      state.allRisks.push(riskCategory);
    });

    state.allRisks.forEach(function (riskCategory) {
      const riskTypes = risksFromApi.filter(r => r[1] === riskCategory.name);

      riskTypes.forEach(function (riskTypeItem) {
        if (null != riskCategory.types.find(t => t.name === riskTypeItem[2])) {
          return;
        }
        riskCategory.types.push({
          name: riskTypeItem[2],
          classes: [],
        });

        riskCategory.types.forEach(function (riskType) {
          const riskClasses = risksFromApi.filter(r => r[2] === riskType.name);

          riskClasses.forEach(function (riskClass) {
            if (null != riskType.classes.find(c => c.name === riskClass[3])) {
              return;
            }

            const elementRiskClass = {
              name: riskClass[3],
              risks: [],
              disabled: false,
            };

            const risks = risksFromApi.filter(r => r[3] === elementRiskClass.name);

            risks.forEach(function (risk, riskIndex) {
              if ("" === risk) {
                return;
              }

              elementRiskClass.risks.push({
                name: risk[4],
                disabled: false,
              });
            });

            riskType.classes.push(elementRiskClass);
          });
        });
      });
    });

    // set selected category person responsible
    if (state.allRisks && 0 < state.allRisks.length
      && state.excursion.categories && 0 < state.excursion.categories.length) {
      state.allRisks.forEach(function (item) {
        const category = state.excursion.categories.find(function (c) {
          return c.name === item.name;
        });

        if (category) {
          item.personResponsible = category.personResponsible;
        }
      });
    }

    state.allRisksInitialized = true;
  },
  setRisksDisabled(state) {
    const metaExcursion = state.excursion.categories;
    if (!metaExcursion) {
      return;
    }
    const allRisks = state.allRisks;
    metaExcursion.forEach(function (category) {
      if (category.name !== state.activitiesCategoryName) {
        category.types.forEach(function (type) {
          type.classes.forEach(function (elementClass) {
            const setTypes = allRisks.find(r => (r.name === category.name));
            if (setTypes && setTypes.types) {
              const setClasses = setTypes.types.find(t => (t.name === type.name));
              if (setClasses && setClasses.classes) {
                const setElementClass = setClasses.classes
                .find(c => (c.name === elementClass.name));
                if (setElementClass) {
                  setElementClass.disabled = true;
                }
              }
            }
          });
        });
      } else {
        category.types.forEach(function (type) {
          type.classes.forEach(function (elementClass) {
            if (elementClass.risks) {
              elementClass.risks.forEach(function (risk) {
                const setTypes = allRisks.find(r => (r.name === category.name));
                if (setTypes && setTypes.types) {
                  const setClasses = setTypes.types.find(t => (t.name === type.name));
                  if (setClasses && setClasses.classes) {
                    const setRiskClass = setClasses.classes
                    .find(c => (c.name === elementClass.name));
                    if (setRiskClass && setRiskClass.risks) {
                      const setRisk = setRiskClass.risks.find(r => (r.name === risk.name));
                      if (setRisk) {
                        setRisk.disabled = true;
                      }
                    }
                  }
                }
              });
            }
          });
        });
      }
    });
  },
  changeSelectRiskClass(state, { riskClass, riskTypeName, riskCategory }) {
    // query riskType from risks
    const selectCategory = state.allRisks.find(risk => risk.name === riskCategory.name);
    let selectType = {};
    selectCategory.types.forEach(function (type) {
      if (type.name === riskTypeName) {
        selectType = {
          name: type.name,
        };

        // set disabled
        type.classes.forEach(function (elementClass) {
          if (elementClass.name === riskClass.name) {
            elementClass.disabled = true;
          }
          return false;
        });
        return false;
      }
      return false;
    });

    // add category
    const existCategory = state.excursion.categories.find(r => r.name === riskCategory.name);
    if (null == existCategory) {
      state.excursion.categories.push({
        name: selectCategory.name,
        types: [{
          name: selectType.name,
          classes: [{
            name: riskClass.name,
            dialog: false,
          }],
        }],
      });
    } else {
      const existType = existCategory.types.find(c => c.name === selectType.name);
      if (null == existType) {
        existCategory.types.push({
          name: selectType.name,
          classes: [{
            name: riskClass.name,
            dialog: false,
          }],
        });
      } else {
        existType.classes.push({
          name: riskClass.name,
          dialog: false,
        });
      }
    }
  },
  changeActivitySelectRisk(state, { risk, riskClassName }) {
    const selectedRisks = state.excursion.categories
    .find(c => c.name === state.activitiesCategoryName);
    const names = riskClassName.split(",");
    const typeName = names[1];
    const className = names[0];
    const selectType = state.allRisks
    .find(c => c.name === state.activitiesCategoryName).types
    .find(t => t.name === typeName);
    const selectClass = selectType.classes.find(c => c.name === className);
    const selectRisk = selectClass.risks.find(r => r.name === risk.name);

    selectRisk.disabled = true;
    if (null == selectedRisks) {
      state.excursion.categories.push({
        name: state.activitiesCategoryName,
        types: [{
          name: selectType.name,
          classes: [{
            name: selectClass.name,
            dialog: false,
            risks: [{
              name: selectRisk.name,
              dialog: false,
            }],
          }],
        }],
      });
    } else {
      const existType = selectedRisks.types.find(t => t.name === selectType.name);
      if (null == existType) {
        selectedRisks.types.push({
          name: selectType.name,
          classes: [{
            name: selectClass.name,
            dialog: false,
            risks: [{
              name: selectRisk.name,
              dialog: false,
            }],
          }],
        });
      } else {
        const existClass = selectedRisks.types.find(t => t.name === selectType.name)
        .classes.find(c => c.name === selectClass.name);
        if (null == existClass) {
          existType.classes.push({
            name: selectClass.name,
            dialog: false,
            risks: [{
              name: selectRisk.name,
              dialog: false,
            }],
          });
        } else {
          existClass.risks.push({
            name: risk.name,
            dialog: false,
          });
        }
      }
    }
  },
  changeHighRiskType(state, { riskTypeName, riskCategory }) {
    // query riskType from risks
    const selectCategory = state.allRisks.find(risk => risk.name === riskCategory.name);

    const type = selectCategory.types.find(t => t.name === riskTypeName);
    type.disabled = true;

    // add category
    const existCategory = state.excursion.categories.find(r => r.name === riskCategory.name);
    if (null == existCategory) {
      state.excursion.categories.push({
        name: selectCategory.name,
        types: [{
          name: riskTypeName,
        }],
      });
    } else {
      const existType = existCategory.types.find(c => c.name === riskTypeName);
      if (null == existType) {
        existCategory.types.push({
          name: riskTypeName,
        });
      }
    }
  },
  updateExcursionName(state, name) {
    state.excursion.name = name;
  },
  updateExcursionStep(state, currentStep) {
    state.excursion.currentStep = currentStep;
  },
  updateVendorExcursionId(state, vendorExcursionSid) {
    state.excursion.vendorData.vendorExcursionSid = vendorExcursionSid;
  },
  updateExcursionType(state, type) {
    state.excursion.typeId = type;
  },
  updateExcursionJurisdiction(state, value) {
    state.excursion.jurisdiction = value;
  },
  updateClearCreateRiskAssessment(state, value) {
    state.clearCreateRiskAssessment = value;
  },
  updateSelectedActivityTypeFilter(state, value) {
    state.selectedActivityTypeFilter = value;
  },
  updateSelectedActivityClassFilter(state, value) {
    state.selectedActivityClassFilter = value;
  },
  updateAdditionalInformations(state, additionalInformationItems) {
    state.excursion.additionalInformationItems = additionalInformationItems;
  },
  updateShowExcursionStepper(state, value) {
    state.showExcursionStepper = value;
  },
  updateShowToolbar(state, value) {
    state.showToolbar = value;
  },
  updatePrevRouteName(state, value) {
    state.prevRouteName = value;
  },
  updateShowExcursionLogo(state, value) {
    state.excursionLogoDisplayReady = value;
  },
  updateExcursionConfirmation(state, agreementConfirmation) {
    state.excursion.agreementConfirmation = agreementConfirmation;
  },
  updateMandatoryLoadingCompleted(state, mandatoryLoadActionCompleted) {
    state.mandatoryLoadActionCompleted = mandatoryLoadActionCompleted;
  },
  resetCategoriesAndRisks(state, typeId) {
    state.excursion.categories = [];
  },
  resetParentClient(state, value) {
    state.parentClientList.loading = false;
    state.parentClientList.items = [];
  },
  updateParentClientItems(state, value) {
    state.parentClientList.items = value;
  },
  setExcursionTypeChoiceList(state, excursionTypeChoiceList) {
    state.excursionTypeChoiceList = excursionTypeChoiceList;
  },
  setDefaultJurisdiction(state, jurisdiction) {
    state.apiDataDefaultJurisdiction = jurisdiction;
  },
  resetClientUserClientList(state, value) {
    state.clientUserClientList.loading = false;
    state.clientUserClientList.items = [];
  },
  updateClientUserClientListItems(state, value) {
    state.clientUserClientList.items = value;
  },
  setProviders(state, providers) {
    const allProviders = [];
    state.allProvidersInitialized = true;
    providers.forEach(function (provider) {
      allProviders.push({
        id: provider["Provider - Key"],
        name: provider["Provider - Name"],
        type: provider["Services Provided"],
        answer: provider["Provider - Answer"],
      });
    });
    state.allProviders = allProviders;
  },
  updateMandatoryChecklist(state, values) {
    if (
      state.excursion.mandatoryChecklist
      && (0 < state.excursion.mandatoryChecklist.length)
    ) {
      state.excursion.mandatoryChecklist
        = state.excursion.mandatoryChecklist.map(function (item) {
          if (item.title === values.title) {
            item.Strategy.data[Object.keys(item.Strategy.data)].children = values.checklist;
          }
          return item;
        });
    }
  },
  resetStore(state) {
    state.allRisks = [];
    state.excursion.categories = [];
    state.excursion.mandatoryChecklist = [];
    state.excursion.additionalInformationItems = [];
    state.excursion.additionalInformationNotes = [];
    state.excursion.status = "draft";
    state.excursion.currentStep = 1;
    state.excursion.agreementConfirmation = {
      ticked: false,
      statement1: "",
      statement2: "",
    };
    state.excursion.name = "";
    state.excursion.typeId = "";
    state.excursion.riskOverall = {};
    state.excursion.riskCategories = [];
    state.excursion.clientTemplate = {};
    state.excursion.staffSupervisorAttendanceCount = "";
    state.excursion.studentAttendanceCount = "";

    if (
      state.vendorExcursionSidInputEnabled
      && state.excursion.vendorData
      && state.excursion.vendorData.vendorExcursionSid
    ) {
      state.excursion.vendorData.vendorExcursionSid = "";
    }
  },
  resetStoreWhenChangingExcursionType(state) {
    state.excursion.categories = [];
  },
  setExcursion(state, cbResponse) {
    state.excursionInitialized = true;
    const defaultExcursion = {
      id: 0,
      metadata: {
        name: "",
        categories: [],
        vendorData: {},
      },
    };
    const excursionFromCb = ("undefined" !== typeof cbResponse.data)
      ? cbResponse.data
      : defaultExcursion;

    state.excursion.id = excursionFromCb.id;
    state.excursion.created_at = excursionFromCb.created_at;
    state.excursion.name = excursionFromCb.metadata.name;
    state.excursion.currentStep = excursionFromCb.metadata.currentStep;
    state.excursion.excursionDestinationContentEnabled
      = excursionFromCb.metadata.excursionDestinationContentEnabled;
    state.excursion.typeId = excursionFromCb.metadata.typeId;
    state.excursion.jurisdiction = excursionFromCb.metadata.jurisdiction;
    state.excursion.schemaVersion = excursionFromCb.metadata.schemaVersion;
    state.originalTypeId = excursionFromCb.metadata.typeId;
    state.excursion.categories
      = excursionFromCb.metadata.categories
        ? excursionFromCb.metadata.categories
        : [];
    state.excursion.status = excursionFromCb.metadata.status
      ? excursionFromCb.metadata.status
      : excursionStatus.DRAFT;
    state.excursion.mandatoryChecklist
       = excursionFromCb.metadata.mandatoryChecklist
        ? excursionFromCb.metadata.mandatoryChecklist
        : [];
    state.excursion.updateSignature = excursionFromCb.metadata.updateSignature;
    state.excursion.vendorData.vendorExcursionSid = (
      excursionFromCb.metadata.vendorData
      && excursionFromCb.metadata.vendorData.vendorExcursionSid
    )
      ? excursionFromCb.metadata.vendorData.vendorExcursionSid
      : "";
    state.showError = cbResponse.error;

    state.showExcursionStepper = ("0" === `${state.excursion.id}`)
      || ("draft" === state.excursion.status);
    state.excursion.agreementConfirmation = excursionFromCb.metadata.agreementConfirmation;

    let additionalInformationItems = [];
    if (
      excursionFromCb.metadata.additionalInformationNotes
      && (0 < excursionFromCb.metadata.additionalInformationNotes.length)
    ) {
      const additionalInformationNotes = excursionFromCb.metadata.additionalInformationNotes;
      state.excursion.additionalInformationNotes
        = JSON.parse(JSON.stringify(additionalInformationNotes));
      additionalInformationNotes.forEach((item) => {
        const noteItem = {
          orderId: item.orderId,
        };
        const fileList = excursionFromCb[dbEntityRelation.ATTACHMENT_LIST];
        let fileFound;
        if (fileList && (0 < fileList.length)) {
          fileFound
            = fileList.find(file => !file.deleted && (file.sid === item.sid));
          if (!fileFound) {
            fileFound
              = fileList.find(file => !file.deleted && (file.metadata.orderId === item.orderId));
          }
          if (fileFound) {
            const file = fileFound.metadata;
            noteItem.sid = fileFound.sid;
            noteItem.filename = file.filename;
            noteItem.comment = file.description;
            noteItem.fileSummary = file.summary;
            if (moment(fileFound.created_at).isValid()) {
              noteItem.fileUpdateDate = moment(fileFound.created_at);
            }
          }
        }

        if (!fileFound) {
          noteItem.notes = item.notes;
          noteItem.fileSummary = item.fileSummary;
        }
        additionalInformationItems.push(noteItem);
      });

      additionalInformationItems = additionalInformationItems.sort((a, b) => {
        return a.orderId - b.orderId;
      });
    }
    state.excursion.additionalInformationItems = additionalInformationItems;

    if (excursionFromCb.metadata.riskOverall) {
      state.excursion.riskOverall = excursionFromCb.metadata.riskOverall;
    }
    if (excursionFromCb.metadata.riskCategories) {
      state.excursion.riskCategories = excursionFromCb.metadata.riskCategories;
    }
    if (excursionFromCb.metadata.predepartures) {
      state.excursion.predepartures = excursionFromCb.metadata.predepartures;
    }

    state.excursion.predepartureChecklistCompleted
      = excursionFromCb.metadata.predepartureChecklistCompleted
        ? excursionFromCb.metadata.predepartureChecklistCompleted
        : false;

    state.excursion.staffSupervisorAttendanceCount
      = excursionFromCb.metadata.staffSupervisorAttendanceCount;
    state.excursion.studentAttendanceCount
      = excursionFromCb.metadata.studentAttendanceCount;
    state.excursion.syncAvailable = excursionFromCb.metadata.syncAvailable ? true : false;
    state.excursion.excursionTemplateFlag
      = excursionFromCb.metadata.excursionTemplateFlag ? true : false;

    if (
      excursionFromCb.client
      && excursionFromCb.client.attachmentList
      && (0 < excursionFromCb.client.attachmentList.length)
    ) {
      const clientAttachments = excursionFromCb.client.attachmentList;
      const clientTemplateFile = clientAttachments.filter(
        a => !a.deleted && (attachmentCategory.PROFORMA_TEMPLATE === a.metadata.category),
      );
      if (0 < clientTemplateFile.length) {
        state.excursion.clientTemplate = {
          sid: clientTemplateFile[0].sid,
          filename: clientTemplateFile[0].metadata.filename,
        };
      }
    }

    state.excursion.excursionCopyFlag = excursionFromCb.metadata.excursionCopyFlag ? true : false;

    state.excursionDisplayReady = true;
  },
  setExcursionStudentFetchTriggered(state, excursionStudentFetchTriggered) {
    state.excursionStudentFetchTriggered = excursionStudentFetchTriggered;
  },
  setExcursionStudentMedicalConditions(state, studentMedicalConditions) {
    state.excursion.computed.studentMedicalConditions = studentMedicalConditions;
  },
  setExcursionStudentMedicalConditionsDisplayReady(
    state,
    excursionStudentMedicalConditionsDisplayReady,
  ) {
    state.excursionStudentMedicalConditionsDisplayReady
      = excursionStudentMedicalConditionsDisplayReady;
  },
  setComponentOptions(state, { cbResponse, componentOption, pluginCommonAdapter }) {
    componentOption.setComponentOptions(state, cbResponse, pluginCommonAdapter);
  },
  setExcursionSelectedProviders(state, { categoryName, selectedProviderNames, providers }) {
    const categories = state.excursion.categories;
    let riskCategory = categories.find(
      c => c.name === categoryName,
    );

    if (!riskCategory) {
      const category = state.allRisks.find(c => c.name === categoryName);
      riskCategory = {
        id: category.id,
        name: categoryName,
        types: [],
      };
      categories.push(riskCategory);
    }

    const selectedProviders = [];
    if (selectedProviderNames && 0 < selectedProviderNames.length) {
      selectedProviderNames.forEach((name) => {
        const provider = providers.find(p => p.name === name);
        if (provider) {
          selectedProviders.push(provider);
        }
      });
    }

    riskCategory.selectedProviders = selectedProviders;

    if (0 === selectedProviders.length) {
      if ("Outdoor Education Providers" === categoryName) {
        categories.splice(categories.indexOf(riskCategory), 1);
      } else {
        if (riskCategory.types && 0 === riskCategory.types.length) {
          categories.splice(categories.indexOf(riskCategory), 1);
        }
      }
    }

    // Let the store know the category have been changed
    categories.push({});
    categories.splice(categories.length - 1, 1);
  },
  getExcursionList(state, result) {
    const excursionList = result.data;
    if (excursionList && 0 < excursionList.data.length) {
      excursionList.data.map(function (item) {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if ("Invalid Date" !== `${createdTime}`) {
            item.created_at = (createdTime).toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });

      state.excursionsList.data = excursionList.data;
      state.excursionsList.total = excursionList.total;
    } else {
      state.excursionsList.data = [];
      state.excursionsList.total = 0;
    }

    state.excursionsList.loading = false;
    state.showError = result.error;
  },
  getClientList(state, result) {
    const clientList = result.data;
    if (clientList && (0 < clientList.data.length)) {
      clientList.data.map(function (item) {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if ("Invalid Date" !== `${createdTime}`) {
            item.created_at = (createdTime).toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });
      state.clientList.data = clientList.data;
      state.clientList.total = clientList.total;
    } else {
      state.clientList.data = [];
      state.clientList.total = 0;
    }
    state.showError = result.error;
    state.isGetClientList = true;
    state.clientList.loading = false;
  },
  getParentClientList(state, result) {
    const clientList = result.data;
    if (clientList && (0 < clientList.data.length)) {
      state.parentClientList.items = clientList.data.map(function (item) {
        return {
          sid: item.sid,
          name: `${item.sid} - ${item.metadata.name}`,
        };
      });
    } else {
      state.parentClientList.items = [];
    }

    if (clientList) {
      state.parentClientList.itemsCache.push({
        key: clientList.key,
        items: state.parentClientList.items,
      });
    }

    state.parentClientList.loading = false;
  },
  getClientUserClientList(state, result) {
    const clientList = result.data;
    if (clientList && (0 < clientList.data.length)) {
      state.clientUserClientList.items = clientList.data.map(function (item) {
        return {
          sid: item.sid,
          name: `${item.sid} - ${item.metadata.name}`,
        };
      });
    } else {
      state.clientUserClientList.items = [];
    }

    if (clientList) {
      state.clientUserClientList.itemsCache.push({
        key: clientList.key,
        items: state.clientUserClientList.items,
      });
    }

    state.clientUserClientList.loading = false;
  },
  setMenu(state, menu) {
    const localTopToolbarTextButtonList = state.topToolbarTextButtonList;
    localTopToolbarTextButtonList.forEach(function (textButton) {
      if (textButton.name === menu || "SafeTripBuilder" === textButton.name) {
        textButton.itemActivated = true;
      } else {
        textButton.itemActivated = false;
      }
    });
  },
  updateIsResponseWithError(state, isResponseWithError) {
    state.isResponseWithError = isResponseWithError;
  },
  initMandatoryChecklist(state, mandatoryChecklist) {
    mandatoryChecklist.forEach(function (arrayItem) {
      if (
        arrayItem.Strategy
        && arrayItem.Strategy.data
      ) {
        const strategyDataKeys = Object.keys(arrayItem.Strategy.data);
        const firstStrategyDataKey = strategyDataKeys[0];
        const firstStrategyDataValue = arrayItem.Strategy.data[firstStrategyDataKey];

        if (firstStrategyDataValue) {
          const items = firstStrategyDataValue.children;
          items.forEach(function (children) {
            if ("undefined" !== typeof children.children) {
              children.children.forEach(function (option) {
                if ("checkbox" === option.contentType) {
                  option.ticked = false;
                }
              });
            }
          });
        }
      }
    });

    state.excursion.mandatoryChecklist = mandatoryChecklist;
    state.mandatoryLoadActionCompleted = false;
  },
  resetAllState(state, payload = []) {
    if (false === payload instanceof Array) {
      return;
    }

    const initState = _store("initState");
    const _initState = payload.length ? _.omit(initState, payload) : initState;
    _.extend(state, _initState);
  },
  updatePersonResponsible(state, value) {
    if (state.excursion.categories && 0 < state.excursion.categories.length) {
      state.excursion.categories.forEach(function (item) {
        if (item.name === value.name) {
          item.personResponsible = value.personResponsible;
        }
      });
    }

    if (state.excursion.riskCategories && 0 < state.excursion.riskCategories.length) {
      state.excursion.riskCategories.forEach((item) => {
        if (item.title === value.name) {
          item.personResponsible = value.personResponsible;
        }
      });
    }

    if (state.allRisks && 0 < state.allRisks.length) {
      state.allRisks.forEach(function (item) {
        if (item.name === value.name) {
          item.personResponsible = value.personResponsible;
        }
      });
    }
  },
  setPredeparture(state, checklist) {
    state.excursion.predepartures = checklist;
  },
  getClientUserList(state, result) {
    const clientUserList = result.data;
    if (clientUserList && (0 < clientUserList.data.length)) {
      clientUserList.data.map(function (item) {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if ("Invalid Date" !== `${createdTime}`) {
            item.created_at = (createdTime).toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });
      state.clientUserList.data = clientUserList.data;
      state.clientUserList.total = clientUserList.total;
    } else {
      state.clientUserList.data = [];
      state.clientUserList.total = 0;
    }
    state.showError = result.error;
    state.clientUserList.loading = false;
  },
  getUserList(state, result) {
    const userList = result.data;
    if (userList && (0 < userList.data.length)) {
      userList.data.map(function (item) {
        if (item.created_at) {
          const createdTime = new Date(item.created_at);
          if ("Invalid Date" !== `${createdTime}`) {
            item.created_at = (createdTime).toLocaleString();
            item.created_time = createdTime.getTime();
          }
        }
        return item;
      });
      state.userList.data = userList.data;
      state.userList.total = userList.total;
    } else {
      state.userList.data = [];
      state.userList.total = 0;
    }
    state.showError = result.error;
    state.userList.loading = false;
  },
  setAuthSchemeApiParam(state, cbResponse) {
    state.userAuthSchemeApiParam = {};
    if (
      cbResponse
      && cbResponse.data
      && cbResponse.data.computed
      && cbResponse.data.computed.vendorSid
      && cbResponse.data.computed.vendorClientSid
    ) {
      state.userAuthSchemeApiParam = {
        "vendor-sid": cbResponse.data.computed.vendorSid,
        "vendor-client-sid": cbResponse.data.computed.vendorClientSid,
      };
    }
  },
  setClientAclRoleInferiorityHash(state, cbResponse) {
    state.clientAclRoleInferiorityHash = {};
    if (
      cbResponse
      && cbResponse.data
      && cbResponse.data.computed
      && cbResponse.data.computed.clientAclRoleInferiorityHash
    ) {
      state.clientAclRoleInferiorityHash = cbResponse.data.computed.clientAclRoleInferiorityHash;
    }
  },
  removeRiskCompleteChecklist(state, risk) {
    if (risk && risk.CompleteChecklist) {
      delete risk.CompleteChecklist;
    }
  },
  changeRiskCompleteChecklist(state, { risk, result }) {
    if (risk) {
      risk.CompleteChecklist = result;
    }
  },
  setExcursionPredepartureStrategies(state, data) {
    state.excursion.predepartures = data.predepartures;
    state.excursion.predepartureChecklistCompleted = data.predepartureChecklistCompleted;
    state.excursion.updateSignature = data.updateSignature;
  },
  setCeCommitOid(state, value) {
    state.ceCommitOid = value;
  },
  toggleClientUserEnabled(state, userId) {
    if (
      state.clientUserList.data
      && Array.isArray(state.clientUserList.data)
    ) {
      const users = state.clientUserList.data.filter(x => x.id === userId);
      if (0 < users.length) {
        const user = users[0];
        user.enabled = !user.enabled;
      }
    }
  },
  toggleUserEnabled(state, userId) {
    if (
      state.clientUserList.data
      && Array.isArray(state.clientUserList.data)
    ) {
      const users = state.userList.data.filter(x => x.id === userId);
      if (0 < users.length) {
        const user = users[0];
        user.enabled = !user.enabled;
      }
    }
  },
  setActivitySelectionValid(state) {
    const selectedActivity = state.excursion.categories.find((c) => {
      return ("Activities" === c.name);
    });

    let activityRiskValid = true;
    if (
      selectedActivity
      && selectedActivity.types
      && (0 < selectedActivity.types.length)
    ) {
      activityRiskValid = selectedActivity.types.every((type) => {
        return type.classes.every((c) => {
          return (c.risks && (0 < c.risks.length));
        });
      });
    }

    state.excursion.activitySelectionValid = activityRiskValid;
  },
  setExcursionAuditList(state, result) {
    state.excursion.auditList = result.data;
    if (
      state.excursion.auditList
      && (0 < state.excursion.auditList.length)
    ) {
      state.excursion.auditList.map(function (item) {
        if (item.timestamp) {
          const timestamp = new Date(item.timestamp);
          if ("Invalid Date" !== `${timestamp}`) {
            item.timestamp = (timestamp).toLocaleString();
          }
        }
        return item;
      });
    }
  },
  toggleClientEnabled(state, sid) {
    if (
      state.clientList.data
      && Array.isArray(state.clientList.data)
    ) {
      const client = state.clientList.data.find(x => x.sid === sid);
      if (client) {
        client.enabled = !client.enabled;
      }
    }
  },
  unapproveExcursionMutation(state, updatedStatus) {
    state.excursion.status = updatedStatus;
  },
};

<template>
  <div class="client-row">
    <v-dialog v-model="clientUserIndividualModalDisplayed" scrollable width="768px" persistent>
      <v-card v-if="clientUserIndividualModalDisplayed">
        <v-card-title class="modalTitle">
          <v-flex>
            <strong>Client User Individual Modal</strong>
          </v-flex>
          <v-btn icon @click="clientUserIndividualModalDisplayed = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text>
          <v-container grid-list-md>
            <div class="client-row client-modal-text">
              <v-flex xs12>
                Email
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  solo
                  v-model="email"
                  required
                  :error-messages="emailErrors"
                  @input="$v.email.$touch();"
                  @blur="$v.email.$touch();"
                  class="excursion-input"
                  placeholder="Enter in the email of the user"
                  id="client-user-individual-modal--email"
                />
              </v-flex>
              <v-flex xs12>
                Given Name
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  solo
                  v-model="givenName"
                  id="client-user-individual-modal--given-name"
                  class="excursion-input"
                  placeholder="Enter in the given name of the user"
                />
              </v-flex>
              <v-flex xs12>
                Surname
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  solo
                  v-model="surname"
                  id="client-user-individual-modal--surname"
                  class="excursion-input"
                  placeholder="Enter in the surname of the user"
                />
              </v-flex>
              <v-flex xs12>
                Client ACL Role
              </v-flex>
              <v-flex xs12>
                <v-select
                  class="type-dropdown excursion-selection"
                  placeholder="Select Client ACL Role"
                  v-model="clientAclRole"
                  :items="clientAclRoles"
                  item-text="text"
                  item-value="value"
                  required
                  :error-messages="clientAclRoleErrors"
                  @input="$v.clientAclRole.$touch();"
                  @blur="$v.clientAclRole.$touch();"
                  solo
                  id="client-user-individual-modal--client-acl-role"
                >
                  <template slot="item" slot-scope="data">
                    <span
                      :id="`client-user-individual-modal--client-acl-role-item-${data.item.text}`"
                    >
                      {{data.item.text}}
                    </span>
                  </template>
                </v-select>
              </v-flex>
              <div v-if="inferiorRoleSetupDisplayed" style="padding-bottom:20px">
                <v-layout v-if="clientAdminAssistantRoleDisplaySetupEnabled">
                  <div class="client-individual-checkbox">
                    <v-checkbox
                      v-model="clientAdminAssistantRoleDisplayEnabled"
                      class="client-individual-checkbox"
                      id="user-client-admin-assistant-role-display-enabled-tickbox"
                    ></v-checkbox>
                  </div>
                  <label
                    class="client-individual-checkbox-label"
                    for="user-client-admin-assistant-role-display-enabled-tickbox"
                  >
                    Display as <b>client-admin-assistant</b>
                  </label>
                </v-layout>
                <v-layout v-if="clientApproverRoleDisplaySetupEnabled">
                  <div class="client-individual-checkbox">
                    <v-checkbox
                      v-model="clientApproverRoleDisplayEnabled"
                      class="client-individual-checkbox"
                      id="user-client-approver-role-display-enabled-tickbox"
                    ></v-checkbox>
                  </div>
                  <label
                    class="client-individual-checkbox-label"
                    for="user-client-approver-role-display-enabled-tickbox"
                  >
                    Display as <b>client-approver</b>
                  </label>
                </v-layout>
                <v-layout v-if="clientUserRoleDisplaySetupEnabled">
                  <div class="client-individual-checkbox">
                    <v-checkbox
                      v-model="clientUserRoleDisplayEnabled"
                      class="client-individual-checkbox"
                      id="user-client-user-role-display-enabled-tickbox"
                    ></v-checkbox>
                  </div>
                  <label
                    class="client-individual-checkbox-label"
                    for="user-client-user-role-display-enabled-tickbox"
                  >
                    Display as <b>client-user</b>
                  </label>
                </v-layout>
              </div>
              <v-flex v-if="clientsDisplayed" xs12>
                Clients
              </v-flex>
              <v-flex v-if="clientsDisplayed" xs12>
                <v-autocomplete
                  v-model="clientSids"
                  :items="clientItems"
                  label="Select one or more Clients"
                  item-text="name"
                  item-value="sid"
                  :search-input.sync="searchClients"
                  :cache-items="false"
                  clearable
                  :multiple="true"
                  @change="handleClientChange"
                  ref="selectClient"
                  chips
                  hide-details
                  hide-selected
                  :deletable-chips="true"
                  solo
                  required
                  :error-messages="clientSidsErrors"
                  @input="$v.clientSids.$touch();"
                  @blur="$v.clientSids.$touch();"
                  id="client-user-individual-modal--clients"
                >
                  <template slot="item" slot-scope="data">
                    <span :id="`client-user-individual-modal--clients-item-${data.item.sid}`">
                      {{data.item.name}}
                    </span>
                  </template>
                </v-autocomplete>
                <v-flex
                  class="pre-clients-error"
                  v-if="clientSidsErrors.length>0"
                >Clients are required</v-flex>
              </v-flex>
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="normal-btn white--text btnSave primary"
            name="btnSave"
            id="btnSave"
            :disabled="clientUserSaveActionInProgress || this.$v.$invalid"
            @click.native="save"
          ><v-icon v-if="clientUserSaveActionInProgress">fa fa-spinner fa-pulse</v-icon>Save</v-btn>
          <v-btn class="normal-btn" @click.native="close()" id="btnClose">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="genericDialogDisplayed" persistent max-width="390">
        <v-card>
          <v-card-title class="headline center">{{genericDialogMessage}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click.native="genericDialogDisplayed = false"
              class="normal-btn white--text primary"
              id="client-user-individual-modal--btn-ok"
            >OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import aclRole from "../lib/const/aclRole";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  validations: {
    email: {
      required,
    },
    clientAclRole: {
      required,
    },
    clientSids: {
      required,
    },
  },
  props: {
    "propClientUserIndividualModalRefreshed": Boolean,
    "propUserId": String,
  },
  data() {
    return {
      genericDialogDisplayed: false,
      clientUserSaveActionInProgress: false,
      genericDialogMessage: "",
      clientUserIndividualModalDisplayed: false,
      clientSids: [],
      selectedClients: [],
      searchClients: '',
      pagination: {
        rowsPerPage: -1,
        descending: false,
        sortBy: "",
        page: 1,
        order: "",
      },
      clientAclRole: "",
      email: "",
      emailConflicted: false,
      emailValid: true,
      givenName: "",
      surname: "",
      clientAdminAssistantRoleDisplayEnabled: false,
      clientApproverRoleDisplayEnabled: false,
      clientUserRoleDisplayEnabled: false,
    };
  },
  computed: {
    clientUserIndividualModalRefreshed() {
      return this.propClientUserIndividualModalRefreshed;
    },
    clientCache() {
      return this.$store.state.clientUserClientList.itemsCache;
    },
    clientItems() {
      const items = this.$app.lodash.cloneDeep(this.$store.state.clientUserClientList.items);
      this.selectedClients.forEach((client) => {
        if (!items.some(e => e.sid === client.sid)) {
          items.push(client);
        }
      });
      return items;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      if (!this.$v.email.required) {
        errors.push("Email is required");
      }

      if (!this.emailValid) {
        errors.push("Invalid Email");
      }

      if (this.emailConflicted) {
        errors.push("Email conflicted");
      }

      return errors;
    },
    clientAclRoleErrors() {
      const errors = [];
      if (!this.$v.clientAclRole.$dirty) return errors;
      if (!this.$v.clientAclRole.required) {
        errors.push("Client ACL Role is required");
      }

      return errors;
    },
    clientSidsErrors() {
      const errors = [];
      if (!this.$v.clientSids.$dirty) return errors;
      if (!this.$v.clientSids.required) {
        errors.push("Clients are required");
      }

      return errors;
    },
    clientAclRoles() {
      const clientAclRoles = [
        { value: aclRole.CLIENT_ADMIN, text: aclRole.CLIENT_ADMIN },
        { value: aclRole.CLIENT_ADMIN_ASSISTANT, text: aclRole.CLIENT_ADMIN_ASSISTANT },
        { value: aclRole.CLIENT_APPROVER, text: aclRole.CLIENT_APPROVER },
        { value: aclRole.CLIENT_LITE_USER, text: aclRole.CLIENT_LITE_USER },
        { value: aclRole.CLIENT_USER, text: aclRole.CLIENT_USER },
        { value: aclRole.CLIENT_WATCHER, text: aclRole.CLIENT_WATCHER },
      ];

      if (this.$store.state.clientUserGroupRoleDisplayed) {
        clientAclRoles.push(
          { value: aclRole.CLIENT_GROUP_ADMIN, text: aclRole.CLIENT_GROUP_ADMIN },
          { value: aclRole.CLIENT_GROUP_APPROVER, text: aclRole.CLIENT_GROUP_APPROVER },
          { value: aclRole.CLIENT_GROUP_USER, text: aclRole.CLIENT_GROUP_USER },
          { value: aclRole.CLIENT_GROUP_WATCHER, text: aclRole.CLIENT_GROUP_WATCHER },
        );
      }
      return clientAclRoles;
    },
    clientsDisplayed() {
      return this.$store.state.clientUserGroupRoleDisplayed;
    },
    clientAdminAssistantRoleDisplaySetupEnabled() {
      return this
      .checkIfInferiorRoleDisplaySetupEnabled(this.clientAclRole, aclRole.CLIENT_ADMIN_ASSISTANT);
    },
    clientApproverRoleDisplaySetupEnabled() {
      return this
      .checkIfInferiorRoleDisplaySetupEnabled(this.clientAclRole, aclRole.CLIENT_APPROVER);
    },
    clientUserRoleDisplaySetupEnabled() {
      return this
      .checkIfInferiorRoleDisplaySetupEnabled(this.clientAclRole, aclRole.CLIENT_USER);
    },
    inferiorRoleSetupDisplayed() {
      return (
        this.clientAdminAssistantRoleDisplaySetupEnabled
        || this.clientApproverRoleDisplaySetupEnabled
        || this.clientUserRoleDisplaySetupEnabled
      );
    },
  },
  methods: {
    alertSaveResult(cbResponse) {
      this.genericDialogDisplayed = true;
      this.clientUserSaveActionInProgress = false;
      this.close();
      this.clear();
      if (cbResponse && cbResponse.data) {
        // success
        this.genericDialogMessage = "User has been saved.";

        this.$emit("reloadUsers");
      } else {
        // failed
        this.genericDialogMessage = "Failed to save User information.";
      }
    },
    clear() {
      this.$v.$reset();
      this.clientSids = [];
      this.clientAclRole = "";
      this.email = "";
      this.emailValid = true;
      this.givenName = "";
      this.surname = "";
      this.selectedClients = [];
      this.clientAdminAssistantRoleDisplayEnabled = false;
      this.clientApproverRoleDisplayEnabled = false;
      this.clientUserRoleDisplayEnabled = false;
    },
    close() {
      this.clientUserIndividualModalDisplayed = false;
      this.clear();
    },
    handleClientChange() {
      this.$refs.selectClient.blur();
      this.$nextTick(() => {
        this.$refs.selectClient.focus();
      });
    },
    async newUserModal(id) {
      if ("" !== `${id}`) {
        const argHash = {
          id,
        };
        const cbResponse = await this.$app.stbApiAdapter.getClientUsers(argHash);
        if (cbResponse && !cbResponse.error) {
          const user = cbResponse.data[0];
          this.email = user.email;
          const userMetadata = user.metadata;

          if (userMetadata) {
            this.givenName = userMetadata.givenName;
            this.surname = userMetadata.surname;
            this.clientAdminAssistantRoleDisplayEnabled
              = userMetadata.clientAdminAssistantRoleDisplayEnabled;
            this.clientApproverRoleDisplayEnabled
              = userMetadata.clientApproverRoleDisplayEnabled;
            this.clientUserRoleDisplayEnabled
              = userMetadata.clientUserRoleDisplayEnabled;
          } else {
            this.givenName = "";
            this.surname = "";
            this.clientAdminAssistantRoleDisplayEnabled = false;
            this.clientApproverRoleDisplayEnabled = false;
            this.clientUserRoleDisplayEnabled = false;
          }

          if (user.clientList && (0 < user.clientList.length)) {
            this.clientSids = user.clientList.map(item => item.sid);
            this.selectedClients = user.clientList.map((item) => {
              return {
                sid: item.sid,
                name: `${item.sid} - ${item.metadata.name}`,
              };
            });
            this.clientAclRole = user.clientList[0].client_acl_role;
          }
        }
      } else {
        this.clear();

        if (!this.$store.state.clientUserGroupRoleDisplayed) {
          this.clientSids.push(this.$store.state.userClientSid);
        }
      }
    },
    async save() {
      this.emailValid = true;
      const regExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

      if (!regExp.test(this.email)) {
        this.emailValid = false;
        return;
      }

      this.clientUserSaveActionInProgress = true;
      let clients = [];
      if (this.clientSids && (0 < this.clientSids.length)) {
        clients = this.clientSids.map((item) => {
          return {
            sid: item,
            "acl-role": this.clientAclRole,
          };
        });
      }

      const apiParam = JSON.parse(JSON.stringify(this.$store.state.apiParam));
      const user = {
        email: this.email,
        metadata: {
          givenName: this.givenName,
          surname: this.surname,
          clientAdminAssistantRoleDisplayEnabled: this.clientAdminAssistantRoleDisplayEnabled,
          clientApproverRoleDisplayEnabled: this.clientApproverRoleDisplayEnabled,
          clientUserRoleDisplayEnabled: this.clientUserRoleDisplayEnabled,
        },
        clients: clients,
      };

      apiParam.user = user;
      if ("" !== `${this.propUserId}`) {
        const argHash = {
          id: this.propUserId,
          payload: apiParam,
        };
        const cbResponse = await this.$app.stbApiAdapter.putClientUsers(argHash);
        this.clientUserSaveActionInProgress = false;
        if ("409" === `${cbResponse.statusCode}`) {
          this.emailConflicted = true;
        } else {
          this.emailConflicted = false;
          this.alertSaveResult(cbResponse);
        }
      } else {
        const argHash = {
          payload: apiParam,
        };
        const cbResponse = await this.$app.stbApiAdapter.postClientUsers(argHash);
        this.clientUserSaveActionInProgress = false;
        if ("409" === `${cbResponse.statusCode}`) {
          this.emailConflicted = true;
        } else {
          this.emailConflicted = false;
          this.alertSaveResult(cbResponse);
        }
      }
    },
    remove(item) {
      const index = this.clientSids.indexOf(item.sid);
      if (0 <= index) this.clientSids.splice(index, 1);
    },
    checkIfInferiorRoleDisplaySetupEnabled(superiorClientAclRole, inferiorClientAclRole) {
      let inferiorRoleDisplaySetupEnabled = false;

      if (superiorClientAclRole) {
        const inferiorClientAclRoleHash
          = this.$store.state.clientAclRoleInferiorityHash[superiorClientAclRole];

        inferiorRoleDisplaySetupEnabled = (
          inferiorClientAclRoleHash
          && inferiorClientAclRoleHash[inferiorClientAclRole]
        );
      }

      return inferiorRoleDisplaySetupEnabled;
    },
    async filterClients(val) {
      let cacheItem;
      val = val ? val : "";
      if (this.clientCache && (0 < this.clientCache.length)) {
        cacheItem = this.clientCache.find(function (item) {
          return item.key === val;
        });
      }

      if (cacheItem) {
        this.$store.commit("updateClientUserClientListItems", cacheItem.items);
      } else {
        this.$store.state.clientUserClientList.loading = true;
        const filterParam = {
          s: val,
          page: this.pagination.page,
          order: this.pagination.sortBy,
          length: 5,
          direction: (false === this.pagination.descending) ? "ASC" : "DESC",
        };

        Object.keys(filterParam).forEach((key) => {
          if ("" === filterParam[key]) {
            delete filterParam[key];
          }
        });
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          filterParam,
        };
        await this.$store.dispatch("getClientUserClientList", argHash);
      }
    },
  },
  async mounted() {
    this.clientUserIndividualModalDisplayed = true;
    await this.newUserModal(this.propUserId);
  },
  watch: {
    clientUserIndividualModalRefreshed() {
      this.newUserModal(this.propUserId);
      this.clientUserIndividualModalDisplayed = true;
    },
    async searchClients(val){
      await this.filterClients(val);
    }
  },
};
</script>

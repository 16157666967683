<template>
  <div class="client-row">
    <v-dialog v-model="clientIndividualModalDisplayed" scrollable width="980px" persistent>
      <v-card v-if="clientIndividualModalDisplayed">
        <v-card-title class="modalTitle">
          <v-flex>
            <strong>Client Individual Modal</strong>
          </v-flex>
          <v-btn icon @click.native="close()">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text>
          <v-container grid-list-md>
            <v-tabs
              color="#fff"
              slider-color="#00adef"
              class="client-row"
            >
              <v-tab :key="'General'" ripple id="tabGeneral">
                General
              </v-tab>
              <v-tab :key="'Group'" ripple id="tabGroup">
                Group
              </v-tab>
              <v-tab :key="'Vendors'" ripple id="tabVendors">
                Excursion Management
              </v-tab>
              <v-tab :key="'Providers'" ripple id="tabPoviders">
                Providers
              </v-tab>
              <v-tab :key="'ContentEngine'" ripple id="tabContentEngine">
                Content Engine
              </v-tab>
              <v-tab :key="'studentManagement'" ripple id="tabstudentManagement">
                Student Management
              </v-tab>
              <v-tab :key="'Templates'" ripple id="tabTemplates">
                Templates
              </v-tab>
              <v-tab-item>
                <div class="client-row client-modal-text">
                  <div class="client-modal-information">
                    <p class="client-modal-information__title">STB Client ID</p>
                    <p class="client-modal-information__content">{{clientSidLabel}}</p>
                  </div>
                  <div class="client-modal-information">
                    <p class="client-modal-information__title">CS Client ID</p>
                    <p class="client-modal-information__content">{{csClientId}}</p>
                  </div>
                  <v-spacer></v-spacer>
                  <v-flex xs12>Client Logo
                  </v-flex>
                  <v-flex xs12 v-if="clientSid!=''">
                    <span
                      class="crop-note"
                    >You can click the button below to select another Logo to crop</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      class="normal-btn btn-margin-left-0 white--text primary"
                      @click="selectFile"
                      id="btnUploadNewImage"
                    >Upload New Image</v-btn>
                    <input
                      type="file"
                      ref="fileSelect"
                      id="fileSelect"
                      v-show="false"
                      @change="setImage"
                      accept="image/png, image/jpeg, image/jpg"
                    >
                  </v-flex>
                  <v-flex xs12>
                    <vue-cropper
                      ref="cropper"
                      :auto-crop-area="1"
                      :src="imgSrc"
                      alt="Client Logo"
                      :imgStyle="{ 'height': '200px' }"
                      v-show="imgSrc != ''"
                      :cropmove="cropMove"
                    ></vue-cropper>
                  </v-flex>
                  <v-flex xs12>
                    <v-card class="crop-img" v-show="cropImg!=''">
                      <v-img
                        max-width="346px"
                        max-height="100px"
                        width="346px"
                        height="100px"
                        :src="cropImg"
                        :contain="true"
                      ></v-img>
                    </v-card>
                  </v-flex>
                  <v-flex xs12>
                    <v-btn
                      class="normal-btn white--text btn-margin-left-0 primary"
                      @click="cropImage"
                      id="btnCropImage"
                      v-show="imgSrc != ''"
                      :disabled="cropButtonDisabled"
                    >Crop Image</v-btn>
                  </v-flex>
                  <v-flex xs12>&nbsp;</v-flex>
                  <v-flex xs12>
                    Client Logo Background Colour
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      solo
                      v-model="clientLogoBackgroundColor"
                      class="excursion-input"
                      id="txtClientLogoBackgroundColour"
                      placeholder="Leave blank for default or enter in a hexadecimal color value"
                    />
                  </v-flex>
                  <v-flex xs12>Search HubSpot Client Domain</v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="searchDomain"
                      label="Search"
                      append-icon="search"
                      class="excursion-input"
                      placeholder="Search HubSpot Client Domain to resolve Client Name"
                      solo
                      @click:append="getClientDomains()"
                      @keyup.enter="getClientDomains()"
                      id="txtSearchClientDomain"
                    ></v-text-field>
                    <div
                      v-show="getClientDomainsError"
                      class="client-domain-error"
                    >{{getClientDomainsError}}</div>
                  </v-flex>
                  <v-flex xs12>
                    Client Name
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      solo
                      v-model="clientName"
                      class="excursion-input"
                      placeholder="Client Name"
                      required
                      :error-messages="clientNameErrors"
                      @input="$v.clientName.$touch();"
                      @blur="$v.clientName.$touch();"
                      id="txtClientName"
                    />
                  </v-flex>
                  <v-flex xs12>
                    Client Subdomain
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      solo
                      v-model="subdomain"
                      class="excursion-input"
                      placeholder="Client Subdomain"
                      required
                      :error-messages="subdomainErrors"
                      @input="$v.subdomain.$touch();"
                      @blur="$v.subdomain.$touch();"
                      id="txtSubdomain"
                    />
                  </v-flex>
                  <v-flex xs12>
                    IdP URL
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      solo
                      v-model="idpUrl"
                      class="excursion-input"
                      placeholder="IdP URL"
                      :error-messages="idpUrlErrors"
                      @input="$v.idpUrl.$touch();"
                      @blur="$v.idpUrl.$touch();"
                      id="txtIdpUrl"
                    />
                  </v-flex>
                  <v-flex xs12>
                    Time Zone
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex xs12>
                    <v-autocomplete
                      v-model="timezone"
                      :items="timezones"
                      label="Select Time Zone"
                      item-text="text"
                      item-value="value"
                      :search-input.sync="searchTimezone"
                      :cache-items="false"
                      clearable
                      :multiple="false"
                      ref="selectTimezone"
                      hide-details
                      hide-selected
                      :deletable-chips="false"
                      solo
                      required
                      :error-messages="timezoneErrors"
                      @input="$v.timezone.$touch();"
                      @blur="$v.timezone.$touch();"
                      id="client-individual-modal-time-zone"
                    >
                      <template slot="item" slot-scope="data">
                        <span :id="`client-individual-modal--time-zones-item-${data.item.value}`">
                          {{data.item.text}}
                        </span>
                      </template>
                    </v-autocomplete>
                    <v-flex
                      class="pre-clients-error"
                      v-if="(0 < timezoneErrors.length)"
                    >Time Zone is required</v-flex>
                  </v-flex>
                  <v-flex xs12 class="client-individual-m-t-30">
                    Client Options
                  </v-flex>
                  <v-flex xs12 class="client-individual-client-options">
                    <v-card
                      class="mx-auto"
                      outlined
                    >
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            :disabled="disableAdditional()"
                            v-model="excursionAdditionalInformationEnabled"
                            class="client-individual-checkbox"
                            id="txtEnableAdditionalInformation"
                          ></v-checkbox>
                        </div>
                        <div
                          class="client-individual-checkbox-label"
                          @click="enableAdditionalInformation()"
                        >Enable Additional Information</div>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="excursionHighRiskStudentMedicalEnabled"
                            id="cbxEnableHighRiskStudentMedical"
                            class="client-individual-checkbox"
                          ></v-checkbox>
                        </div>
                        <div
                          class="client-individual-checkbox-label"
                          @click="
                            excursionHighRiskStudentMedicalEnabled
                              = !excursionHighRiskStudentMedicalEnabled
                          "
                        >Enable High-Risk Student Medical</div>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="excursionPersonResponsibleEnabled"
                            id="cbxEnablePersonResponsible"
                            class="client-individual-checkbox"
                          ></v-checkbox>
                        </div>
                        <div
                          class="client-individual-checkbox-label"
                          @click="
                            excursionPersonResponsibleEnabled=!excursionPersonResponsibleEnabled
                          "
                        >Enable Person Responsible</div>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="excursionPolicyViewActionEnabled"
                            class="client-individual-checkbox"
                            id="cbxEnablePolicyView"
                          ></v-checkbox>
                        </div>
                        <div
                          class="client-individual-checkbox-label"
                          @click="
                            excursionPolicyViewActionEnabled=!excursionPolicyViewActionEnabled
                          "
                        >Enable Policy View</div>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="excursionChecklistEnforcementBeforeSubmissionEnabled"
                            class="client-individual-checkbox"
                            id="cbxEnablePolicyView"
                          ></v-checkbox>
                        </div>
                        <div
                          class="client-individual-checkbox-label"
                          @click="
                            excursionChecklistEnforcementBeforeSubmissionEnabled
                              = !excursionChecklistEnforcementBeforeSubmissionEnabled
                          "
                        >Enable Checklist Enforcement Before Submission</div>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="excursionCopyActionEnabled"
                            class="client-individual-checkbox"
                            id="client-individual-modal--excursion-copy-enabled"
                          ></v-checkbox>
                        </div>
                        <div
                          class="client-individual-checkbox-label"
                          @click="excursionCopyActionEnabled = !excursionCopyActionEnabled"
                        >Enable Copy Excursion</div>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="idpRbaclEnabled"
                            class="client-individual-checkbox"
                            id="client-individual-modal--excursion-copy-enabled"
                          ></v-checkbox>
                        </div>
                        <div
                          class="client-individual-checkbox-label"
                          @click="idpRbaclEnabled = !idpRbaclEnabled"
                        >Enable IdP RBACL</div>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="publicSchoolFeatureSetEnabled"
                            class="client-individual-checkbox"
                            id="client-individual-modal--public-school-feature-set-enabled"
                          ></v-checkbox>
                        </div>
                        <label
                          class="client-individual-checkbox-label"
                          for="client-individual-modal--public-school-feature-set-enabled"
                        >
                          Enable Public School Feature Set
                        </label>
                      </v-layout>
                      <v-layout class="client-individual-checkbox-layout">
                        <div class="client-individual-checkbox">
                          <v-checkbox
                            v-model="excursionInherentRiskRatingEnabled"
                            class="client-individual-checkbox"
                            id="client-individual-modal--excursion-inherent-risk-rating-enabled"
                          ></v-checkbox>
                        </div>
                        <label
                          class="client-individual-checkbox-label"
                          for="client-individual-modal--excursion-inherent-risk-rating-enabled"
                        >
                          Enable Excursion Inherent Risk Rating
                        </label>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-flex>
                  Parent Client SID
                </v-flex>
                <v-flex xs12>
                  <v-autocomplete
                    v-model="parentClientSid"
                    :items="parentClientItems"
                    :loading="parentClientLoadActionInProgress"
                    :search-input.sync="searchParentClients"
                    id="parentClientSid"
                    item-value="sid"
                    item-text="name"
                    :cache-items="true"
                    clearable
                    hide-details
                    hide-selected
                    label="Parent Client SID"
                    solo
                    class="excursion-selection parent-client-sid"
                  ></v-autocomplete>
                </v-flex>
              </v-tab-item>
              <v-tab-item>
                <v-flex xs12>
                  Vendor Name
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    class="type-dropdown excursion-selection"
                    item-text="name"
                    item-value="sid"
                    :items="excursionManagementVendors"
                    solo
                    v-model="vendorSid"
                    placeholder="Select Vendor Name"
                    required
                    :error-messages="vendorSidErrors"
                    @input="$v.vendorSid.$touch()"
                    @blur="$v.vendorSid.$touch()"
                    id="txtVendorId"
                  ></v-select>
                </v-flex>
                <v-flex>
                  Vendor Client SID
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    solo
                    v-model="vendorClientSid"
                    placeholder="Vendor Client SID"
                    class="name-textbox excursion-input"
                    required
                    :error-messages="vendorClientSidErrors"
                    @input="$v.vendorClientSid.$touch()"
                    @blur="$v.vendorClientSid.$touch()"
                    id="txtVendorClientSid"
                    @keyup="autoPopulateVenderClientSid()"
                  />
                </v-flex>
                <v-flex>
                  Vendor Client Name
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="vendorClientName"
                    placeholder="Vendor Client Name"
                    class="name-textbox excursion-input"
                    required
                    :error-messages="vendorClientNameErrors"
                    @input="$v.vendorClientName.$touch()"
                    @blur="$v.vendorClientName.$touch()"
                    id="txtVendorClientName"
                  />
                </v-flex>
                <v-flex>
                  Vendor Webhook URL
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="vendorWebhookUrl"
                    placeholder="Vendor Webhook URL"
                    class="name-textbox excursion-input"
                    required
                    :error-messages="vendorWebhookUrlErrors"
                    @input="$v.vendorWebhookUrl.$touch()"
                    @blur="$v.vendorWebhookUrl.$touch()"
                    id="txtvendorWebhookUrl"
                  />
                </v-flex>
                <v-layout class="client-individual-checkbox-layout">
                  <v-flex xs1>
                    <v-checkbox
                      v-model="vendorExcursionSidInputEnabled"
                      class="client-individual-checkbox"
                      id="cbxEnableVendorExcursionInput"
                    ></v-checkbox>
                  </v-flex>
                  <v-flex
                    xs11
                    class="client-individual-checkbox-label"
                    @click="vendorExcursionSidInputEnabled=!vendorExcursionSidInputEnabled"
                  >Enable Vendor Excursion SID Input</v-flex>
                </v-layout>
                <v-layout class="client-individual-checkbox-layout">
                  <v-flex xs1>
                    <v-checkbox
                      v-model="boardingSupportEnabled"
                      class="client-individual-checkbox"
                      id="boardingSupportEnabledCheckbox"
                    ></v-checkbox>
                  </v-flex>
                  <div
                    id="boardingSupportEnabledCheckboxLabel"
                    class="client-individual-checkbox-label"
                    @click="boardingSupportEnabled=!boardingSupportEnabled"
                  >Enable Boarding Support</div>
                </v-layout>
                <v-flex>
                  School Types
                </v-flex>
                <v-flex xs12 class="client-individual-client-options">
                  <v-card
                    class="mx-auto client-individual-p-10"
                    outlined
                  >
                    <v-layout>
                      <v-flex xs11>
                      <v-text-field
                        solo
                        v-model="newSchoolType.name"
                        class="name-textbox excursion-input client-individual-p-r-10"
                        required
                        placeholder="Eg. Junior School or Primary"
                        :error-messages="newSchoolType.errorMessage"
                        id="newSchoolType"
                      />
                      </v-flex>
                      <v-flex>
                        <v-btn
                          small
                          class="normal-btn client-individual-line-btn"
                          @click.native="addSchoolType()"
                          id="btnAddSchoolType"
                        >Add</v-btn>
                      </v-flex>
                    </v-layout>
                  <v-layout v-for="(school, index) in schoolTypes" :key="index">
                    <v-flex xs11>
                      <v-text-field
                        solo
                        v-model="school.name"
                        class="name-textbox excursion-input client-individual-p-r-10"
                        placeholder="Eg. Junior School or Primary"
                        :error-messages="school.errorMessage"
                        @change.native="changeSchool(school, index)"
                      />
                    </v-flex>
                    <v-flex>
                      <v-btn
                        small
                        class="
                          normal-btn
                          client-individual-line-btn
                          client-individual-line-btn-remove"
                        @click.native="deleteSchoolType(index)"
                        :id="getDeleteSchoolButtunId(index)"
                      >Delete</v-btn>
                    </v-flex>
                  </v-layout>
                  </v-card>
                </v-flex>
              </v-tab-item>
              <v-tab-item>
                <v-flex>
                  Provider Source Type
                </v-flex>
                <v-flex>
                  <v-select
                    class="type-dropdown excursion-selection"
                    item-text="text"
                    item-value="value"
                    :items="providerSourceTypes"
                    solo
                    v-model="provider.sourceType"
                    placeholder="Select Provider Source Type"
                    clearable
                    id="ddlProviderSourceType"
                    @change="changeProviderSourceType()"
                  ></v-select>
                </v-flex>
                <div v-if="displaySftp()">
                  <v-flex>
                    SFTP Path
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      solo
                      v-model="provider.sftpPath"
                      placeholder="SFTP Path"
                      class="name-textbox excursion-input"
                      id="txtSftpPath"
                      :disabled="!clientProviderSftpPathUpdateActionEnabled"
                    />
                  </v-flex>
                </div>
                <div v-if="displayApi()">
                  <v-flex>
                    API Base URL
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      solo
                      v-model="provider.apiBaseUrl"
                      placeholder="API Base URL"
                      class="name-textbox excursion-input"
                      id="txtProviderApiBaseUrl"
                      required
                      :error-messages="providerApiBaseUrlErrors"
                      @blur="$v.provider.$touch()"
                    />
                  </v-flex>
                  <v-flex>
                    API Key
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      solo
                      v-model="provider.apiKey"
                      placeholder="API Key"
                      class="name-textbox excursion-input"
                      id="txtProviderApiKey"
                      required
                      :error-messages="providerApiKeyErrors"
                      @blur="$v.provider.$touch()"
                    />
                  </v-flex>
                  <v-flex>
                    API Tenant SID
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      solo
                      v-model="provider.apiTenantSid"
                      placeholder="API Tenant SID"
                      class="name-textbox excursion-input"
                      id="txtProviderApiTenantSid"
                      required
                      :error-messages="providerApiTenantSidErrors"
                      @blur="$v.provider.$touch()"
                    />
                  </v-flex>
                  <v-flex>
                    API Report SID
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      solo
                      v-model="provider.apiReportSid"
                      placeholder="API Report SID "
                      class="name-textbox excursion-input"
                      id="txtProviderApiReportSid"
                      required
                      :error-messages="providerApiReportSidErrors"
                      @blur="$v.provider.$touch()"
                    />
                  </v-flex>
                </div>
              </v-tab-item>
              <v-tab-item>
                <v-flex>
                  ce-api-uri
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-select
                    class="type-dropdown excursion-selection"
                    item-text="ceApiUri"
                    :items="ceEnvironments"
                    :return-object="true"
                    solo
                    v-model="ceEnvironmentSelection"
                    placeholder="Select Content Engine environment"
                    @change="changeCeEnviromentSelection"
                    clearable
                    id="ce-environment-selection"
                    required
                    :error-messages="ceApiUriErrors"
                    @input="$v.ceEnvironmentSelection.$touch()"
                    @blur="$v.ceEnvironmentSelection.$touch()"
                  >
                    <template slot="item" slot-scope="data">
                      {{data.item.ceApiUri}}
                    </template>
                  </v-select>
                </v-flex>
                <v-flex>
                  x-site-uri
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="contentEngine.xSiteUri"
                    placeholder="x-site-uri"
                    class="name-textbox excursion-input"
                    id="txtXSiteUri"
                    required
                    :error-messages="ceXSiteUriErrors"
                    @blur="$v.contentEngine.$touch()"
                    @keyup="autoPopulatexSiteUrl()"
                  />
                </v-flex>
                <v-flex>
                  module-name
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="contentEngine.moduleName"
                    placeholder="module-name"
                    class="name-textbox excursion-input"
                    id="txtModuleName"
                    required
                    :error-messages="ceModuleNameErrors"
                    @blur="$v.contentEngine.$touch()"
                  />
                </v-flex>
                <v-flex v-show="ceParamErrorDisplayed" style="color:red;">
                  {{ceParamErrorMessage}}
                </v-flex>
                <v-flex>
                  <v-btn
                    v-if="moduleCacheClearanceButtonDisplayed"
                    @click.native="prevDeleteCacheDialog = true"
                    class="normal-btn white--text delete-module-cache primary"
                    :disabled="clearModuleCacheButtonDisabled()"
                    id="btnPrevDeleteCache"
                  >Clear Module Cache</v-btn>
                </v-flex>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <v-flex>
                    Vendor Name
                  </v-flex>
                  <v-flex>
                    <v-select
                      class="type-dropdown excursion-selection"
                      item-text="name"
                      item-value="sid"
                      :items="studentManagementVendors"
                      solo
                      v-model="activeStudentManagementVendorSid"
                      placeholder="Select Vendor Name"
                      @change="changeActiveStudentManagementVendorSid()"
                      required
                      clearable
                      id="ddlVendorId"
                    ></v-select>
                  </v-flex>
                </div>
                <div v-for="(field, index) in studentManagementClientApiFields" :key="index">
                  <div v-if="'text' === field.type">
                    <v-flex>
                      {{field.label}}
                      <span class="red--text">*</span>
                    </v-flex>
                    <v-flex>
                      <v-text-field
                        solo
                        v-model="field.value"
                        :placeholder="field.label"
                        class="name-textbox excursion-input"
                        :id="`txt_${field.name}`"
                        required
                        :error-messages="field.errorMessage"
                        @change="getStudentManagementFieldErrors(field)"
                        @blur="getStudentManagementFieldErrors(field)"
                      />
                    </v-flex>
                  </div>
                  <div v-if="'json-textarea' === field.type">
                    <v-flex>
                      {{field.label}}
                    </v-flex>
                    <v-flex>
                      <v-textarea
                        solo
                        v-model="field.value"
                        :placeholder="field.label"
                        :id="`txt_${field.name}`"
                        :error-messages="field.errorMessage"
                        @change="getStudentManagementJsonTextareaFieldErrors(field)"
                        @blur="getStudentManagementJsonTextareaFieldErrors(field)"
                      ></v-textarea>
                    </v-flex>
                  </div>
                  <div v-if="'checkbox' === field.type">
                    <v-flex>
                      <v-checkbox
                        v-model="field.value"
                        class="client-api-metadata-schema-checkbox"
                        :id="`tickbox_${field.name}`"
                        :error-messages="field.errorMessage"
                        :label="field.label"
                      ></v-checkbox>
                    </v-flex>
                  </div>
                  <div v-if="'file' === field.type">
                    <div :key="index" v-for="(file, index) in studentManagementFiles">
                      <div v-if="!file.isDeleted">
                        <v-layout wrap>
                          <v-flex xs12>
                            <v-layout wrap>
                              <v-flex xs12>
                                <input
                                  :id="getUploadFileId('studentManagement', index)"
                                  style="display:none;"
                                  type="file"
                                  :accept="studentManagementFileExtensions"
                                  @change="
                                    handleStudentManagementFileChange($event, file, index)
                                  "
                                >
                                <v-btn
                                  @click="uploadFileClick('studentManagement', index)"
                                  class="normal-btn white--text continue-btn
                                  excursion-stepper-upload-btn v-btn-upload primary"
                                >
                                  <i class="fa fa-upload" aria-hidden="true"></i>
                                  <span> Upload Subjects CSV</span>
                                </v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 class="form-label template-file-font">
                            File Name
                          </v-flex>
                          <v-flex xs12>
                            <v-layout wrap>
                              <v-flex xs12 style="margin-left: 3px;">
                                {{file.filename}}
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </div>
                    </div>
                  </div>
                  <div v-if="'group-management' === field.type">
                    <v-flex class="client-individual-group-button-container">
                      <v-btn
                        class="
                          normal-btn
                          white--text
                          btn-update-groups
                          client-individual-float-left
                          primary
                        "
                        name="btnUpdateGroups"
                        id="btnUpdateGroups"
                        :disabled="updateGroupsButtonDisabled()"
                        @click.native="updateGroups()"
                      >Update Groups</v-btn>
                      <v-autocomplete
                        class="client-individual-bulk-parent-select client-individual-float-left"
                        v-model="selectedApplyParentId"
                        :items="groupList"
                        label="Set parent for selected group"
                        item-text="group"
                        item-value="id"
                        :cache-items="false"
                        clearable
                        :multiple="false"
                        ref="selectGroup"
                        hide-details
                        hide-selected
                        :deletable-chips="false"
                        solo
                      ></v-autocomplete>
                      <div class="client-individual-float-left client-individual-apply-option">
                        <v-checkbox
                          id="client-individual-apply-subject-checkbox"
                          class="client-individual-float-left"
                          v-model="applySubjectEnabled"
                        ></v-checkbox>
                        <label for="client-individual-apply-subject-checkbox">
                          Subject
                        </label>
                      </div>
                      <div class="client-individual-float-left client-individual-apply-option">
                        <v-checkbox
                          id="client-individual-apply-custom-checkbox"
                          class="client-individual-float-left"
                          v-model="applyCustomEnabled"
                        ></v-checkbox>
                        <label for="client-individual-apply-custom-checkbox">
                          Custom
                        </label>
                      </div>
                      <v-btn
                        style="margin-left: 10px;"
                        class="
                          normal-btn
                          white--text
                          btn-update-groups
                          client-individual-float-left
                          primary
                        "
                        name="btnApply"
                        id="btnApply"
                        :disabled="applyButtonDisabled()"
                        @click.native="applySelectedOption()"
                      >Apply</v-btn>
                    </v-flex>
                    <v-layout style="height: 62vh">
                      <v-flex style="overflow: auto">
                        <v-data-table
                          :headers="groupHeaders"
                          :select-all="true"
                          v-model="selectedGroupItems"
                          :items="groupList"
                          class="elevation-2 excursion-table"
                          :loading="groupsLoading"
                          no-data-text="No group was found"
                          :footer-props="footerProps"
                        >
                          <template slot="item" slot-scope="props">
                            <tr>
                              <td class="excursion-name-cell">
                                <v-checkbox
                                    hide-details
                                    v-model="props.selected"
                                    class="client-individual-group-checkbox"
                                ></v-checkbox>
                              </td>
                              <td class="excursion-name-cell">{{props.item.group}}</td>
                              <td class="excursion-name-cell">
                                <v-select
                                    clearable
                                    v-model="props.item.school"
                                    class="type-dropdown client-individual-student-select"
                                    :items="schoolTypes"
                                    item-text="name"
                                    item-value="id"
                                    label="School type"
                                    solo
                                    :hide-details="true"
                                ></v-select>
                              </td>
                              <td class="text-xs-left">
                                <div v-if="!props.item.parentSelectionDisplayed">
                                  <a
                                      class="client-individual-parent"
                                      v-if="props.item.parent"
                                      @click="displayParentSelection(props.item)"
                                  >
                                    {{getParentGroupName(props.item.parent)}}
                                  </a>
                                  <a
                                      class="client-individual-parent"
                                      v-if="!props.item.parent"
                                      @click="displayParentSelection(props.item)"
                                  >
                                    [select]
                                  </a>
                                </div>
                                <v-autocomplete
                                    class="client-individual-student-select"
                                    v-if="props.item.parentSelectionDisplayed"
                                    v-model="props.item.parent"
                                    :items="groupList"
                                    label="Parent"
                                    item-text="group"
                                    item-value="id"
                                    :cache-items="false"
                                    clearable
                                    :multiple="false"
                                    ref="selectGroup"
                                    hide-details
                                    hide-selected
                                    :deletable-chips="false"
                                    solo
                                    @blur="parentSelected(props.item)"
                                ></v-autocomplete>
                              </td>
                              <td class="text-xs-left">
                                <v-checkbox
                                    v-model="props.item.subjectGroup"
                                    class="client-individual-checkbox"
                                ></v-checkbox>
                              </td>
                              <td class="text-xs-left">
                                <v-checkbox
                                    v-model="props.item.customGroup"
                                    class="client-individual-checkbox"
                                ></v-checkbox>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
              </v-tab-item>
              <v-tab-item>
               <div class="additionalInfoList">
                 <v-flex xs12 class="additional-info-list-info-text">
                   <i class="fas fa-info-circle"></i>
                   You can upload one file for client template.
                 </v-flex>
                 <div :key="index" v-for="(proformaTemplateFile, index) in clientTemplateFiles">
                   <div v-if="!proformaTemplateFile.isDeleted">
                     <v-layout wrap>
                       <v-flex xs12>
                         <v-layout wrap>
                           <v-flex xs12>
                             <input
                               :id="getUploadFileId('proformaTemplate', index)"
                               style="display:none;"
                               type="file"
                               :accept="proformaTemplateFileExtensions"
                               @change="
                                 handleTemplateFileChange($event, proformaTemplateFile, index)
                               "
                             >
                             <v-btn
                               @click="uploadFileClick('proformaTemplate', index)"
                               class="normal-btn white--text continue-btn
                               excursion-stepper-upload-btn v-btn-upload primary"
                             >
                               <i class="fa fa-upload" aria-hidden="true"></i> Upload
                             </v-btn>
                           </v-flex>
                         </v-layout>
                       </v-flex>
                       <v-flex xs12 class="form-label template-file-font">
                         File Name
                       </v-flex>
                       <v-flex xs12>
                         <v-layout wrap>
                           <v-flex xs12 style="margin-left: 3px;">
                             {{proformaTemplateFile.filename}}
                             <v-icon v-if="proformaTemplateFile.sid && !templateFileChanged"
                               @click="downloadFile(proformaTemplateFile)"
                               medium
                               style="font-size:15px;height:17px;margin-left:.7%;"
                             >fa fa-download</v-icon>
                           </v-flex>
                         </v-layout>
                       </v-flex>
                     </v-layout>
                     <v-layout wrap>
                       <v-flex xs12 class="form-label template-file-font">
                         Uploaded Date
                       </v-flex>
                       <v-flex xs12>
                         {{getUploadDate(proformaTemplateFile.fileUpdateDate)}}
                       </v-flex>
                     </v-layout>
                   </div>
                 </div>
                 <div>
                   <v-dialog v-model="invalidFileTypeDialogDisplayed" persistent max-width="490">
                     <v-card>
                       <v-card-title class="headline center">
                         type not allowed:<br> {{uploadFilename}}</v-card-title>
                       <v-card-actions>
                         <v-spacer></v-spacer>
                         <v-btn
                           @click.native="invalidFileTypeDialogDisplayed = false"
                           class="normal-btn white--text primary"
                         >OK</v-btn>
                       </v-card-actions>
                     </v-card>
                   </v-dialog>
                 </div>
               </div>
             </v-tab-item>
            </v-tabs>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-flex class="client-individual-error">
            {{saveActionErrorMessage}}
          </v-flex>
          <v-spacer></v-spacer>
          <v-icon v-if="clientSaveActionInProgress" medium style="height:8px;">
            fa fa-spinner fa-pulse
          </v-icon>
          <v-btn
            class="normal-btn white--text btnSave primary"
            name="btnSave"
            id="btnSave"
            :disabled="saveButtonDisabled"
            @click.native="save"
          >Save</v-btn>
          <v-btn class="normal-btn" @click.native="close()" id="btnClose">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="genericDialogDisplayed" persistent max-width="390">
        <v-card>
          <v-card-title class="headline center">{{genericDialogMessage}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click.native="genericDialogDisplayed = false"
              class="normal-btn white--text primary"
              id="btnOk"
            >OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="prevDeleteCacheDialog" persistent max-width="590">
        <v-card>
          <v-card-title class="headline center">
            Clearing the cache may affect other clients using the same module.
            Are you sure you want to clear the cache?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="normal-btn white--text btnSave primary"
              name="btnDeleteCache"
              id="btnDeleteCache"
              :disabled="cacheClearActionInProgress"
              @click.native="deleteRiskCaches"
            >
              <v-icon v-if="cacheClearActionInProgress">
                fa fa-spinner fa-pulse
              </v-icon>
              Yes
            </v-btn>
            <v-btn
              class="normal-btn"
              @click.native="prevDeleteCacheDialog = false"
              id="btnCanceDeleteCache"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="deleteCacheResult" persistent max-width="490">
        <v-card>
          <v-card-title class="headline center">{{resultMessage}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click.native="deleteCacheResult = false"
              class="normal-btn white--text primary"
              id="btnCloseResultDialog"
            >OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import attachmentCategory from "../lib/const/attachmentCategory";
import dbEntityRelation from "../lib/const/dbEntityRelation";
import errorSid from "../lib/const/errorSid";
import providerSourceType from "../lib/const/providerSourceType";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { deleteCacheMessages } from "../lib/errorMessages";

function validateKebabCasedString(value) {
  const regex = new RegExp(/^([a-z0-9]*)([-.][a-z0-9]+)*$/);
  const kebabCasedStringValid = regex.test(value);
  return kebabCasedStringValid;
}

function validateSubmomain(value) {
  if (!value || (value && 0 === value.length)) {
    return true;
  }

  const regex = new RegExp(/^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})*$/);
  const subdomainValid = regex.test(value);
  return subdomainValid;
}

function validateUrl(value) {
  if (!value || (value && 0 === value.length)) {
    return true;
  }

  const regex = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/);
  const urlValid = regex.test(value);
  return urlValid;
}

export default {
  mixins: [validationMixin],
  validations: {
    clientName: {
      required,
    },
    vendorSid: {
      required,
    },
    vendorClientSid: {
      required,
      validateKebabCasedString,
    },
    vendorClientName: {
      required,
    },
    timezone: {
      required,
    },
    subdomain: {
      required,
      validateSubmomain,
    },
    idpUrl: {
      validateIdpUrl: validateUrl,
    },
    vendorWebhookUrl: {
      validateVendorWebhookUrl: validateUrl,
    },
    ceEnvironmentSelection: {
      ceApiUri: {
        required,
      },
    },
    contentEngine: {
      xSiteUri: {
        required,
      },
      moduleName: {
        required,
      },
    },
    provider: {
      apiBaseUrl: {
        validateApiBaseUrl: validateUrl,
      },
    },
  },
  props: {
    propType: String,
    propClientSid: String,
    propClientIndividualModalRefreshed: Boolean,
  },
  data() {
    return {
      clientIndividualModalDisplayed: false,
      clientSid: "",
      clientName: "",
      csClientId: "-",
      vendorSid: "",
      vendorClientSid: "",
      vendorClientName: "",
      vendorWebhookUrl: "",
      clientLogoBackgroundColor: "",
      parentClientSid: "",
      searchParentClients: null,
      prevGetParent: 0,
      footerProps: {
        'items-per-page-options': [
          5,
          10,
          25,
        ]
      },
      pagination: {
        itemsPerPage: -1,
        descending: false,
        page: 1,
        sortDesc: [false],
        sortBy: ['']
      },
      excursionManagementVendors: [],
      studentManagementVendors: [],
      genericDialogDisplayed: false,
      genericDialogMessage: "",
      vendorExcursionSidInputEnabled: false,
      boardingSupportEnabled: false,
      vendorClientSidConflicted: false,
      excursionAdditionalInformationEnabled: false,
      excursionChecklistEnforcementBeforeSubmissionEnabled: false,
      excursionCopyActionEnabled: false,
      excursionDestinationContentEnabled: false,
      excursionHighRiskStudentMedicalEnabled: false,
      excursionPersonResponsibleEnabled: false,
      excursionPolicyViewActionEnabled: false,
      excursionInherentRiskRatingEnabled: false,
      idpRbaclEnabled: false,
      publicSchoolFeatureSetEnabled: false,
      imgSrc: "",
      cropImg: "",
      logoFile: "",
      searchDomain: "",
      getClientDomainsError: "",
      logoFileChanged: false,
      originalLogoUsed: true,
      contentEngine: {
        ceApiUri: "",
        xAppKey: "",
        xWebApiKey: "",
        xSiteUri: "",
        moduleName: "",
      },
      cropButtonDisabled: false,
      timezone: "",
      timezones: [],
      allTimezones: [],
      clientSaveActionInProgress: false,
      ceParamErrorDisplayed: false,
      subdomain: "",
      idpUrl: "",
      subdomainConflicted: false,
      ceEnvironmentSelection: {
        ceApiUri: "",
        xAppKey: "",
        xWebApiKey: "",
      },
      ceEnvironments: [],
      deleteCacheResult: false,
      prevDeleteCacheDialog: false,
      resultMessage: "",
      cacheClearActionInProgress: false,
      activeStudentManagementVendorSid: "",
      studentManagementClientApiFields: [],
      searchTimezone: Function,
      studentManagement: {},
      newSchoolType: {},
      schoolTypes: [],
      savedSchoolTypes: [],
      schoolTypeValid: true,
      groupHeaders: [
        {
          text: "Group",
          value: "group",
          class: "header",
          width: 220,
        },
        {
          text: "School Type",
          value: "school",
          class: "header",
          width: 220,
        },
        {
          text: "Parent",
          value: "parent",
          class: "header",
          width: 220,
        },
        {
          text: "Subject Group",
          value: "subjectGroup",
          class: "header",
          width: 100,
        },
        {
          text: "Custom Group",
          value: "customGroup",
          class: "header",
          width: 100,
        },
      ],
      selectedGroupItems: [],
      groupList: [],
      groupsLoading: false,
      selectedApplyParentId: 0,
      applySubjectEnabled: false,
      applyCustomEnabled: false,
      clientTemplateFiles: [{}],
      invalidFileTypeDialogDisplayed: false,
      selectedTemplateFile: {},
      proformaTemplateFileExtensions: `.pdf,.doc,.docx,.xls,.xlsx`,
      studentManagementFileExtensions: `.csv`,
      studentManagementFiles: [{}],
      uploadFilename: "",
      templateFileChanged: false,
      studentManagementFileChanged: false,
      providerSourceTypes: [
        {
          text: providerSourceType.SFTP,
          value: providerSourceType.SFTP,
        },
        {
          text: providerSourceType.API,
          value: providerSourceType.API,
        },
      ],
      provider: {},
      ceParamErrorMessage: "Invalid Content Engine parameter",
      apiProviderParamErrorMessage: "Invalid Api Provider parameter",
      saveActionErrorMessage: "",
      studentManagementErrorHash: {},
      clientStudentGroupSubsetAttributeKeys: [
        "sub_long",
        "sub_short",
        "sub_type",
      ],
    };
  },
  computed: {
    clientSidLabel() {
      return ((0 < this.clientSid) ? this.clientSid : "-");
    },
    clientNameErrors() {
      const errors = [];
      if (!this.$v.clientName.$dirty) return errors;
      if (!this.$v.clientName.required) {
        errors.push("Client Name is required");
      }
      return errors;
    },
    vendorSidErrors() {
      const errors = [];
      if (!this.$v.vendorSid.$dirty) return errors;
      if (!this.$v.vendorSid.required) {
        errors.push("Vendor Name is required");
      }
      return errors;
    },
    timezoneErrors() {
      const errors = [];
      if (!this.$v.timezone.$dirty) return errors;
      if (!this.$v.timezone.required) {
        errors.push("Time Zone is required");
      }
      return errors;
    },
    vendorClientSidErrors() {
      const errors = [];
      if (!this.$v.vendorClientSid.$dirty) return errors;
      if (!this.$v.vendorClientSid.required) {
        errors.push("Vendor Client SID is required");
      } else if (!this.$v.vendorClientSid.validateKebabCasedString) {
        errors.push("Vendor Client SID must be a kebab-cased string");
      } else {
        if (this.vendorClientSidConflicted) {
          errors.push("Vendor Client SID conflicted");
        }
      }
      return errors;
    },
    vendorClientNameErrors() {
      const errors = [];
      if (!this.$v.vendorClientName.$dirty) return errors;
      if (!this.$v.vendorClientName.required) {
        errors.push("Vendor Client Name is required");
      }
      return errors;
    },
    subdomainErrors() {
      const errors = [];
      if (!this.$v.subdomain.$dirty) return errors;

      if (!this.$v.subdomain.required) {
        errors.push("Client Subdomain is required");
      } else if (!this.$v.subdomain.validateSubmomain) {
        errors.push("Invalid Client Subdomain");
      } else if (this.subdomainConflicted) {
        errors.push("Client Subdomain conflicted");
      }
      return errors;
    },
    idpUrlErrors() {
      const errors = [];
      if (!this.$v.idpUrl.$dirty) return errors;
      if (!this.$v.idpUrl.validateIdpUrl) {
        errors.push("Invalid IdP URL");
      }
      return errors;
    },
    clientIndividualModalRefreshed() {
      return this.propClientIndividualModalRefreshed;
    },
    inputInvalid() {
      return this.$v.$invalid;
    },
    providerInvalid() {
      return (
        this.provider.sourceType
        && (providerSourceType.API === this.provider.sourceType)
        && (
          !this.provider.apiBaseUrl
          || !this.provider.apiKey
          || !this.provider.apiTenantSid
          || !this.provider.apiReportSid
        )
      );
    },
    saveButtonDisabled() {
      let studentManagementValid = true;
      if (
        this.studentManagementClientApiFields
        && (0 < this.studentManagementClientApiFields.length)
      ) {
        // TODO: Refactor this since certain text fields might not be mandatory
        const validatedFields = this.studentManagementClientApiFields
        .filter(f => "text" === f.type);
        if (validatedFields && (0 < validatedFields.length)) {
          studentManagementValid = validatedFields
          .every(f => (
            f.value
            && (0 < `${f.value}`.length)
          ));
        }
      }
      return (
        this.inputInvalid
        || this.clientSaveActionInProgress
        || !studentManagementValid
        || !(
          this.ceEnvironmentSelection
          && this.contentEngine.xSiteUri
          && this.contentEngine.moduleName
        )
        || !this.schoolTypeValid
        || this.providerInvalid
        || (0 < Object.keys(this.studentManagementErrorHash).length)
      );
    },
    parentClientLoadActionInProgress() {
      return this.$store.state.parentClientList.loading;
    },
    parentClientItems() {
      return this.$store.state.parentClientList.items
      .filter(item => item.sid !== this.propClientSid);
    },
    parentClientCache() {
      return this.$store.state.parentClientList.itemsCache;
    },
    vendorWebhookUrlErrors() {
      const errors = [];
      if (!this.$v.vendorWebhookUrl.$dirty) return errors;
      if (!this.$v.vendorWebhookUrl.validateVendorWebhookUrl) {
        errors.push("Invalid Vendor Webhook URL");
      }
      return errors;
    },
    clientProviderSftpPathUpdateActionEnabled() {
      return this.$store.state.clientProviderSftpPathUpdateActionEnabled;
    },
    ceApiUriErrors() {
      const errors = [];
      if (!this.$v.ceEnvironmentSelection.$dirty) return errors;
      if (!this.$v.ceEnvironmentSelection.ceApiUri.required) {
        errors.push("ce-api-uri is required");
      }
      return errors;
    },
    ceXSiteUriErrors() {
      const errors = [];
      if (!this.$v.contentEngine.$dirty) return errors;
      if (!this.$v.contentEngine.xSiteUri.required) {
        errors.push("x-site-uri is required");
      }
      return errors;
    },
    ceModuleNameErrors() {
      const errors = [];
      if (!this.$v.contentEngine.$dirty) return errors;
      if (!this.$v.contentEngine.moduleName.required) {
        errors.push("module-name is required");
      }
      return errors;
    },
    providerApiBaseUrlErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiBaseUrl) {
        errors.push("API Base URL is required");
      } else if (!this.$v.provider.apiBaseUrl.validateApiBaseUrl) {
        errors.push("Invalid API Base URL");
      }
      return errors;
    },
    providerApiKeyErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiKey) {
        errors.push("API Key is required");
      }

      return errors;
    },
    providerApiTenantSidErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiTenantSid) {
        errors.push("API Tenant SID is required");
      }
      return errors;
    },
    providerApiReportSidErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiReportSid) {
        errors.push("API Report SID is required");
      }
      return errors;
    },

    moduleCacheClearanceButtonDisplayed() {
      return this.$store.state.moduleCacheClearanceButtonDisplayed;
    },
  },
  methods: {
    getSelectedVendorName() {
      return this.excursionManagementVendors.reduce(
        (result, vendor) => {
          if (this.vendorSid === vendor.sid) {
            result = vendor.name;
          }
          return result;
        },
        false,
      );
    },
    async newClient(clientSid) {
      this.vendorClientSidConflicted = false;
      this.subdomainConflicted = false;

      if (this.$store.state.ceEnvironment) {
        this.setCeEnvironments(this.$store.state.ceEnvironment);
      }

      if ("" !== clientSid && undefined !== clientSid) {
        let vendorClientSid = "";
        let vendorClientName = "";
        let vendorExcursionSidInputEnabled = false;
        let boardingSupportEnabled = false;
        let excursionAdditionalInformationEnabled = false;
        let excursionChecklistEnforcementBeforeSubmissionEnabled = false;
        let excursionCopyActionEnabled = false;
        let excursionDestinationContentEnabled = false;
        let excursionHighRiskStudentMedicalEnabled = false;
        let excursionPersonResponsibleEnabled = false;
        let excursionPolicyViewActionEnabled = false;
        let excursionInherentRiskRatingEnabled = false;
        let clientLogoBackgroundColor = "";
        let provider = {};
        let vendorWebhookUrl = "";
        let idpRbaclEnabled = false;
        let publicSchoolFeatureSetEnabled = false;

        // get logo
        const requestParam = {
          "vendor-sid": this.$store.state.apiParam["vendor-sid"],
          "oauth-signature": this.$store.state.apiParam["oauth-signature"],
        };

        const argHash = {
          sid: this.clientSid,
          requestParam,
        };

        const cbResponse = await this.$app.stbApiAdapter.getClients(argHash);

        this.imgSrc = "";
        if (!cbResponse.error) {
          const client = cbResponse.data[0];

          if (client.cs_client_id) {
            this.csClientId = client.cs_client_id;
          }

          if (client.computed.logoAttachmentUrl) {
            this.cropImg = client.computed.logoAttachmentUrl;
          }

          const activeVendorSid = client.metadata.activeVendorSid
            ? client.metadata.activeVendorSid
            : "";

          excursionAdditionalInformationEnabled
            = client.metadata.excursionAdditionalInformationEnabled
              ? client.metadata.excursionAdditionalInformationEnabled
              : false;

          excursionChecklistEnforcementBeforeSubmissionEnabled
            = client.metadata.excursionChecklistEnforcementBeforeSubmissionEnabled
              ? client.metadata.excursionChecklistEnforcementBeforeSubmissionEnabled
              : false;

          excursionCopyActionEnabled
            = client.metadata.excursionCopyActionEnabled
              ? client.metadata.excursionCopyActionEnabled
              : false;

          excursionDestinationContentEnabled
            = client.metadata.excursionDestinationContentEnabled
              ? client.metadata.excursionDestinationContentEnabled
              : false;

          excursionHighRiskStudentMedicalEnabled
            = client.metadata.excursionHighRiskStudentMedicalEnabled
              ? client.metadata.excursionHighRiskStudentMedicalEnabled
              : false;

          excursionPolicyViewActionEnabled
            = client.metadata.excursionPolicyViewActionEnabled
              ? client.metadata.excursionPolicyViewActionEnabled
              : false;

          excursionInherentRiskRatingEnabled
            = client.metadata.excursionInherentRiskRatingEnabled
              ? client.metadata.excursionInherentRiskRatingEnabled
              : false;

          idpRbaclEnabled
            = client.metadata.idpRbaclEnabled
              ? client.metadata.idpRbaclEnabled
              : false;

          publicSchoolFeatureSetEnabled
            = client.metadata.publicSchoolFeatureSetEnabled
              ? client.metadata.publicSchoolFeatureSetEnabled
              : false;

          if (client.metadata.provider) {
            provider = client.metadata.provider;
          }

          const vendors = client[dbEntityRelation.VENDOR_LIST];
          if ("" !== activeVendorSid) {
            const vendor = vendors.find(v => v.sid === `${activeVendorSid}`);
            if (vendor) {
              if (vendor["vendor-client-sid"]) {
                vendorClientSid = vendor["vendor-client-sid"];
              }

              if (vendor.join_metadata.vendorClientName) {
                vendorClientName = vendor.join_metadata.vendorClientName;
              }

              if (vendor.join_metadata.vendorExcursionSidInputEnabled) {
                vendorExcursionSidInputEnabled
                  = vendor.join_metadata.vendorExcursionSidInputEnabled;
              }

              if (vendor.join_metadata.boardingSupportEnabled) {
                boardingSupportEnabled = vendor.join_metadata.boardingSupportEnabled;
              }

              if (vendor.join_metadata.excursionPersonResponsibleEnabled) {
                excursionPersonResponsibleEnabled
                  = vendor.join_metadata.excursionPersonResponsibleEnabled;
              }

              if (vendor.join_metadata.clientLogoBackgroundColor) {
                clientLogoBackgroundColor
                  = vendor.join_metadata.clientLogoBackgroundColor;
              }

              if (vendor.join_metadata.vendorWebhookUrl) {
                vendorWebhookUrl = vendor.join_metadata.vendorWebhookUrl;
              }
            }
          }

          this.clientLogoBackgroundColor = clientLogoBackgroundColor;
          this.clientName = client.metadata.name;
          this.excursionAdditionalInformationEnabled = excursionAdditionalInformationEnabled;
          this.excursionCopyActionEnabled = excursionCopyActionEnabled;
          this.excursionDestinationContentEnabled = excursionDestinationContentEnabled;
          this.excursionHighRiskStudentMedicalEnabled = excursionHighRiskStudentMedicalEnabled;
          this.excursionPersonResponsibleEnabled = excursionPersonResponsibleEnabled;
          this.excursionInherentRiskRatingEnabled = excursionInherentRiskRatingEnabled;
          this.idpRbaclEnabled = idpRbaclEnabled;
          this.publicSchoolFeatureSetEnabled = publicSchoolFeatureSetEnabled;
          this.excursionPolicyViewActionEnabled = excursionPolicyViewActionEnabled;
          this.excursionChecklistEnforcementBeforeSubmissionEnabled
            = excursionChecklistEnforcementBeforeSubmissionEnabled;
          this.provider = provider;
          this.vendorClientName = vendorClientName;
          this.vendorClientSid = vendorClientSid;
          this.vendorExcursionSidInputEnabled = vendorExcursionSidInputEnabled;
          this.boardingSupportEnabled = boardingSupportEnabled;
          this.vendorSid = client.metadata.activeVendorSid;
          this.vendorWebhookUrl = vendorWebhookUrl;
          this.activeStudentManagementVendorSid
            = client.metadata.activeStudentManagementVendorSid;

          if ("" !== this.activeStudentManagementVendorSid) {
            const vendor = vendors.find(v => v.sid === `${this.activeStudentManagementVendorSid}`);
            if (vendor && vendor.join_metadata && vendor.join_metadata.studentManagement) {
              this.studentManagement = vendor.join_metadata.studentManagement;
            }
          }

          if (
            this.activeStudentManagementVendorSid
            && this.studentManagement
            && this.studentManagement.clientApiMetadata
          ) {
            this.studentManagementClientApiFields = [];
            const vendor = this.studentManagementVendors.find((v) => {
              return (v.sid === this.activeStudentManagementVendorSid);
            });

            let clientApiMetadataSchema = {};
            if (
              vendor
              && vendor.studentManagement
              && vendor.studentManagement.clientApiMetadataSchema
            ) {
              clientApiMetadataSchema = vendor.studentManagement.clientApiMetadataSchema;
            }

            Object.keys(clientApiMetadataSchema).forEach((clientApiFieldKey) => {
              const clientApiField = clientApiMetadataSchema[clientApiFieldKey];
              let clientApiFieldValue = "";

              if ("undefined" !== typeof (this.studentManagement.clientApiMetadata[clientApiFieldKey])) {
                clientApiFieldValue = this.studentManagement.clientApiMetadata[clientApiFieldKey];
              }

              this.studentManagementClientApiFields.push({
                name: clientApiFieldKey,
                label: clientApiField.label,
                value: clientApiFieldValue,
                type: clientApiField.type,
                groupListQueryFieldFlag: clientApiField.groupListQueryFieldFlag,
              });
            });

            if (this.studentManagement.clientApiMetadata.groupManagement) {
              this.groupsLoading = true;
              this.groupList = [];
              const {
                entryHash,
                associationHash,
              } = this.studentManagement.clientApiMetadata.groupManagement;

              Object.keys(entryHash).forEach((entryHashKey) => {
                const entryHashValue = entryHash[entryHashKey];
                const clientStudentGroup = {
                  id: entryHashKey,
                  group: entryHashValue.name,
                };

                this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
                  const subsetAttributeValue = entryHashValue[subsetAttributeKey];

                  if ("undefined" !== typeof subsetAttributeValue) {
                    clientStudentGroup[subsetAttributeKey] = subsetAttributeValue;
                  }
                });

                switch (entryHashValue.parentType) {
                  case "group":
                    clientStudentGroup.parent = `${entryHashValue.parentId}`;
                    break;

                  case "school":
                    clientStudentGroup.school = `${entryHashValue.parentId}`;
                    break;

                  default:
                    break;
                }

                if (
                  associationHash.custom
                  && associationHash.custom[entryHashKey]
                ) {
                  clientStudentGroup.customGroup = true;
                }

                if (
                  associationHash.subject
                  && associationHash.subject[entryHashKey]
                ) {
                  clientStudentGroup.subjectGroup = true;
                }

                this.groupList.push(clientStudentGroup);
              });

              this.groupsLoading = false;
            }
          }

          this.contentEngine = {};
          if (client.metadata.contentEngine) {
            this.contentEngine = client.metadata.contentEngine;
            let ceApiUri = this.contentEngine.ceApiUri;
            if ("/" === ceApiUri.substring(ceApiUri.length - 1)) {
              ceApiUri = ceApiUri.substring(0, ceApiUri.lastIndexOf("/"));
            }
            this.ceEnvironmentSelection = {
              ceApiUri: ceApiUri,
              xAppKey: this.contentEngine.xAppKey,
              xWebApiKey: this.contentEngine.xWebApiKey,
            };
          }
          this.timezone = client.metadata.timezone ? client.metadata.timezone : "";
          if (client.parentClient && client.parentClient.metadata) {
            this.$store.state.parentClientList.items.push({
              sid: client.parentClient.sid,
              name: `${client.parentClient.sid} - ${client.parentClient.metadata.name}`,
            });
            this.parentClientSid = client.parentClient.sid;
          }

          this.subdomain = client.metadata.subdomain ? client.metadata.subdomain : "";
          this.idpUrl = client.metadata.idpUrl ? client.metadata.idpUrl : "";

          this.schoolTypes = [];
          if (client.metadata.schoolType) {
            const schoolType = client.metadata.schoolType;
            Object.keys(schoolType).forEach((schoolKey) => {
              this.schoolTypes.push({
                id: schoolKey,
                name: schoolType[schoolKey],
              });
            });

            this.savedSchoolTypes = JSON.parse(JSON.stringify(this.schoolTypes));
          }

          // Get template file
          if (client.computed.templateAttachment) {
            const file = client.computed.templateAttachment;
            this.clientTemplateFiles[0].filename = file.metadata.filename;
            this.clientTemplateFiles[0].fileUpdateDate = file.created_at;
            this.clientTemplateFiles[0].sid = file.sid;
          }
        }
      } else {
        this.clear();
      }
    },
    constructStudentManagementGroupManagement() {
      const groupManagement = {
        entryHash: {},
        associationHash: {
          year: {},
          class: {},
          custom: {},
          subject: {},
        },
      };

      this.groupList.forEach((clientStudentGroup) => {
        const entryId = clientStudentGroup.id;
        const entry = {
          name: clientStudentGroup.group,
          parentId: "",
          parentType: "",
        };
        groupManagement.entryHash[entryId] = entry;

        this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
          const subsetAttributeValue = clientStudentGroup[subsetAttributeKey];

          if ("undefined" !== typeof subsetAttributeValue) {
            entry[subsetAttributeKey] = subsetAttributeValue;
          }
        });

        if (clientStudentGroup.school) {
          entry.parentId = `${clientStudentGroup.school}`;
          entry.parentType = "school";
          groupManagement.associationHash.year[entryId] = true;
        } else if (clientStudentGroup.parent) {
          entry.parentId = `${clientStudentGroup.parent}`;
          entry.parentType = "group";
          if (clientStudentGroup.subjectGroup) {
            groupManagement.associationHash.subject[entryId] = true;
          } else {
            groupManagement.associationHash.class[entryId] = true;
          }
        } else if (clientStudentGroup.customGroup) {
          groupManagement.associationHash.custom[entryId] = true;
        }
      });

      return groupManagement;
    },
    async save() {
      this.vendorClientSidConflicted = false;
      this.subdomainConflicted = false;
      this.cropButtonDisabled = true;
      this.saveActionErrorMessage = "";
      const apiParam = JSON.parse(JSON.stringify(this.$store.state.apiParam));
      if (!this.ceEnvironmentSelection) {
        this.contentEngine.ceApiUri = "";
        this.contentEngine.xAppKey = "";
        this.contentEngine.xWebApiKey = "";
      }
      const client = {
        clientLogoBackgroundColor: this.clientLogoBackgroundColor,
        contentEngine: this.contentEngine,
        excursionAdditionalInformationEnabled:
          this.excursionAdditionalInformationEnabled,
        excursionChecklistEnforcementBeforeSubmissionEnabled:
          this.excursionChecklistEnforcementBeforeSubmissionEnabled,
        excursionCopyActionEnabled: this.excursionCopyActionEnabled,
        excursionDestinationContentEnabled: this.excursionDestinationContentEnabled,
        excursionHighRiskStudentMedicalEnabled: this.excursionHighRiskStudentMedicalEnabled,
        excursionPersonResponsibleEnabled: this.excursionPersonResponsibleEnabled,
        excursionPolicyViewActionEnabled: this.excursionPolicyViewActionEnabled,
        excursionInherentRiskRatingEnabled: this.excursionInherentRiskRatingEnabled,
        idpRbaclEnabled: this.idpRbaclEnabled,
        publicSchoolFeatureSetEnabled: this.publicSchoolFeatureSetEnabled,
        name: this.clientName,
        parentClientSid: this.parentClientSid,
        provider: this.provider,
        timezone: this.timezone,
        vendorClientName: this.vendorClientName,
        vendorClientSid: this.vendorClientSid,
        vendorExcursionSidInputEnabled: this.vendorExcursionSidInputEnabled,
        boardingSupportEnabled: this.boardingSupportEnabled,
        vendorWebhookUrl: this.vendorWebhookUrl,
        vendorSid: this.vendorSid,
        subdomain: this.subdomain,
        idpUrl: this.idpUrl,
      };

      if (
        !client.contentEngine.ceApiUri
        && !client.contentEngine.xAppKey
        && !client.contentEngine.xWebApiKey
        && !client.contentEngine.xSiteUri
        && !client.contentEngine.moduleName
      ) {
        delete client.contentEngine;
      }

      if (this.activeStudentManagementVendorSid) {
        client.activeStudentManagementVendorSid = this.activeStudentManagementVendorSid;
        client.studentManagement = this.studentManagement;

        if (!client.studentManagement.clientApiMetadata) {
          client.studentManagement.clientApiMetadata = {};
        }

        if (
          this.studentManagementClientApiFields
          && (0 < this.studentManagementClientApiFields.length)
        ) {
          this.studentManagementClientApiFields.forEach((f) => {
            let apiFieldsValue = "";
            if ("undefined" !== typeof (f.value)) {
              apiFieldsValue = f.value;
            }
            client.studentManagement.clientApiMetadata[f.name] = apiFieldsValue;
          });

          const groupManagement = this.constructStudentManagementGroupManagement();
          client.studentManagement.clientApiMetadata.groupManagement = groupManagement;
        }
      }

      const schoolType = {};
      this.schoolTypes.forEach((s) => {
        schoolType[s.id] = s.name;
      });
      client.schoolType = schoolType;

      apiParam.metadata = client;

      this.clientSaveActionInProgress = true;
      this.ceParamErrorDisplayed = false;
      if ("" !== `${this.clientSid}`) {
        if (this.templateFileChanged) {
          this.uploadClientTemplateFiles(this.clientSid);
        }
        if (this.studentManagementFileChanged) {
          const dataFromFile = await this.uploadStudentManagementFiles(this.clientSid);
          client.studentManagement = { clientApiMetadata: dataFromFile.clientApiMetadata };
          client.schoolType = dataFromFile.schoolType;
        }
        const argHash = {
          sid: this.clientSid,
          payload: apiParam,
        };
        const cbResponse = await this.$app.stbApiAdapter.putClients(argHash);
        if (!this.$store.state.isResponseWithError) {
          if ("409" === `${cbResponse.statusCode}`) {
            if (errorSid.SUBDOMAIN_CONFLICTED === `${cbResponse.errorSid}`) {
              this.subdomainConflicted = true;
              this.saveActionErrorMessage = this.subdomainErrors[0];
            }
            if (errorSid.VENDOR_CLIENT_SID_CONFLICTED === `${cbResponse.errorSid}`) {
              this.vendorClientSidConflicted = true;
              this.saveActionErrorMessage = this.vendorClientSidErrors[0];
            }
          } else if ("400" === `${cbResponse.statusCode}`) {
            if (errorSid.INVALID_CONTENT_ENGINE_PARAMETER === `${cbResponse.errorSid}`) {
              this.ceParamErrorDisplayed = true;
              this.saveActionErrorMessage = this.ceParamErrorMessage;
            }

            if (errorSid.INVALID_PROVIDER_PARAMETER === `${cbResponse.errorSid}`) {
              this.saveActionErrorMessage = this.apiProviderParamErrorMessage;
            }
          } else if (!cbResponse.error) {
            this.clientSid = cbResponse.data.sid;
            this.uploadClientTemplateFiles(this.clientSid);
            if (("" === `${this.cropImg}`) || !this.logoFileChanged) {
              this.alertSaveResult(cbResponse);
            } else {
              this.saveLogo();
            }
          } else {
            this.alertSaveResult(cbResponse);
          }
        }
        this.clientSaveActionInProgress = false;
      } else {
        const apiArgHash = {
          payload: apiParam,
        };
        if (this.studentManagementFileChanged) {
          const dataFromFile = await this.uploadStudentManagementFiles(this.clientSid);
          apiArgHash.payload.metadata.studentManagement = {
            clientApiMetadata: dataFromFile.clientApiMetadata,
          };
          apiArgHash.payload.metadata.schoolType = dataFromFile.schoolType;
        }
        const cbResponse = await this.$app.stbApiAdapter.postClients(apiArgHash);
        if (!this.$store.state.isResponseWithError) {
          if ("409" === `${cbResponse.statusCode}`) {
            if (errorSid.SUBDOMAIN_CONFLICTED === `${cbResponse.errorSid}`) {
              this.subdomainConflicted = true;
              this.saveActionErrorMessage = this.subdomainErrors[0];
            }
            if (errorSid.VENDOR_CLIENT_SID_CONFLICTED === `${cbResponse.errorSid}`) {
              this.vendorClientSidConflicted = true;
              this.saveActionErrorMessage = this.vendorClientSidErrors[0];
            }
          } else if ("400" === `${cbResponse.statusCode}`) {
            if (errorSid.INVALID_CONTENT_ENGINE_PARAMETER === `${cbResponse.errorSid}`) {
              this.ceParamErrorDisplayed = true;
              this.saveActionErrorMessage = this.ceParamErrorMessage;
            }

            if (errorSid.INVALID_PROVIDER_PARAMETER === `${cbResponse.errorSid}`) {
              this.saveActionErrorMessage = this.apiProviderParamErrorMessage;
            }
          } else if (!cbResponse.error) {
            this.clientSid = cbResponse.data.sid;
            this.uploadClientTemplateFiles(this.clientSid);
            if ("" === `${this.cropImg}`) {
              this.alertSaveResult(cbResponse);
            } else {
              this.saveLogo();
            }
          } else {
            this.alertSaveResult(cbResponse);
          }
        }
        this.clientSaveActionInProgress = false;
      }
    },
    async saveLogo() {
      // post image
      if (("" === `${this.cropImg}`) || !this.logoFileChanged) {
        return;
      }
      this.$refs.cropper.getCroppedCanvas().toBlob(async (blob) => {
        const requestFormData = new FormData();
        requestFormData.append("category", attachmentCategory.LOGO);
        if (this.originalLogoUsed && "" !== this.logoFile) {
          requestFormData.append("file", this.logoFile);
        } else {
          requestFormData.append("file", blob);
        }
        const argHash = {
          sid: this.clientSid,
          payload: requestFormData,
        };
        const cbResponse = await this.$app.stbApiAdapter.postClientAttachment(argHash);
        this.alertSaveLogoResult(cbResponse);
      });
    },
    alertSaveResult(cbResponse) {
      this.genericDialogDisplayed = true;
      this.clientIndividualModalDisplayed = false;
      this.clear();
      if (cbResponse && cbResponse.data) {
        // success
        this.genericDialogMessage = "Client has been saved.";

        this.clear();
        this.$emit("reloadClients");
      } else {
        // failed
        this.genericDialogMessage = "Failed to save Client information.";
      }
    },
    alertSaveLogoResult(cbResponse) {
      this.genericDialogDisplayed = true;
      this.clientIndividualModalDisplayed = false;
      this.clear();
      if (cbResponse && cbResponse.data) {
        // success
        this.genericDialogMessage = "Client has been saved.";

        this.clear();
        this.$emit("reloadClients");
      } else {
        // failed
        this.genericDialogMessage = "Failed to save Client Logo.";
      }

      this.cropButtonDisabled = false;
    },
    alertCheckLogoFormat(errorMsg) {
      this.genericDialogDisplayed = true;
      this.genericDialogMessage = errorMsg;
    },
    clear() {
      this.$v.$reset();
      this.clientName = "";
      this.vendorSid = "";
      this.vendorClientSid = "";
      this.vendorClientName = "";
      this.vendorExcursionIdInputEnabled = false;
      this.vendorWebhookUrl = "";
      this.excursionPersonResponsibleEnabled = false;
      this.excursionAdditionalInformationEnabled = false;
      this.excursionChecklistEnforcementBeforeSubmissionEnabled = false;
      this.excursionCopyActionEnabled = false;
      this.excursionDestinationContentEnabled = false;
      this.excursionHighRiskStudentMedicalEnabled = false;
      this.excursionPolicyViewActionEnabled = false;
      this.excursionInherentRiskRatingEnabled = false;
      this.idpRbaclEnabled = false;
      this.publicSchoolFeatureSetEnabled = false;
      this.clientLogoBackgroundColor = "";
      this.searchDomain = "";
      this.contentEngine = {};
      this.resetImage();
      this.timezone = "";
      this.clientSaveActionInProgress = false;
      this.ceParamErrorDisplayed = false;
      this.cropButtonDisabled = false;
      this.$store.commit("resetParentClient");
      this.parentClientSid = "";
      this.subdomain = "";
      this.idpUrl = "";
      this.ceEnvironmentSelection = {};
      this.activeStudentManagementVendorSid = "";
      this.studentManagementClientApiFields = [];
      this.vendorExcursionSidInputEnabled = false;
      this.boardingSupportEnabled = false;
      this.schoolTypes = [];
      this.savedSchoolTypes = [];
      this.schoolTypeValid = true;
      this.newSchoolType = {};
      this.groupList = [];
      this.selectedGroupItems = [];
      this.applySubjectEnabled = false;
      this.applyCustomEnabled = false;
      this.clientTemplateFiles = [{}];
      this.studentManagementFiles = [{}];
      this.provider = {};
      this.saveActionErrorMessage = "";
      this.studentManagementErrorHash = {};
    },
    resetImage() {
      this.cropImg = "";
      this.imgSrc = "";
    },
    selectFile() {
      this.$refs.fileSelect.click();
    },
    setImage(e) {
      const file = e.target.files[0];
      this.logoFile = file;

      if (!file.type.includes("image/")) {
        this.alertCheckLogoFormat("Please select an image file");
        return;
      }

      if ("function" === typeof FileReader) {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(event.target.result);
          }
        };

        reader.readAsDataURL(file);
      } else {
        this.alertCheckLogoFormat("Sorry, FileReader API not supported");
      }
    },
    cropImage() {
      // TODO: Fix image cropping
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper
      .getCroppedCanvas()
      .toDataURL(this.logoFile.type);

      this.logoFileChanged = true;
    },
    async getClientDomains() {
      if (0 === this.searchDomain.trim().length) {
        return;
      }

      const argHash = {
        domain: this.searchDomain,
      };

      const cbResponse = await this.$app.stbApiAdapter.getClientDomains(argHash);
      this.getClientDomainsError = "";

      if (cbResponse.error) {
        this.getClientDomainsError = "Unable to get client name";
        return;
      }

      const clients = cbResponse.data;
      if (clients && 0 < clients.length) {
        if (clients[0].name) {
          this.clientName = clients[0].name;
        }
      }
    },
    close() {
      this.clear();
      this.clientIndividualModalDisplayed = false;
    },
    cropMove() {
      this.originalLogoUsed = false;
    },
    disableAdditional() {
      return (
        ("update" === this.propType)
        && this.excursionAdditionalInformationEnabled
      );
    },
    enableAdditionalInformation() {
      if (!this.disableAdditional()) {
        this.excursionAdditionalInformationEnabled = !this.excursionAdditionalInformationEnabled;
      }
    },
    changeCeEnviromentSelection() {
      if (this.ceEnvironmentSelection) {
        this.contentEngine.ceApiUri = this.ceEnvironmentSelection.ceApiUri;
        this.contentEngine.xAppKey = this.ceEnvironmentSelection.xAppKey;
        this.contentEngine.xWebApiKey = this.ceEnvironmentSelection.xWebApiKey;

        this.autoPopulatexSiteUrl(true);
      }
    },
    setCeEnvironments(ceEnvironment) {
      this.ceEnvironments = [];
      Object.keys(ceEnvironment).forEach((key) => {
        this.ceEnvironments.push(ceEnvironment[key]);
      });
    },
    async deleteRiskCaches() {
      this.cacheClearActionInProgress = true;
      const argHash = {
        queryParam: this.contentEngine,
      };
      const response = await this.$app.stbApiAdapter.deleteRiskCaches(argHash);
      this.deleteCacheResult = true;
      this.prevDeleteCacheDialog = false;
      this.cacheClearActionInProgress = false;
      this.$store.state.isResponseWithError = false;
      if (
        response
        && !response.error
        && response.data
        && response.data.isCleared
      ) {
        this.resultMessage = "Content Cache has successfully been cleared";
      } else {
        this.resultMessage = deleteCacheMessages(response.data);
      }
    },
    clearModuleCacheButtonDisabled() {
      let disabled = false;
      if (
        !this.contentEngine
        || !this.contentEngine.xWebApiKey
        || !this.contentEngine.xSiteUri
        || !this.contentEngine.moduleName
        || this.clientSaveActionInProgress
      ) {
        disabled = true;
      }
      return disabled;
    },
    changeActiveStudentManagementVendorSid() {
      this.studentManagementClientApiFields = [];

      if (this.activeStudentManagementVendorSid) {
        const vendor = this.studentManagementVendors
        .find(v => v.sid === this.activeStudentManagementVendorSid);

        if (
          vendor
          && vendor.studentManagement
          && vendor.studentManagement.clientApiMetadataSchema
        ) {
          const schema = vendor.studentManagement.clientApiMetadataSchema;
          Object.keys(schema).forEach((s) => {
            const field = {
              name: s,
              label: schema[s].label,
              type: schema[s].type,
              value: "",
            };
            this.studentManagementClientApiFields.push(field);
          });
        }
      }
    },
    getStudentManagementFieldErrors(field) {
      const errors = [];
      if (!field.value || (field.value && (0 === field.value.length))) {
        errors.push(`${field.label} is required`);
      }
      field.errorMessage = errors;
      this.$forceUpdate();
    },
    getStudentManagementJsonTextareaFieldErrors(field) {
      const errors = [];
      const fieldName = field.name;
      const fieldValue = field.value;

      if (
        ("undefined" !== typeof fieldValue)
        && ("" !== `${fieldValue}`)
      ) {
        let parsedJson;

        try {
          parsedJson = JSON.parse(fieldValue);
        } catch (_err) {
          errors.push(`${field.label} needs to be a valid JSON`);
        }

        if (parsedJson) {
          const parsedJsonKeys = Object.keys(parsedJson);

          if (0 >= parsedJsonKeys.length) {
            errors.push(`${field.label} needs to be a non-empty JSON`);
          }
        }
      }

      const clonedStudentManagementErrorHash
        = JSON.parse(JSON.stringify(this.studentManagementErrorHash));

      if (0 < errors.length) {
        clonedStudentManagementErrorHash[fieldName] = true;
      } else {
        delete clonedStudentManagementErrorHash[fieldName];
      }

      if (
        clonedStudentManagementErrorHash[fieldName]
        !== this.studentManagementErrorHash[fieldName]
      ) {
        // Reassign this variable to force recalculation of saveButtonDisabled
        this.studentManagementErrorHash = clonedStudentManagementErrorHash;
      }

      field.errorMessage = errors;
      this.$forceUpdate();
    },
    autoPopulatexSiteUrl(forceChanged) {
      if (
        (forceChanged || !this.contentEngine.xSiteUri)
        && this.subdomain
        && this.ceEnvironmentSelection
      ) {
        const selectedCeEnvironment = this.getSelectedCeEnvironment();
        if (selectedCeEnvironment) {
          if ("production" === selectedCeEnvironment) {
            this.contentEngine.xSiteUri = `https://${this.subdomain}.app.safetripbuilder.com`;
          } else {
            this.contentEngine.xSiteUri = `https://${this.subdomain}.app-${selectedCeEnvironment}.safetripbuilder.com`;
          }
        }
        this.contentEngine = JSON.parse(JSON.stringify(this.contentEngine));
      }
    },
    autoPopulateVenderClientSid() {
      if (!this.vendorClientSid && this.clientSid) {
        this.vendorClientSid = this.clientSid;
      }
    },
    getSelectedCeEnvironment() {
      let environment = "";
      const ceEnvironment = this.$store.state.ceEnvironment;
      if (ceEnvironment) {
        Object.keys(ceEnvironment).forEach((key) => {
          if (this.ceEnvironmentSelection.ceApiUri === ceEnvironment[key].ceApiUri) {
            environment = key;
          }
        });
      }
      return environment;
    },
    showShoolTypeWarning() {
      if ("" !== this.propClientSid && undefined !== this.propClientSid) {
        this.genericDialogDisplayed = true;
        this.genericDialogMessage = "Doing school type changes may cause issues in PlanCheckGo!";
      }
    },
    addSchoolType() {
      let newSchoolTypeName = this.newSchoolType.name;
      const errors = [];
      this.newSchoolType.errorMessage = errors;
      this.$forceUpdate();
      if (!newSchoolTypeName || (newSchoolTypeName && (0 === newSchoolTypeName.trim().length))) {
        errors.push("School Name is required");
        this.newSchoolType.errorMessage = errors;
        this.$forceUpdate();
      } else {
        newSchoolTypeName = newSchoolTypeName.trim();
        if (this.schoolTypes.some(s => s.name === newSchoolTypeName)) {
          errors.push("School Name conflicted");
          this.newSchoolType.errorMessage = errors;
          this.$forceUpdate();
        }
      }

      if (0 === errors.length) {
        let addedId = 1;
        if (0 < this.savedSchoolTypes.length) {
          const savedSchoolType = this.savedSchoolTypes.find(s => s.name === newSchoolTypeName);
          if (savedSchoolType) {
            addedId = savedSchoolType.id;
          }
        }

        if (0 < this.schoolTypes.length && 1 === parseInt(addedId)) {
          const schoolTypeIds = this.schoolTypes.map(s => parseInt(s.id));
          schoolTypeIds.sort();
          addedId = schoolTypeIds[schoolTypeIds.length - 1] + 1;
        }

        this.schoolTypes.push({
          id: addedId,
          name: newSchoolTypeName,
        });
        this.newSchoolType = {};
        this.showShoolTypeWarning();
      }
    },
    changeSchool(school, index) {
      let editName = school.name;
      const errors = [];
      school.errorMessage = errors;
      this.$forceUpdate();
      if (!editName || (editName && (0 === editName.trim().length))) {
        errors.push("School Name is required");
        school.errorMessage = errors;
        this.$forceUpdate();
        this.schoolTypeValid = false;
      } else {
        editName = editName.trim();
        const conflictedSchool
          = this.schoolTypes.some(s => (s.name === editName) && (s.id !== school.id));
        if (conflictedSchool) {
          errors.push("School Name conflicted");
          school.errorMessage = errors;
          this.$forceUpdate();
          this.schoolTypeValid = false;
        }
      }

      if (0 === errors.length) {
        this.showShoolTypeWarning();
      }
    },
    deleteSchoolType(index) {
      this.schoolTypes.splice(index, 1);
      this.showShoolTypeWarning();
    },
    getDeleteSchoolButtunId(index) {
      return `btnDeleteSchoolButton${index}`;
    },
    updateGroupsButtonDisabled() {
      // TODO: Refactor this since certain text fields might not be mandatory
      let buttonDisabled = false;

      for (let i = 0; i < this.studentManagementClientApiFields.length; i++) {
        const validatedField = this.studentManagementClientApiFields[i];

        if ("text" === validatedField.type) {
          if (
            !validatedField.value
            || (
              validatedField.errorMessage
              && (0 < validatedField.errorMessage.length)
            )
          ) {
            buttonDisabled = true;
            break;
          }
        }
      }

      return buttonDisabled;
    },
    async updateGroups() {
      const vendor = this.studentManagementVendors
      .find(v => v.sid === this.activeStudentManagementVendorSid);
      const studentVendorParams = {
        "module-name": vendor.name,
      };
      this.studentManagementClientApiFields.forEach((clientApiField) => {
        if (clientApiField.groupListQueryFieldFlag) {
          if ("checkbox" === clientApiField.type) {
            if (clientApiField.value) {
              studentVendorParams[clientApiField.name] = true;
            }
          } else {
            studentVendorParams[clientApiField.name] = clientApiField.value;
          }
        }
      });

      this.groupsLoading = true;
      const argHash = {
        studentVendorParams,
      };
      const cbResponse = await this.$app.stbApiAdapter.getClientStudentGroupList(argHash);
      this.groupsLoading = false;
      if (!cbResponse.error) {
        const remoteGroupList = cbResponse.data;
        const existingGroupList = JSON.parse(JSON.stringify(this.groupList));

        this.groupList = remoteGroupList.map((remoteGroup) => {
          const existingGroup = existingGroupList.find(s => (`${s.id}` === `${remoteGroup.id}`));

          if (existingGroup && (existingGroup.group !== remoteGroup.name)) {
            existingGroup.group = remoteGroup.name;
          }

          const resolvedGroup = existingGroup
            ? JSON.parse(JSON.stringify(existingGroup))
            : {
              id: remoteGroup.id,
              group: remoteGroup.name,
            };

          this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
            const subsetAttributeValue = remoteGroup[subsetAttributeKey];

            if ("undefined" !== typeof subsetAttributeValue) {
              resolvedGroup[subsetAttributeKey] = subsetAttributeValue;
            }
          });

          return resolvedGroup;
        });
      }
    },
    parentSelected(item) {
      item.parentSelectionDisplayed = false;
      this.$forceUpdate();
    },
    getParentGroupName(parentId) {
      const parentGroup = this.groupList.find(g => `${g.id}` === `${parentId}`);
      return parentGroup ? parentGroup.group : "";
    },
    displayParentSelection(item) {
      this.groupList.forEach((g) => { g.parentSelectionDisplayed = false; });
      item.parentSelectionDisplayed = true;
      this.$forceUpdate();
    },
    applyButtonDisabled() {
      return !this.selectedGroupItems || (0 === this.selectedGroupItems.length);
    },
    applySelectedOption() {
      const selectedApplyParentId = this.selectedApplyParentId;
      if (this.selectedGroupItems && (0 < this.selectedGroupItems.length)) {
        this.selectedGroupItems.forEach((group) => {
          if (this.applySubjectEnabled) {
            group.subjectGroup = true;
          }
          if (this.applyCustomEnabled) {
            group.customGroup = true;
          }
          group.parent = selectedApplyParentId;
        });
      }
      this.selectedGroupItems = [];
      this.selectedApplyParentId = 0;
      this.applySubjectEnabled = false;
      this.applyCustomEnabled = false;
      this.$forceUpdate();
    },
    getUploadFileId(category, index) {
      return `uploadFile__${category}__${index}`;
    },
    uploadFileClick(category, index) {
      document.getElementById(`uploadFile__${category}__${index}`).click();
    },
    getUploadDate(uploadDate) {
      let timezoneDate = "";
      if (uploadDate) {
        const localDate = this.$app.moment(uploadDate);
        timezoneDate = this.$app.moment(localDate)
        .utc()
        .tz(this.$store.state.clientTimezone)
        .format("DD/MM/YYYY, hh:mm:ss A");
      }
      return timezoneDate;
    },
    handleTemplateFileChange(e, proformaTemplateFile, index) {
      if (e && e.target.files && (0 < e.target.files.length)) {
        const file = e.target.files[0];
        const filename = file.name;
        const extensionType = filename.split(".").pop();

        if (0 > this.proformaTemplateFileExtensions.indexOf(extensionType)) {
          this.invalidFileTypeDialogDisplayed = true;
          this.uploadFilename = filename;
          return;
        }

        this.$set(proformaTemplateFile, "file", file);
        this.$set(proformaTemplateFile, "filename", file.name);
        this.$set(proformaTemplateFile, "fileUpdateDate", "");
        this.$forceUpdate();
        this.templateFileChanged = true;
      }
    },
    handleStudentManagementFileChange(e, studentManagementFile, index) {
      if (e && e.target.files && (0 < e.target.files.length)) {
        const file = e.target.files[0];
        const filename = file.name;
        const extensionType = filename.split(".").pop();

        if (0 > this.studentManagementFileExtensions.indexOf(extensionType)) {
          this.invalidFileTypeDialogDisplayed = true;
          this.uploadFilename = filename;
          return;
        }

        this.$set(studentManagementFile, "file", file);
        this.$set(studentManagementFile, "filename", file.name);
        this.$forceUpdate();
        this.studentManagementFileChanged = true;
      }
    },
    async downloadFile(proformaTemplateFile) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: proformaTemplateFile.sid,
        store: this.$store,
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (0 < attachments.length) {
          const file = attachments[0];
          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, "");
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, "");
            const fileUrl = `${apiUrl}/${filePath}`;
            const filename = file.metadata.filename;
            const excursionCommonAdapter
              = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
            excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },
    async uploadClientTemplateFiles(clientSid) {
      if (
        this.clientTemplateFiles
        && (0 < this.clientTemplateFiles.length)
      ) {
        const fd = new FormData();
        fd.append("category", attachmentCategory.PROFORMA_TEMPLATE);
        const addTemplateFilesList = this.clientTemplateFiles.filter(
          a => "undefined" !== typeof a.file,
        );
        if (addTemplateFilesList && (0 < addTemplateFilesList.length)) {
          for (let i = 0; i < addTemplateFilesList.length; i++) {
            const proformaTemplateFile = addTemplateFilesList[i];
            fd.append("file", proformaTemplateFile.file);
          }
          const argHash = {
            sid: this.clientSid,
            payload: fd,
          };
          await this.$app.stbApiAdapter.postClientAttachment(argHash);
        }
      }
    },
    async uploadStudentManagementFiles(clientSid) {
      let clientApiMetadata = {};
      if (
        this.studentManagementFiles
        && (0 < this.studentManagementFiles.length)
      ) {
        const fd = new FormData();
        fd.append("category", attachmentCategory.STUDENT_MANAGEMENT);
        const addStudentManagementFilesList = this.studentManagementFiles.filter(
          a => "undefined" !== typeof a.file,
        );
        if (addStudentManagementFilesList && (0 < addStudentManagementFilesList.length)) {
          for (let i = 0; i < addStudentManagementFilesList.length; i++) {
            const studentManagementFile = addStudentManagementFilesList[i];
            fd.append("file", studentManagementFile.file);
          }
          const argHash = {
            sid: "" === this.clientSid ? "new-client-record" : this.clientSid,
            payload: fd,
          };
          clientApiMetadata = await this.$app.stbApiAdapter.postClientSubjects(argHash);
        }
      }
      return clientApiMetadata;
    },
    changeProviderSourceType() {
      if (providerSourceType.SFTP === this.provider.sourceType) {
        delete this.provider.apiBaseUrl;
        delete this.provider.apiKey;
        delete this.provider.apiTenantSid;
        delete this.provider.apiReportSid;
      } else if (providerSourceType.API === this.provider.sourceType) {
        delete this.provider.sftpPath;
      } else {
        delete this.provider.apiBaseUrl;
        delete this.provider.apiKey;
        delete this.provider.apiTenantSid;
        delete this.provider.apiReportSid;
        delete this.provider.sftpPath;
      }
    },
    displaySftp() {
      return providerSourceType.SFTP === this.provider.sourceType;
    },
    displayApi() {
      return providerSourceType.API === this.provider.sourceType;
    },
  },
  async mounted() {
    this.logoFileChanged = false;
    this.templateFileChanged = false;
    this.studentManagementFileChanged = false;
    this.excursionManagementVendors
      = await this.$app.stbApiAdapter.getExcursionManagementVendorList();

    const cbResponse = await this.$app.stbApiAdapter.getStudentManagementVendorList();
    this.studentManagementVendors = cbResponse;
    this.logoFileChanged = false;

    this.clientSid = this.propClientSid;
    await this.newClient(this.propClientSid);
    this.clientIndividualModalDisplayed = true;
    const timezoneNames = this.$app.moment.tz.names();

    timezoneNames.forEach((name) => {
      const text = `${name.replace(/_/g, " ")} (UTC${this.$app.moment.tz(name).format("Z")})`;
      this.timezones.push({ value: name, text });
    });

    this.allTimezones = this.$app.lodash.cloneDeep(this.timezones);
  },
  watch: {
    async clientIndividualModalRefreshed() {
      this.logoFileChanged = false;
      this.templateFileChanged = false;
      this.studentManagementFileChanged = false;
      this.clientSid = this.propClientSid;
      await this.newClient(this.propClientSid);
      this.clientIndividualModalDisplayed = true;
    },
    async searchParentClients(val) {
      let cacheItem;

      if (this.parentClientCache && (0 < this.parentClientCache.length)) {
        cacheItem = this.parentClientCache.find(function (item) {
          return (item.key === val);
        });
      }

      if (cacheItem) {
        this.$store.commit("updateParentClientItems", cacheItem.items);
      } else {
        this.$store.state.parentClientList.loading = true;
        const filterParam = {
          s: val,
          page: this.pagination.page,
          direction: (false === this.pagination.sortDesc[0]) ? "ASC" : "DESC",
        };

        Object.keys(filterParam).forEach((key) => {
          if ("" === filterParam[key]) {
            delete filterParam[key];
          }
        });
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          filterParam,
        };
        await this.$store.dispatch("getParentClientList", argHash);
      }
    },
    searchTimezone(val) {
      val = val ? val.toLowerCase() : "";
      this.timezones = this.allTimezones.filter(t => -1 < t.text.toLowerCase().indexOf(val));
    },
  },
};
</script>

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
  actions,
  getters,
  mutations,
  state: {
    apiVersion: "",
    isResponseWithError: false,
    apiDataDefaultJurisdiction: "",
    excursionTypeChoiceList: {},
    allRisks: [],
    allRisksInitialized: false,
    tickedMandatoryChecklists: [],
    mandatoryChecklistsLength: 7, // TODO: Allow variable length of mandatory checklists
    tickedRiskCategoryChecklists: {},
    retrievedCategoriesAndRisks: [],
    riskTypes: {
      Transport: 1,
      Venues: 2,
      Accommodation: 3,
      FoodAndBeverage: 4,
      Activity: 5,
    },
    EnumExcursionTypes: {
      DayExcursion: 1,
      OvernightExcursion: 2,
      OutdoorEducationExcursion: 3,
      InternationalExcursion: 4,
      InterSchoolSport: 5,
      InterSchoolExtraCurricularActivities: 6,
      RegularOffCampusActivity: 7,
      Incursion: 8,
    },
    activitiesCategoryName: "Activities", // TODO: Use riskCategoryLabel instead
    selectedActivityTypeFilter: {
      id: 0,
      name: "",
    },
    selectedActivityClassFilter: {
      id: 0,
      name: "",
    },
    excursion: {
      id: 0,
      excursionDestinationContentEnabled: false,
      schemaVersion: 0,
      name: "",
      typeId: "",
      jurisdiction: "",
      status: "draft",
      categories: [],
      vendorData: {
        vendorExcursionSid: "",
      },
      updateSignature: "",
      currentStep: 1,
      additionalInformationItems: [],
      mandatoryChecklist: [],
      agreementConfirmation: {
        ticked: false,
        statement1: "",
        statement2: "",
      },
      computed: {
        logoAttachmentUrl: "",
        studentMedicalConditions: [],
      },
      validCategories: false,
      riskOverall: {},
      riskCategories: [],
      predepartures: [],
      predepartureChecklistCompleted: false,
      staffSupervisorAttendanceCount: "",
      studentAttendanceCount: "",
      syncAvailable: false,
      activitySelectionValid: true,
      auditList: [],
      clientTemplate: {},
      excursionCopyFlag: false,
    },
    allProviders: [],
    allProvidersInitialized: false,
    apiParam: {},
    userAuthSchemeApiParam: {},
    showError: false, // TODO: Classify errors e.g.: showExcursionError, showClientError
    allExcursions: {},
    excursionInitialized: false,
    excursionsList: {
      data: [],
      total: 0,
      loading: false,
    },
    clientList: {
      data: [],
      total: 0,
      loading: false,
    },
    clientUserList: {
      data: [],
      total: 0,
      loading: false,
    },
    userList: {
      data: [],
      total: 0,
      loading: false,
    },
    isGetClientList: false, // TODO: Use different route for client list
    showExcursionStepper: true,
    showToolbar: true,
    vendorClientLogoEnabled: false,
    vendorExcursionSidInputEnabled: false,
    providerUpdateActionDisplayed: false,
    providerUpdateActionEnabled: false,
    excursionAdditionalInformationEnabled: false,
    excursionChecklistEnforcementBeforeSubmissionEnabled: false,
    excursionCopyActionEnabled: false,
    excursionDestinationContentEnabled: false,
    excursionPersonResponsibleEnabled: false,
    excursionHighRiskStudentMedicalEnabled: false,
    excursionInherentRiskRatingEnabled: false,
    excursionStudentFetchTriggered: false,
    excursionStudentMedicalConditionsDisplayReady: false,
    originalTypeId: "",
    logoAttachmentUrl: "",
    clearCreateRiskAssessment: false,
    excursionDisplayReady: false,
    excursionLogoDisplayReady: false,
    clientAdminSectionButtonDisplayed: false,
    clientsTabDisplayed: false,
    clientUsersTabDisplayed: false,
    usersTabDisplayed: false,
    clientTemplate: {},
    excursionBuilderButtonDisplayed: false,
    excursionCreationButtonDisplayed: false,
    systemAdminSelectionOptionDisplayed: false,
    excursionSyncButtonDisplayed: false,
    excursionApprovalButtonDisplayed: false,
    excursionUnapprovalButtonDisplayed: false,
    clientLogoBackgroundColor: "",
    prevRouteName: "",
    userInitials: "",
    mandatoryLoadActionCompleted: false,
    parentClientList: {
      loading: false,
      items: [],
      itemsCache: [],
    },
    topToolbarTextButtonList: [
      {
        title: "Excursions List",
        to: "/excursions",
        router: "/excursions",
        label: "Home",
        itemActivated: false,
        name: "Excursions",
      },
      {
        title: "Client List",
        to: "/clients",
        router: "/clients",
        label: "Home",
        itemActivated: false,
        name: "Clients",
      },
      {
        title: "Excursion Builder",
        router: "/excursions/builder/new",
        to: "/excursions/builder/new",
        label: "Excursion Builder",
        itemActivated: false,
        name: "ExcursionBuilder",
      },
      {
        title: "Create Excursion",
        router: "/excursions/new",
        to: "/excursions/new",
        label: "Create Excursion",
        itemActivated: false,
        name: "SafeTripBuilder",
      },
    ],
    excursionTypeLoaded: false,
    ceCommitOid: "",
    clientUserClientList: {
      loading: false,
      items: [],
      itemsCache: [],
    },
    moduleCacheClearanceButtonDisplayed: false,
    checklistFetchActionInProgress: false,
    excursionListClientColumnDisplayed: false,
    excursionListFilterSectionDisplayed: false,
    excursionListAllClientSuggestionDisplayed: false,
    userAuthenticated: true,
    userAuthenticationInProgress: false,
    clientParentId: false,
    parentClientSiteFlag: false,
    selectedExcursionTypeLabel: "",
    clientAclRoleInferiorityHash: {},
  },
};

<template>
  <div v-if="selectedriskClasses.length>0"
    class="sub-category-section-margin sub-category-section-not-provider">
    <div
      v-for="(riskType, index) in selectedriskClasses[0].types"
      :key="index"
      class="sub-category-section"
    >
      <v-layout align-center justify-space-between v-if="riskType.classes.length>0">
        <v-flex xs6 v-if="riskTypeLabelDisplayed">
          <div class="name label-padding">{{riskType.name}}</div>
        </v-flex>
        <v-flex v-if="selectedriskClasses[0].name==='Activities'">
          <complete-checklist
            :categoryName="riskType.name"
            :riskClassName="selectedriskClasses[0].name"
            :formControlEnabled="formControlEnabled"
            :checklistFetchActionInProgress="false"
          />
          <view-policy :categoryName="riskType.name" :riskClassName="selectedriskClasses[0].name"/>
        </v-flex>
      </v-layout>
      <v-layout
        justify-center
        v-for="(selectedRiskClass,index) in riskType.classes"
        :key="index"
      >
        <v-select
          :id="getRiskClassId(selectedRiskClass)"
          class="type-dropdown risk-class-select excursion-selection"
          :items="listRiskClasses(
            selectedRiskClass.name,
            riskType.name,selectedriskClasses[0].name
          )"
          item-text="name"
          item-value="name"
          item-disabled="disabled"
          solo
          :value="selectedRiskClass.name"
          @change="changeSelectedClassId(
            riskType.name,
            selectedriskClasses[0].name,
            selectedRiskClass,$event
          )"
          :disabled="
            !selectedClassExistInItems(
              selectedRiskClass.name, riskType.name, selectedriskClasses[0].name
            )
            || !formControlEnabled
          "
        ></v-select>
        <div>
          <v-btn
            :id="getRiskClassDeleteId(selectedRiskClass)"
            class="remove-icon"
            icon
            :disabled="!formControlEnabled"
            @click.native="selectedRiskClass.dialog=true">
            <v-icon>delete</v-icon>
          </v-btn>
          <v-dialog v-model="selectedRiskClass.dialog" persistent max-width="290">
            <v-card>
              <v-card-title class="headline">Are you Sure?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="normal-btn white--text primary"
                  id="btnDelete"
                  @click.stop="removeSelectedClassId(
                    riskType.name,
                    selectedriskClasses[0].name,
                    selectedRiskClass
                    )"
                  :disabled="!formControlEnabled"
                >Delete</v-btn>
                <v-btn class="normal-btn" @click="selectedRiskClass.dialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedriskClasses", "formControlEnabled", "riskTypeLabelDisplayed"],
  data() {
    return {};
  },
  computed: {
    placeholder: function () {
      return `Add ${this.selectedriskClasses[0].name} Type`;
    },
    allRisks: function () {
      return this.$store.state.allRisks;
    },
    selectedRisks: function () {
      const categories = this.$store.state.excursion.categories;
      if (categories) {
        return this.$store.state.excursion.categories;
      }
      return [];
    },
  },
  methods: {
    listRiskClasses(selectedClassName, typeName, categoryName) {
      let classes = [];
      for (const categoryKey in this.allRisks) {
        if (this.allRisks[categoryKey].name === categoryName) {
          for (const typeKey in this.allRisks[categoryKey].types) {
            if (this.allRisks[categoryKey].types[typeKey].name === typeName) {
              classes = this.allRisks[categoryKey].types[typeKey].classes;
              break;
            }
          }
        }
      }
      if (selectedClassName) {
        if (0 === classes.length || -1 === classes.findIndex(c => c.name === selectedClassName)) {
          return [{ name: selectedClassName }];
        }
      }
      return classes;
    },
    getClassFromAllRisks(typeName, categoryName, className) {
      for (const categoryKey in this.allRisks) {
        if (this.allRisks[categoryKey].name === categoryName) {
          for (const typeKey in this.allRisks[categoryKey].types) {
            if (this.allRisks[categoryKey].types[typeKey].name === typeName) {
              return this.allRisks[categoryKey].types[typeKey].classes.find(
                c => c.name === className,
              );
            }
          }
        }
      }
      return undefined;
    },
    getRiskClassId(riskClass) {
      return `risk-class--${riskClass.name.replace(/\s/g, "")}`;
    },
    getRiskClassDeleteId(riskClass) {
      return `risk-class--delete-${riskClass.name.replace(/\s/g, "")}`;
    },
    changeSelectedClassId(typeName, categoryName, selectedRiskClass, event) {
      const currentSelected = this.getClassFromAllRisks(
        typeName,
        categoryName,
        event,
      );
      const previousSelected = this.getClassFromAllRisks(
        typeName,
        categoryName,
        selectedRiskClass.name,
      );
      selectedRiskClass.name = currentSelected.name;
      currentSelected.disabled = true;
      previousSelected.disabled = false;

      this.$emit("changeRiskCategoryDone");
    },
    removeSelectedClassId(typeName, categoryName, selectedRiskClass) {
      if (!selectedRiskClass.dialog) {
        return false;
      }
      const deleteClass = this.getClassFromAllRisks(
        typeName,
        categoryName,
        selectedRiskClass.name,
      );
      if (deleteClass) {
        deleteClass.disabled = false;
      }
      for (const categoryKey in this.selectedRisks) {
        if (this.selectedRisks[categoryKey].name === categoryName) {
          for (const typeKey in this.selectedRisks[categoryKey].types) {
            if (this.selectedRisks[categoryKey].types[typeKey].name === typeName) {
              const item = this.selectedRisks[categoryKey]
              .types[typeKey].classes.find(c => c.name === selectedRiskClass.name);

              const index = this.selectedRisks[categoryKey]
              .types[typeKey].classes.indexOf(item);

              this.selectedRisks[categoryKey].types[typeKey].classes.splice(index, 1);
              this.resetCategories(categoryName, typeName);

              this.$emit("changeRiskCategoryDone");

              return true;
            }
          }
        }
      }

      return true;
    },
    resetCategories(categoryName, typeName) {
      const removeCategory = this.selectedRisks.find(c => c.name === categoryName);
      const removeType = removeCategory.types.find(t => t.name === typeName);
      if (removeType.classes && (0 === removeType.classes.length)) {
        removeCategory.types.splice(removeCategory.types.indexOf(removeType), 1);
        if (0 === removeCategory.types.length) {
          this.selectedRisks.splice(this.selectedRisks.indexOf(removeCategory), 1);
          const categoryFound
            = this.$store.state.allRisks.find(category => category.name === categoryName);
          if (categoryFound && categoryFound.CompleteChecklist) {
            delete categoryFound.CompleteChecklist;
          }
        }
      }
    },
    selectedClassExistInItems(selectedClassName, typeName, categoryName) {
      const items = this.listRiskClasses("", typeName, categoryName);
      if (-1 < items.findIndex(i => i.name === selectedClassName)) {
        return true;
      }
      return false;
    },
  },
};
</script>

<template>
  <cs-view single-view>
    <div slot="content">
      <excursion-stepper
        v-if="showExcursionStepper"
        propHasParentComponent
      ></excursion-stepper>
      <excursion-builder
        v-else
        propHasParentComponent
      ></excursion-builder>
    </div>
  </cs-view>
</template>

<script>
import excursionStatus from "../lib/const/excursionStatus";
import { CSBase } from '@complispace/cs-design-system';

export default {

  extends: CSBase,

  data() {
    return {};
  },

  computed: {
    showExcursionStepper() {
      return this.$store.state.showExcursionStepper;
    },
    createAndUpdateExcursionEnabled() {
      return this.$store.state.createAndUpdateExcursionEnabled;
    },
  },
  beforeDestroy() {
    this.$app.eventBus.$off("getClientSetupsDone");
  },
  mounted() {
    const query = this.$route.query;
    const params = this.$route.params;
    this.$app.authAgent.enforceVendorOrUserAuthStrategy(this.$route, query);

    if (query.referrer || query["vendor-sid"] || query["oauth-signature"]) {
      $(window).resize(function () {
        const w = 960;
        const h = window.screen.availHeight;
        window.resizeTo(w, h);
      });
    }

    const excursionId = params && params.id ? params.id : "0";
    const store = this.$store;

    const that = this;
    this.$app.eventBus.$on("getClientSetupsDone", async function () {
      if (store.state.userAuthenticated) {
        const getExcursionsArgHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store,
          excursionId,
          query,
        };

        await store.dispatch("getExcursions", getExcursionsArgHash);
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store,
          queryParam: query,
        };
        await store.dispatch("getExcursionTypes", argHash);
        if (!that.createAndUpdateExcursionEnabled) {
          store.commit("updateShowExcursionStepper", false);
        } else {
          store.dispatch("updateProviders", argHash);
        }

        const excursionTypeName = store.state.excursion.typeId;
        if (excursionTypeName) {
          await store.dispatch("getAllRisksByExcursionTypeName", {
            stbApiAdapter: this.$app.stbApiAdapter,
            store,
            queryParam: query,
            excursionTypeName,
          });
        }

        await store.dispatch("getDefaultJurisdiction", {
          stbApiAdapter: this.$app.stbApiAdapter,
          store,
          queryParam: query,
          excursionTypeName,
        });
      }
    });

    this.$nextTick(() => {
      if (this.$store.state.excursion.status === excursionStatus.APPROVED) {
        this.$app.excursionCommon.enableFormControl(".excursion-builder", false);
      }
    });

    window.onbeforeunload = function () {
      if (window.opener) {
        window.opener.postMessage("refresh", "*");
        window.self.close();
      }
    };
  },

  watch: {
    showExcursionStepper() {
      this.$app.eventBus.$emit("setLogoImgUrlReady");
    },
  },
};
</script>

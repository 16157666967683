<template>
  <div style="width:100%">
    <div class="search-container">
      <v-flex xs4>
        <v-select
          xs5
          v-model="selectedActivityType"
          class="type-dropdown excursion-selection"
          :items="listActivityTypes"
          item-text="name"
          item-value="name"
          label="Select an Activity Type"
          id="selectedActivityType"
          testing-id="selected-activity-type"
          solo
          @change="resetSelectedClass"
          :return-object="true"
          :clearable="isClearable"
          :disabled="!formControlEnabled"
        ></v-select>
      </v-flex>
      <div class="search-arrow">
        <v-icon medium>chevron_right</v-icon>
      </div>

      <v-flex xs4>
        <v-select
          v-model="selectedActivityClass"
          class="type-dropdown excursion-selection"
          :items="listActivityClasses"
          item-text="name"
          item-value="name"
          :label="placeholderActivityRiskClass"
          solo
          :clearable="isClearable"
          :return-object="true"
          :disabled="!formControlEnabled"
          :menu-props="{
            maxHeight: 400
          }"
        ></v-select>
      </v-flex>
    </div>
    <select
      class="js-example-basic-single excursion-selection safe-select2-dropdown"
      style="margin-left: 2px;width: calc(100% - 4px);"
      name="selectActivityRisk"
      id="selectActivityRisk"
      :disabled="!formControlEnabled"
    >
      <option value></option>
      <optgroup
        v-for="(riskClass, cindex) in filterActivityRisks(
          selectedActivityType,
          selectedActivityClass
        )"
        :key="cindex"
        :label="riskClass.name"
      >
        <option
          v-for="(risk, rindex) in riskClass.risks"
          :key="rindex"
          :value="risk.name"
          :title="riskClass.name+','+riskClass.typeName"
          :disabled="risk.disabled"
        >{{risk.name}}</option>
      </optgroup>
    </select>
    <div
      v-if="!selectedActivityClasses || (0 >= selectedActivityClasses.length)"
      class="builder-activity-sub"
    ></div>
    <div
      class="activity sub-category-section-margin builder-activity-sub activity-category-class"
      v-if ="0 < selectedActivityClasses.length"
    >
      <div v-for="(riskType, tindex) in selectedActivityClasses[0].types" :key="tindex">
        <div
          v-for="riskClass in riskType.classes"
          :key="setKey(riskClass)"
          class="sub-category-section"
        >
          <v-layout align-center justify-space-between>
            <v-flex xs4>
              <v-layout align-center justify-space-between>
                <div class="name">{{riskClass.name}}</div>
              </v-layout>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex xs8 class="sub-category-view-policy">
              <div class="float-right">
                <view-policy
                  v-if="checklistAndPolicyDisplayed"
                  :categoryName="selectedActivityClasses[0].name"
                  :riskTypeName="riskType.name"
                  :riskClassName="riskClass.name"
                  :elementDisabled="!riskClass.risks"
                  :activitySupervisorScrollDisplayed="true"
                />
              </div>
              <div class="float-right">
                <complete-checklist
                  v-if="checklistAndPolicyDisplayed"
                  ref="completeChecklist"
                  :categoryName="selectedActivityClasses[0].name"
                  :riskTypeName="riskType.name"
                  :riskClassName="riskClass.name"
                  :associatedChecklistCompleted="getTicked(riskClass)"
                  :formControlEnabled="formControlEnabled"
                  :elementDisabled="
                    (
                      riskClass.risks
                      && elementDisabled
                      && (riskType.name === checklistFetchActionRiskTypeName)
                      && (riskClass.name === checklistFetchActionRiskClassName)
                    )
                    || !riskClass.risks
                  "
                  :checklistFetchActionCategoryName="selectedActivityClasses[0].name"
                  :checklistFetchActionRiskTypeName="checklistFetchActionRiskTypeName"
                  :checklistFetchActionRiskClassName="checklistFetchActionRiskClassName"
                  :checklistFetchActionInProgress="checklistFetchActionInProgress"
                  :saveDraft="saveDraft"
                />
              </div>
            </v-flex>
          </v-layout>
          <v-layout justify-center v-if="!riskClass.risks">
            <v-select
              class="type-dropdown risk-class-select excursion-selection empty-risk-selection"
              :items="listActivityRisks('', riskClass.name, riskType.name)"
              item-text="name"
              item-value="name"
              item-disabled="disabled"
              solo
              id="changeActivityPreSelectedRisk"
              testing-id="list-risk"
              @change="changeActivitySelectedRiskId(
                riskClass.name,
                riskType.name,
                {},
                $event
              )"
              label="Select a risk"
            ></v-select>
            <div>
              <v-btn
                icon
                class="remove-icon"
                :disabled="!formControlEnabled"
                @click.native="displayDeleteRiskConfirm(riskType, riskClass, null)"
                :id="getActivityRiskDeleteId(riskType, riskClass, null)"
              >
                <v-icon>delete</v-icon>
              </v-btn>
            </div>
          </v-layout>
          <div v-if="riskClass.risks && (0 < riskClass.risks.length)">
            <v-layout justify-center v-for="(selectedRisk, index) in riskClass.risks" :key="index">
              <v-select
                class="type-dropdown risk-class-select excursion-selection"
                :items="listActivityRisks(selectedRisk.name, riskClass.name, riskType.name)"
                item-text="name"
                item-value="name"
                item-disabled="disabled"
                solo
                :value="selectedRisk.name"
                :id="getActivityRiskId(riskType, riskClass, selectedRisk)"
                testing-id="list-risk"
                @change="changeActivitySelectedRiskId(
                  riskClass.name,
                  riskType.name,
                  selectedRisk,
                  $event
                )"
              ></v-select>
              <div>
                <v-btn
                  icon
                  class="remove-icon"
                  :disabled="!formControlEnabled"
                  @click.native="displayDeleteRiskConfirm(riskType, riskClass, selectedRisk)"
                  :id="getActivityRiskDeleteId(riskType, riskClass, selectedRisk)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="deleteActivityRiskDisplayed" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Are you Sure?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="normal-btn white--text primary"
            testing-id="remove-risk"
            @click.native="removeActivitySelectedRiskId()"
            id="activity-risk--remove-risk"
          >Delete</v-btn>
          <v-btn class="normal-btn" @click="deleteActivityRiskDisplayed = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: [
    "formControlEnabled",
    "checklistAndPolicyDisplayed",
    "saveDraft",
  ],
  data() {
    return {
      isClearable: true,
      checklistFetchActionRiskTypeName: "",
      checklistFetchActionRiskClassName: "",
      checklistFetchActionInProgress: false,
      checklistFetchActionCount: 0,
      checklistFetchActionDoneCount: 0,
      elementDisabled: true,
      currentSelectedType: {},
      currentSelectedClass: {},
      currentSelectedRisk: {},
      deleteActivityRiskDisplayed: false,
    };
  },
  computed: {
    selectedActivityType: {
      get() {
        return this.$store.state.selectedActivityTypeFilter;
      },
      set(value) {
        this.$store.commit("updateSelectedActivityTypeFilter", value);
      },
    },
    selectedActivityClass: {
      get() {
        return this.$store.state.selectedActivityClassFilter;
      },
      set(value) {
        this.$store.commit("updateSelectedActivityClassFilter", value);
      },
    },
    riskTypes() {
      return this.$store.state.riskTypes;
    },
    allActivityRisks() {
      return this.$store.getters.getAllActivityRisks;
    },
    filterActivityRisks() {
      return function (type, riskClass) {
        const typeName = type ? type.name : "";
        const className = riskClass ? riskClass.name : "";
        return this.$store.getters.getActivityRisksByClass(typeName, className);
      };
    },
    selectedActivityClasses() {
      return this.$store.getters.getSelectedActivityClasses;
    },
    listActivityClasses() {
      let selectActivityName = "";
      if (this.selectedActivityType) {
        selectActivityName = this.selectedActivityType.name;
      }
      return this.allActivityRisks.filter(
        r => (r.typeName === selectActivityName),
      );
    },
    listActivityTypes() {
      return this.$store.getters.getAllActivityTypes;
    },
    placeholderActivityRiskClass() {
      const activityType = this.$store.state.selectedActivityTypeFilter;
      if (activityType && ("" !== activityType.name)) {
        return `Select ${activityType.name} Type`;
      }

      return "";
    },
  },
  methods: {
    listActivityRisks(selectedRiskName, riskClassName, riskTypeName) {
      let risks;
      const riskClass = this.allActivityRisks.find(
        r => ((r.name === riskClassName) && (r.typeName === riskTypeName)),
      );
      if (!riskClass || !riskClass.risks) {
        risks = [];
      } else {
        risks = riskClass.risks;
      }

      if (selectedRiskName) {
        if (-1 === risks.findIndex(r => (r.name === selectedRiskName))) {
          return [{ name: selectedRiskName }];
        }
      }

      // Prevent "Complete Checklist" button from being disabled when the activity risk loads
      if (!this.checklistFetchActionInProgress) {
        this.elementDisabled = false;
      }

      return risks;
    },
    getActivityRiskFromAllRisks(className, typeName, selectedRisk) {
      const riskClass = this.allActivityRisks
      .find(c => ((c.name === className) && (c.typeName === typeName)));
      if (riskClass && riskClass.risks) {
        return riskClass.risks.find(r => (r.name === selectedRisk));
      }

      return undefined;
    },
    changeActivitySelectedRiskId(className, typeName, selectedRisk, event) {
      const currentSelected = this.getActivityRiskFromAllRisks(
        className,
        typeName,
        event,
      );

      if (selectedRisk.name) {
        const previousSelected = this.getActivityRiskFromAllRisks(
          className,
          typeName,
          selectedRisk.name,
        );
        previousSelected.disabled = false;
      }

      selectedRisk.name = currentSelected.name;
      currentSelected.disabled = true;

      const selectedClass = this.selectedActivityClasses[0].types
      .find(t => (t.name === typeName))
      .classes.find(c => (c.name === className));

      if (!selectedClass.risks) {
        selectedClass.risks = [selectedRisk];
      }

      this.changeActivityRiskClassChecklist(typeName, className);
      this.$store.dispatch("setActivitySelectionValid");
      this.$emit("changeActivityDone");
    },
    removeActivitySelectedRiskId() {
      const typeName = this.currentSelectedType.name;
      const className = this.currentSelectedClass.name;

      if (!this.currentSelectedRisk) {
        const selectedClasses = this.selectedActivityClasses[0].types
        .find(t => (t.name === typeName))
        .classes;
        const selectedClass = selectedClasses.find(c => (c.name === className));
        const index = selectedClasses.indexOf(selectedClass);
        selectedClasses.splice(index, 1);
      } else {
        const riskName = this.currentSelectedRisk.name;
        const deleteRisk = this.getActivityRiskFromAllRisks(
          className,
          typeName,
          riskName,
        );
        if (deleteRisk) {
          deleteRisk.disabled = false;
        }

        const selectedRisks = this.selectedActivityClasses[0].types
        .find(t => (t.name === typeName))
        .classes.find(c => (c.name === className)).risks;
        const risk = selectedRisks.find(r => (r.name === riskName));
        const index = selectedRisks.indexOf(risk);
        selectedRisks.splice(index, 1);
        this.resetCategories(typeName, className);
        this.changeActivityRiskClassChecklist(typeName, className);
      }

      this.$store.dispatch("setActivitySelectionValid");
      this.currentSelectedType = {};
      this.currentSelectedClass = {};
      this.currentSelectedRisk = {};
      this.deleteActivityRiskDisplayed = false;
      return true;
    },
    resetCategories(typeName, className) {
      const removeCategory = this.selectedActivityClasses[0];
      const removeType = removeCategory.types.find(c => (c.name === typeName));
      const removeClass = removeType.classes.find(c => (c.name === className));
      if (removeClass.risks && (0 === removeClass.risks.length)) {
        removeType.classes.splice(removeType.classes.indexOf(removeClass), 1);
        if (removeType && (0 === removeType.classes.length)) {
          removeCategory.types.splice(removeCategory.types.indexOf(removeType), 1);
          if (0 === removeCategory.types.length) {
            this.$store.state.excursion.categories
              = this.$store.state.excursion.categories.filter(c => ("Activities" !== c.name));
          }
        }
      }
    },
    resetSelectedClass() {
      this.$store.state.selectedActivityClassFilter = {
        name: "",
      };
    },
    getTicked(item) {
      let allBox = 0; let allChecked = 0;
      if (
        item
        && item.CompleteChecklist
        && (0 < item.CompleteChecklist.length)
        && item.CompleteChecklist[0].checklist
        && item.CompleteChecklist[0].checklist.Strategy
        && item.CompleteChecklist[0].checklist.Strategy.data
      ) {
        const data = item.CompleteChecklist[0].checklist.Strategy.data;
        const dataKey = Object.keys(data);

        if (
          data[dataKey]
          && data[dataKey].children
          && (0 < data[dataKey].children.length)
          && data[dataKey].children[0].children
        ) {
          const checklist = data[dataKey].children[0].children;

          if (checklist && (0 < checklist.length)) {
            checklist.forEach((box) => {
              if (box.children && (0 < box.children.length)) {
                const boxes = box.children.filter((ck) => {
                  return ("checkbox" === ck.contentType);
                });

                if (boxes && (0 < boxes.length)) {
                  allBox += boxes.length;
                }

                const checked = box.children.filter((ck) => {
                  return (ck.ticked && ("checkbox" === ck.contentType));
                });

                if (checked && (0 < checked.length)) {
                  allChecked += checked.length;
                }
              }
            });
          }
        }
      } else {
        allChecked = -1;
      }

      return (allBox === allChecked);
    },
    selectedRiskExistInItems(selectedRiskName, riskClassName, riskTypeName) {
      const items = this.listActivityRisks(null, riskClassName, riskTypeName);
      if (-1 < items.findIndex(i => (i.name === selectedRiskName))) {
        return true;
      }
      return false;
    },
    changeActivityRiskClassChecklist(riskTypeName, riskClassName) {
      const risk = this.$store.getters.getActivityRiskClass(riskTypeName, riskClassName);
      const excursionCommonAdapter
        = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
      const allParams = excursionCommonAdapter.getActivityParams();

      const params = allParams.filter(p => (0 <= p.indexOf(riskClassName)));
      const queryParam = this.$route.query;
      this.checklistFetchActionRiskTypeName = riskTypeName;
      this.checklistFetchActionRiskClassName = riskClassName;
      this.checklistFetchActionInProgress = true;
      this.$store.state.checklistFetchActionInProgress = true;
      this.elementDisabled = true;
      this.checklistFetchActionCount += 1;
      const that = this;
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        store: this.$store,
        queryParam,
        params,
        risk,
        resolve: function () {
          that.checklistFetchActionDoneCount += 1;
          if (that.checklistFetchActionCount === that.checklistFetchActionDoneCount) {
            that.checklistFetchActionInProgress = false;
            that.$store.state.checklistFetchActionInProgress = false;
            that.elementDisabled = false;
            that.checklistFetchActionCount = 0;
            that.checklistFetchActionDoneCount = 0;
          }
        },
      };

      this.$store.dispatch("changeActivityRiskCategoryCompleteChecklist", argHash);

      if (this.$refs.completeChecklist) {
        const currentButtons = this.$refs.completeChecklist.filter(
          c => (c.completeChecklistBtnId === `btnChecklist${riskTypeName}${riskClassName}`),
        );
        if (currentButtons && (0 < currentButtons.length)) {
          currentButtons[0].resetCompleteButton();
        }
      }
    },
    displayDeleteRiskConfirm(riskType, riskClass, risk) {
      this.deleteActivityRiskDisplayed = true;
      this.currentSelectedType = riskType;
      this.currentSelectedClass = riskClass;
      this.currentSelectedRisk = risk;
    },
    setKey(riskClass) {
      return riskClass.name.replace(/\s*/g, "");
    },
    getActivityRiskId(riskType, riskClass, selectedRisk) {
      const riskTypeId = riskType.name.replace(/\s*/g, "");
      const riskClassId = riskClass.name.replace(/\s*/g, "");
      const riskId = selectedRisk.name.replace(/\s*/g, "");
      return `activity-risk--${riskTypeId}-${riskClassId}-${riskId}`;
    },
    getActivityRiskDeleteId(riskType, riskClass, selectedRisk) {
      const riskTypeId = riskType.name.replace(/\s*/g, "");
      const riskClassId = riskClass.name.replace(/\s*/g, "");
      const riskId = selectedRisk ? `-${selectedRisk.name.replace(/\s*/g, "")}` : "";
      return `activity-risk--btn-delete-${riskTypeId}-${riskClassId}${riskId}`;
    },
  },
  mounted() {
    const store = this.$store;
    const that = this;
    const $selector = $("#selectActivityRisk");
    if (
      ("undefined" === typeof $selector.data("select2"))
      && $selector.next().hasClass("select2-container")
    ) {
      $selector.select2("destroy");
    }

    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      width: "resolve",
      placeholder: "Add Activity Type",
    });

    $selector.on("select2:select", function (e) {
      const risk = {
        name: e.params.data.text,
      };
      const riskClassName = e.params.data.title;
      $("#selectActivityRisk").val("");
      $("#selectActivityRisk").trigger("change.select2");

      store.dispatch("changeActivitySelectRisk", {
        risk,
        riskClassName,
      });

      const typeName = riskClassName.split(",")[1];
      const className = riskClassName.split(",")[0];
      that.changeActivityRiskClassChecklist(typeName, className);
      that.$emit("changeActivityDone");
    });

    $(".v-input").on("click", function (e) {
      $selector.select2("close");
    });
  },
  updated() {
    const $selector = $("#selectActivityRisk");
    if (
      ("undefined" === typeof $selector.data("select2"))
      && $selector.next().hasClass("select2-container")
    ) {
      $selector.next().remove();
    }

    let placeHolder = "Add Activity Type";
    if (
      ("undefined" !== typeof this.selectedActivityType)
      && ("undefined" !== typeof this.selectedActivityClass)
      && ("" !== this.selectedActivityType.name)
      && ("" !== this.selectedActivityClass.name)
    ) {
      placeHolder = `Select ${this.selectedActivityClass.name} Type`;
    }
    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      width: "resolve",
      placeholder: placeHolder,
    });
  },
  destroyed: function () {
    const $selector = $(`#selectActivityRisk`);
    $selector.select2("destroy");
  },
};
</script>

<template>
  <div class="btn-padding-0">
    <v-btn
      v-if="categoryName===undefined && !showViewPolicyLabel"
      class="normal-btn white--text primary"
      :disabled="elementDisabled"
      :class = "showViewPolicyLabel ? 'normal-btn' : ''"
      @click.stop="getData()"
      id="btnViewPolicy"
    >
      <v-flex v-if="showViewPolicyLabel != true">View Excursion Policy</v-flex>
      <v-flex v-else>View Policy</v-flex>
    </v-btn>

    <v-btn
      v-else
      :disabled="elementDisabled"
      @click.native="getData()"
      :loading="policyDisplayed"
      depressed
      class="normal-btn category-view-policy"
      :id="viewPolicyBtnId"
    >
      View Policy
    </v-btn>
    <v-dialog v-model="policyDisplayed" scrollable persistent>
      <v-card v-if="policyDisplayed">
        <v-card-title class="modalTitle">
          <v-flex>
            <strong v-if="!activitySupervisorScrollDisplayed">View Policy</strong>
            <v-btn
              id="policy-view--supervisor-qualifications-display-button"
              v-if="activitySupervisorScrollDisplayed && content && (0 < content.length)"
              @click.stop="turnToSupervisor"
              depressed
              class="normal-btn"
              style="width: auto"
            >
              Supervision Qualifications and Ratios
            </v-btn>
            <v-chip
              v-model="ratioChipDisplayed"
              close
              color="#ff7f32"
              style="height:30px; margin:0;"
              label
              outlined
            >Calculated Supervision Ratio - {{getStaffToStudentRatio()}}</v-chip>
          </v-flex>
          <v-btn icon @click="policyDisplayed = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text id="policy" class="policy view-checklist-policy">
          <div v-if="!content || (content.length <= 0)" class="complete-loading">
            <v-progress-circular
              :size="70"
              color="rgb(29, 175, 236)"
              class="loading-img"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list id="policyList" v-for="(option, index) in content" :key="index">
            <vue-markdown :source="option.Policy" :typographer="false"></vue-markdown>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="policyDisplayed = false" class="normal-btn" id="btnClose">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import riskCategoryLabel from "../lib/const/riskCategoryLabel";

export default {
  props: [
    "categoryName",
    "riskTypeName",
    "riskClassName",
    "riskName",
    "elementDisabled",
    "showViewPolicyLabel",
    "activitySupervisorScrollDisplayed",
    "masterPolicyExported",
    "excursionPolicyExported",
  ],
  data: () => ({
    ratioChipDisplayed: false,
    policyDisplayed: false,
    content: [],
    masterPolicyRiskCategory: ["Master Excursions Policy"],
  }),
  computed: {
    activityTlrcName() { // tlrc stands for: top level risk category
      return riskCategoryLabel.ACTIVITIES;
    },
    allSelectedActivityRisk() {
      return this.$store.getters.getSelectedActivityClasses[0];
    },
    viewPolicyBtnId() {
      let idViewPolicy;

      if ("undefined" !== typeof this.categoryName) {
        idViewPolicy = `btnViewPolicy${this.categoryName.replace(/\s/g, "")}`;
      }

      if (
        ("undefined" !== typeof this.riskTypeName)
        && ("undefined" !== typeof this.riskClassName)
      ) {
        idViewPolicy = `btnViewPolicy${this.riskTypeName.replace(/\s/g, "")}${this.riskClassName.replace(/\s/g, "")}`;
      }

      if (!idViewPolicy) {
        idViewPolicy = "btnViewPolicyUndefined";
      }

      return idViewPolicy;
    },
  },
  methods: {
    getStaffToStudentRatio() {
      const excursion = this.$store.state.excursion;
      return this.$app.excursionCommon.getStaffToStudentRatio(
        excursion.staffSupervisorAttendanceCount,
        excursion.studentAttendanceCount,
      );
    },
    turnToSupervisor() {
      const supervisorSelector = $("h3:contains(Supervisor Qualifications)");
      const supervisionSelector = $("h3:contains(Supervision Ratios)");
      supervisorSelector.css("color", "rgb(29, 175, 236)");
      supervisionSelector.css("color", "rgb(29, 175, 236)");
      const policyListSelector = $("#policyList");
      if (
        supervisorSelector
        && supervisorSelector.position()
        && supervisorSelector
        && supervisorSelector.position()
      ) {
        const supervisorTop = supervisorSelector.position().top;
        const policyListTop = policyListSelector.position().top;
        const policyElement = document.getElementById("policy");
        policyElement.scrollTop = (supervisorTop - policyListTop + 30);
      }

      this.ratioChipDisplayed = true;
    },
    async getData() {
      this.ratioChipDisplayed = false;
      this.content = [];
      this.policyDisplayed = true;
      const that = this;

      if (this.categoryName === undefined) {
        const riskCategories = this.$store.state.excursion.categories
        .filter(tlrc => `${tlrc.name}` !== `${that.activityTlrcName}`);
        const paramRiskCategories = this.generateAllParams(riskCategories);
        if (this.masterPolicyExported) {
          paramRiskCategories.push(this.masterPolicyRiskCategory);
        }

        const argHash = {
          queryParam: this.$route.query,
          store: this.$store,
          frcs: paramRiskCategories,
        };

        const apiResponse = await this.$app.stbApiAdapter.getViewPolicy(argHash);
        const mandatory = apiResponse.mandatory;
        const optional = apiResponse.optional;
        const excursionName = that.$store.state.excursion.name;
        let headerName = `${excursionName} - Excursion Policy`;
        if (that.masterPolicyExported) {
          that.$store.state.ceCommitOid = apiResponse.ceCommitOid;
          headerName = `Master Excursions Policy`;
        }
        if (
          mandatory
          && mandatory.length
          && mandatory[0].Policy
        ) {
          mandatory[0].Policy = mandatory[0].Policy
          .replace(that.masterPolicyRiskCategory[0], `${headerName}`);
        }
        that.content = mandatory.concat(optional);
      } else if (
        ("undefined" !== typeof this.categoryName)
        && (undefined === this.riskTypeName)
        && (undefined === this.riskClassName)
      ) {
        const recordedTlrc = this.$store.state.excursion.categories
        .find(tlrc => (tlrc.name === this.categoryName));

        let paramRiskCategories = [];

        if (this.categoryName !== that.activityTlrcName) {
          paramRiskCategories = this.generateParams(recordedTlrc);
        } else {
          paramRiskCategories = this.generateParams(this.allSelectedActivityRisk);
        }

        if (0 === paramRiskCategories.length) {
          const paramRiskCategory = [recordedTlrc.name];
          paramRiskCategories.push(paramRiskCategory);
        }

        const argHash = {
          queryParam: this.$route.query,
          store: this.$store,
          frcs: paramRiskCategories,
        };

        const apiResponse = await this.$app.stbApiAdapter.getViewPolicy(argHash);
        const optional = apiResponse.optional;
        this.content = optional;
        this.$store.state.ceCommitOid = apiResponse.ceCommitOid;
      } else {
        const riskType = this.$app.enumerable
        .from(this.allSelectedActivityRisk.types)
        .firstOrDefault(x => x.name === this.riskTypeName);

        const riskClass = this.$app.enumerable
        .from(riskType.classes)
        .firstOrDefault(x => x.name === this.riskClassName);

        const frcs = [];

        for (let riskKey = 0; riskKey < riskClass.risks.length; riskKey++) {
          const riskName = riskClass.risks[riskKey].name;
          const paramRiskCategory = [
            this.activityTlrcName,
            riskType.name,
            riskClass.name,
            riskName,
          ];
          frcs.push(paramRiskCategory);
        }

        if (0 < frcs.length) {
          const argHash = {
            queryParam: this.$route.query,
            store: this.$store,
            frcs,
          };
          const response = await this.$app.stbApiAdapter.getViewPolicy(argHash);
          const optional = response.optional;
          this.content = optional;
          this.$store.state.ceCommitOid = response.ceCommitOid;
        }
      }
    },
    generateParams(riskCategory) {
      const paramRiskCategories = [];
      const activityTlrcName = this.activityTlrcName;
      if (riskCategory.name !== activityTlrcName) {
        riskCategory.types.forEach(function (riskType) {
          riskType.classes.forEach(function (riskClass) {
            const paramRiskCategory = [
              riskCategory.name,
              riskType.name,
              riskClass.name,
            ];
            paramRiskCategories.push(paramRiskCategory);
          });
        });
      } else {
        riskCategory.types.forEach(function (riskType) {
          riskType.classes.forEach(function (riskClass) {
            for (
              let riskKey = 0;
              riskKey < riskClass.risks.length;
              riskKey++
            ) {
              const riskName = riskClass.risks[riskKey].name;
              const paramRiskCategory = [
                activityTlrcName,
                riskType.name,
                riskClass.name,
                riskName,
              ];
              paramRiskCategories.push(paramRiskCategory);
            }
          });
        });
      }
      return paramRiskCategories;
    },
    generateAllParams(riskCategories) {
      const paramRiskCategories = [];
      const activityTlrcName = this.activityTlrcName;

      riskCategories.forEach(function (category) {
        category.types.forEach(function (riskType) {
          riskType.classes.forEach(function (riskClass) {
            const paramRiskCategory = [
              category.name,
              riskType.name,
              riskClass.name,
            ];
            paramRiskCategories.push(paramRiskCategory);
          });
        });
      });
      if ("undefined" !== typeof this.allSelectedActivityRisk) {
        this.allSelectedActivityRisk.types.forEach(function (riskType) {
          riskType.classes.forEach(function (riskClass) {
            for (
              let riskKey = 0;
              riskKey < riskClass.risks.length;
              riskKey++
            ) {
              const riskName = riskClass.risks[riskKey].name;
              const paramRiskCategory = [
                activityTlrcName,
                riskType.name,
                riskClass.name,
                riskName,
              ];
              paramRiskCategories.push(paramRiskCategory);
            }
          });
        });
      }
      return paramRiskCategories;
    },
  },
  updated() {
    const h1Elements = $("#policy h1");
    const h2Elements = $("#policy h2");
    const h3Elements = $("#policy h3");
    h1Elements.each((index, h1Element) => {
      $(h1Element).attr("id", `view-policy-modal--h1-${index}`);
    });

    h2Elements.each((index, h2Element) => {
      $(h2Element).attr("id", `view-policy-modal--h2-${index}`);
    });

    h3Elements.each((index, h3Element) => {
      $(h3Element).attr("id", `view-policy-modal--h3-${index}`);
    });
  },
};
</script>

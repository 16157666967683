<template>
  <div class="category-div staff-student-supervision">
    <v-layout align-center justify-space-between style="display:flex;">
      <div>
        <v-layout align-center justify-space-between class="category-section">
          <v-icon class="icon" @click.stop="toggleSupervision()">
            {{ !supervisionContentDisplayed ? "keyboard_arrow_left" : "keyboard_arrow_down" }}
          </v-icon>
          <div class="name" @click.stop="toggleSupervision()">Staff and Student Supervision</div>
        </v-layout>
      </div>
    </v-layout>
    <div :hidden="!supervisionContentDisplayed" class="sub-category-section">
      <v-flex xs12 class="sub-label">
        <v-layout align-center justify-space-between>
          <div>How many staff / supervisors attending?</div>
        </v-layout>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 style="width:calc(100% - 4px);">
        <v-text-field
          id="staff-student-supervision--staff-supervisor-attendance-count"
          :disabled="!formControlEnabled"
          @keyup="formatStaffSupervisorAttendanceCount($event)"
          v-model="staffSupervisorAttendanceCount"
          solo
          class="name-textbox excursion-input"
          placeholder="Enter the amount of staff / supervisors attending"
        />
      </v-flex>
      <v-flex xs12 class="sub-label">
        <v-layout align-center justify-space-between>
          <div>How many students attending?</div>
        </v-layout>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 class="excursion-detail-text" style="width:calc(100% - 4px);">
        <v-text-field
          id="staff-student-supervision--student-attendance-count"
          :disabled="!formControlEnabled"
          @keyup="formatStudentAttendanceCount($event)"
          v-model="studentAttendanceCount"
          solo
          class="name-textbox excursion-input"
          placeholder="Enter the amount of students attending"
        />
      </v-flex>
      <v-flex xs12 class="sub-label">
        <v-layout align-center justify-space-between>
          <div>Calculated Staff to Student Supervision Ratio</div>
        </v-layout>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 class="excursion-detail-text" style="width:calc(100% - 4px);">
        <v-text-field
          id="staff-student-supervision--staff-to-student-ratio"
          disabled
          v-model="staffToStudentRatio"
          solo
          class="name-textbox excursion-input"
          placeholder="e.g. 1:1.10"
        />
      </v-flex>
    </div>
  </div>
</template>

<script>
export default {
  props: ["formControlEnabled"],
  data() {
    return {
      supervisionContentDisplayed: true,
    };
  },
  computed: {
    staffSupervisorAttendanceCount: {
      get() {
        return this.$store.state.excursion.staffSupervisorAttendanceCount;
      },
      set(value) {
        this.$store.state.excursion.staffSupervisorAttendanceCount = value;
      },
    },
    studentAttendanceCount: {
      get() {
        return this.$store.state.excursion.studentAttendanceCount;
      },
      set(value) {
        this.$store.state.excursion.studentAttendanceCount = value;
      },
    },
    staffToStudentRatio: {
      get() {
        return this.$app.excursionCommon.getStaffToStudentRatio(
          this.staffSupervisorAttendanceCount,
          this.studentAttendanceCount,
        );
      },
    },
  },
  methods: {
    toggleSupervision() {
      this.supervisionContentDisplayed = !this.supervisionContentDisplayed;
    },
    getNumberValue(value) {
      const numberValue = value.replace(/[^\d]+/g, "");
      const maxValue = 9999;
      const minValue = 0;
      let returnValue;

      if ("" === numberValue) {
        returnValue = "";
      } else if (numberValue <= minValue) {
        returnValue = minValue;
      } else if (maxValue < numberValue) {
        returnValue = maxValue;
      } else {
        returnValue = numberValue;
      }

      return returnValue;
    },
    formatStaffSupervisorAttendanceCount(e) {
      this.staffSupervisorAttendanceCount = this.getNumberValue(e.target.value);
    },
    formatStudentAttendanceCount(e) {
      this.studentAttendanceCount = this.getNumberValue(e.target.value);
    },
  },
};
</script>

const checkIfDeviceMobile = () => {
  return /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(navigator.userAgent);
};

// reference: https://gist.github.com/dalethedeveloper/1503252

const checkIfDeviceInIosFamily = () => {
  return [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  || checkIfDeviceMobile();
};

// reference: https://stackoverflow.com/questions/9038625/detect-if-device-is-ios

export default {
  checkIfDeviceInIosFamily,
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.riskCategories),function(category,index){return _c('div',{key:index,staticClass:"category-div"},[(_vm.categorySectionDisplayed(category.name))?_c('div',[_c('div',[_c('v-layout',{staticStyle:{"display":"flex"},attrs:{"align-center":"","justify-space-between":""}},[_c('div',[_c('v-layout',{staticClass:"category-section",attrs:{"align-center":"","justify-space-between":""}},[_c('v-icon',{staticClass:"icon",on:{"click":function($event){$event.stopPropagation();return _vm.toggleCategory(index)}}},[_vm._v(_vm._s(!category.displayed ? "keyboard_arrow_left" : "keyboard_arrow_down"))]),_c('div',{staticClass:"name",on:{"click":function($event){$event.stopPropagation();return _vm.toggleCategory(index)}}},[_vm._v(_vm._s(category.name))]),(0 > _vm.excludedRiskCategories.indexOf(category.name))?_c('div',{staticClass:"quantity ml-3"},[_vm._v(_vm._s(_vm.typesCount(category.name))+" Types")]):_vm._e(),('Activities' === category.name)?_c('div',{staticClass:"quantity ml-3"},[_vm._v(_vm._s(_vm.activityTypesCount)+" Types")]):_vm._e(),(_vm.highRiskCategoryName !== category.name)?_c('div',{staticClass:"quantity ml-3"},[_vm._v(_vm._s(_vm.approvedProvidersCount(category.name))+" Providers")]):_vm._e()],1)],1),_c('div',{staticStyle:{"display":"flex"}},[('Activities' !== category.name)?_c('complete-checklist',{attrs:{"categoryName":category.name,"checklistViewModeEnabled":false,"formControlEnabled":_vm.formControlEnabled,"associatedChecklistCompleted":_vm.getTicked(category),"elementDisabled":(
                  (0 >= _vm.typesCount(category.name))
                  && (category.name !== _vm.excludedRiskCategories[1])
                )
                || (
                  (0 >= _vm.approvedProvidersCount(category.name))
                  && (category.name === _vm.excludedRiskCategories[1])
                )
                || (
                  _vm.checklistFetchActionInProgress
                  && (_vm.checklistFetchActionCategoryName === category.name)
                ),"checklistFetchActionCategoryName":_vm.checklistFetchActionCategoryName,"checklistFetchActionInProgress":_vm.checklistFetchActionInProgress,"saveDraft":_vm.saveDraft}}):_vm._e(),(
                (
                  _vm.excursionPolicyViewActionEnabled
                  && ('Activities' !== category.name)
                )
                || ('Outdoor Education Providers' === category.name)
              )?_c('view-policy',{attrs:{"categoryName":category.name,"elementDisabled":(
                  (0 >= _vm.typesCount(category.name))
                  && (category.name !== _vm.excludedRiskCategories[1])
                )
                || (
                  (0 >= _vm.approvedProvidersCount(category.name))
                  && (category.name === _vm.excludedRiskCategories[1])
                )}}):_vm._e(),(_vm.excursionPolicyViewActionEnabled && ('Activities' === category.name))?_c('view-policy',{attrs:{"categoryName":category.name,"elementDisabled":0 >= _vm.activityTypesCount,"activitySupervisorScrollDisplayed":false}}):_vm._e()],1)])],1),_c('div',{attrs:{"hidden":!category.displayed}},[_c('v-layout',[(
              (0 > _vm.excludedRiskCategories.indexOf(category.name))
              && (category.name !== _vm.highRiskCategoryName)
            )?_c('select-control',{attrs:{"background-color":"#EEEEEE","name":category.name,"id":_vm.getTypeControlId(category.name),"formControlEnabled":_vm.formControlEnabled},on:{"changeRiskCategoryDone":_vm.changeRiskCategory}}):_vm._e(),(category.name === _vm.highRiskCategoryName)?_c('high-risk',{attrs:{"background-color":"#EEEEEE","name":category.name,"id":_vm.getTypeControlId(category.name),"formControlEnabled":_vm.formControlEnabled},on:{"changeRiskCategoryDone":_vm.changeRiskCategory}}):_vm._e(),('Activities' === category.name)?_c('activity-risk',{attrs:{"formControlEnabled":_vm.formControlEnabled,"checklistAndPolicyDisplayed":true,"saveDraft":_vm.saveDraft}}):_vm._e()],1),_c('v-layout',{staticClass:"sub-category-section"},[(0 < _vm.getProvidersByCategoryCount(category.name))?_c('v-flex',{attrs:{"xs12":"","providers":"","label-padding":""}},[_vm._v(" Providers "),_c('v-tooltip',{attrs:{"right":"","color":"#ffffff"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var tooltip = ref.on;
return [(_vm.hasNegativeProviderAnswer(category.name))?_c('v-btn',_vm._g({staticClass:"provider-tooltip-button",attrs:{"icon":""}},Object.assign({}, tooltip)),[_c('i',{staticClass:"fas fa-info-circle"})]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"provider-tooltip-content"},[_vm._v(_vm._s(_vm.negativeProviderAnswerHint))])])],1):_vm._e()],1),(0 < _vm.getProvidersByCategoryCount(category.name))?_c('v-layout',{staticClass:"move-top-risk-title"},[_c('providers',{attrs:{"categoryName":category.name,"formControlEnabled":_vm.formControlEnabled}})],1):_vm._e(),(_vm.excursionPersonResponsibleEnabled)?_c('v-flex',{staticClass:"person-responsible",style:({
            'padding-top': (0 >= _vm.getProvidersByCategoryCount(category.name))
              ? '8px'
              : '0px'
          }),attrs:{"xs12":""}},[_vm._v("Person Responsible")]):_vm._e(),(_vm.excursionPersonResponsibleEnabled)?_c('v-flex',{staticClass:"excursion-detail-text",staticStyle:{"width":"calc(100% - 4px)"},attrs:{"xs12":""}},[_c('v-text-field',{staticClass:"name-textbox excursion-input",attrs:{"solo":"","placeholder":_vm.personResponsiblePlaceholder,"disabled":!_vm.formControlEnabled
              || (
                (0 >= _vm.approvedProvidersCount(category.name))
                && ('Outdoor Education Providers' === category.name)
              ),"id":("personResponsible-" + (_vm.getTypeControlId(category.name)))},on:{"keyup":function($event){return _vm.updatePersonResponsible(category)}},model:{value:(category.personResponsible),callback:function ($$v) {_vm.$set(category, "personResponsible", $$v)},expression:"category.personResponsible"}})],1):_vm._e(),(
            (0 >= _vm.getProvidersByCategoryCount(category.name))
            && (category.name !== _vm.highRiskCategoryName)
          )?_c('div',{staticStyle:{"padding-bottom":"18px"}}):_vm._e()],1)]):_vm._e()])}),(!_vm.riskCategories || (0 >= _vm.riskCategories.length))?_c('div',{staticClass:"excursion-waiting"},[_c('v-progress-circular',{staticClass:"loading-img",attrs:{"size":30,"color":"rgb(29, 175, 236)","indeterminate":""}})],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
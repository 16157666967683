<template>
  <div class="additionalInfoList">
    <v-flex xs12 class="additional-info-list-info-text">
      <i class="fas fa-info-circle"></i>
        <!-- eslint-disable -->
        This section allows you to upload files and add typed notes. For example, additional information could include risk assessments provided by external providers.
        <!-- eslint-enable -->
        <br><br>
        You can upload one or more file(s) including additional relevant documents
        such as risk assessments or relevant medical information. Alternatively,
        you can enter in notes in the Note section. Add additional files or notes
        as required by clicking Add New.
      </v-flex>
    <div :key="index" v-for="(additional, index) in additionalInformationItems">
      <div v-if="!additional.isDeleted">
        <div v-if="!additional.notes">
          <v-layout wrap>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12>
                  <input
                    :id="getUploadFileId(index)"
                    style="display:none;"
                    type="file"
                    :accept="fileTypeAccept"
                    @change="handleTemplateFileChange($event, additional, index)"
                  >
                  <v-btn
                    :id="getUploadButtonId(index)"
                    @click="uploadFileClick(index)"
                    class="normal-btn white--text continue-btn
                    excursion-stepper-upload-btn v-btn-upload primary"
                    :disabled="!formControlEnabled"
                  >
                    <i class="fa fa-upload" aria-hidden="true"></i> Upload
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 class="form-label safe-trip-font">
              File Name
            </v-flex>
            <v-flex xs12>
              <v-layout wrap>
                <v-flex xs12>
                  {{additional.filename}}
                  <v-icon v-if="additional.sid" @click="downloadFile(additional)"
                    medium
                    style="font-size:15px;height:17px;margin-left:.7%;"
                  >fa fa-download</v-icon>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="form-label safe-trip-font">
              Uploaded Date
            </v-flex>
            <v-flex xs12>
              {{getUploadDate(additional.fileUpdateDate)}}
            </v-flex>
          </v-layout>
        </div>
        <v-layout wrap>
          <v-flex xs12 class="form-label safe-trip-font">
            Summary
            <span v-show="showFirstMandatory(additional)" class="red--text">*</span>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              :id="getFileSummaryId(index)"
              solo
              placeholder="Summary"
              class="name-textbox safe-trip-font excursion-input"
              v-model="additional.fileSummary"
              @input="additionalValueChange(additional, index)"
              :disabled="!formControlEnabled"
            />
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="!additional.notes">
          <v-flex xs12 class="form-label safe-trip-font excursion-stepper-upload-comment">
            File Description</v-flex>
          <v-flex xs12>
            <div>
              <v-textarea
                :id="getDescriptionId(index)"
                solo
                placeholder="Add a file description or add notes without uploading a file"
                rows="4"
                class="textarea-comment excursion-input"
                height="100px"
                style="margin-top:0px"
                :no-resize="true"
                v-model="additional.comment"
                @change="additionalValueChange(additional, index)"
                :disabled="!formControlEnabled"
              ></v-textarea>
            </div>
          </v-flex>
        </v-layout>
        <v-layout wrap v-if="!additional.filename">
          <v-flex xs12 class="form-label safe-trip-font excursion-stepper-upload-comment">
            Notes
            <span v-show="showFirstMandatory(additional)" class="red--text">*</span>
          </v-flex>
          <v-flex xs12>
            <div>
              <v-textarea
                solo
                placeholder="Notes"
                rows="4"
                class="textarea-comment excursion-input"
                height="100px"
                style="margin-top:0px"
                :no-resize="true"
                v-model="additional.notes"
                @input="additionalValueChange(additional, index)"
                :disabled="!formControlEnabled"
              ></v-textarea>
            </div>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-btn
            :id="getAddFileId(index)"
            v-if="showAddFileButton(index)"
            @click.native="addAdditionalInfo()"
            small
            class="normal-btn continue-btn add-additional-btn v-btn-upload"
            :disabled="!formControlEnabled"
          >Add New</v-btn>
          <v-btn
            :id="getDeleteId(index)"
            v-if="additional.showDeleteButton"
            @click="deleteAdditionalInfoConfirm(index)"
            small
            class="normal-btn error delete-additional-btn"
            :class="{'continue-btn':!showAddFileButton(index)}"
            :disabled="!formControlEnabled"
          >Delete</v-btn>
        </v-layout>
      </div>
    </div>
    <div>
      <v-dialog v-model="deletionConfirmationDialogDisplayed" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Are you Sure?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="additional-info-list--delele-confirm-dialog-delete"
              class="normal-btn white--text delete-additional-btn-yes primary"
              @click.stop="deleteAdditionalInfo()"
              :disabled="!formControlEnabled"
            >Delete</v-btn>
            <v-btn
              id="additional-info-list--delele-confirm-dialog-cancel"
              class="normal-btn"
              @click="deletionConfirmationDialogDisplayed = false"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-dialog v-model="invalidFileTypeDialogDisplayed" persistent max-width="490">
        <v-card>
          <v-card-title class="headline center">
            type not allowed:<br> {{uploadFilename}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="additional-info-list--wrong-file-type-dialog-ok"
              @click.native="invalidFileTypeDialogDisplayed = false"
              class="normal-btn white--text primary"
            >OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  props: ["additionalInformationItems", "formControlEnabled"],
  data: () => ({
    deletionConfirmationDialogDisplayed: false,
    invalidFileTypeDialogDisplayed: false,
    selectedAdditional: {},
    fileTypeAccept: `.pdf,.csv,.doc,.docx,.xls,.xlsx,.txt,.odt,.ods,.odp,.png,.jpeg,.jpg`,
    uploadFilename: "",
  }),
  methods: {
    addAdditionalInfo() {
      const additionalInformationItemsAdded
       = this.additionalInformationItems.filter(r => !r.isDeleted);
      let showDeleteButton = true;
      if (0 === additionalInformationItemsAdded.length) {
        showDeleteButton = false;
      }

      this.additionalInformationItems.push({
        fileSummary: "",
        comment: "",
        showDeleteButton,
      });

      this.$forceUpdate();
      this.setAdditionalInformationValid();
    },
    deleteAdditionalInfoConfirm(index) {
      this.selectedAdditional.index = index;
      this.deletionConfirmationDialogDisplayed = true;
    },
    deleteAdditionalInfo() {
      const index = this.selectedAdditional.index;
      const items = this.additionalInformationItems;
      const item = this.additionalInformationItems[index];

      if (item.sid) {
        item.isDeleted = true;
      } else {
        items.splice(index, 1);
      }
      this.deleteAttachment(item);
      this.deletionConfirmationDialogDisplayed = false;
      return true;
    },
    async deleteAttachment(item) {
      const items = this.additionalInformationItems;
      const additionalInformationItemsAdded = items.filter((a) => {
        return !a.isDeleted;
      });

      if (0 === additionalInformationItemsAdded.length) {
        this.addAdditionalInfo();
      }

      const excursionId = this.$store.state.excursion.id;
      if (item.isDeleted) {
        const argHash = {
          queryParam: this.$store.state.apiParam,
          excursionId,
          attachmentSid: item.sid,
        };
        await this.$app.stbApiAdapter.deleteExcursionAttachments(argHash);
      }
      this.setAdditionalInformationValid();
    },
    getUploadFileId(index) {
      return `uploadFile_${index}`;
    },
    getUploadButtonId(index) {
      return `additional-info-list--upload-${index}`;
    },
    getFileSummaryId(index) {
      return `additional-info-list--file-summary-${index}`;
    },
    getDescriptionId(index) {
      return `additional-info-list--description-${index}`;
    },
    getAddFileId(index) {
      return `additional-info-list--add-file-${index}`;
    },
    getDeleteId(index) {
      return `additional-info-list--delete-${index}`;
    },
    uploadFileClick(index) {
      document.getElementById(`uploadFile_${index}`).click();
    },
    getUploadDate(uploadDate) {
      let timezoneDate = "";
      if (uploadDate) {
        const localDate = this.$app.moment(uploadDate);
        timezoneDate = this.$app.moment(localDate)
        .utc()
        .tz(this.$store.state.clientTimezone)
        .format("MM/DD/YYYY, hh:mm:ss A");
      }
      return timezoneDate;
    },
    handleTemplateFileChange(e, additional, index) {
      if (e && e.target.files && 0 < e.target.files.length) {
        const file = e.target.files[0];
        const filename = file.name;
        const extensionType = filename.split(".").pop();

        if (0 > this.fileTypeAccept.indexOf(extensionType)) {
          this.invalidFileTypeDialogDisplayed = true;
          this.uploadFilename = filename;
          return;
        }

        this.$set(additional, "file", file);

        this.$set(additional, "filename", file.name);
        // TODO: Use the date from API
        this.$set(additional, "fileUpdateDate", "");
        this.$forceUpdate();

        this.additionalValueChange(additional, index);

        this.setAdditionalInformationValid();
      }
    },
    showAddFileButton(index) {
      const additionalInformationItemsAdded = this.additionalInformationItems.filter((a) => {
        return !a.isDeleted;
      });

      if (1 === additionalInformationItemsAdded.length) {
        return true;
      }

      // Allow uploading up to 50 files
      if (50 === additionalInformationItemsAdded.length) {
        return false;
      }

      for (let i = this.additionalInformationItems.length - 1; 0 < i; i--) {
        if (!this.additionalInformationItems[i].isDeleted) {
          if (index === i) {
            return true;
          }
          break;
        }
      }

      return false;
    },
    additionalValueChange(additional, index) {
      if (0 === index) {
        if (
          additional.filename
          || additional.fileSummary
          || additional.comment
          || additional.notes
        ) {
          additional.showDeleteButton = true;
        } else {
          additional.showDeleteButton = false;
        }
      }
      if (additional.notes && (0 < additional.notes.length)) {
        additional.comment = "";
      }
      this.$forceUpdate();
      additional.isUpdated = true;
      this.setAdditionalInformationValid();
    },
    setAdditionalInformationValid() {
      const additionalInformationItemsAdded = this.additionalInformationItems.filter((a) => {
        return !a.isDeleted;
      });

      let additionalInformationValid = false;
      if (additionalInformationItemsAdded) {
        if (1 === additionalInformationItemsAdded.length) {
          const additional = additionalInformationItemsAdded[0];
          if (
            (additional.filename && additional.fileSummary)
            || (
              !additional.filename
              && ("" !== additional.fileSummary)
              && additional.notes
            )
            || (
              !additional.filename
              && !additional.fileSummary
              && !additional.comment
              && !additional.notes
            )
          ) {
            additionalInformationValid = true;
          }
        } else {
          additionalInformationValid = additionalInformationItemsAdded.every((additional) => {
            return (
              (additional.filename && additional.fileSummary)
              || (
                !additional.filename
                && ("" !== additional.fileSummary)
                && additional.notes
              )
            );
          });
        }
      }

      this.$emit("setAdditionalInformationValid", additionalInformationValid);
    },
    async downloadFile(additional) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: additional.sid,
        store: this.$store,
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (0 < attachments.length) {
          const file = attachments[0];

          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, "");
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, "");
            const fileUrl = `${apiUrl}/${filePath}`;
            const filename = file.metadata.filename;
            const excursionCommonAdapter
              = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
            excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },
    showFirstMandatory(additional) {
      if (additional.isDeleted) {
        return false;
      }

      const additionalInformationItemsAdded = this.additionalInformationItems.filter((a) => {
        return !a.isDeleted;
      });
      if (additionalInformationItemsAdded && 1 === additionalInformationItemsAdded.length) {
        const additionalInfo = additionalInformationItemsAdded[0];
        if (
          additionalInfo.filename
          || additionalInfo.fileSummary
          || additionalInfo.comment
          || additionalInfo.notes
        ) {
          return true;
        }

        return false;
      }

      return true;
    },
  },
};
</script>

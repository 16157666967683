const getFileBlob = (href) => {
  const byteCharacters = atob(href);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: "application/pdf" });
};

const downloadFile = (href, filename, deviceDetector) => {
  if (deviceDetector && deviceDetector.checkIfDeviceInIosFamily()) {
    const blob = getFileBlob(href);
    const blobUrl = URL.createObjectURL(blob);

    window.location = blobUrl;
    // window.open(blobUrl, "_blank"); // request apple users to unblock popup settings for safari
  } else {
    const link = document.createElement("a");
    link.href = href;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

const downloadFileForIE = (href, filename) => {
  const blob = getFileBlob(href);

  window.navigator.msSaveOrOpenBlob(blob, filename);
};

const downloadPdf = (base64Data, filename, deviceDetector) => {
  const data = (deviceDetector && deviceDetector.checkIfDeviceInIosFamily())
    ? base64Data : `data:application/pdf;base64,${base64Data}`;

  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    downloadFileForIE(base64Data, filename);
  } else {
    downloadFile(data, filename, deviceDetector);
  }
};

const downloadPagePdf = {
  downloadRiskAssignmentPdf: async (
    stbApiAdapter,
    store,
    excursionId,
    normalizedRequestParam,
    allChecklistsExported,
    clientLogoEnabled,
    predepartureChecklistExported,
    deviceDetector,
  ) => {
    const argHash = {
      store,
      queryParam: normalizedRequestParam,
      excursionId,
      allChecklistsExported,
      clientLogoEnabled,
      predepartureChecklistExported,
    };

    const response = await stbApiAdapter.postExcursionAssessments(argHash);
    downloadPdf(response.data.computed.source, response.data.computed.filename, deviceDetector);
  },
  downloadPredeparturePdf: async (argHash, deviceDetector) => {
    const {
      stbApiAdapter,
      store,
      queryParam,
    } = argHash;

    const apiArgHash = {
      store,
      queryParam,
    };
    const response = await stbApiAdapter.postExcursionPredepartureStrategies(apiArgHash);

    if (!response.error) {
      downloadPdf(response.data.computed.source, response.data.computed.filename, deviceDetector);
    }

    return response.error;
  },
  downloadExcursionRiskContentsPdf: async (
    stbApiAdapter,
    store,
    queryParam,
    params,
    deviceDetector,
  ) => {
    const argHash = {
      store,
      queryParam,
      params: {
        "excursion-id": params.excursionId,
        "category-checklists-exported": params.categoryChecklistsExported,
        "client-logo-enabled": params.clientLogoEnabled,
        "mandatory-checklists-exported": params.mandatoryChecklistsExported,
        "master-policy-exported": params.masterPolicyExported,
        "category-checklists": params.exportedCategoryChecklists,
        "risk-categories": params.riskCategoryParams,
        "excursion-policies": params.excursionPolicies,
      },
    };
    const response = await stbApiAdapter.postExcursionRiskContents(argHash);
    downloadPdf(response.data.computed.source, response.data.computed.filename, deviceDetector);
  },
};

export default {
  downloadFile,
  downloadPdf,
  downloadPagePdf,
};

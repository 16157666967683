export default {
  EXCURSION_DETAILS: "Excursion Details",
  STUDENT_MEDICAL_AND_RATIOS: "Student Medical and Ratios",
  MANDATORY_EXCURSION_CHECKLISTS: "Mandatory Excursion Checklists",
  RISK_CATEGORIES: "Risk Categories",
  ADDITIONAL_INFORMATION: "Additional Information",
  EXCURSION_RISK_ASSESSMENT: "Excursion Risk Assessment",
  EXCURSION_RISK_MANAGEMENT_CONFIRMATION: "Excursion Risk Management Confirmation",
  PREDEPARTURE_AND_POST_EXCURSION: "Pre-Departure and Post Excursion",
};

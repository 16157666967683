<template>
  <div v-show="displayed" class="page-loading-animation">
    <v-progress-circular
      :size="100"
      color="rgb(29, 175, 236)"
      class="loading-img"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  props: {
    displayed: Boolean,
  },
};
</script>

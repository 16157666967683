<template>
  <v-layout>
    <v-btn
      @click.native="displayExcursionPolicyExportModalWithPresaveConfirmation()"
      class="normal-btn white--text primary"
      id="btnExportExcursionPolicy"
      :disabled="this.excursionPolicyExportActionEnabled"
    >
      <v-icon class="excursion-policy-export-icon">format_indent_increase</v-icon>
      Export Checklists / Policies
    </v-btn>
    <v-dialog
      v-model="excursionPolicyExportModalDisplayed"
      persistent
      content-class="excursion-policy-dialog"
    >
      <v-card>
        <v-card-title class="modalTitle">
          <v-flex>
            <strong v-if="excursionPolicyViewActionEnabled">
              Excursion Policies and Checklists
            </strong>
            <strong v-if="!excursionPolicyViewActionEnabled">Excursion Checklists</strong>
          </v-flex>
          <v-btn icon @click="excursionPolicyExportModalDisplayed = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="modalDivider"></v-divider>
        <v-card-text>
          <v-container class="export-excursion-policy">
            <div v-if="excursionPolicyViewActionEnabled">
              <v-flex>
                <strong>Excursion Policies</strong>
              </v-flex>
              <v-layout class="selected-risk-container">
                <v-flex xs1>
                  <v-checkbox
                    id="export-excursion-policy--export-excursion-policy"
                    @change="changeMasterExcursionPolicy()"
                    v-model="excursionPolicyExportChecked"
                    class="excursion-policy-row-title-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  xs11
                  class="excursion-policy-row-title"
                >
                  <label for="export-excursion-policy--export-excursion-policy">
                    Export Excursion Policy
                  </label>
                </v-flex>
                <view-policy
                  :showViewPolicyLabel=true
                  :masterPolicyExported=false
                  :excursionPolicyExported=true
                />
              </v-layout>
              <v-layout class="selected-risk-container">
                <v-flex xs1>
                  <v-checkbox
                    id="export-excursion-policy--export-master-excursion-policy"
                    @change.native="changeExcursionPolicy()"
                    v-model="masterPolicyExportChecked"
                    class="excursion-policy-row-title-checkbox"
                    testing-id="masterExcursionPolicy"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  xs11
                  class="excursion-policy-row-title"
                >
                  <label for="export-excursion-policy--export-master-excursion-policy">
                    Export Master Excursion Policy
                  </label>
                </v-flex>
                <view-policy
                  :showViewPolicyLabel=true
                  :masterPolicyExported=true
                  :excursionPolicyExported=false
                />
              </v-layout>
              <hr style="margin-bottom:unset;" />
              <v-layout class="selected-risk-container" v-for="item in items" :key="item.id">
                <v-flex xs1>
                  <v-checkbox
                    :id="getChecklistItemId(item)"
                    v-model="item.ticked"
                    @change="checkRiskItem()"
                    :disabled="!item.isActive"
                    class="excursion-policy-row-title-checkbox"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  xs11
                  :class="item.isActive
                    ? 'excursion-policy-row-title selected-risk'
                    : 'excursion-policy-row-title unselected-risk'
                  "
                  @click.native="checkRiskItemByTitle(item)"
                >{{item.name}}</v-flex>
                <view-policy
                  :categoryName="item.name"
                  :elementDisabled="!item.isActive"
                  :showViewPolicyLabel=true
                />
              </v-layout>
            </div>
            <v-flex  style="margin-top:10px;">
              <strong>Excursion Checklists</strong>
            </v-flex>
            <v-layout class="selected-risk-container">
              <v-flex xs1>
                <v-checkbox
                  id="export-excursion-policy--mandatory-excursion-checklists"
                  v-model="mandatoryExcursionChecklistsExportChecked"
                  class="excursion-policy-row-title-checkbox"
                ></v-checkbox>
              </v-flex>
              <v-flex
                xs11
                class="excursion-policy-row-title"
                @click="changeMandatoryExcursionChecklistsTitle()"
              >Mandatory Excursion Checklists</v-flex>
              <view-all-mandatory-checklist></view-all-mandatory-checklist>
            </v-layout>
            <v-layout class="selected-risk-container">
              <v-flex xs1>
                <v-checkbox
                  id="export-excursion-policy--risk-category-checklists"
                  v-model="categoryChecklistsExported"
                  :disabled="checklistViewActionEnabled"
                  class="excursion-policy-row-title-checkbox"
                ></v-checkbox>
              </v-flex>
              <v-flex
                xs11
                class="excursion-policy-row-title"
                @click="changeCategoryChecklistsTitle()"
              >Risk Category Checklists</v-flex>
              <complete-checklist
                :checklistReadOnly="true"
                :checklistViewModeEnabled="true"
                :checklistViewActionEnabled="checklistViewActionEnabled"
              ></complete-checklist>
            </v-layout>
            <v-layout
              class="selected-risk-container"
              v-for="item in activityItems" :key="item.name"
            >
              <v-flex xs1>
                <v-checkbox
                  :id="getChecklistItemId(item)"
                  v-model="item.ticked"
                  class="excursion-policy-row-title-checkbox"
                ></v-checkbox>
              </v-flex>
              <v-flex
                xs11
                class="excursion-policy-row-title selected-risk"
                @click="checkChecklistItemByTitle(item)"
              >{{item.name}}</v-flex>
              <complete-checklist
                :categoryName="item.activityClassesName"
                :riskTypeName="item.riskTypeName"
                :riskClassName="item.riskClassName"
                :checklistViewModeEnabled="true"
              ></complete-checklist>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="export-excursion-policy--export"
            class="normal-btn white--text btnClear primary"
            :disabled="disableExportExcursion || policyDownloadActionInProgress"
            @click.native="downloadExcursionPoliciesPdf()"
            testing-id="btnDownloadExcursionPoliciesPdf"
          >
            <v-icon v-if="policyDownloadActionInProgress">fa fa-spinner fa-pulse</v-icon>
            Export
          </v-btn>
          <v-btn
            id="export-excursion-policy--cancel"
            class="normal-btn"
            @click.native="excursionPolicyExportModalDisplayed = false"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div>
      <v-dialog v-model="presaveConfirmationDialogDisplayed" persistent max-width="490">
        <v-card>
          <v-card-text>
            You need to Save before you can export.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="export-excursion-policy--presave-dialog-save"
              class="normal-btn white--text primary"
              :disabled="excursionSaveActionInProgress"
              @click.native="saveExcursion()"
              testing-id="btnSaveExcursion"
            >
              <v-icon v-if="excursionSaveActionInProgress">fa fa-spinner fa-pulse</v-icon>
              Save
            </v-btn>
            <v-btn
              id="export-excursion-policy--presave-dialog-cancel"
              class="normal-btn"
              @click.native="presaveConfirmationDialogDisplayed = false;
              excursionSaveActionInProgress = false"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: [
    "excursionPolicyExportActionEnabled",
    "valuesChanged",
    "submitExcursion",
    "excursionBuilderViewDisplayed",
  ],
  data() {
    return {
      excursionPolicyExportModalDisplayed: false,
      excursionPolicyExportChecked: false,
      masterPolicyExportChecked: false,
      items: [],
      policyDownloadActionInProgress: false,
      excursionSaveActionInProgress: false,
      excludeRiskCategory: "Outdoor Education Providers",
      mandatoryExcursionChecklistsExportChecked: false,
      activityItems: [],
      excursionPolicyViewActionEnabled: false,
      presaveConfirmationDialogDisplayed: false,
      categoryChecklistsExported: false,
    };
  },
  computed: {
    disableExportExcursion() {
      let disabled = true;
      if (this.masterPolicyExportChecked || this.excursionPolicyExportChecked) {
        disabled = false;
      } else {
        this.items.forEach((item) => {
          if (item.ticked) {
            disabled = false;
          }
        });

        if (this.mandatoryExcursionChecklistsExportChecked) {
          disabled = false;
        }

        if (this.categoryChecklistsExported) {
          disabled = false;
        }

        this.activityItems.forEach((item) => {
          if (item.ticked) {
            disabled = false;
          }
        });
      }
      return disabled;
    },
    checklistViewActionEnabled() {
      let disabled = true;
      const selectedExcursion = this.$store.state.excursion;

      const categories = selectedExcursion.categories;
      if (categories && (0 < categories.length)) {
        const categoryComplete = selectedExcursion.categories
        .filter(c => (
          ("activities" !== c.name.toLowerCase())
          && c.CompleteChecklist
          && (0 < c.CompleteChecklist.length)
        ));

        disabled = (categoryComplete && (0 < categoryComplete.length)) ? false : true;
      }

      return disabled;
    },
  },
  created() {
    this.generateRiskData();
  },
  methods: {
    getChecklistItemId(item) {
      return `export-excursion-policy--${item.name.replace(/\s*/g, "")}`;
    },
    generateRiskData() {
      this.items = [];
      this.excursionPolicyViewActionEnabled = this.$store.state.excursionPolicyViewActionEnabled;
      this.categoryChecklistsExported = false;
      this.excursionPolicyChecked = false;
      this.mandatoryExcursionChecklistsExportChecked = false;
      this.masterPolicyExportChecked = false;
      this.excursionPolicyExportChecked = false;
      const allRisk = this.$store.state.allRisks;
      const selectedRisk = this.$store.state.excursion.categories;
      this.activityItems = [];

      if (allRisk && (0 < allRisk.length)) {
        allRisk.forEach((item) => {
          const riskTreeItem = {
            id: item.id,
            name: item.name,
            ticked: false,
            isActive: false,
          };

          if (selectedRisk && (0 < selectedRisk.length)) {
            const existRisk = selectedRisk.find((sk) => {
              return `${sk.name}` === `${item.name}`;
            });

            if (
              existRisk
              && (existRisk.name !== this.excludeRiskCategory)
              && existRisk.types
              && (0 < existRisk.types.length)
            ) {
              existRisk.types.forEach((type) => {
                if (type && type.classes && (0 < type.classes.length)) {
                  riskTreeItem.isActive = true;

                  if ("Activities" === item.name) {
                    this.generateActivityData(type.classes, type.name, item.name);
                  }
                }
              });
            } else if (existRisk && existRisk.name === this.excludeRiskCategory) {
              riskTreeItem.isActive = true;
            }
          }

          this.items.push(riskTreeItem);
        });
      }
    },
    generateActivityData(classes, riskTypeName, activityClassesName) {
      classes.forEach((item) => {
        this.activityItems.push({
          name: item.name,
          riskTypeName: riskTypeName,
          riskClassName: item.name,
          activityClassesName: activityClassesName,
          ticked: false,
          checklist: item.CompleteChecklist,
        });
      });
    },
    checkChecklistItemByTitle(item) {
      if (item.checklist) {
        item.ticked = !item.ticked;
      }
    },
    async downloadExcursionPoliciesPdf() {
      this.policyDownloadActionInProgress = true;

      const exportedCategoryChecklists = [];
      if (this.activityItems && (0 < this.activityItems.length)) {
        this.activityItems.forEach((item) => {
          if (item.ticked) {
            exportedCategoryChecklists.push(item.name);
          }
        });
      }

      const query = this.$route.query;
      const clientLogoEnabled = !(
        query
        && query.referrer
        && ("pcg" === query.referrer)
      );

      const params = {
        masterPolicyExported: this.masterPolicyExportChecked,
        excursionPolicyExported: this.excursionPolicyExportChecked,
        exportedItems: this.items,
        clientLogoEnabled,
        mandatoryChecklistsExported: this.mandatoryExcursionChecklistsExportChecked,
        exportedCategoryChecklists: exportedCategoryChecklists,
        categoryChecklistsExported: this.categoryChecklistsExported,
      };

      const excursionCommonAdapter
        = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });

      await excursionCommonAdapter.downloadExcursionRiskContentsPdf(
        this.$route.query,
        params,
        this.$app.deviceDetector,
      );

      this.policyDownloadActionInProgress = false;
      this.excursionPolicyExportModalDisplayed = false;
      this.categoryChecklistsExported = false;
    },
    checkRiskItem() {
      this.masterPolicyExportChecked = false;
      this.excursionPolicyExportChecked = false;
    },
    checkRiskItemByTitle(item) {
      if (!item.isActive) {
        return;
      }
      this.masterPolicyExportChecked = false;
      this.excursionPolicyExportChecked = false;
      item.ticked = !item.ticked;
    },
    changeExcursionPolicy() {
      if (
        this.masterPolicyExportChecked
        && this.items
        && this.items.length
      ) {
        this.excursionPolicyExportChecked = false;
      }
      this.clearLinesChecking();
    },
    changeMasterExcursionPolicy() {
      if (
        this.excursionPolicyExportChecked
        && this.items
        && this.items.length
      ) {
        this.masterPolicyExportChecked = false;
      }
      this.clearLinesChecking();
    },
    changeMandatoryExcursionChecklistsTitle() {
      this.mandatoryExcursionChecklistsExportChecked
        = !this.mandatoryExcursionChecklistsExportChecked;
    },
    changeCategoryChecklistsTitle() {
      if (!this.checklistViewActionEnabled) {
        this.categoryChecklistsExported = !this.categoryChecklistsExported;
        this.excursionPolicyChecked = false;
      }
    },
    clearLinesChecking() {
      if (this.items && (0 < this.items.length)) {
        this.items.forEach((item) => {
          item.ticked = false;
        });
      }
    },
    saveExcursion() {
      this.excursionSaveActionInProgress = true;
      if (this.excursionBuilderViewDisplayed) {
        this.submitExcursion(
          null,
          false,
          () => {
            this.excursionSaveActionInProgress = false;
            this.presaveConfirmationDialogDisplayed = false;
            this.excursionPolicyExportModalDisplayed = true;
            this.generateRiskData();
          },
        );
      } else {
        this.submitExcursion(
          null,
          false,
          () => {
            this.excursionSaveActionInProgress = false;
            this.presaveConfirmationDialogDisplayed = false;
            this.excursionPolicyExportModalDisplayed = true;
            this.generateRiskData();
          },
          "downloadExcursionPolicy",
        );
      }
    },
    displayExcursionPolicyExportModalWithPresaveConfirmation() {
      if (
        this.submitExcursion
        && (("function" === typeof this.submitExcursion) && this.valuesChanged)
      ) {
        this.presaveConfirmationDialogDisplayed = true;
      } else {
        this.excursionPolicyExportModalDisplayed = true;
        this.generateRiskData();
      }
    },
  },
};
</script>

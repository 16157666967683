<template>
  <div style="width:100%">
    <select
      class="js-example-basic-single safe-select2-dropdown"
      name="selectControl"
      :id="objId"
      :disabled="!formControlEnabled"
    >
      <option value></option>
      <optgroup v-for="(type, index) in riskTypes" :key="index" :label="type.name">
        <option
          v-for="(riskclass, cindex) in type.classes"
          :key="cindex"
          :value="riskclass.name"
          :title="type.name"
          :disabled="riskclass.disabled"
        >{{riskclass.name}}</option>
      </optgroup>
    </select>

    <risk-class
      :selectedriskClasses="selectedRiskClasses"
      :formControlEnabled="formControlEnabled"
      @changeRiskCategoryDone="changeRiskCategory"
      :riskTypeLabelDisplayed="true"
    ></risk-class>
  </div>
</template>

<script>
// TODO: Use dependency injections instead of directly importing dependencies
import "select2";

export default {
  props: ["name", "id", "formControlEnabled"],
  data() {
    return {};
  },
  computed: {
    placeholder: function () {
      return `Add ${this.name} Type`;
    },
    objId: function () {
      return `selectControl${this.id}`;
    },
    categoryName: function () {
      return this.name;
    },
    selectedRiskClasses() {
      return this.$store.getters.selectedClassByCategoryName(this.name);
    },
    riskTypes() {
      return this.$store.getters.getListRiskTypesByCategoryName(this.name);
    },
  },
  methods: {
    changeRiskCategory() {
      this.$emit("changeRiskCategoryDone", this.name);
    },
  },
  mounted() {
    const riskCategory = {
      name: this.categoryName,
    };
    const store = this.$store;
    const $selector = $(`#${this.objId}`);
    if (
      ("undefined" === typeof $selector.data("select2"))
      && $selector.next().hasClass("select2-container")
    ) {
      $selector.select2("destroy");
    }

    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      placeholder: this.placeholder,
    });

    const that = this;

    $selector.on("select2:select", function (e) {
      const riskClass = {
        name: e.params.data.text,
      };
      const riskTypeName = e.params.data.title;
      $(this).val("");
      $(this).trigger("change.select2");
      store.dispatch("changeSelectRiskClass", {
        riskClass,
        riskTypeName,
        riskCategory,
      });

      that.$emit("changeRiskCategoryDone", that.name);
    });
  },
  updated() {
    const $selector = $(`#${this.objId}`);
    if (
      ("undefined" !== typeof $selector.data("select2"))
      && $selector.next().hasClass("select2-container")
    ) {
      $selector.next().remove();
    }

    const $copyExcursionModal = $(".copy-excursion-modal");
    if (0 < $copyExcursionModal.length) {
      $selector.select2({
        matcher: this.$app.selectCustom.matchCustom,
        placeholder: this.placeholder,
        dropdownParent: $copyExcursionModal,
      });
    } else {
      $selector.select2({
        matcher: this.$app.selectCustom.matchCustom,
        placeholder: this.placeholder,
      });
    }
  },
  destroyed: function () {
    const $selector = $(`#${this.objId}`);
    $selector.select2("destroy");
  },
};
</script>

<template>
  <v-card flat class="tab-card-client">
    <v-btn
      @click.native="clientIndividualModalDisplay('new','')"
      id="newClient"
      class="normal-btn white--text create-new-client-button primary"
    >Create New Client</v-btn>
    <v-layout class="search">
      <v-flex xs2 class="filter-by">FILTER BY:</v-flex>
      <v-flex xs4>
        <v-text-field
          v-model="searchClientKeyword"
          label="Search"
          append-icon="search"
          class="excursion-filter-search excursion-input"
          single-line
          type="text"
          id="clientSearch"
          @click:append="getClientFilterData"
          @keyup.enter="getClientFilterData"
        ></v-text-field>
      </v-flex>
    </v-layout>
      <v-data-table
        :headers="clientHeaders"
        :items="clientList"
        :options.sync="pagination"
        class="elevation-2 excursion-table"
        :footer-props="footerProps"
        :server-items-length="clientListTotal"
        :loading="loading"
        no-data-text="No client was found"
      >
        <template slot="item" slot-scope="props">
          <tr>
            <td class="excursion-name-cell">
              <a
                  @click="clientIndividualModalDisplay('update',props.item.sid)"
                  class="client-id-label"
                  id="updateClient"
              >{{props.item.sid}}</a>
            </td>
            <td class="text-xs-left">{{ props.item.metadata.name }}</td>
            <td class="text-xs-left">{{
                props.item.parent_client_metadata &&
                props.item.parent_client_metadata.name ?
                    props.item.parent_client_metadata.name : ""}}
            </td>
            <td class="text-xs-left">
              <v-tooltip left color="#ffffff">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                      :id="getClientEnableButtonId(props.item.sid)"
                      v-on="{ ...tooltip }"
                      icon
                      @click.native="
                    displayClientEnabledConfirmationDialog(props.item.sid, props.item.enabled)
                  "
                  >
                    <i v-if="props.item.enabled" class="fas fa-check action-tooltip-icon"></i>
                    <i v-if="!props.item.enabled" class="fas fa-ban action-tooltip-icon"></i>
                  </v-btn>
                </template>
                <span v-if="props.item.enabled" class="action-tooltip-content">Disable</span>
                <span v-if="!props.item.enabled" class="action-tooltip-content">Enable</span>
              </v-tooltip>
            </td>
          </tr>
        </template>
      </v-data-table>
    <client-individual-modal
      :propType="clientModalType"
      :propClientSid="clientModalClientSid"
      @reloadClients="getClientFilterData"
      :propClientIndividualModalRefreshed="clientIndividualModalRefreshed"
      v-if="clientIndividualModalDisplayed"
    />
    <div>
      <v-dialog v-model="clientEnablementConfirmationDialogDisplayed" persistent max-width="490">
        <v-card>
          <v-card-text>
            {{clientEnablementConfirmationDialogContent}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="client-list--client-enablement-confirmation-dialog--confirmation-button"
              class="normal-btn white--text primary"
              @click.native="confirmClientEnabledToggle()"
            >{{clientEnablementConfirmationDialogButtonText}}</v-btn>
            <v-btn
              id="client-list--client-enablement-confirmation-dialog--cancellation-button"
              class="normal-btn"
              @click.native="clientEnablementConfirmationDialogDisplayed = false"
            >Cancel</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      clientEnablementConfirmationDialogDisplayed: false,
      clientEnablementConfirmationDialogContent: "",
      clientEnablementConfirmationDialogButtonText: "",
      clientEnablementConfirmationDialogClientSid: "",
      clientEnablementConfirmationDialogEnabled: true,
      search: "",
      footerProps: {
        'items-per-page-options': [
          10,
          30,
          50,
        ]
      },
      pagination: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [false],
        sortBy: [''],
      },
      clientHeaders: [
        {
          text: "Client ID",
          value: "sid",
          class: "header",
        },
        {
          text: "Client Name",
          value: "client.metadata.name",
          class: "header",
        },
        {
          text: "Organisation Name",
          value: "OrganisationName",
          class: "header",
        },
        {
          text: "Action",
          value: "item.enabled",
          class: "header",
        },
      ],
      client: {},
      searchClientKeyword: "",
      clientModalType: "new",
      clientModalClientSid: "",
      clientIndividualModalDisplayed: false,
      clientIndividualModalRefreshed: true,
    };
  },
  methods: {
    async getClientFilterData() {
      this.$store.state.clientList.loading = true;
      const filterParam = {
        s: this.searchClientKeyword,
        page: this.pagination.page,
        length: this.pagination.itemsPerPage,
        direction: false === this.pagination.sortDesc[0] ? "ASC" : "DESC",
      };

      Object.keys(filterParam).forEach((key) => {
        if ("" === filterParam[key]) {
          delete filterParam[key];
        }
      });
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        filterParam,
      };

      await this.$store.dispatch("getClientList", argHash);
    },
    async confirmClientEnabledToggle() {
      const store = this.$store;
      const requestParam = {
        "enabled": this.clientEnablementConfirmationDialogEnabled,
      };
      const argHash = {
        sid: this.clientEnablementConfirmationDialogClientSid,
        payload: requestParam,
      };

      await this.$app.stbApiAdapter.putClientEnabled(argHash);

      store.dispatch("toggleClientEnabled", this.clientEnablementConfirmationDialogClientSid);

      this.clientEnablementConfirmationDialogDisplayed = false;
    },
    clientIndividualModalDisplay(type, clientSid, client) {
      this.$app.authAgent.enforceUserAuthStrategy(this.$route);
      this.clientModalType = type;
      this.clientModalClientSid = clientSid;
      this.clientIndividualModalDisplayed = true;
      this.clientIndividualModalRefreshed = !this.clientIndividualModalRefreshed;
    },
    displayClientEnabledConfirmationDialog(sid, enabled) {
      if (enabled) {
        this.clientEnablementConfirmationDialogContent = "Are you sure you want to disable this client?";
        this.clientEnablementConfirmationDialogButtonText = "Disable";
      } else {
        this.clientEnablementConfirmationDialogContent = "Are you sure you want to enable this client?";
        this.clientEnablementConfirmationDialogButtonText = "Enable";
      }
      this.clientEnablementConfirmationDialogClientSid = sid;
      this.clientEnablementConfirmationDialogDisplayed = true;
      this.clientEnablementConfirmationDialogEnabled = !enabled;
    },
    getClientEnableButtonId(sid) {
      return `client-list--client-enablement-confirmation-dialog-${sid}`;
    },
  },
  computed: {
    clientList() {
      return this.$store.state.clientList.data;
    },
    loading() {
      return this.$store.state.clientList.loading;
    },
    clientListTotal() {
      return this.$store.state.clientList.total;
    },
  },
  mounted() {
  },
  watch: {
    pagination: {
      handler() {
        this.getClientFilterData();
      },
    },
  },
};
</script>

<template>
  <div>
    <div v-for="(category, index) in riskCategories" :key="index" class="category-div">
      <div v-if="categorySectionDisplayed(category.name)">
        <div>
          <v-layout align-center justify-space-between style="display:flex;">
            <div>
              <v-layout align-center justify-space-between class="category-section">
                <v-icon
                  class="icon"
                  @click.stop="toggleCategory(index)"
                >{{!category.displayed ? "keyboard_arrow_left" : "keyboard_arrow_down"}}</v-icon>
                <div class="name" @click.stop="toggleCategory(index)">{{category.name}}</div>
                <div
                  class="quantity ml-3"
                  v-if="0 > excludedRiskCategories.indexOf(category.name)"
                >{{typesCount(category.name)}} Types</div>
                <div
                  class="quantity ml-3"
                  v-if="'Activities' === category.name"
                >{{activityTypesCount}} Types</div>
                <div
                  v-if="highRiskCategoryName !== category.name"
                  class="quantity ml-3"
                >{{approvedProvidersCount(category.name)}} Providers</div>
              </v-layout>
            </div>
            <div style="display: flex;">
              <complete-checklist
                :categoryName="category.name"
                :checklistViewModeEnabled="false"
                :formControlEnabled="formControlEnabled"
                :associatedChecklistCompleted="getTicked(category)"
                :elementDisabled="
                  (
                    (0 >= typesCount(category.name))
                    && (category.name !== excludedRiskCategories[1])
                  )
                  || (
                    (0 >= approvedProvidersCount(category.name))
                    && (category.name === excludedRiskCategories[1])
                  )
                  || (
                    checklistFetchActionInProgress
                    && (checklistFetchActionCategoryName === category.name)
                  )
                "
                :checklistFetchActionCategoryName="checklistFetchActionCategoryName"
                :checklistFetchActionInProgress="checklistFetchActionInProgress"
                v-if="'Activities' !== category.name"
                :saveDraft="saveDraft"
              />
              <view-policy
                :categoryName="category.name"
                :elementDisabled="
                  (
                    (0 >= typesCount(category.name))
                    && (category.name !== excludedRiskCategories[1])
                  )
                  || (
                    (0 >= approvedProvidersCount(category.name))
                    && (category.name === excludedRiskCategories[1])
                  )
                "
                v-if="
                  (
                    excursionPolicyViewActionEnabled
                    && ('Activities' !== category.name)
                  )
                  || ('Outdoor Education Providers' === category.name)
                "
              />
              <view-policy
                :categoryName="category.name"
                :elementDisabled="0 >= activityTypesCount"
                :activitySupervisorScrollDisplayed="false"
                v-if="excursionPolicyViewActionEnabled && ('Activities' === category.name)"
              />
            </div>
          </v-layout>
        </div>
        <div :hidden="!category.displayed">
          <v-layout>
            <select-control
              background-color="#EEEEEE"
              :name="category.name"
              :id="getTypeControlId(category.name)"
              v-if="
                (0 > excludedRiskCategories.indexOf(category.name))
                && (category.name !== highRiskCategoryName)
              "
              :formControlEnabled="formControlEnabled"
              @changeRiskCategoryDone="changeRiskCategory"
            ></select-control>
            <high-risk
              background-color="#EEEEEE"
              :name="category.name"
              :id="getTypeControlId(category.name)"
              v-if="category.name === highRiskCategoryName"
              :formControlEnabled="formControlEnabled"
              @changeRiskCategoryDone="changeRiskCategory"
            ></high-risk>
            <activity-risk
              v-if="'Activities' === category.name"
              :formControlEnabled="formControlEnabled"
              :checklistAndPolicyDisplayed="true"
              :saveDraft="saveDraft"
            ></activity-risk>
          </v-layout>
          <v-layout class="sub-category-section">
            <v-flex
              xs12
              providers
              label-padding
              v-if="0 < getProvidersByCategoryCount(category.name)"
            >
              Providers
              <v-tooltip right color="#ffffff">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    v-if="hasNegativeProviderAnswer(category.name)"
                    v-on="{ ...tooltip }"
                    class="provider-tooltip-button"
                    icon
                  >
                    <i class="fas fa-info-circle"></i>
                  </v-btn>
                </template>
                <span class="provider-tooltip-content">{{negativeProviderAnswerHint}}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <v-layout
            class="move-top-risk-title"
            v-if="0 < getProvidersByCategoryCount(category.name)"
          >
            <providers
              :categoryName="category.name"
              :formControlEnabled="formControlEnabled"
            ></providers>
          </v-layout>
          <v-flex
            xs12
            v-if="excursionPersonResponsibleEnabled"
            class="person-responsible"
            :style="{
              'padding-top': (0 >= getProvidersByCategoryCount(category.name))
                ? '8px'
                : '0px'
            }"
          >Person Responsible</v-flex>
          <v-flex
            xs12
            class="excursion-detail-text"
            style="width:calc(100% - 4px);"
            v-if="excursionPersonResponsibleEnabled"
          >
            <v-text-field
              solo
              v-model="category.personResponsible"
              @keyup="updatePersonResponsible(category)"
              :placeholder="personResponsiblePlaceholder"
              class="name-textbox excursion-input"
              :disabled="
                !formControlEnabled
                || (
                  (0 >= approvedProvidersCount(category.name))
                  && ('Outdoor Education Providers' === category.name)
                )
              "
              :id="`personResponsible-${getTypeControlId(category.name)}`"
            />
          </v-flex>
          <div
            v-if="
              (0 >= getProvidersByCategoryCount(category.name))
              && (category.name !== highRiskCategoryName)
            "
            style="padding-bottom: 18px;">
          </div>
        </div>
      </div>
    </div>
    <div v-if="!riskCategories || (0 >= riskCategories.length)" class="excursion-waiting">
      <v-progress-circular
        :size="30"
        color="rgb(29, 175, 236)"
        class="loading-img"
        indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
import riskCategoryLabel from "../lib/const/riskCategoryLabel";

export default {
  props: {
    saveDraft: {
      type: Function,
    },
    riskCategories: Array,
    formControlEnabled: Boolean,
  },
  data() {
    return {
      riskClasses: ["Swimming", "Boarding"],
      personResponsiblePlaceholder: "Enter the full name or first and last initials of the person responsible for this Risk Category",
      excludedRiskCategories: ["Activities", "Outdoor Education Providers"],
      negativeProviderAnswerHint: "There are approved providers that have not completed all requested information.\nPlease speak with your school’s administration staff for more information.",
      checklistFetchActionCategoryName: "",
      checklistFetchActionCount: 0,
      checklistFetchActionDoneCount: 0,
      highRiskCategoryName: "",
    };
  },
  computed: {
    typesCount() {
      return function (categoryName) {
        return this.$store.getters.getTypesCountFromSelectedClasses(categoryName);
      };
    },
    activityTypesCount() {
      return this.$store.getters.getTypesCountForActivity;
    },
    riskTypes() {
      return this.$store.state.riskTypes;
    },
    approvedProvidersCount() {
      return function (categoryName) {
        return this.$store.getters.getSelectedApprovedProvidersCount(categoryName);
      };
    },
    excursionPersonResponsibleEnabled() {
      return this.$store.state.excursionPersonResponsibleEnabled;
    },
    getTypeControlId() {
      return function (categoryName) {
        return categoryName.replace(/\s*/g, "");
      };
    },
    getProvidersByCategoryCount() {
      return function (categoryName) {
        return this.$store.getters.getProvidersByCategory(categoryName).length;
      };
    },
    hasNegativeProviderAnswer() {
      return function (categoryName) {
        const providers = this.$store.getters.getProvidersByCategory(categoryName);
        if (providers && (0 < providers.length)) {
          return providers.some(provider => !provider.answer);
        }
        return false;
      };
    },
    excursionPolicyViewActionEnabled() {
      return this.$store.state.excursionPolicyViewActionEnabled;
    },
    categories() {
      return this.$store.state.excursion.categories;
    },
    checklistFetchActionInProgress() {
      return this.$store.state.checklistFetchActionInProgress;
    },
  },
  methods: {
    toggleCategory(index) {
      this.riskCategories[index].displayed = !this.riskCategories[index]
      .displayed;
    },
    updatePersonResponsible(category) {
      this.$store.commit("updatePersonResponsible", category);
    },
    getTicked(item) {
      if (this.categories && (0 < this.categories.length)) {
        const categoryItem = this.categories.find(
          category => category.CompleteChecklist && (category.name === item.name),
        );
        if (categoryItem && categoryItem.CompleteChecklist) {
          item.CompleteChecklist = categoryItem.CompleteChecklist;
        }
      }

      let allBox = 0;
      let allChecked = 0;

      if (
        item
        && item.CompleteChecklist
        && (0 < item.CompleteChecklist.length)
        && item.CompleteChecklist[0].checklist
        && item.CompleteChecklist[0].checklist.Strategy
        && item.CompleteChecklist[0].checklist.Strategy.data
      ) {
        const data = item.CompleteChecklist[0].checklist.Strategy.data;
        const dataKey = Object.keys(data);

        if (
          data[dataKey]
          && data[dataKey].children
          && (0 < data[dataKey].children.length)
        ) {
          data[dataKey].children.forEach((checkData) => {
            const checklist = checkData.children;

            if (checklist && (0 < checklist.length)) {
              checklist.forEach((box) => {
                if (box.children && (0 < box.children.length)) {
                  const boxes = box.children.filter((ck) => {
                    return ("checkbox" === ck.contentType);
                  });
                  if (boxes && (0 < boxes.length)) {
                    allBox += boxes.length;
                  }

                  const checked = box.children.filter((ck) => {
                    return (ck.ticked && ("checkbox" === ck.contentType));
                  });

                  if (checked && (0 < checked.length)) {
                    allChecked += checked.length;
                  }
                } else {
                  if ("checkbox" === box.contentType) {
                    allBox += 1;
                    if (box.ticked) {
                      allChecked += 1;
                    }
                  }
                }
              });
            }
          });
        }
      } else {
        allChecked = -1;
      }
      if (
        item.CompleteChecklist
        && (0 < item.CompleteChecklist.length)
        && item.CompleteChecklist[0].checklist
      ) {
        const title = item.CompleteChecklist[0].checklist.title;

        const typesCount = this.typesCount(title);
        if (0 === typesCount) {
          this.deleteTickedRiskCategoryChecklists(title);
        } else {
          this.updateTickedRiskCategoryChecklists(title, allBox === allChecked);
        }
      }
      return (allBox === allChecked);
    },
    updateTickedRiskCategoryChecklists(checklistTitle, ticked) {
      this.$store.state.tickedRiskCategoryChecklists[checklistTitle] = ticked;
    },
    deleteTickedRiskCategoryChecklists(checklistTitle) {
      delete this.$store.state.tickedRiskCategoryChecklists[checklistTitle];
    },
    categorySectionDisplayed(categoryName) {
      let sectionDisplayed = false;

      if (this.highRiskCategoryName === categoryName) {
        sectionDisplayed = this.$store.state.excursionHighRiskStudentMedicalEnabled;
      } else {
        sectionDisplayed = (
          (categoryName !== this.excludedRiskCategories[1])
          || (0 < this.getProvidersByCategoryCount(categoryName))
        );
      }

      return sectionDisplayed;
    },
    async changeRiskCategory(tlrcName) { // tlrc stands for: top level risk category
      this.checklistFetchActionCategoryName = tlrcName;
      this.$store.state.checklistFetchActionInProgress = true;
      this.checklistFetchActionCount += 1;

      const queryParam = this.$route.query;
      const tlrc = this.$store.getters.getTlrc(tlrcName);
      const excursionCommonAdapter
        = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
      const allParams = excursionCommonAdapter.getRiskCategoryParams();
      const params = allParams.filter(p => (0 <= p.indexOf(tlrcName)));

      const that = this;

      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        store: this.$store,
        queryParam,
        params,
        risk: tlrc,
        resolve: function () {
          that.checklistFetchActionDoneCount += 1;
          if (that.checklistFetchActionCount === that.checklistFetchActionDoneCount) {
            that.$store.state.checklistFetchActionInProgress = false;
            that.checklistFetchActionCount = 0;
            that.checklistFetchActionDoneCount = 0;
          }
        },
      };
      await this.$store.dispatch("changeRiskCategoryCompleteChecklist", argHash);
    },
  },
  async mounted() {
    this.highRiskCategoryName = riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS;
    const argHash = {
      stbApiAdapter: this.$app.stbApiAdapter,
      store: this.$store,
      queryParam: this.$route.query,
    };
    await this.$store.dispatch("getProviders", argHash);
  },
  updated() {
    this.$app.excursionCommon.setListItemId(".v-list__item__title");
  },
};
</script>

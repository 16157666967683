<template>
  <div class="excursion-history" v-if="historyDisplayed">
    <hr class="mt-4 hr"/>
    <v-expansion-panels>
      <v-expansion-panel>
        <v-expansion-panel-header>
          History
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(item, index) in historyItems">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-list-item-title>{{item["audit-metadata"].userDisplayName}}</v-list-item-title>
                <v-list-item-subtitle class="detail-line">
                  {{item["event-type"]}} | {{item.metadata.status}}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text
                    class="list-action-created-date"
                >
                  {{item.timestamp}}
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  computed: {
    historyDisplayed() {
      return (
        this.$store.state.excursionHistoryDisplayed
        && (0 < this.$store.state.excursion.id)
        && this.$store.state.excursion.auditList
        && (0 < this.$store.state.excursion.auditList.length)
      );
    },
    historyItems() {
      return this.$store.state.excursion.auditList;
    },
  },
  async mounted() {
    if (0 < this.$store.state.excursion.id) {
      const argHash = {
        id: this.$store.state.excursion.id,
        query: this.$route.query,
      };
      const cbResponse = await this.$app.stbApiAdapter.getAuditExcursions(argHash);
      if (!cbResponse.error) {
        this.$store.commit("setExcursionAuditList", cbResponse);
      }
    }
  },
};
</script>

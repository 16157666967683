<template>
  <v-card flat class="tab-card">
    <div class="new-client-button-clear"></div>
    <v-layout>
      <v-expansion-panels>
        <v-expansion-panel class="search">
          <v-expansion-panel-header>
            <div id="excursion-list--filter-by-header">
              <v-icon class="fa fa-filter"></v-icon>
              FILTER BY
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <v-subheader>General</v-subheader>
              <v-layout ml-3>
                <v-flex xs5>
                  <v-text-field
                      v-model="searchExcursionId"
                      label="Excursion ID"
                      class="excursion-filter-id excursion-filter-search excursion-input"
                      single-line
                      type="text"
                      @keyup.enter="getExcursionFilterData"
                      :error-messages="excursionIdErrors"
                      @input="$v.searchExcursionId.$touch()"
                      @blur="$v.searchExcursionId.$touch()"
                  ></v-text-field>
                </v-flex>
                <v-flex xs5>
                  <v-text-field
                      v-model="searchExcursionKeyword"
                      id="searchExcursionText"
                      label="Search"
                      single-line
                      @click:append="getExcursionFilterData"
                      @keyup.enter="getExcursionFilterData"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout ml-3>
                <v-flex xs5>
                  <v-select
                      placeholder="Select Excursion Type"
                      :items="excursionTypeChoiceList"
                      v-model="searchExcursionType"
                      @change="getExcursionFilterData"
                  >
                  </v-select>
                </v-flex>
                <v-flex xs5>
                  <v-select
                      placeholder="Select Excursion Status"
                      :items="excursionStatus"
                      v-model="searchExcursionStatus"
                      @change="getExcursionFilterData"
                  >
                  </v-select>
                </v-flex>
              </v-layout>
              <v-subheader v-if="excursionListFilterSectionDisplayed">Group</v-subheader>
              <v-layout ml-3 v-if="excursionListFilterSectionDisplayed">
                <v-flex xs12>
                  <v-autocomplete
                      label="Select one or more Clients"
                      :items="clientItems"
                      v-model="searchClientSids"
                      multiple
                      :search-input.sync="searchClients"
                      cache-items
                      clearable
                      chips
                      hide-details
                      hide-selected
                      :deletable-chips="true"
                      id="excursion-list--search-clients"
                      item-text="name"
                      item-value="sid"
                      @change.native="handleClientChange"
                      ref="selectClient"
                      @input="searchClients=null"
                  >
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-subheader>Integration</v-subheader>
              <v-layout ml-3>
                <v-flex xs3>
                  <v-text-field
                      v-model="searchVendorExcursionSid"
                      label="Vendor Excursion SID"
                      single-line
                      type="text"
                      @keyup.enter="getExcursionFilterData"
                      id="excursion-list--search-vendor-excursion-sid"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout ml-3 mt-3>
                <v-btn
                    class="normal-btn"
                    color="primary"
                    id="excursion-list--search-button"
                    @click.native="getExcursionFilterData"
                >
                  <v-icon>search</v-icon>Search
                </v-btn>
              </v-layout>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-layout>
    <v-data-table
      :headers="getExcursionTableHeaders()"
      :items="excursionList"
      :options.sync="pagination"
      class="elevation-2 excursion-table table-responsive"
      :footer-props="footerProps"
      :server-items-length="excursionListTotal"
      :loading="loading"
      :no-data-text="noDataText"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="text-xs-center">
            <a :href="`/excursions/${props.item.id}`">{{props.item.id}}</a>
          </td>
          <td class="excursion-name-cell white-wrap-normal">
            <div class="excursion-list--padding-top">{{props.item.name}}</div>
            <div class="excursion-list-created">
              <div class="excursion-list--padding-bottom">
              <span>
                <v-tooltip right>
                  <template v-slot:activator="{ on: tooltip }">
                    <span v-on="{ ...tooltip }">
                      {{userSegmentFromAuthor(props.item.user_email, props.item.author_display_name)}}
                    </span>
                  </template>
                  <span>
                    {{emailAddressFromAuthor(
                      props.item.user_email,
                      props.item.author_display_name
                  )}}
                  </span>
                </v-tooltip>
                ,&nbsp;{{props.item.created_at}}
              </span>
              </div>
            </div>
          </td>
          <td class="text-xs-center">
            <v-chip
                color="primary"
                outlined
                class="excursion-list--chip-size excursion-list--type"
            >
              {{props.item.type}}
            </v-chip>
          </td>
          <td class="text-xs-center">
            <v-chip
                color="#555757"
                outlined
                label
                class="excursion-list--chip-size excursion-list--status"
            >
              {{
                props.item.status
                    ? (props.item.status.substr(0,1).toUpperCase() + props.item.status.substr(1))
                    : ""
              }}
            </v-chip>
          </td>
          <td class="text-xs-left" v-if="excursionListClientColumnDisplayed">
            {{props.item.client_name}}
          </td>
          <td class="text-xs-left" v-if="excursionListOrganisationNameColumnDisplayed">
            {{props.item.parent_client_name}}
          </td>
          <td class="text-xs-center" v-if="excursionCopyDisplayed">
            <v-tooltip
                left
                color="#ffffff"
            >
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                    v-on="{ ...tooltip }"
                    icon
                    @click.native="openExcursionBeforeCopyDialog(props.item.id, props.item.type)"
                    :id="`excursion-list--btn-copy-${props.index}`"
                >
                  <i class="fas fa-copy copy-icon"></i>
                </v-btn>
              </template>
              <span style="color:gray;" class="action-tooltip-content">Copy</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <excursion-copy-modal
      :propExcursionCopyModalRefreshed="propExcursionCopyModalRefreshed"
      :excursionTypeName="excursionTypeName"
      :excursionId="excursionId"
      :excursionTemplateFlag="convertToTemplateEnabled"
      v-if="excursionCopyModalDisplayed"
    ></excursion-copy-modal>
    <div>
      <v-dialog
        v-model="excursionBeforeCopyConfirmationDialogDisplayed"
        persistent
        max-width="490"
      >
        <v-card>
          <v-card-text>
            <v-layout>
              {{excursionBeforeCopyConfirmationDialogMessage}}
            </v-layout>
            <v-layout class="excursion-convert-to-template">
              <v-flex xs1>
                <v-checkbox
                  v-model="convertToTemplateEnabled"
                  class="excursion-list-checkbox"
                  id="convertToTemplateEnabled"
                ></v-checkbox>
              </v-flex>
              <v-flex
                @click="toggleConvertToEmplate()"
                xs11
                class="excursion-list-checkbox-label"
              >Convert to template</v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              id="excursion-copy-modal--continue"
              class="normal-btn white--text primary"
              @click.native="openExcursionCopy()"
            >
              Continue
            </v-btn>
            <v-btn
              id="excursion-copy-modal--cancel"
              class="normal-btn"
              @click="closeExcursionCopy()"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";
import { numeric } from "vuelidate/lib/validators";
import excursionTypeInfo from "../lib/const/excursionTypeInfo";
import excursionStatus from "../lib/const/excursionStatus";
import trackableEvent from "../lib/const/trackableEvent";

export default {
  mixins: [validationMixin],
  validations: {
    searchExcursionId: {
      numeric,
    },
  },
  props: ["excursionTemplateFlag"],
  data() {
    return {
      propExcursionCopyModalRefreshed: true,
      excursionTypeName: "",
      excursionId: 0,
      excursionCopyModalDisplayed: false,
      search: "",
      footerProps: {
        'items-per-page-options': [
          10,
          30,
          50,
        ]
      },
      pagination: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [true],
        sortBy: ['']
      },
      excursionHeaders: [
        {
          text: "ID",
          value: "id",
          class: "header text-xs-center",
          sortable: false,
        },
        {
          text: "Details",
          value: "name",
          class: "header"
        },
        {
          text: "Type",
          value: "typeId",
          class: "header",
          align: "center",
          sortable: false,
        },
        {
          text: "Status",
          value: "status",
          class: "header",
          align: "center",
          sortable: false,
        },
      ],
      searchClientName: "",
      searchExcursionId: "",
      searchExcursionKeyword: "",
      searchVendorExcursionSid: "",
      searchExcursionType: "",
      searchExcursionStatus: "",
      searchClientSids: "",
      noDataText: "No excursion was found",
      excursionBeforeCopyConfirmationDialogDisplayed: false,
      excursionBeforeCopyConfirmationDialogMessage: "Only the risk profile will be copied. All content and checked items will be created from scratch.",
      convertToTemplateEnabled: false,
      searchClients: "",
      clientSubdomain: "",
    };
  },
  methods: {
    getExcursionTableHeaders() {
      const header = JSON.parse(JSON.stringify(this.excursionHeaders));
      if (this.excursionListClientColumnDisplayed) {
        header.splice(6, 0, {
          text: "Client",
          value: "client.name",
          class: "header",
        });
      }

      if (this.excursionListOrganisationNameColumnDisplayed) {
        header.push({
          text: "Organisation",
          value: "OrganisationName",
          class: "header",
        });
      }
      if (this.excursionCopyDisplayed) {
        header.push({
          text: "Action",
          value: "id",
          class: "header",
          align: "center",
          sortable: false,
        });
      }

      return header;
    },
    async getExcursionFilterData() {
      if (!this.$v.$invalid) {
        this.$store.state.excursionsList.loading = true;
        const filterClientSids = this.searchClientSids && (0 < this.searchClientSids.length)
          ? this.searchClientSids
          : "";
        const filterParam = {
          "excursion-template-flag": this.excursionTemplateFlag,
          s: this.searchExcursionKeyword,
          "s-excursion-id": this.searchExcursionId,
          "s-excursion-type": this.searchExcursionType,
          "s-vendor-client-sid": this.searchVendorExcursionSid,
          "s-excursion-status": this.searchExcursionStatus,
          "s-client-sids": filterClientSids,
          page: this.pagination.page,
          length: this.pagination.itemsPerPage,
          direction: false === this.pagination.sortDesc[0] ? "ASC" : "DESC",
        };

        Object.keys(filterParam).forEach((key) => {
          if ("" === filterParam[key]) {
            delete filterParam[key];
          }
        });

        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          filterParam,
        };

        await this.$store.dispatch("getExcursionList", argHash);
        this.$store.state.excursionsList.loading = false;
      }
    },
    openExcursionBeforeCopyDialog(id, type) {
      this.excursionTypeName = type;
      this.excursionId = id;
      this.excursionBeforeCopyConfirmationDialogDisplayed = true;
      this.convertToTemplateEnabled = false;
    },
    openExcursionCopy() {
      this.excursionBeforeCopyConfirmationDialogDisplayed = false;
      this.excursionCopyModalDisplayed = true;
      this.propExcursionCopyModalRefreshed = !this.propExcursionCopyModalRefreshed;
    },
    closeExcursionCopy() {
      this.excursionBeforeCopyConfirmationDialogDisplayed = false;
      this.convertToTemplateEnabled = false;
    },
    toggleConvertToEmplate() {
      this.convertToTemplateEnabled = !this.convertToTemplateEnabled;
    },
    capitalize(str) {
      return str.toLowerCase()
      .split(" ")
      .map(s => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    },
    async handleClientChange() {
      await this.getExcursionFilterData();
    },
    userSegmentFromAuthor(email, authorDisplayName) {
      let localEmail = email ? email : authorDisplayName;
      if (localEmail) {
        localEmail = localEmail.substr(0, localEmail.indexOf("@"));
      } else {
        localEmail = "";
      }
      return localEmail;
    },
    emailAddressFromAuthor(email, authorDisplayName) {
      let localEmail = email ? email : authorDisplayName;
      if (!localEmail) {
        localEmail = "";
      }
      return localEmail;
    },
    async filterClients(val) {
      const excursionListAllClientSuggestionDisplayed
        = this.$store.state.excursionListAllClientSuggestionDisplayed;

      val = val ? val : "";

      this.$store.state.clientUserClientList.loading = true;
      const filterParam = {
        s: val,
        page: this.pagination.page,
        length: 5,
        direction: (false === this.pagination.sortDesc[0]) ? "ASC" : "DESC",
      };

      Object.keys(filterParam).forEach((key) => {
        if ("" === filterParam[key]) {
          delete filterParam[key];
        }
      });
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        filterParam,
      };

      if (excursionListAllClientSuggestionDisplayed && !this.parentClientSiteFlag) {
        await this.$store.dispatch("getClientList", argHash);
      } else {
        await this.$store.dispatch("getClientUserClientList", argHash);
      }

      this.$store.state.clientUserClientList.loading = false;
    },
  },
  computed: {
    excursionIdErrors() {
      const errors = [];
      if (!this.$v.searchExcursionId.$dirty) return errors;
      if (!this.$v.searchExcursionId.numeric) {
        errors.push("Excursion ID must be a number");
      }
      return errors;
    },
    excursionListClientColumnDisplayed() {
      return (
        (this.$store.state.excursionListClientColumnDisplayed && !this.clientSubdomain)
        || (this.clientSubdomain && this.parentClientSiteFlag)
      );
    },
    excursionListFilterSectionDisplayed() {
      return this.$store.state.excursionListFilterSectionDisplayed;
    },
    excursionList() {
      return this.$store.state.excursionsList.data;
    },
    excursionCopyDisplayed() {
      return (
        this.$store.state.excursionCopyActionEnabled
        && this.$store.state.copyExcursionButtonDisplayed
      );
    },
    loading() {
      return this.$store.state.excursionsList.loading;
    },
    excursionListTotal() {
      return this.$store.state.excursionsList.total;
    },
    clientItems() {
      let items;
      if (
        this.$store.state.excursionListAllClientSuggestionDisplayed
        && !this.parentClientSiteFlag
      ) {
        items = this.$store.state.clientList.data.map((clientItem) => {
          return {
            sid: clientItem.sid,
            name: `${clientItem.sid} - ${clientItem.metadata.name}`,
          };
        });
      } else {
        items = this.$store.state.clientUserClientList.items;
      }
      return items;
    },
    excursionTypeChoiceList() {
      return [
        excursionTypeInfo.DAY_EXCURSION.label,
        excursionTypeInfo.OVERNIGHT_EXCURSION.label,
        excursionTypeInfo.INTERNATIONAL_EXCURSION.label,
      ];
    },
    excursionStatus() {
      return [
        this.capitalize(excursionStatus.DRAFT),
        this.capitalize(excursionStatus.SUBMITTED),
        this.capitalize(excursionStatus.APPROVED),
      ];
    },
    excursionListOrganisationNameColumnDisplayed() {
      const clientParentId = this.$store.state.clientParentId;
      return (
        !this.clientSubdomain
        || (this.clientSubdomain && clientParentId)
      );
    },
    parentClientSiteFlag() {
      return this.$store.state.parentClientSiteFlag;
    },
  },
  async mounted() {
    const hostname = window.location.hostname;
    const uiBaseDomain = this.$app.env.UI_BASE_DOMAIN;
    this.clientSubdomain = hostname.substring(0, hostname.indexOf(uiBaseDomain) - 1);

    this.$app.pluginCommonAdapter.registerTrackableEvent(trackableEvent.EXCURSION_LIST_LOADED);
    const getExcursionsArgHash = {
      stbApiAdapter: this.$app.stbApiAdapter,
      store: this.$store,
      excursionId: 0,
    };
    await this.$store.dispatch("getExcursions", getExcursionsArgHash);
  },
  watch: {
    pagination: {
      handler() {
        this.getExcursionFilterData();
      },
      deep: true,
    },
    excursionTemplateFlag: function () {
      this.getExcursionFilterData();
    },
    async searchClients(val) {
      await this.filterClients(val);
    },
  },
};
</script>

<template>
<div>
  <div v-if="mandatoryChecklist!==undefined && mandatoryChecklist.length>0">
     <v-layout v-for="item in mandatoryChecklist" :key="item.name">
      <v-layout>
        <v-flex xs12 class="form-label mandatory-checklist-sub-header">
          {{item.title}}
        </v-flex>
        <view-checklist
          :title="item.title"
          :saveDraft="saveDraft"
          :formControlEnabled="formControlEnabled"
        />
      </v-layout>
    </v-layout>
  </div>
  <div class="excursion-waiting" v-if="!mandatoryChecklist || mandatoryChecklist.length <= 0">
    <v-progress-circular
      :size="30"
      color="rgb(29, 175, 236)"
      class="loading-img"
      indeterminate
    ></v-progress-circular>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    saveDraft: {
      type: Function,
    },
    formControlEnabled: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    mandatoryChecklist() {
      this.$store.commit("updateMandatoryLoadingCompleted", true);
      const mandatoryChecklist = this.$store.state.excursion.mandatoryChecklist;
      if (!mandatoryChecklist || 0 === mandatoryChecklist.length) {
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: this.$route.query,
        };
        this.$store.dispatch("getMandatoryChecklist", argHash);
      } else {
        const mandatory = this;
        this.$app.lodash.delay(function () {
          mandatory.$store.commit("updateMandatoryLoadingCompleted", false);
        }, 200);
      }
      return mandatoryChecklist.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
  },
};
</script>

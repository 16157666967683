<template>
  <cs-view single-view>
    <div class="content" slot="content">
      <page-loading-animation :displayed="pageLoadingAnimationDisplayed()"></page-loading-animation>
      <v-dialog
        v-model="studentMedicalConditionsLoadingAnimationDisplayed"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-text>
            <div>
              Standby whilst we retrieve student medical data from your student information system.
              <br>This may take some time as we need to retrieve data
              for each individual student selected.
              <br>Please report any errors to our support team.
            </div>
            <div style="margin-top:20px; text-align:center;">
              <v-progress-circular
                :size="70"
                color="rgb(29, 175, 236)"
                class="loading-img"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="excursion-stepper excursion-builder" v-show="!pageLoadingAnimationDisplayed()">
        <v-layout
          v-show="clientLogoMarginDisplayed()"
          :class="vendorClientLogoEnabled ? 'mt-10' : 'mt-2'"
        >
        </v-layout>
        <v-layout xm xs v-show="vendorClientLogoEnabled && userAuthenticated">
          <v-layout class="client-logo">
            <img
              id="clientLogo"
              :style="{ background: clientLogoBackgroundColor }"
            >
          </v-layout>
        </v-layout>
        <form class="mt-10" v-if="showError || !userAuthenticated">
          <v-layout class="mb-20">
            <v-flex>
              <v-img  max-width="280px" :src="stbHorizontalLogo"></v-img>
            </v-flex>
          </v-layout>
          <v-layout class="mt-2 mb-3">
            <v-flex>
              You are not authorized to view Excursion Builder,
              please provide a correct auth token.
            </v-flex>
          </v-layout>
        </form>
        <form
          id="form"
          :class="vendorClientLogoEnabled ? 'mt-10' : ''"
          v-if="!showError && userAuthenticated"
        >
          <v-layout>
            <v-flex>
            </v-flex>
          </v-layout>
          <v-layout class="mb-20" v-show="stbLogoDisplayed">
            <v-img id="clientStepLogo" max-width="280px" :src="stbHorizontalLogo"></v-img>
          </v-layout>
          <v-layout class="mt-2 mb-3">
            <v-flex class="excursion-page-title-heading">
              <i class="fas fa-info-circle"></i>
              Fill in your excursion details and complete checklists
              to produce your excursion risk assessment
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="vendorExcursionSidInputEnabled">
            <v-flex xs12 class="form-label">
              Excursion Manager ID
              <span class="red--text">&ast;</span>
            </v-flex>
            <v-flex xs12 class="excursion-detail-text">
              <v-text-field
                id="excursion-builder--excursion-manager-id"
                solo
                placeholder="Excursion Manager ID"
                class="name-textbox excursion-input"
                v-model="vendorExcursionSid"
                :error-messages="vendorExcursionSidErrors"
                @input="$v.vendorExcursionSid.$touch()"
                @blur="$v.vendorExcursionSid.$touch()"
                required
              />
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="form-label safe-trip-font">
              Excursion Name
              <span class="red--text">&ast;</span>
            </v-flex>
            <v-flex xs12 class="excursion-detail-text">
              <v-text-field
                id="excursion-builder--excursion-name"
                solo
                placeholder="Name"
                class="name-textbox excursion-input"
                v-model="name"
                :error-messages="nameErrors"
                required
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
                :disabled="!formControlEnabled"
              />
            </v-flex>
          </v-layout>
          <v-layout wrap>
            <v-flex xs12 class="form-label safe-trip-font">
              Excursion Type
              <span class="red--text">&ast;</span>
              <v-tooltip right color="#ffffff"  max-width="650px">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn v-on="{ ...tooltip }" class="provider-tooltip-button" icon>
                    <i class="fas fa-info-circle"></i>
                  </v-btn>
                </template>
                <div class="type-tooltip-content">
                  <div
                    v-for="(excursionTypeInfoEntry, index) in excursionTypeInfoEntryList"
                    :key="index"
                  >
                    <!-- eslint-disable-next-line max-len -->
                    <b>{{excursionTypeInfoEntry.label}}</b> - {{excursionTypeInfoEntry.description}}
                  </div>
                </div>
              </v-tooltip>
            </v-flex>
            <v-flex xs12 d-flex  class="excursion-detail-text">
              <v-select
                id="excursion-builder--excursion-type"
                class="type-dropdown excursion-selection"
                v-model="excursionType"
                :items="excursionTypeChoiceList"
                item-text="name"
                item-value="id"
                solo
                :label="'Choose an Excursion Type'"
                :error-messages="selectErrors"
                required
                @blur="$v.excursionType.$touch()"
                @change="resetJurisdiction()"
                :menu-props="{
                  closeOnClick: false,
                  closeOnContentClick: false,
                  openOnClick: false,
                  maxHeight: 400
                }"
                :disabled="!formControlEnabled"
                :loading="excursionTypeLoaded"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout wrap v-if="showJurisdiction">
            <v-flex xs12 class="form-label safe-trip-font">
              Excursion Destination
              <span class="red--text">&ast;</span>
              <v-menu open-on-hover right offset-x max-width="650px">
                <template v-slot:activator="{ on: menu }">
                  <v-btn v-on="{ ...menu }" class="provider-tooltip-button" icon>
                    <i class="fas fa-info-circle"></i>
                  </v-btn>
                </template>
                <v-list class="jurisdiction-tooltip-content">
                  <v-list-item>
                    <p class="py-2 mb-0">
                      {{jurisdictionTooltip}}
                      Click
                      <a href="https://complispace-jira.atlassian.net/wiki/spaces/CS/pages/448056/STB+Excursion+Details" target="_blank">here</a>
                      to learn more.
                    </p>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-flex>
            <v-flex xs12 d-flex>
              <v-select
                id="excursion-stepper--excursion-jurisdiction"
                class="type-dropdown safe-trip-font excursion-selection
                  safe-excursion-detail-text"
                v-model="jurisdiction"
                :items="jurisdictions"
                solo
                :label="'Choose an Excursion State'"
                :error-messages="selectErrors"
                required
                :menu-props="{
                  closeOnClick: false,
                  closeOnContentClick: false,
                  openOnClick: false,
                  maxHeight: 400
                }"
                :disabled="!formControlEnabled"
                :loading="excursionJurisdictionLoaded"
              ></v-select>
            </v-flex>
          </v-layout>
          <hr class="mt-4 mb-4" v-if="excursionType && excursionHighRiskStudentMedicalEnabled">
          <v-layout v-if="excursionType && excursionHighRiskStudentMedicalEnabled">
            <v-flex>
              <risk-category
                :riskCategories="excursionHighRisks"
                :formControlEnabled="formControlEnabled"
                :saveDraft="saveChangesThenRevertToDraft"
              />
              <staff-student-supervision
                :formControlEnabled="formControlEnabled"
              ></staff-student-supervision>
            </v-flex>
          </v-layout>
          <hr class="mt-4 mb-4" v-if="excursionType">
          <div @click.stop="toggleMandatoryChecklist()" v-if="excursionType" id="div">
            <v-layout align-center justify-space-between>
              <div>
                <v-layout align-center justify-space-between class="category-section">
                  <v-icon class="icon">
                    {{
                      mandatoryChecklistDisplayed
                        ? "keyboard_arrow_down"
                        : "keyboard_arrow_left"
                    }}
                  </v-icon>
                  <div class="form-label mandatory-checklist">Mandatory Excursion Checklists</div>
                </v-layout>
              </div>
            </v-layout>
          </div>
          <div :hidden="!mandatoryChecklistDisplayed" v-if="excursionType">
            <mandatory-checklist
              :saveDraft="saveChangesThenRevertToDraft"
              :formControlEnabled="formControlEnabled"
            />
          </div>

          <hr
            class="mt-4 mb-4"
            v-if="(excursionType != '') && (excursionType != null) && (excursionType != undefined)"
          >
          <v-layout v-if="excursionType">
            <v-flex>
              <v-flex class="update-provider-stepper" v-if="providerUpdateActionDisplayed">
                <v-flex class="update-provider-stepper-text">
                  <i class="fas fa-info-circle"></i>
                  Click the Update Providers button for any immediate updates that are required.
                </v-flex>
                <v-flex class="update-provider-stepper-button">
                  <update-providers
                    :buttonUpdateProvidersEnabled="buttonUpdateProvidersEnabled"
                    :formControlEnabled="formControlEnabled"
                  >
                  </update-providers>
                </v-flex>
              </v-flex>
              <v-flex class="riskManifest">
                <i class="fas fa-info-circle"></i>
                <span>The SafeTripBuilder risk manifest is available</span>
                <span class="riskManifestLink">
                  <a
                    href="/public/SafeTripBuilder - Risk Categories Manifest.pdf"
                    target="_blank"
                  >here</a>
                </span>
                <span>
                  if you want to view all supported risk selections.
                </span>
              </v-flex>
              <risk-category
                :riskCategories="risks"
                :formControlEnabled="formControlEnabled"
                :saveDraft="saveChangesThenRevertToDraft"
              />
              <export-excursion-policy
                style="margin-left:-10px"
                :excursionPolicyExportActionEnabled="hasExcursion()"
                :submitExcursion="submit"
                :valuesChanged="valuesChanged"
                :excursionBuilderViewDisplayed="true"
              ></export-excursion-policy>
            </v-flex>
          </v-layout>
          <hr class="mt-4 mb-4">
          <v-layout v-if="excursionType && excursionAdditionalInformationEnabled">
            <v-flex>
              <v-layout wrap v-if="clientTemplate.sid">
                <v-flex xs12 class="form-label safe-trip-font">
                  Pro Forma Template
                </v-flex>
                <v-flex xs12>
                  {{clientTemplate.filename}}
                  <v-icon @click="downloadFile(clientTemplate)"
                    medium
                    style="font-size:15px;height:17px;margin-left:.7%;"
                  >fa fa-download</v-icon>
                </v-flex>
              </v-layout>
              <additional-info-list
                :additionalInformationItems="additionalInformationItems"
                :formControlEnabled="formControlEnabled"
                @setAdditionalInformationValid="setAdditionalInformationValid"
                ref="additionalInfoList"
              ></additional-info-list>
            </v-flex>
          </v-layout>
          <hr class="mt-4 mb-4">
          <v-layout>
            <v-flex class="main-title">
              {{excursionStepperHeadingLabel.EXCURSION_RISK_ASSESSMENT}}
            </v-flex>
          </v-layout>
          <v-layout class="mt-3" v-if="excursionInherentRiskRatingDetected">
            <risk-rating-summary
                headingLabel="Inherent Rating"
                :tooltipBubbleText="inherentRiskRatingTooltipBubbleText"
                riskRatingRefname="inherent"
            ></risk-rating-summary>
          </v-layout>
          <v-layout class="mt-3">
            <risk-rating-summary
                :headingLabel="residualRiskRatingSummaryHeadingLabel"
                :tooltipBubbleText="residualRiskRatingTooltipBubbleText"
                riskRatingRefname="residual"
            ></risk-rating-summary>
          </v-layout>
          <v-layout align-start justify-start class="create-risk-assessment">
            <div>
              <export-excursion-risk-assessment
                :excursionPolicyExportActionEnabled="
                  isInvalid
                  || !riskCategoriesValid
                  || hasExcursion()
                "
                :registerRiskAssessmentExportEvent="registerRiskAssessmentExportEvent"
                :submitExcursion="submit"
                :valuesChanged="valuesChanged"
              ></export-excursion-risk-assessment>
            </div>
          </v-layout>
          <hr class="mt-4 mb-4">
          <v-layout>
            <v-flex class="main-title">
              {{excursionStepperHeadingLabel.EXCURSION_RISK_MANAGEMENT_CONFIRMATION}}
            </v-flex>
          </v-layout>
          <v-flex class="confirmation-info-text">
            <i class="fas fa-info-circle"></i>
            Confirm acknowledgement by reading, agreeing and ticking below.
          </v-flex>
          <v-layout align-start justify-start class="create-risk-assessment">
            <excursion-confirmation
              :disabledCheckbox="isInvalid || !formControlEnabled"
              @setAgreementConfirmationManuallyTicked="setAgreementConfirmationManuallyTicked"
            ></excursion-confirmation>
          </v-layout>

          <v-layout align-end justify-end class="margin-b">
            <v-btn
              class="normal-btn white--text btnSave primary"
              @click.native="displayExcursionApprovalToggleDialog()"
              :name="'btnApprove'"
              :id="'btnApprove'"
              v-if="excursionApprovalButtonDisplayed"
              :disabled="
                isInvalid
                || !additionalInformationValid
                || excursionApprovalToggleActionInProgress
                || (excursionApproved ? formControlEnabled : !formControlEnabled)
              "
            >
              <v-icon v-if="excursionApprovalToggleActionInProgress">fa fa-spinner fa-pulse</v-icon>
              Approve
            </v-btn>
            <v-btn
              class="normal-btn white--text btnSave primary"
              @click.native="displayExcursionApprovalToggleDialog()"
              :name="'btnUnapprove'"
              :id="'btnUnapprove'"
              v-if="excursionUnapprovalButtonDisplayed"
              :disabled="
                isInvalid
                || !additionalInformationValid
                || excursionApprovalToggleActionInProgress
                || (excursionApproved ? formControlEnabled : !formControlEnabled)
              "
            >
              <v-icon v-if="excursionApprovalToggleActionInProgress">fa fa-spinner fa-pulse</v-icon>
              Undo Approval
            </v-btn>
            <v-btn
              :disabled="
                isInvalid
                || !additionalInformationValid
                || excursionSaveActionInProgress
                || !formControlEnabled
              "
              class="normal-btn white--text btnSave primary"
              @click.native="saveChangesAsDraft()"
              name="btnSave"
              id="btnSave"
            >
              <v-icon v-if="excursionSaveActionInProgress">fa fa-spinner fa-pulse</v-icon>
              Save Excursion
            </v-btn>
            <v-btn
              class="normal-btn"
              @click.native="resetConfirmationDialogDisplayed = true"
              id="btnClearAndRestart"
              :disabled="!formControlEnabled"
            >Clear and Restart</v-btn>
          </v-layout>
          <hr class="mt-4 mb-4">
          <v-layout>
            <v-flex class="main-title">
              {{excursionStepperHeadingLabel.PREDEPARTURE_AND_POST_EXCURSION}}
            </v-flex>
          </v-layout>
          <v-layout align-start justify-start class="create-risk-assessment">
            <div>
              <view-predeparture-checklist
                :savePredeparture="submit"
                :formControlEnabled="formControlEnabled"
              ></view-predeparture-checklist>
            </div>
            <div>
              <v-btn
                class="normal-btn white--text primary"
                :disabled="true"
                id="btnIncidentReporting"
                testing-id="btnIncidentReporting"
              >
                <font>Incident Reporting</font>
              </v-btn>
            </div>
          </v-layout>
          <!-- Excursion History -->
          <excursion-history v-if="excursionHistoryDisplayed"/>
          <div>
            <v-dialog
              v-model="successDialogDisplayed"
              v-if="!presaveConfirmed"
              persistent
              max-width="450"
            >
              <v-card>
                <v-card-title class="headline center">
                  Excursion has been saved successfully
                </v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.native="confirmSuccess()"
                    class="normal-btn white--text primary"
                    id="confirmSuccess"
                  >OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog
              v-model="failureDialogDisplayed"
              v-if="!presaveConfirmed"
              persistent
              max-width="490"
            >
              <v-card>
                <v-card-title class="headline center">{{errorMessage}}</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    @click.native="failureDialogDisplayed = false"
                    class="normal-btn white--text primary"
                    id="failureDialogDisplayed"
                  >OK</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="resetConfirmationDialogDisplayed" persistent max-width="290">
              <v-card>
                <v-card-text>Are you sure you want to clear and restart your excursion?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="normal-btn white--text btnClear primary"
                    @click.native="clear()"
                    id="btnConfirmClear"
                  >Clear and Restart</v-btn>
                  <v-btn
                    class="normal-btn"
                    @click.native="resetConfirmationDialogDisplayed = false"
                  >Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="excursionTypeWarningDisplayed" persistent max-width="490">
              <v-card>
                <v-card-text>
                  You are about to change your Excursion Type after previously saving your selection.
                  Doing so will clear your excursion and you will have to re-enter your details.
                  It will keep other information except risk categories.<br /><br />
                  Are you sure you want to continue?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="normal-btn white--text primary"
                    @click.native="
                      excursionTypeWarningDisplayed = false; clearCategoriesOnExcursionTypeUpdate()
                    "
                    id="btnUpdateExcursionTypeOK"
                  >OK</v-btn>
                  <v-btn
                    class="normal-btn"
                    @click.native="excursionTypeWarningDisplayed = false; cancelExcursionTypeUpdate()"
                    id="btnUpdateExcursionTypeCancel"
                  >Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="changesSaveDialogDisplayed" persistent max-width="490">
              <v-card>
                <v-card-text>
                  Changes have been made to this excursion, are you sure you want to save?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="normal-btn white--text primary"
                    @click.native="saveChangesThenRevertToDraft()"
                  >Save</v-btn>
                  <v-btn
                    class="normal-btn"
                    @click.native="changesSaveDialogDisplayed = false"
                  >Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
          <div>
            <v-dialog v-model="excursionApprovalToggleDialogDisplayed" persistent max-width="490">
              <v-card>
                <v-card-text>
                  Are you sure you want to {{ excursionApproved ? 'undo approval' : 'approve' }}
                  this excursion?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    :testing-id="excursionApproved ? 'unapproveTrigger' : 'approveTrigger'"
                    class="normal-btn white--text primary"
                    @click.native="excursionApproved ? unapproveExcursion() : approveExcursion()"
                  >{{ excursionApproved ? "Undo Approval" : "Approve" }}</v-btn>
                  <v-btn
                    class="normal-btn"
                    @click.native="excursionApprovalToggleDialogDisplayed = false"
                  >Cancel</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </form>
      </div>
    </div>
  </cs-view>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import excursionJurisdictions from "../lib/const/excursionJurisdictions";
import excursionStatus from "../lib/const/excursionStatus";
import excursionStepperHeading from "../lib/const/excursionStepperHeading";
import excursionTypeInfo from "../lib/const/excursionTypeInfo";
import riskCategoryLabel from "../lib/const/riskCategoryLabel";
import trackableEvent from "../lib/const/trackableEvent";
import stbHorizontalLogo from '../../public/StbHorizontalLogo.png';

export default {

  extends: CSBase,

  mixins: [validationMixin],

  validations: {
    name: {
      required,
    },
    excursionType: {
      required,
    },
    vendorExcursionSid: {
      required,
    },
  },

  props: {
    propHasParentComponent: Boolean,
  },

  data() {
    return {
      stbHorizontalLogo,
      excursionApprovalToggleDialogDisplayed: false,
      successDialogDisplayed: false,
      failureDialogDisplayed: false,
      resetConfirmationDialogDisplayed: false,
      excursionId: 0,
      pdf: {
        title: "master excursion risk assessment - sample",
        path: "doc",
      },
      mandatoryChecklistDisplayed: true,
      excursionTypeWarningDisplayed: false,
      originalExcursionType: "",
      additionalInformationValid: true,
      newExcursionType: "",
      excursionSaveActionInProgress: false,
      excursionApprovalToggleActionInProgress: false,
      errorMessage: "",
      presaveConfirmed: false,
      valuesChanged: false,
      firstTimeLoaded: false,
      changesSaveDialogDisplayed: false,
      agreementConfirmationManuallyTicked: false,
      additionalInformationChanged: false,
      excursionStatusChanged: false,
      excursionSetByExcursionChanges: false,
      excursionSetByRiskChanges: false,
      pageRefreshRequired: false,
      jurisdictionTooltip: "The content of the destination state should be considered when travelling interstate. Given that we research content across each State and Territory's Department of Education, in some cases there may be additional information to consider even if you are only travelling interstate for a day excursion.",
    };
  },

  computed: {
    name: {
      get() {
        return this.$store.state.excursion.name;
      },
      set(value) {
        this.$store.commit("updateExcursionName", value);
      },
    },
    providerUpdateActionDisplayed() {
      return this.$store.state.providerUpdateActionDisplayed;
    },
    excursionType: {
      get() {
        if (this.$store.state.excursion.typeId) {
          this.setOriginalExcursionType(this.$store.state.excursion.typeId);
        }

        return this.$store.state.excursion.typeId;
      },
      async set(value) {
        if (!this.$store.state.excursion.typeId) {
          this.$store.commit("updateExcursionType", value);
          this.originalExcursionType = value;
          if (value) {
            this.$store.commit("resetStoreWhenChangingExcursionType");

            await this.$store.dispatch("getAllRisksByExcursionTypeName", {
              stbApiAdapter: this.$app.stbApiAdapter,
              store: this.$store,
              queryParam: this.$route.query,
              excursionTypeName: value,
            });
          }
        } else {
          this.newExcursionType = value;
          this.excursionTypeWarningDisplayed = true;
        }
      },
    },
    jurisdiction: {
      get() {
        return ("" === this.$store.state.excursion.jurisdiction || !this.$store.state.excursion.jurisdiction)
          ? this.$store.state.apiDataDefaultJurisdiction : this.$store.state.excursion.jurisdiction;
      },
      set(value) {
        this.$store.commit("updateExcursionJurisdiction", value);
      },
    },
    vendorExcursionSid: {
      get() {
        if (this.vendorExcursionSidInputEnabled) {
          return this.$store.state.excursion.vendorData.vendorExcursionSid;
        }
        return 0;
      },
      set(value) {
        this.$store.commit("updateVendorExcursionId", value);
      },
    },
    excursionTypeChoiceList() {
      return Object.keys(this.$store.state.excursionTypeChoiceList);
    },
    excursionApproved() {
      return excursionStatus.APPROVED === this.excursionsStatus;
    },
    jurisdictions() {
      return excursionJurisdictions;
    },
    showJurisdiction() {
      return this.$app.excursionCommon.showJurisdiction(this.$store.state);
    },
    excursionHighRisks() {
      const allRisks = this.$store.state.allRisks;
      return allRisks
      .filter(r => r.name === riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS);
    },
    risks() {
      const allRisks = this.$store.state.allRisks;
      return allRisks
      .filter(r => r.name !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS);
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.excursionType.$dirty) return errors;
      if (!this.$v.excursionType.required) {
        errors.push("Excursion Type is required");
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) {
        errors.push("Excursion is required.");
      }

      return errors;
    },
    vendorExcursionSidErrors() {
      const errors = [];

      if (this.vendorExcursionSidInputEnabled) {
        if (!this.$v.vendorExcursionSid.$dirty) return errors;
        if (!this.$v.vendorExcursionSid.required) {
          errors.push("Excursion Manager ID is required.");
        }
      }

      return errors;
    },
    userAuthenticated() {
      return this.$store.state.userAuthenticated;
    },
    userAuthenticationInProgress() {
      return this.$store.state.userAuthenticationInProgress;
    },
    showError() {
      return this.$store.state.showError ? this.$store.state.showError : false;
    },
    activityCategoryName() {
      return this.$store.state.activitiesCategoryName;
    },
    allSelectedActivityRisk() {
      return this.$store.getters.getSelectedActivityClasses[0];
    },
    agreementConfirmationTicked() {
      if (!this.$store.state.excursion.agreementConfirmation) {
        return false;
      }
      return this.$store.state.excursion.agreementConfirmation.ticked;
    },
    additionalInformationItems: {
      get() {
        const additionalInformationItems = this.$store.state.excursion.additionalInformationItems;
        const infors = additionalInformationItems.sort((a, b) => {
          return (a.orderId - b.orderId);
        });
        if (infors && 0 < infors.length) {
          const inforsAdded = infors.filter(r => !r.isDeleted);
          if (1 === inforsAdded.length) {
            infors.forEach((r) => {
              if (!r.isDeleted) {
                if (r.filename || r.fileSummary || r.comment) {
                  r.showDeleteButton = true;
                }
              }
            });
          } else {
            infors.forEach((r) => {
              r.showDeleteButton = true;
            });
          }

          return infors;
        }

        return [
          {
            fileSummary: "",
            comment: "",
            showDeleteButton: false,
          },
        ];
      },
    },
    vendorExcursionSidInputEnabled() {
      return this.$store.state.vendorExcursionSidInputEnabled;
    },
    excursionAdditionalInformationEnabled() {
      return this.$store.state.excursionAdditionalInformationEnabled;
    },
    excursionHighRiskStudentMedicalEnabled() {
      return this.$store.state.excursionHighRiskStudentMedicalEnabled;
    },
    isInvalid() {
      return this.$v.$invalid;
    },
    riskCategoriesValid() {
      return 0 < this.$store.getters.getSelectedCategoryCount;
    },
    excursionDisplayReady() {
      return this.$store.state.excursionDisplayReady;
    },
    excursionStudentMedicalConditionsDisplayReady() {
      return this.$store.state.excursionStudentMedicalConditionsDisplayReady;
    },
    excursionLogoDisplayReady() {
      return this.$store.state.excursionLogoDisplayReady;
    },
    clientLogoBackgroundColor() {
      return this.$store.state.clientLogoBackgroundColor;
    },
    vendorClientLogoEnabled() {
      return this.$store.state.vendorClientLogoEnabled;
    },
    stbLogoDisplayed() {
      const query = this.$route.query;

      let stbLogoDisplayed = true;
      if (query && query.referrer && ("pcg" === query.referrer.toLowerCase())) {
        stbLogoDisplayed = false;
      }

      return stbLogoDisplayed;
    },
    formControlEnabled() {
      return (
        !(this.excursionsStatus === excursionStatus.APPROVED)
        && !(this.excursionsStatus === excursionStatus.DRAFT)
      );
    },
    buttonUpdateProvidersEnabled() {
      return !(this.excursionsStatus === excursionStatus.DRAFT);
    },
    excursionTypeLoaded() {
      return this.$store.state.excursionTypeLoaded;
    },
    excursionJurisdictionLoaded() {
      return this.$store.state.excursionJurisdictionLoaded;
    },
    excursions() {
      return JSON.parse(JSON.stringify(this.$store.state.excursion));
    },
    clientTemplate() {
      let clientTemplate = this.$store.state.excursion.clientTemplate;
      if (0 === this.excursionId) {
        clientTemplate = this.$store.state.clientTemplate;
      }
      return clientTemplate;
    },
    excursionsStatus() {
      return this.$store.state.excursion.status;
    },
    excursionHistoryDisplayed() {
      return (0 < this.$store.state.excursion.id);
    },
    pcgIntegrationDetected() {
      const { query } = this.$route;
      return query && query.referrer && ("pcg" === query.referrer.toLowerCase());
    },
    excursionApprovalButtonDisplayed() {
      const vendorExcursionSid = this.$store.state.excursion.vendorData.vendorExcursionSid;
      return (
        this.$store.state.excursion.agreementConfirmation.ticked
          && !this.pcgIntegrationDetected
          && !(vendorExcursionSid && (0 < parseInt(vendorExcursionSid)))
          && excursionStatus.APPROVED !== this.excursionsStatus
          && this.$store.state.excursionApprovalButtonDisplayed
      );
    },
    excursionUnapprovalButtonDisplayed() {
      return excursionStatus.APPROVED === this.excursionsStatus
        && !this.pcgIntegrationDetected
        && this.$store.state.excursionUnapprovalButtonDisplayed;
    },
    excursionStepperHeadingLabel() {
      return excursionStepperHeading;
    },
    excursionTypeInfoEntryList() {
      return Object.values(excursionTypeInfo);
    },
    excursionStudentFetchTriggered() {
      return this.$store.state.excursionStudentFetchTriggered;
    },
    studentMedicalConditionsLoadingAnimationDisplayed() {
      return (
        !this.pageLoadingAnimationDisplayed()
        && (
          this.excursionStudentFetchTriggered
          && !this.excursionStudentMedicalConditionsDisplayReady
        )
      );
    },
    excursionRiskOverall() {
      return this.$store.state.excursion.riskOverall;
    },
    excursionInherentRiskRatingEnabled() {
      return this.$store.state.excursionInherentRiskRatingEnabled;
    },
    excursionInherentRiskRatingDetected() {
      const riskOverall = this.excursionRiskOverall;
      const localExcursionInherentRiskRatingDetected = (
          this.excursionInherentRiskRatingEnabled
          && riskOverall
          && riskOverall.residualRiskRating
          && riskOverall.inherentRiskRating
      );

      return localExcursionInherentRiskRatingDetected;
    },
    residualRiskRatingSummaryHeadingLabel() {
      let localResidualRiskRatingSummaryHeadingLabel = "Overall Rating";

      if (this.excursionInherentRiskRatingDetected) {
        localResidualRiskRatingSummaryHeadingLabel = "Residual Rating";
      }

      return localResidualRiskRatingSummaryHeadingLabel;
    },
    residualRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected ? "Residual risk is the level of risk that remains after controls have been applied." : "";
    },
    inherentRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected ? "Inherent risk is the level of risk that exists where no controls have been applied." : "";
    },
  },

  methods: {
    getRiskAssessmentTrackableEventBaseArgHash() {
      const { apiDataDefaultJurisdiction, excursion } = this.$store.state;
      return this.$app.excursionCommon
      .getRiskAssessmentTrackableEventBaseArgHash(apiDataDefaultJurisdiction, excursion);
    },

    getRiskAssessmentTrackableEventOptionArgHash() {
      return this.$app.excursionCommon.getRiskAssessmentTrackableEventOptionArgHash();
    },

    registerRiskAssessmentBuilderViewUpdateEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter
      .registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_BUILDER_VIEW_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
    },

    registerRiskAssessmentExportEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter
      .registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_EXPORT,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
    },

    registerRiskAssessmentApprovalEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter
      .registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_APPROVAL,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
    },

    registerRiskAssessmentUnapprovalEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.pluginCommonAdapter
      .registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_UNAPPROVAL,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
      );
    },

    async submit(pdfType, statusChanged, callback) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));
      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.excursion));
      this.excursionId = excursionMetadata.id;
      delete excursionMetadata.id;
      delete excursionMetadata.additionalInformationItems;
      delete excursionMetadata.validCategories;
      delete excursionMetadata.computed;
      delete excursionMetadata.auditList;

      excursionMetadata.name = this.name;
      excursionMetadata.typeId = this.excursionType;

      const apiPayload = {
        metadata: excursionMetadata,
      };

      if (!pdfType && !callback) {
        this.excursionSaveActionInProgress = true;
      }

      const excursionCommonAdapter
        = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
      const excursionRiskCombo
        = await excursionCommonAdapter.getExcursionRiskCombo(this.$route.query);
      if (excursionRiskCombo) {
        apiPayload.metadata.riskOverall = excursionRiskCombo.riskOverall;
        apiPayload.metadata.riskCategories = excursionRiskCombo.riskCategories;
      }

      for (let i = 0; i < this.additionalInformationItems.length; i++) {
        this.additionalInformationItems[i].orderId = i + 1;
      }

      await this.insertAdditionalInformationItems(this.additionalInformationItems);

      apiPayload.metadata.additionalInformationNotes = this.additionalInformationItems
      .filter(item => !item.isDeleted).map((item) => {
        const additionalItem = {
          orderId: item.orderId,
          comment: item.comment,
          fileSummary: item.fileSummary,
          notes: item.notes,
        };
        if (item.sid) {
          additionalItem.sid = item.sid;
        }
        return additionalItem;
      });

      if ("0" !== `${this.excursionId}`) {
        // set updateSignature
        if (!apiPayload.metadata.updateSignature) {
          apiPayload.metadata.updateSignature = this.$app.uuid.v4();
        }

        const argHash = {
          store: this.$store,
          queryParam: apiQuery,
          id: this.excursionId,
          payload: apiPayload,
        };
        const putExcursionsCbResponse = await this.$app.stbApiAdapter.putExcursions(argHash);

        if (
          !this.$store.state.isResponseWithError
          && !pdfType
          && (!callback || ("function" !== typeof callback))
        ) {
          if (!putExcursionsCbResponse.error) {
            this.excursionSetByExcursionChanges = true;
            this.excursionSetByRiskChanges = true;
            this.$store.commit("setExcursion", putExcursionsCbResponse);

            if (
              this.valuesChanged
              && statusChanged
              && !this.$store.state.excursion.agreementConfirmation.ticked
            ) {
              this.excursionStatusChanged = true;
              const putExcursionStatusesArgHash = {
                queryParam: apiQuery,
                newStatus: excursionStatus.DRAFT,
                store: this.$store,
                id: this.excursionId,
              };
              await this.$app.stbApiAdapter.putExcursionStatuses(putExcursionStatusesArgHash);
            }

            this.excursionSaveActionInProgress = false;
            this.successDialogDisplayed = true;
          } else {
            this.excursionSaveActionInProgress = false;
            this.failureDialogDisplayed = true;
            this.errorMessage = putExcursionsCbResponse.message;
          }
        } else {
          this.excursionSaveActionInProgress = false;
          this.excursionSetByExcursionChanges = true;
          this.excursionSetByRiskChanges = true;
          this.$store.commit("setExcursion", putExcursionsCbResponse);
        }

        this.valuesChanged = false;

        if (callback && ("function" === typeof callback)) {
          this.presaveConfirmed = true;
          await callback();
        }
      } else {
        const argHash = {
          store: this.$store,
          queryParam: apiQuery,
          payload: apiPayload,
        };
        const postExcursionsCbResponse = await this.$app.stbApiAdapter.postExcursions(argHash);

        if (!this.$store.state.isResponseWithError && !pdfType) {
          if (!postExcursionsCbResponse.error) {
            if (postExcursionsCbResponse.data.id) {
              this.excursionId = postExcursionsCbResponse.data.id;
              this.excursionSetByExcursionChanges = true;
              this.excursionSetByRiskChanges = true;
              this.$store.commit("setExcursion", postExcursionsCbResponse);
              const query = JSON.parse(JSON.stringify(this.$route.query));
              this.$router.replace(`/excursions/builder/${this.excursionId}`);

              if (query) {
                this.$router.replace({ query: query });
              }

              this.excursionSaveActionInProgress = false;
              this.successDialogDisplayed = true;
              this.pageRefreshRequired = true;
            } else {
              this.excursionSaveActionInProgress = false;
              this.successDialogDisplayed = true;
            }
          } else {
            this.excursionSaveActionInProgress = false;
            this.failureDialogDisplayed = true;
            this.errorMessage = postExcursionsCbResponse.message;
          }
        }

        this.valuesChanged = false;
        if (callback && ("function" === typeof callback)) {
          this.presaveConfirmed = true;
          await callback();
        }
      }

      if (this.excursionSaveActionInProgress) {
        return true;
      }
      return false;
    },

    resetJurisdiction() {
      this.$store.state.excursion.jurisdiction = "";
    },

    clear() {
      const updateAdditionalInformationList = this.additionalInformationItems.filter(
        a => a.sid && 0 < a.sid.length,
      );
      updateAdditionalInformationList.forEach((a) => { a.isDeleted = true; });
      this.updateAdditionalInformationList(updateAdditionalInformationList);

      this.resetConfirmationDialogDisplayed = false;
      this.$v.$reset();
      this.$store.commit("resetAllState", [
        "excursionTypeChoiceList",
        "apiParam",
        "excursion",
        "excursionAdditionalInformationEnabled",
        "excursionBuilderButtonDisplayed",
        "excursionCreationButtonDisplayed",
        "excursionDestinationContentEnabled",
        "excursionDisplayReady",
        "excursionHighRiskStudentMedicalEnabled",
        "excursionLogoDisplayReady",
        "excursionPersonResponsibleEnabled",
        "excursionStudentFetchTriggered",
        "excursionStudentMedicalConditionsDisplayReady",
        "logoAttachmentUrl",
        "topToolbarTextButtonList",
        "providerUpdateActionEnabled",
        "showToolbar",
        "excursionSyncButtonDisplayed",
        "userAuthenticated",
        "userInitials",
        "vendorClientLogoEnabled",
        "vendorExcursionSidInputEnabled",
      ]);
      this.$store.commit("resetStore");
    },

    toggleMandatoryChecklist() {
      this.mandatoryChecklistDisplayed = !this.mandatoryChecklistDisplayed;
    },

    confirmSuccess() {
      this.successDialogDisplayed = false;

      if (window.opener) {
        window.self.close();
      } else if (this.excursionStatusChanged || this.pageRefreshRequired) {
        window.location.reload();
      }
    },

    setOriginalExcursionType(value) {
      this.originalExcursionType = value;
    },

    async clearCategoriesOnExcursionTypeUpdate() {
      this.$store.commit("updateExcursionType", this.newExcursionType);
      this.originalExcursionType = this.newExcursionType;
      if (this.newExcursionType) {
        this.$store.state.allRisks = [];
        this.$store.commit("resetStoreWhenChangingExcursionType");
        await this.$store.dispatch("getAllRisksByExcursionTypeName", {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: this.$route.query,
          excursionTypeName: this.newExcursionType,
        });
      }
    },

    cancelExcursionTypeUpdate() {
      this.$store.commit("updateExcursionType", "");

      this.$nextTick(() => {
        this.$store.commit("updateExcursionType", this.originalExcursionType ? this.originalExcursionType : "");
      });
    },

    async updateAdditionalInformationList(updateAdditionalInformationList) {
      if (!updateAdditionalInformationList || 0 === updateAdditionalInformationList.length) {
        return;
      }

      for (let i = 0; i < updateAdditionalInformationList.length; i++) {
        const item = updateAdditionalInformationList[i];
        if (
          !item.sid
          || (("0" !== `${this.excursionId}`) && !(item.isUpdated || item.isDeleted))
        ) {
          continue;
        }

        if (item.isDeleted) {
          this.$refs.additionalInfoList.deleteAttachment(item);
        } else {
          const requestData = {};

          requestData.metadata = {
            summary: item.fileSummary,
            description: item.comment,
          };

          if (
            ("undefined" !== typeof requestData.metadata.summary)
            && ("" === requestData.metadata.summary.trim())
          ) {
            delete requestData.metadata.summary;
          }

          if (
            ("undefined" !== typeof requestData.metadata.description)
            && ("" === requestData.metadata.description.trim())
          ) {
            delete requestData.metadata.description;
          }

          const argHash = {
            queryParam: this.$store.state.apiParam,
            excursionId: this.excursionId,
            attachmentSid: item.sid,
            payload: requestData,
          };
          await this.$app.stbApiAdapter.putExcursionAttachments(argHash);
        }
      }
    },
    async insertAdditionalInformationItems(additionalInformationItems) {
      const newAdditionalInformationItems = this.$app.lodash.cloneDeep(additionalInformationItems);
      if (newAdditionalInformationItems && (0 < newAdditionalInformationItems.length)) {
        // add
        const addAdditionalList = newAdditionalInformationItems.filter(
          a => "undefined" !== typeof a.file,
        );

        if (addAdditionalList && (0 < addAdditionalList.length)) {
          // Remove old additional files before adding new files
          const excursionId = this.$store.state.excursion.id;
          if (0 < excursionId) {
            for (const item of addAdditionalList) {
              const argHash = {
                queryParam: this.$store.state.apiParam,
                excursionId,
                attachmentSid: item.sid,
              };
              if (item.sid) {
                await this.$app.stbApiAdapter.deleteExcursionAttachments(argHash);
              }
            }
          }

          for (let i = 0; i < addAdditionalList.length; i++) {
            await this.postNewAdditionalFile(addAdditionalList[i]);
          }
        }

        // update
        const updateAdditionalInformationList = newAdditionalInformationItems.filter(
          a => a.sid && 0 < a.sid.length,
        );

        await this.updateAdditionalInformationList(updateAdditionalInformationList);

        const existAdditions = newAdditionalInformationItems.filter(r => !r.isDeleted);

        if (existAdditions && 0 === existAdditions.length) {
          newAdditionalInformationItems.push({
            fileSummary: "",
            comment: "",
            showDeleteButton: false,
          });
        }
        this.$store.commit(
          "updateAdditionalInformations",
          newAdditionalInformationItems,
        );
      }
    },

    async postNewAdditionalFile(additional) {
      const addItems = [];
      const fd = new FormData();
      fd.append(`file-0`, additional.file);
      addItems.push({
        summary: additional.fileSummary,
        description: additional.comment,
        orderId: additional.orderId,
      });

      fd.append("items", JSON.stringify(addItems));

      const argHash = {
        queryParam: this.$store.state.apiParam,
        excursionId: this.excursionId,
        payload: fd,
      };
      const addedRes = await this.$app.stbApiAdapter.postExcursionAttachments(argHash);
      addedRes.data.forEach((a) => {
        const file = a.metadata;
        additional.sid = a.sid;
        additional.comment = file.description;
        additional.filename = file.filename;
        additional.fileSummary = file.summary;
        additional.contentType = file.contentType;
        additional.orderId = file.orderId;
        const fileUpdateDate = new Date(a.created_at);
        if ("Invalid Date" !== fileUpdateDate.toString()) {
          additional.fileUpdateDate = (fileUpdateDate).toLocaleString();
        }
      });

      return additional;
    },

    setAdditionalInformationValid(valid) {
      this.additionalInformationValid = valid;
      this.additionalInformationChanged = true;
    },

    clientLogoMarginDisplayed() {
      const query = this.$route.query;
      if (query.referrer || query["vendor-sid"] || query["oauth-signature"]) {
        return false;
      }
      return true;
    },

    hasExcursion() {
      if (this.excursionId) {
        if (0 < parseInt(this.excursionId)) {
          return false;
        }
      }
      return true;
    },

    setAgreementConfirmationTick() {
      this.valuesChanged = true;
      if (!this.agreementConfirmationManuallyTicked) {
        this.$store.state.excursion.agreementConfirmation.ticked = false;
      } else {
        this.agreementConfirmationManuallyTicked = false;
      }
    },

    async saveChangesAsDraft() {
      if (this.valuesChanged || this.additionalInformationChanged) {
        this.additionalInformationChanged = false;
        this.changesSaveDialogDisplayed = true;
      } else {
        this.registerRiskAssessmentBuilderViewUpdateEvent();
        await this.submit(null, true);
      }
    },

    async saveChangesThenRevertToDraft() {
      this.registerRiskAssessmentBuilderViewUpdateEvent();
      this.$store.state.excursion.status = excursionStatus.DRAFT;
      this.$store.state.excursion.currentStep = 1;
      this.changesSaveDialogDisplayed = false;
      await this.submit(null, true);
    },

    setAgreementConfirmationManuallyTicked(flag) {
      this.agreementConfirmationManuallyTicked = flag;
    },

    displayExcursionApprovalToggleDialog() {
      this.excursionApprovalToggleDialogDisplayed = true;
    },

    async unapproveExcursion() {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));
      const putExcursionStatusParams = {
        queryParam: apiQuery,
        newStatus: excursionStatus.SUBMITTED,
        store: this.$store,
        id: this.excursionId,
      };

      this.excursionApprovalToggleActionInProgress = true;
      await this.$store.dispatch("unapproveExcursion",
        {
          stbApiAdapter: this.$app.stbApiAdapter,
          putExcursionStatusParams,
        });

      this.excursionApprovalToggleActionInProgress = false;
      this.excursionApprovalToggleDialogDisplayed = false;
      this.$app.excursionCommon.enableFormControl(".excursion-builder", true);
      // ensure tickbox is ticked when status changed from approved to submitted
      this.agreementConfirmationManuallyTicked = true;
      const confirmInfo = {
        ...this.$store.state.excursion.agreementConfirmation,
        ticked: true,
      };
      this.$store.commit("updateExcursionConfirmation", confirmInfo);

      this.registerRiskAssessmentUnapprovalEvent();
    },

    async approveExcursion() {
      this.excursionApprovalToggleDialogDisplayed = false;
      this.excursionApprovalToggleActionInProgress = true;
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));
      const putExcursionStatusesArgHash = {
        queryParam: apiQuery,
        newStatus: excursionStatus.APPROVED,
        store: this.$store,
        id: this.excursionId,
      };

      await this.$app.stbApiAdapter.putExcursionStatuses(putExcursionStatusesArgHash);
      this.excursionApprovalToggleActionInProgress = false;
      this.$store.state.excursion.status = excursionStatus.APPROVED;
      this.$app.excursionCommon.enableFormControl(".excursion-builder", false);
      this.excursionApprovalToggleDialogDisplayed = false;

      this.registerRiskAssessmentApprovalEvent();
    },

    async downloadFile(additional) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: additional.sid,
        store: this.$store,
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (0 < attachments.length) {
          const file = attachments[0];

          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, "");
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, "");
            const fileUrl = `${apiUrl}/${filePath}`;
            const filename = file.metadata.filename;
            const excursionCommonAdapter
              = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
            excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },

    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.userAuthenticationInProgress) {
        loadingAnimationDisplayed = true;
      } else {
        if (
          this.userAuthenticated
          && (
            !this.excursionDisplayReady
            || !this.excursionLogoDisplayReady
            || this.excursionSaveActionInProgress
            || this.excursionApprovalToggleActionInProgress
          )
        ) {
          loadingAnimationDisplayed = true;
        }
      }

      return loadingAnimationDisplayed;
    },
  },

  beforeDestroy() {
    this.$app.eventBus.$off("getClientSetupsDone");
    this.$app.eventBus.$off("setAgreementConfirmationTick");
  },

  async mounted() {
    const that = this;
    this.$store.commit("setMenu", this.$route.name);
    const query = this.$route.query;
    const params = this.$route.params;

    if (query && query[`excursion-id`]) {
      this.excursionId = query[`excursion-id`];
    }

    const apiQuery = JSON.parse(JSON.stringify(query));

    if (params && params.id) {
      this.excursionId = params.id;
      apiQuery[`excursion-id`] = this.excursionId;
    }

    this.$app.eventBus.$on("setAgreementConfirmationTick", this.setAgreementConfirmationTick);

    if (!this.propHasParentComponent) {
      this.$app.authAgent.enforceVendorOrUserAuthStrategy(this.$route, apiQuery);
      const store = this.$store;
      const stbApiAdapter = this.$app.stbApiAdapter;

      this.$app.eventBus.$on("getClientSetupsDone", async function () {
        if (store.state.userAuthenticated) {
          const getExcursionsArgHash = {
            stbApiAdapter,
            store,
            excursionId: that.excursionId,
            query: apiQuery,
          };
          await store.dispatch("getExcursions", getExcursionsArgHash);
          const argHash = {
            stbApiAdapter,
            store,
            queryParam: apiQuery,
          };
          await store.dispatch("updateProviders", argHash);
          await store.dispatch("getExcursionTypes", argHash);
          await this.$store.dispatch("getDefaultJurisdiction", argHash);

          const excursionCommonAdapter
            = new this.$app.excursionCommon.Adapter({ store: this.$store, app: this.$app });
          const predepartureArgHash = this.$app.lodash.cloneDeep(argHash);
          const genericFrcs = excursionCommonAdapter.getRiskCategoryParams();
          const activityFrcs = excursionCommonAdapter.getActivityParams();
          // frc stands for: factor risk category
          const frcs = genericFrcs.concat(activityFrcs);

          const excursionTypeName = store.state.excursion.typeId;
          frcs.push([excursionTypeName]);
          predepartureArgHash.payload = {
            "risk-categories": frcs,
          };

          if (frcs) {
            await store.dispatch("postPredepartureStrategies", predepartureArgHash);
          }

          if (excursionTypeName) {
            await store.dispatch("getAllRisksByExcursionTypeName", {
              stbApiAdapter: this.$app.stbApiAdapter,
              store: this.$store,
              queryParam: query,
              excursionTypeName,
            });
          }
        }
      });
    }

    this.$nextTick(() => {
      if (this.excursionsStatus === excursionStatus.APPROVED) {
        this.$app.excursionCommon.enableFormControl(".excursion-builder", false);
      }
    });
  },

  watch: {
    excursions(newValue, oldValue) {
      if (
        (excursionStatus.SUBMITTED === this.excursionsStatus)
        && !this.excursionSetByExcursionChanges
      ) {
        const oldExcursion = JSON.parse(JSON.stringify(oldValue));
        const newExcursion = JSON.parse(JSON.stringify(newValue));

        delete oldExcursion.updateSignature;
        delete newExcursion.updateSignature;
        delete oldExcursion.additionalInformationItems;
        delete newExcursion.additionalInformationItems;
        delete oldExcursion.predepartures;
        delete newExcursion.predepartures;
        delete oldExcursion.predepartureChecklistCompleted;
        delete newExcursion.predepartureChecklistCompleted;
        delete oldExcursion.auditList;
        delete newExcursion.auditList;

        if (!this.$app.lodash.isEqual(oldExcursion, newExcursion)) {
          this.valuesChanged = true;
          this.setAgreementConfirmationTick();
        }
      }
      this.excursionSetByExcursionChanges = false;
    },
    risks(newValue, oldValue) {
      if (this.firstTimeLoaded && !this.excursionSetByRiskChanges) {
        if (excursionStatus.SUBMITTED === this.excursionsStatus) {
          this.valuesChanged = true;
          this.setAgreementConfirmationTick();
        }
      } else {
        this.firstTimeLoaded = true;
      }

      this.excursionSetByRiskChanges = false;
    },
  },
};
</script>

import excursionTypeInfo from "../lib/const/excursionTypeInfo";

export default {
  async updateApiVersion({ commit }, argHash) {
    const { stbApiAdapter } = argHash;
    const data = await stbApiAdapter.getHealthAlive();
    if ("undefined" !== typeof data.version) {
      commit("updateApiVersion", data.version);
    }
  },
  resetStore({ commit }) {
    commit("resetStore");
  },
  // TODO: 'getAllRisksByExcursionTypeName' actually sets all form inputs
  // instead of just risk-specific inputs, so this might need to be renamed
  async getAllRisksByExcursionTypeName({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
      excursionTypeName,
      excursionCopyModalHandlingExpected,
    } = argHash;
    const risksFromApi = await stbApiAdapter.getRisks({
      store,
      queryParam,
      excursionTypeName,
      excursionCopyModalHandlingExpected,
    });
    commit("setCeCommitOid", risksFromApi.ceCommitOid);
    commit("setRisks", risksFromApi.relevant);
    commit("setRisksDisabled");
  },
  async getExcursionTypes({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
    } = argHash;
    store.state.excursionTypeLoaded = true;
    const response = await stbApiAdapter.getExcursionTypes({ store, queryParam });
    if (!response.error) {
      commit("setCeCommitOid", response.data.ceCommitOid);
      commit("setExcursionTypeChoiceList", response.data.excursionTypes);
    }
    store.state.excursionTypeLoaded = false;
  },
  async getDefaultJurisdiction({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
    } = argHash;
    if (store.state.excursionDestinationContentEnabled) {
      const response = await stbApiAdapter.getClientJurisdiction({ store, queryParam });

      if (!response.error) {
        commit("setDefaultJurisdiction", response.data.data.jurisdiction);
      }
    }
  },
  resetCategoriesAndRisks({ commit }, typeName) {
    commit("resetCategoriesAndRisks", typeName);
  },
  removeSelectRisk({ commit }, selectedRisk) {
    commit("removeSelectRisk", selectedRisk);
  },
  changeSelectRisk({ commit }, selectedRisk) {
    commit("changeSelectRisk", selectedRisk);
  },
  removeSelectRiskClass({ commit }, selectedRiskClass) {
    commit("removeSelectRiskClass", selectedRiskClass);
  },
  changeSelectRiskClass({ commit }, { riskClass, riskTypeName, riskCategory }) {
    commit("changeSelectRiskClass", { riskClass, riskTypeName, riskCategory });
  },
  changeActivitySelectRisk({ commit }, { risk, riskClassName }) {
    commit("changeActivitySelectRisk", { risk, riskClassName });
  },
  changeHighRiskType({ commit }, { riskTypeName, riskCategory }) {
    commit("changeHighRiskType", { riskTypeName, riskCategory });
  },
  async getProviders({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
    } = argHash;

    const apiArgHash = {
      store,
      queryParam,
    };
    const providers = await stbApiAdapter.getProviders(apiArgHash);
    commit("setProviders", providers);
  },
  async updateProviders({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
      excursionCopyModalHandlingExpected,
    } = argHash;

    const apiArgHash = {
      store,
      queryParam,
      excursionCopyModalHandlingExpected,
    };
    const providers = await stbApiAdapter.postProviders(apiArgHash);
    if ("undefined" !== typeof providers.data) {
      commit("setProviders", providers.data);
    }
  },
  setExcursionSelectedProviders({ commit }, { categoryName, selectedProviderNames, providers }) {
    commit("setExcursionSelectedProviders", { categoryName, selectedProviderNames, providers });
  },
  async getExcursionList({ commit }, argHash) {
    const {
      stbApiAdapter,
      filterParam,
    } = argHash;

    const apiArgHash = {
      filterParam,
    };
    const cbResponse = await stbApiAdapter.getExcursionList(apiArgHash);
    commit("getExcursionList", cbResponse);
  },
  async getExcursions({ commit }, argHash) {
    let { query } = argHash;
    const {
      stbApiAdapter,
      store,
      excursionId,
      excursionCopyModalHandlingExpected,
    } = argHash;

    const apiArgHash = {
      excursionId,
      query,
      store,
      excursionCopyModalHandlingExpected,
    };

    if (!query) {
      query = {};
    }
    const apiParam = JSON.parse(JSON.stringify(query));
    store.state.apiParam = apiParam;

    let studentMedicalConditions = [];

    if ("0" === `${excursionId}`) {
      const newExcursion = {
        id: "0",
        metadata: {
          name: "",
          categories: [],
        },
      };

      if (query) {
        if ("undefined" !== typeof query["excursion-name"]) {
          newExcursion.metadata.name = query["excursion-name"];
        }

        if ("undefined" !== typeof query["vendor-excursion-type"]) {
          let vendorExcursionTypeCompatible = false;
          const { selectedExcursionTypeLabel } = store.state;
          const excursionTypeInfoKeys = Object.keys(excursionTypeInfo);

          for (let i = 0; i < excursionTypeInfoKeys.length; i++) {
            const excursionTypeInfoKey = excursionTypeInfoKeys[i];
            const excursionTypeInfoValue = excursionTypeInfo[excursionTypeInfoKey];
            const excursionTypeLabel = excursionTypeInfoValue.label;

            if (selectedExcursionTypeLabel === excursionTypeLabel) {
              vendorExcursionTypeCompatible = true;
              break;
            }
          }

          if (vendorExcursionTypeCompatible) {
            newExcursion.metadata.typeId = selectedExcursionTypeLabel;
          }
        }
      }

      commit("setExcursion", { data: newExcursion });
      commit("setActivitySelectionValid");
    } else {
      const getExcursionsCbResponse = await stbApiAdapter.getExcursions(apiArgHash);
      commit("setExcursion", getExcursionsCbResponse);
      commit("setActivitySelectionValid");

      const excursionFromCb = getExcursionsCbResponse.data;

      if (excursionFromCb) {
        if (excursionFromCb.computed) {
          const excursionStudentFetchTriggered
            = excursionFromCb.computed.excursionStudentFetchTriggered
              ? true
              : false;

          if (excursionStudentFetchTriggered) {
            commit("setExcursionStudentFetchTriggered", excursionStudentFetchTriggered);

            const getExcursionStudentMedicalConditionsCbResponse
              = await stbApiAdapter.getExcursionStudentMedicalConditions(apiArgHash);

            studentMedicalConditions = getExcursionStudentMedicalConditionsCbResponse.data.data;
          }
        }
      }
    }

    commit("setExcursionStudentMedicalConditions", studentMedicalConditions);
    commit("setExcursionStudentMedicalConditionsDisplayReady", true);
  },
  async getClientList({ commit }, argHash) {
    const {
      stbApiAdapter,
      filterParam,
    } = argHash;

    const apiArgHash = {
      filterParam,
    };
    const cbResponse = await stbApiAdapter.getClientList(apiArgHash);
    commit("getClientList", cbResponse);
  },
  async getClientSetups({ commit }, argHash) {
    const {
      componentOption,
      queryParam,
      pluginCommonAdapter,
      stbApiAdapter,
    } = argHash;
    const apiArgHash = {
      queryParam,
    };
    const cbResponse = await stbApiAdapter.getClientSetups(apiArgHash);
    commit("setComponentOptions", { cbResponse, componentOption, pluginCommonAdapter });
    commit("setAuthSchemeApiParam", cbResponse);
    commit("setClientAclRoleInferiorityHash", cbResponse);
  },
  async getParentClientList({ commit }, argHash) {
    const {
      stbApiAdapter,
      filterParam,
    } = argHash;

    const apiArgHash = {
      filterParam,
    };
    const cbResponse = await stbApiAdapter.getClientList(apiArgHash);
    if (cbResponse.data) {
      cbResponse.data.key = filterParam.s ? filterParam.s : "";
    }
    commit("getParentClientList", cbResponse);
  },
  async getClientUserClientList({ commit }, argHash) {
    const {
      stbApiAdapter,
      filterParam,
    } = argHash;

    const apiArgHash = {
      filterParam,
    };

    const cbResponse = await stbApiAdapter.getClientUserClientList(apiArgHash);
    if (cbResponse.data) {
      cbResponse.data.key = filterParam.s ? filterParam.s : "";
    }
    commit("getClientUserClientList", cbResponse);
  },
  async getMandatoryChecklist({ commit }, argHash) {
    const { stbApiAdapter, store, queryParam } = argHash;
    const frcs = [];
    if (store.state.excursion.typeId) {
      frcs.unshift([store.state.excursion.typeId]);
    }

    const apiArgHash = {
      store,
      queryParam: queryParam,
      frcs,
    };
    const response = await stbApiAdapter.getCompleteChecklist(apiArgHash);

    if (response) {
      this.optional = response;
      commit("setCeCommitOid", response.ceCommitOid);
      if (response.controls) {
        commit("initMandatoryChecklist", response.controls);
      }
    }
  },
  async postPredepartureStrategies({ commit }, argHash) {
    const {
      stbApiAdapter,
      queryParam,
      payload,
    } = argHash;
    const apiArgHash = {
      queryParam,
      payload,
    };

    if (payload) {
      const response = await stbApiAdapter.postPredepartureStrategies(apiArgHash);

      if (response) {
        commit("setPredeparture", response);
      }
    } else {
      commit("setPredeparture", []);
    }
  },
  async getClientUserList({ commit }, argHash) {
    const {
      stbApiAdapter,
      filterParam,
    } = argHash;
    const apiArgHash = {
      filterParam,
    };
    const cbResponse = await stbApiAdapter.getClientUserList(apiArgHash);
    commit("getClientUserList", cbResponse);
  },
  async getUserList({ commit }, argHash) {
    const {
      stbApiAdapter,
      filterParam,
    } = argHash;
    const apiArgHash = {
      filterParam,
    };
    const cbResponse = await stbApiAdapter.getUserList(apiArgHash);
    commit("getUserList", cbResponse);
  },
  async changeActivityRiskCategoryCompleteChecklist({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
      params,
      risk,
      resolve,
    } = argHash;

    const result = [];
    if (!risk) {
      resolve();
      return;
    }

    if (params && 0 === params.length) {
      commit("removeRiskCompleteChecklist", risk);
      resolve();
      return;
    }

    const apiArgHash = {
      store,
      queryParam,
      frcs: params,
    };
    const response = await stbApiAdapter.getCompleteChecklist(apiArgHash);
    result.push({
      checklist: response.optional
      .find(optionalContent => optionalContent.title === store.state.activitiesCategoryName),
    });
    commit("setCeCommitOid", response.ceCommitOid);

    // filter completechecklist for the risk
    commit("changeRiskCompleteChecklist", { risk, result });

    resolve();
  },
  async changeRiskCategoryCompleteChecklist({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
      params,
      risk,
      resolve,
    } = argHash;

    const result = [];
    if (params && (0 === params.length)) {
      commit("removeRiskCompleteChecklist", risk);
    }
    const apiArgHash = {
      store,
      queryParam,
      frcs: params,
    };
    const response = await stbApiAdapter.getCompleteChecklist(apiArgHash);
    result.push({
      checklist: response.optional
      .find(optionalContent => optionalContent.title === risk.name),
    });
    commit("setCeCommitOid", response.ceCommitOid);
    commit("changeRiskCompleteChecklist", { risk, result });
    resolve();
  },
  async updateExcursionPredepartureStrategies({ commit }, argHash) {
    const {
      stbApiAdapter,
      store,
      queryParam,
    } = argHash;

    const apiArgHash = {
      store,
      queryParam,
    };

    const response = await stbApiAdapter.putExcursionPredepartureStrategies(apiArgHash);
    if (response && response.data) {
      commit("setExcursionPredepartureStrategies", response.data);
    }
  },
  toggleClientUserEnabled({ commit }, userId) {
    commit("toggleClientUserEnabled", userId);
  },
  toggleUserEnabled({ commit }, userId) {
    commit("toggleUserEnabled", userId);
  },
  setActivitySelectionValid({ commit }) {
    commit("setActivitySelectionValid");
  },
  toggleClientEnabled({ commit }, sid) {
    commit("toggleClientEnabled", sid);
  },
  async unapproveExcursion({ commit }, excursionArgs) {
    const {
      stbApiAdapter,
      putExcursionStatusParams,
    } = excursionArgs;
    // trigger API request
    const { data: statusResponse }
        = await stbApiAdapter.putExcursionStatuses(putExcursionStatusParams);

    if (statusResponse && statusResponse.to) {
      commit("unapproveExcursionMutation", statusResponse.to);
    }
  },
};

import aclDomain from "./const/aclDomain";
import aclPrivilege from "./const/aclPrivilege";
import authScheme from "./const/authScheme";

export default {
  privilegeOptionHash: {
    excursionListClientColumnDisplayed: [
      aclPrivilege.DISPLAY_EXCURSION_LIST_CLIENT_COLUMN,
    ],
    excursionListFilterSectionDisplayed: [
      aclPrivilege.DISPLAY_EXCURSION_LIST_FILTER_SECTION,
    ],
    excursionListAllClientSuggestionDisplayed: [
      aclPrivilege.LIST_CLIENT,
    ],
    clientAdminSectionButtonDisplayed: [
      aclPrivilege.DISPLAY_CLIENT_ADMIN_SECTION,
    ],
    clientsTabDisplayed: [
      aclPrivilege.DISPLAY_CLIENT_MANAGEMENT,
    ],
    clientUsersTabDisplayed: [
      aclPrivilege.DISPLAY_CLIENT_USER_MANAGEMENT,
    ],
    usersTabDisplayed: [
      aclPrivilege.LIST_SYSTEM_USER,
    ],
    excursionBuilderButtonDisplayed: [
      aclPrivilege.DISPLAY_EXCURSION_BUILDER,
    ],
    excursionCreationButtonDisplayed: [
      aclPrivilege.CREATE_EXCURSION,
    ],
    systemAdminSelectionOptionDisplayed: [
      aclPrivilege.LIST_SYSTEM_ADMIN,
    ],
    clientProviderSftpPathUpdateActionEnabled: [
      aclPrivilege.UPDATE_CLIENT_PROVIDER_SFTP_PATH,
    ],
    clientUserGroupRoleDisplayed: [
      aclPrivilege.DISPLAY_CLIENT_USER_GROUP_ROLE,
    ],
    excursionSyncButtonDisplayed: [
      aclPrivilege.SYNC_EXCURSION,
    ],
    moduleCacheClearanceButtonDisplayed: [
      aclPrivilege.DELETE_RISK_CONTENT_CACHE,
    ],
    copyExcursionButtonDisplayed: [
      aclPrivilege.COPY_EXCURSION,
    ],
    createAndUpdateExcursionEnabled: [
      aclPrivilege.CREATE_EXCURSION,
      aclPrivilege.UPDATE_EXCURSION,
    ],
    excursionHistoryDisplayed: [
      aclPrivilege.DISPLAY_EXCURSION_HISTORY,
    ],
    excursionApprovalButtonDisplayed: [
      aclPrivilege.APPROVE_EXCURSION,
    ],
    excursionUnapprovalButtonDisplayed: [
      aclPrivilege.UNAPPROVE_EXCURSION,
    ],
  },
  setPrivilegeComponentOptions(state, getClientSetupResponse) {
    const computed = getClientSetupResponse.data.computed;
    const systemAcl = computed.systemAcl;
    const clientAcl = computed.clientAcl;
    const parentClientAcl = computed.parentClientAcl;

    Object.keys(this.privilegeOptionHash).forEach((privilegeKey) => {
      const privileges = this.privilegeOptionHash[privilegeKey];
      if (privileges) {
        let privilegesValid = true;
        privileges.forEach((p) => {
          let privilegeValid = false;
          if (
            (
              systemAcl
              && systemAcl[aclDomain.ANY]
              && systemAcl[aclDomain.ANY][p]
            )
            || (
              clientAcl
              && clientAcl[aclDomain.SELF]
              && clientAcl[aclDomain.SELF][p]
            )
            || (
              parentClientAcl
              && parentClientAcl[aclDomain.SELF]
              && parentClientAcl[aclDomain.SELF][p]
            )
          ) {
            privilegeValid = true;
          }

          if (!privilegeValid) {
            privilegesValid = false;
          }
        });

        state[privilegeKey] = privilegesValid;
      }
    });
  },
  setComponentOptions(state, getClientSetupResponse, pluginCommonAdapter) {
    if (getClientSetupResponse.data && getClientSetupResponse.data.computed) {
      const computed = getClientSetupResponse.data.computed;
      const optionParams = [
        "ceEnvironment",
        "clientParentId",
        "clientTimezone",
        "excursionAdditionalInformationEnabled",
        "excursionChecklistEnforcementBeforeSubmissionEnabled",
        "excursionCopyActionEnabled",
        "excursionDestinationContentEnabled",
        "excursionHighRiskStudentMedicalEnabled",
        "excursionPersonResponsibleEnabled",
        "excursionPolicyViewActionEnabled",
        "excursionInherentRiskRatingEnabled",
        "idpUrl",
        "impersonatedClientSid",
        "parentClientSiteFlag",
        "providerUpdateActionDisplayed",
        "providerUpdateActionEnabled",
        "selectedExcursionTypeLabel",
        "userClientSid",
        "userParentId",
        "vendorClientLogoEnabled",
        "vendorExcursionSidInputEnabled",
        "userDisplayName",
        "userEmail"
      ];
      optionParams.forEach((key) => {
        state[key] = computed[key] ? computed[key] : false;
      });
      state.userAuthenticationInProgress = false;

      const userIdentificationTriggered = (
        computed.userEmail
        && computed.requestAuthScheme
        && ((authScheme.USER_AUTH_SCHEME === computed.requestAuthScheme)
        || (authScheme.VENDOR_AUTH_SCHEME === computed.requestAuthScheme))
      );

      if (userIdentificationTriggered) {
        const userEmail = computed.userEmail;
        const userClientAclRole = computed.userClientAclRole ? computed.userClientAclRole : "";
        const vendorName = computed.vendorName ? computed.vendorName : "";
        const csClientId = computed.csClientId ? computed.csClientId : "";

        const trackableArgHash = {
          csClientId,
          userEmail,
          userClientAclRole,
          vendorName,
        };

        pluginCommonAdapter.initTrackableVarBatch(trackableArgHash);
        pluginCommonAdapter.identifyUser();
        pluginCommonAdapter.registerLoginEvent();
      }

      // Set user initials
      if (computed.userDisplayName) {
        const names = computed.userDisplayName.split(" ");
        if (names && (0 < names.length)) {
          const firstLetters = names.slice(0, 2).map((item) => {
            return item.substring(0, 1);
          });
          state.userInitials = firstLetters.join("").toUpperCase();
        }
      }

      // Set client logo
      if (!computed.logoAttachmentUrl) {
        state.logoAttachmentUrl = "";
      } else if (computed.logoAttachmentUrl && ("" !== computed.logoAttachmentUrl)) {
        state.logoAttachmentUrl = computed.logoAttachmentUrl;
      }

      state.clientLogoBackgroundColor = (
        computed
        && computed.clientLogoBackgroundColor
      )
        ? computed.clientLogoBackgroundColor
        : "#fafafa";

      if (computed.proformaTemplateFile && computed.proformaTemplateFile.sid) {
        state.clientTemplate = {
          sid: computed.proformaTemplateFile.sid,
          filename: computed.proformaTemplateFile.metadata.filename,
        };
      }

      this.setPrivilegeComponentOptions(state, getClientSetupResponse);
    }
  },
};

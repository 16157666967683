<template>
  <div style="width:100%;" class="provider-select">
    <v-select
      :items="providers"
      :menu-props="{ maxHeight: '400' }"
      label="Add Approved Providers"
      multiple
      class="excursion-selection"
      :deletable-chips="true"
      :small-chips="true"
      persistent-hint
      item-text="name"
      item-value="name"
      testing-id="selectedProviders"
      :id="getProviderHtmlId()"
      v-model="selectedProviderNames"
      single-line
      ref="providers"
      row
      :disabled="!formControlEnabled"
      @change="changeSelectedProvider"
    >
      <template slot="selection" slot-scope="data">
        <v-chip
          close
          @input="removeProviders(data.item)"
          :class="{
            'chip--select-multi': data.item.answer,
            'chip--select-multi light-orange-chip': !data.item.answer
          }"
        >
          {{data.item.name}}
        </v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <v-container
          align-center
          justify-start
          row
          fill-height
          :class="{
            'provider-list-item': data.item.answer,
            'provider-list-item light-orange-chip': !data.item.answer
          }"
        >
        <div>
          <v-checkbox
            :id="getProviderItemHtmlId(data.item.id)"
            :input-value="providerMenuItemSelected(data.item.name)"
          />
        </div>
         <div>
           {{ data.item.name }}
        </div>
        </v-container>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  props: ["categoryName", "formControlEnabled"],
  data() {
    return {
      selectedProviderNames: [],
      providers: [],
    };
  },
  computed: {
    providerMenuItemSelected() {
      return function (name) {
        return this.selectedProviderNames.some(provider => provider === name);
      };
    },
  },
  methods: {
    async changeRiskCategoryCompleteChecklist(tlrcName, value) {
      // tlrc stands for: top level risk category
      const frcs = [[tlrcName]];
      this.$store.state.checklistFetchActionInProgress = true;
      const argHash = {
        store: this.$store,
        queryParam: this.$route.query,
        frcs,
      };
      const response = await this.$app.stbApiAdapter.getCompleteChecklist(argHash);
      this.$store.state.ceCommitOid = response.ceCommitOid;
      this.$store.state.checklistFetchActionInProgress = false;
      const risk = this.$store.getters.getTlrc(tlrcName);

      if (risk && risk.name) {
        const result = [{
          checklist: response.optional.find(
            o => o.title === this.categoryName,
          ),
        }];

        risk.CompleteChecklist = result;
      }
    },
    getProviderHtmlId: function () {
      const id = this.categoryName ? this.categoryName.replace(/ /g, "") : "";
      return `select${id}Provider`;
    },
    removeProviders(item) {
      const index = this.selectedProviderNames.indexOf(item.name);
      if (0 <= index) {
        this.selectedProviderNames.splice(index, 1);

        for (let i = 0; i < this.providers.length; i++) {
          if (
            item.name === this.providers[i].name
            && this.providers[i].previousItem
          ) {
            this.providers.splice(i, 1);
          }
        }
      }
      this.$store.dispatch("setExcursionSelectedProviders", {
        categoryName: this.categoryName,
        selectedProviderNames: this.selectedProviderNames,
        providers: this.providers,
      });
      this.$emit("changeProviderDone");
    },
    getProviderItemHtmlId: function (itemIndex) {
      const id = this.categoryName ? this.categoryName.replace(/ /g, "") : "";
      return `select${id}Provider${itemIndex}`;
    },
    changeSelectedProvider() {
      this.$store.dispatch("setExcursionSelectedProviders", {
        categoryName: this.categoryName,
        selectedProviderNames: this.selectedProviderNames,
        providers: this.providers,
      });
      if ("Outdoor Education Providers" === this.categoryName) {
        this.changeRiskCategoryCompleteChecklist(this.categoryName, this.selectedProviderNames);
      }
      this.$emit("changeProviderDone");
    },
  },
  mounted() {
    const selectedProviders = this.$store.getters.getSelectedProvidersByCategory(this.categoryName);
    this.providers = this.$store.getters.getProvidersByCategory(this.categoryName);
    if (selectedProviders && (0 < selectedProviders.length)) {
      this.selectedProviderNames = selectedProviders.map(provider => provider.name);
    }
    this.$emit("changeProviderDone");
  },
  updated() {
    $(".provider-list-item").parent().addClass("provider-list-item-v-list__item");
  },
};
</script>

<style>
  .excursion-stepper .provider-select .v-input__control .v-input__slot {
    height: auto;
  }
</style>
